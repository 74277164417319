import { fork, takeLeading, all, call, put, delay, select, takeEvery } from 'redux-saga/effects'

import { ActionCreator } from '~/sagas/saga-action-creator'
import { getProcessInstanceState } from '~/store/processinstance'
import { ActionCreators as SetupStoreActionCreators, getSetupInstanceState } from '~/store/setupinstance'
import API from '~/API'
import * as enums from '~/typing/KENAI/enums.d.ts'
import translate from '~/translations/translate'

export const ActionCreators = {
  SagaSetupRequestOTPCode: new ActionCreator<'SagaSetupRequestOTPCode', void>('SagaSetupRequestOTPCode'),
  SagaSetupValidateOTPCode: new ActionCreator<'SagaSetupValidateOTPCode', { otpCode: string }>('SagaSetupValidateOTPCode'),
  SagaSetupSearchHost: new ActionCreator<'SagaSetupSearchHost', { searchString: string; entityHierarchy: string }>('SagaSetupSearchHost'),
  SagaSetupGetAttendeeDetailsForLocation: new ActionCreator<'SagaSetupGetAttendeeDetailsForLocation', { entityHierarchy: string }>(
    'SagaSetupGetAttendeeDetailsForLocation'
  ),
  SagaSetupCreateInvitesForEvent: new ActionCreator<'SagaSetupCreateInvitesForEvent', object>('SagaSetupCreateInvitesForEvent'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

const messages = {
  ERROR_OTP_GENERIC: {
    id: 'saga.setupProcessing.error.otp.generic',
  },
  ERROR_OTP_TOKEN: {
    id: 'saga.setupProcessing.error.otp.token',
  },
  ERROR_OTP_EXPIRED: {
    id: 'saga.setupProcessing.error.otp.expired',
  },
  ERROR_OTP_PROCESSED: {
    id: 'saga.setupProcessing.error.otp.processed',
  },
  ERROR_OTP_UNDEFINED: {
    id: 'saga.setupProcessing.error.otp.undefined',
  },
  ERROR_OTP_VAL_EXPIRED: {
    id: 'saga.setupProcessing.error.otpValidation.codeExpired',
  },
  ERROR_OTP_VAL_INCORRECT: {
    id: 'saga.setupProcessing.error.otpValidation.incorrectCode',
  },
}

const GENERIC_ERROR_MSG = translate(messages.ERROR_OTP_GENERIC)

const getOTPErrorMessage = (type): string => {
  if (type === 'GENERIC') {
    return translate(messages.ERROR_OTP_TOKEN)
  } else if (type === 'EXPIRED') {
    return translate(messages.ERROR_OTP_EXPIRED)
  } else if (type === 'PROCESSED') {
    return translate(messages.ERROR_OTP_PROCESSED)
  } else {
    return translate(messages.ERROR_OTP_UNDEFINED)
  }
}

function* processSagaSetupRequestOTPCode(action: Action, backoff?: number) {
  try {
    const processInstance = yield select(getProcessInstanceState)
    const otpDetails = yield call([API, API.requestSetupOTP], processInstance.setupToken)
    if (otpDetails.key === 'OPERATION_PROCESSED') {
      yield put(
        SetupStoreActionCreators.StoreSetupSetOTPInstanceCode.create({
          otpInstanceCode: otpDetails.instanceCode,
          otpRequestErrorMessage: undefined,
        })
      )
    } else {
      if (otpDetails.key === 'TOKEN_ERROR' && otpDetails.type === 'GENERIC') {
        yield put(
          SetupStoreActionCreators.StoreSetupSetOTPInstanceCode.create({
            otpInstanceCode: otpDetails.otpInstance,
            otpRequestErrorMessage: getOTPErrorMessage(otpDetails.type),
          })
        )
      } else {
        throw Object.assign({
          message: GENERIC_ERROR_MSG,
          code: 999,
        })
      }
    }
  } catch (e) {
    if (!backoff) {
      yield call(processSagaSetupRequestOTPCode, action, 100)
    } else {
      if (backoff && backoff > 2500) {
        let errorMessage = e && e.message ? e.message : GENERIC_ERROR_MSG
        if (errorMessage === 'Failed to fetch') {
          errorMessage = GENERIC_ERROR_MSG
        }
        yield put(
          SetupStoreActionCreators.StoreSetupSetOTPInstanceCode.create({
            otpInstanceCode: '',
            otpRequestErrorMessage: errorMessage,
          })
        )
      } else {
        yield call(delay, backoff, true)
        yield call(processSagaSetupRequestOTPCode, action, backoff * 5)
      }
    }
  }
}

function* processSagaValidateOTPCode(action: Action, backoff?: number) {
  try {
    const { otpCode } = action.payload as { otpCode: string }
    if (otpCode) {
      const processInstance = yield select(getProcessInstanceState)
      const setupInstance = yield select(getSetupInstanceState)
      const otpValidation = yield call([API, API.validateSetupOTP], processInstance.setupToken, setupInstance.otpInstanceCode, otpCode)
      if (otpValidation.key === 'OPERATION_PROCESSED') {
        yield put(
          SetupStoreActionCreators.StoreSetSetupOtpValid.create({
            message: undefined,
            type: undefined,
            eventDetails: otpValidation.eventDetails,
          })
        )
      } else {
        if (otpValidation.key === 'TOKEN_ERROR' && otpValidation.type === 'GENERIC') {
          yield put(
            SetupStoreActionCreators.StoreSetSetupOtpValid.create({
              message: getOTPErrorMessage(otpValidation.type),
              type: enums.OTP_VALIDATION_ERRORS.OTP_INCORRECT_OR_EXPIRED,
              eventDetails: undefined,
            })
          )
        } else {
          let message = GENERIC_ERROR_MSG
          if (otpValidation && otpValidation.key === 'OTP_ERROR') {
            if (otpValidation.error === 'EXPIRED') {
              message = translate(messages.ERROR_OTP_VAL_EXPIRED)
            } else if (otpValidation.error === 'INVALID_OTPCODE' || otpValidation.error === 'INVALID') {
              message = translate(messages.ERROR_OTP_VAL_INCORRECT)
            }
          }
          throw Object.assign({
            message: message,
            code: 999,
          })
        }
      }
    }
  } catch (e) {
    if (!backoff) {
      yield call(processSagaValidateOTPCode, action, 100)
    } else {
      if (backoff && backoff > 2500) {
        let errorMessage = e && e.message ? e.message : GENERIC_ERROR_MSG
        if (errorMessage === 'Failed to fetch') {
          errorMessage = GENERIC_ERROR_MSG
        }
        yield put(
          SetupStoreActionCreators.StoreSetSetupOtpValid.create({
            message: errorMessage,
            type: enums.OTP_VALIDATION_ERRORS.TECHNICAL_ERROR,
            eventDetails: undefined,
          })
        )
      } else {
        yield call(delay, backoff, true)
        yield call(processSagaValidateOTPCode, action, backoff * 5)
      }
    }
  }
  // }
}

function* processSagaSetupSearchHost(action: Action) {
  try {
    const { searchString, entityHierarchy } = action.payload as {
      searchString: string
      entityHierarchy: string
    }
    const processInstance = yield select(getProcessInstanceState)
    const setupInstance = yield select(getSetupInstanceState)
    const hostSearchResults = yield call(
      [API, API.searchSetupHost],
      processInstance.setupToken,
      setupInstance.otpInstanceCode,
      searchString,
      entityHierarchy
    )
    hostSearchResults.searchResult.push({
      EntityHierarchy: entityHierarchy,
      uniqueAttributeValue: '00000000',
      email: '00000000',
      phone_number: '00000000',
      name: 'Not on list',
    })
    if (hostSearchResults.key === 'OPERATION_PROCESSED') {
      yield put(
        SetupStoreActionCreators.StoreSetSetupHostResults.create({
          message: undefined,
          type: undefined,
          hostSearchResults: hostSearchResults.searchResult,
        })
      )
    } else {
      yield put(
        SetupStoreActionCreators.StoreSetSetupHostResults.create({
          message: GENERIC_ERROR_MSG,
          type: enums.SEARCH_SETUP_HOSTS_ERRORS.TECHNICAL_ERROR,
          hostSearchResults: undefined,
        })
      )
    }
  } catch (e) {
    let errorMessage = e && e.message ? e.message : GENERIC_ERROR_MSG
    yield put(
      SetupStoreActionCreators.StoreSetSetupHostResults.create({
        message: errorMessage,
        type: enums.SEARCH_SETUP_HOSTS_ERRORS.TECHNICAL_ERROR,
        hostSearchResults: undefined,
      })
    )
  }
}

function* processSagaSetupGetAttendeeDetailsForLocation(action: Action, backoff?: number) {
  try {
    const { entityHierarchy } = action.payload as { entityHierarchy: string }
    const processInstance = yield select(getProcessInstanceState)
    const setupInstance = yield select(getSetupInstanceState)
    const attendeeDetailsResults = yield call(
      [API, API.getSetupAttendeeDetailsForLocation],
      processInstance.setupToken,
      setupInstance.otpInstanceCode,
      entityHierarchy
    )
    if (attendeeDetailsResults.key === 'OPERATION_PROCESSED') {
      const attendeeDetails = attendeeDetailsResults.attendeeDetails.map(attendeeDetail => {
        if (attendeeDetail.shouldCreateInvite) {
          if (attendeeDetail.inviteEmailCommand === enums.ATTENDEE_EMAIL_COMMANDS.SEND) {
            attendeeDetail.inviteSendText = translate({
              id: 'global.text.send',
            })
          } else {
            if (attendeeDetail.inviteHasBeenMailed) {
              attendeeDetail.inviteSendText = translate({
                id: 'global.text.sent',
              })
            } else {
              attendeeDetail.inviteSendText = translate({
                id: 'global.text.dont-send',
              }) //should never reach here as server should send a SEND command if inviteHasBeenMailed === false
            }
          }
        } else {
          attendeeDetail.inviteSendText = 'N/A'
        }
        return attendeeDetail
      })
      yield put(
        SetupStoreActionCreators.StoreSetSetupAttendeeDetails.create({
          message: undefined,
          type: undefined,
          attendeeDetails,
        })
      )
    } else {
      yield put(
        SetupStoreActionCreators.StoreSetSetupAttendeeDetails.create({
          message: GENERIC_ERROR_MSG,
          type: enums.SETUP_GET_ATTENDEE_ERRORS.TECHNICAL_ERROR,
          attendeeDetails: undefined,
        })
      )
    }
  } catch (e) {
    if (!backoff) {
      yield call(processSagaSetupGetAttendeeDetailsForLocation, action, 100)
    } else {
      if (backoff && backoff > 2500) {
        let errorMessage = e && e.message ? e.message : GENERIC_ERROR_MSG
        if (errorMessage === 'Failed to fetch') {
          errorMessage = GENERIC_ERROR_MSG
        }
        yield put(
          SetupStoreActionCreators.StoreSetSetupAttendeeDetails.create({
            message: errorMessage,
            type: enums.SETUP_GET_ATTENDEE_ERRORS.TECHNICAL_ERROR,
            attendeeDetails: undefined,
          })
        )
      } else {
        yield call(delay, backoff, true)
        yield call(processSagaSetupGetAttendeeDetailsForLocation, action, backoff * 5)
      }
    }
  }
}

function* processSagaSetupCreateInvitesForEvent(action: Action, backoff?: number) {
  try {
    const setupProcessingDetailsSubmission = action.payload as object
    const processInstance = yield select(getProcessInstanceState)
    const setupInstance = yield select(getSetupInstanceState)
    const submissionResults = yield call(
      [API, API.submitSetupProcessingUpdate],
      processInstance.setupToken,
      setupInstance.otpInstanceCode,
      setupProcessingDetailsSubmission
    )
    if (submissionResults.key === 'OPERATION_PROCESSED') {
      yield put(
        SetupStoreActionCreators.StoreSetSetupCreateInvites.create({
          message: undefined,
          type: undefined,
        })
      )
    } else {
      yield put(
        SetupStoreActionCreators.StoreSetSetupCreateInvites.create({
          message: GENERIC_ERROR_MSG,
          type: enums.SETUP_CREATE_INVITES_ERRORS.TECHNICAL_ERROR,
        })
      )
    }
  } catch (e) {
    if (!backoff) {
      yield call(processSagaSetupCreateInvitesForEvent, action, 100)
    } else {
      if (backoff && backoff > 2500) {
        let errorMessage = e && e.message ? e.message : GENERIC_ERROR_MSG
        if (errorMessage === 'Failed to fetch') {
          errorMessage = GENERIC_ERROR_MSG
        }
        yield put(
          SetupStoreActionCreators.StoreSetSetupCreateInvites.create({
            message: errorMessage,
            type: enums.SETUP_CREATE_INVITES_ERRORS.TECHNICAL_ERROR,
          })
        )
      } else {
        yield call(delay, backoff, true)
        yield call(processSagaSetupCreateInvitesForEvent, action, backoff * 5)
      }
    }
  }
}

function* watchLookupsSagas() {
  yield takeLeading(ActionCreators.SagaSetupRequestOTPCode.type, processSagaSetupRequestOTPCode)
  yield takeLeading(ActionCreators.SagaSetupValidateOTPCode.type, processSagaValidateOTPCode)
  yield takeEvery(ActionCreators.SagaSetupSearchHost.type, processSagaSetupSearchHost)
  yield takeEvery(ActionCreators.SagaSetupGetAttendeeDetailsForLocation.type, processSagaSetupGetAttendeeDetailsForLocation)
  yield takeLeading(ActionCreators.SagaSetupCreateInvitesForEvent.type, processSagaSetupCreateInvitesForEvent)
  yield null
}

export default function* lookupsSagas() {
  yield all([fork(watchLookupsSagas)])
}

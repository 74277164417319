import getMessages, { ENUM_LOCALES } from '~/translations/messages'
import { browserLocale } from '~/utils/browserLocale'

const initI18N = () => {
  // GET AVAILABLE LOCALES
  const languageKeys: Array<string> = Object.keys(ENUM_LOCALES)

  // GET URL SEARCH STRING
  const windowSearch = window.location.search
  const urlLocal =
    windowSearch.includes('?locale=') && windowSearch.substring(windowSearch.indexOf('?locale=') + 8, windowSearch.indexOf('?locale=') + 10)

  // SET LANGUAGE
  let locale
  if (urlLocal && languageKeys.indexOf(urlLocal) > -1) {
    locale = urlLocal
  } else if (localStorage.getItem('locale') && localStorage.getItem('locale') !== null) {
    locale = localStorage.getItem('locale')
  } else if (languageKeys.includes(browserLocale())) {
    locale = browserLocale()
  } else {
    locale = languageKeys[0]
  }

  // GET MESSGES
  const messages = getMessages(locale)

  return { locale, messages }
}

export default initI18N

import getMessages from '~/translations/messages'

export function getLocalMessages() {
  let messages
  const storageMessages = localStorage.getItem('messages')
  if (storageMessages) {
    messages = JSON.parse(storageMessages)
  } else {
    const locale: any = localStorage.getItem('locale')
    if (locale) {
      messages = getMessages(locale)
    } else {
      messages = getMessages('en')
    }
  }

  return messages
}

export function getLocalMessage(id: string) {
  const messages = getLocalMessages()
  const message = messages[id]
  if (!message) {
    console.error('message for id [ ', id, ' ] not found!')
    return id as string
  }
  return message as string
}

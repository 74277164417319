import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getReturnOfExpression } from 'utility-types'
import { ThemeProvider } from 'styled-components'

import { getProcessInstanceState } from '~/store/processinstance'
import SetupPage from '~/app/components/SetupPage'
import GlobalStyle from '~/app/styles/base'

const mapStateToProps = state => {
  return {
    processinstance: getProcessInstanceState(state),
  }
}

const mapDispatchToProps = {}

const stateProps = getReturnOfExpression(mapStateToProps)

type LocalCompProps = {}

type CompProps = typeof stateProps & typeof mapDispatchToProps & LocalCompProps

type State = {}

class SetupInviteContainer extends React.Component<CompProps, State> {
  render() {
    return (
      <ThemeProvider theme={this.props.processinstance.registrationEntity}>
        <React.Fragment>
          <GlobalStyle />
          <SetupPage entityName={this.props.processinstance.registrationEntity.entityName} />
        </React.Fragment>
      </ThemeProvider>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SetupInviteContainer)
)

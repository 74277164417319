import React, { ReactNode } from 'react'
import { Animated } from 'react-animated-css'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import StyledInputAlert from '../StyledInputAlert'

const StyledAnimatedMessage = styled(Animated)`
  color: red;
  .link {
    color: rgba(0, 0, 0, 0.85);
    &.button {
      text-decoration: underline;
      font-weight: 400;
      cursor: pointer;
      &:hover {
        color: ${props => props.theme.branding.primary};
      }
    }
    &.text {
      color: red;
    }
  }
`

type Props = {
  message?: string | ReactNode
  linkSuffix?: string | ReactNode
  linkText?: string | ReactNode
  linkEvent?: () => void
  visible: boolean
}

const CustomError: React.FC<Props> = props => {
  if (props.linkEvent && props.linkEvent) {
    return (
      <StyledAnimatedMessage
        animationIn='fadeIn'
        animationOut='fadeOut'
        animateOnMount={false}
        isVisible={props.visible}
        {...props.visible === false && { style: { position: 'absolute' } }}
      >
        <StyledInputAlert
          style={{ textAlign: 'left' }}
          className={'input-error'}
          type='error'
          message={
            <span>
              {props.message}{' '}
              <span onClick={props.linkEvent} role='link'>
                {props.linkText ? props.linkText : <FormattedMessage id='form.registration.button.clickHere' />}
              </span>{' '}
              {props.linkSuffix}
            </span>
          }
          banner={true}
        />
      </StyledAnimatedMessage>
    )
  } else {
    return (
      <StyledAnimatedMessage
        animationIn='fadeIn'
        animationOut='fadeOut'
        animateOnMount={false}
        isVisible={props.visible}
        {...props.visible === false && { style: { position: 'absolute' } }}
      >
        <StyledInputAlert className={'input-error'} type='error' message={props.message} banner={true} />
      </StyledAnimatedMessage>
    )
  }
}

export default CustomError

import { fork, takeLeading, all, call, put, delay, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { ActionCreator } from '~/sagas/saga-action-creator'
import { ActionCreators as ProcessInstanceActionCreators, getProcessInstanceState } from '~/store/processinstance'
import API from '~/API'
import * as interfaces from '~/typing/KENAI/interfaces.d.ts'
import bootstrapAnalytics from '~/utils/bootstrapAnalytics'

export const ActionCreators = {
  SagaLoadRegistrationEntityDetails: new ActionCreator<'SagaLoadRegistrationEntityDetails', string>('SagaLoadRegistrationEntityDetails'),
  SagaRetrieveEntityAgreement: new ActionCreator<'SagaRetrieveEntityAgreement', string>('SagaRetrieveEntityAgreement'),
  SagaRequestEventBasedInviteToken: new ActionCreator<'SagaRequestEventBasedInviteToken', string>('SagaRequestEventBasedInviteToken'),
  SagaRequestDeviceBasedInviteToken: new ActionCreator<'SagaRequestDeviceBasedInviteToken', string>('SagaRequestDeviceBasedInviteToken'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

const messages = {
  ERROR_TITLE: {
    id: 'page.error.text.title',
  },
  ERROR_TEXT: {
    id: 'page.error.text',
  },
  ERROR_ALREADY_PROCESSED_TITLE: {
    id: 'page.error.alreadyprocessed.title',
  },
  ERROR_ALREADY_PROCESSED_TEXT: {
    id: 'page.error.alreadyprocessed',
  },
  ERROR_PROFILE_REMOVED_TEXT: {
    id: 'page.error.profileremoved',
  },
  ERROR_DEVICE_TOKEN_EXPIRED_TEXT: {
    id: 'page.error.devicetokenexpired',
  },
  ERROR_TOKEN_EXPIRED_TEXT: {
    id: 'page.error.tokenexpired',
  },
  ERROR_TOKEN_CANCELLED_TEXT: {
    id: 'page.error.eventcancelled',
  },
}

export function* processTokenError(key, type, deviceTokenBased, blockingPreviousSubmissionAlert?) {
  document.title = 'Invitation error'
  let errorMessage = messages.ERROR_TEXT
  if (type === 'BLOCKED_BY_PREVIOUS' && blockingPreviousSubmissionAlert) {
    yield put(
      ProcessInstanceActionCreators.StoreSetRegistrationTokenBlockingPreviousSubmissionAlert.create(blockingPreviousSubmissionAlert)
    )
  } else {
    if (key === 'TOKEN_ERROR' && type && type === 'PROCESSED') {
      errorMessage = messages.ERROR_ALREADY_PROCESSED_TEXT
      document.title = 'Invitation complete'
    } else if (key === 'TOKEN_ERROR' && type && type === 'PROFILE_REMOVED') {
      errorMessage = messages.ERROR_PROFILE_REMOVED_TEXT
    } else if (key === 'TOKEN_ERROR' && type && type === 'EXPIRED') {
      if (deviceTokenBased) {
        errorMessage = messages.ERROR_DEVICE_TOKEN_EXPIRED_TEXT
      } else {
        errorMessage = messages.ERROR_TOKEN_EXPIRED_TEXT
      }
    } else if (key === 'TOKEN_ERROR' && type && type === 'CANCELLED') {
      errorMessage = messages.ERROR_TOKEN_CANCELLED_TEXT
    }
    yield put(ProcessInstanceActionCreators.StoreSetRegistrationTokenError.create(errorMessage))
  }
  yield put(push('/error'))
}

function* processSagaLoadRegistrationEntityDetails(action: Action, backoff?: number) {
  try {
    const inviteToken = action.payload
    if (inviteToken) {
      const entityConfigResponse: {
        key: string
        type?: string
        blockingPreviousSubmissionAlert?: any
        configData?: interfaces.REGISTRATION_ENTITY
        deviceTokenBased?: boolean
      } = yield call([API, API.getEntityConfig], inviteToken)
      const processInstanceState = yield select(getProcessInstanceState)
      if (entityConfigResponse.key === 'OPERATION_PROCESSED' && entityConfigResponse.configData) {
        //activate email input for event based processes
        if (processInstanceState.eventToken) {
          entityConfigResponse.configData.optionalFieldsEnabled.email = true
          entityConfigResponse.configData.optionalFieldsEnabled.emailRequired = false //Make config flag based in the future
        } else if (processInstanceState.deviceToken || entityConfigResponse.configData.sourceDeviceToken) {
          entityConfigResponse.configData.optionalFieldsEnabled.email = true
          entityConfigResponse.configData.optionalFieldsEnabled.emailRequired = false //Make config flag based in the future
        } else {
          entityConfigResponse.configData.optionalFieldsEnabled.email = false
          entityConfigResponse.configData.optionalFieldsEnabled.emailRequired = false
        }
        const registrationEntity: interfaces.REGISTRATION_ENTITY = {
          ...entityConfigResponse.configData,
          hasBeenLoaded: true,
        }
        if (registrationEntity.whiteLabeled) {
          document.title = `${registrationEntity.companyName} Invitations`
        } else {
          document.title = 'Kenai Invitations'
        }
        yield put(ProcessInstanceActionCreators.StoreSetRegistrationEntity.create(registrationEntity))
      } else {
        yield call(
          processTokenError,
          entityConfigResponse.key,
          entityConfigResponse.type,
          entityConfigResponse.configData ? !!entityConfigResponse.configData.sourceDeviceToken : !!entityConfigResponse.deviceTokenBased,
          entityConfigResponse.blockingPreviousSubmissionAlert
        )
      }
    }
  } catch (e) {
    if (e && e.key && e.type) {
      if (e.key === 'TOKEN_ERROR' && e.type === 'GENERIC') {
        document.title = 'Invitation error'
        yield put(push('/error'))
      }
    }
    if (!backoff) {
      yield call(processSagaLoadRegistrationEntityDetails, action, 100)
    } else {
      if (backoff && backoff > 2500) {
        document.title = 'Invitation error'
        yield put(push('/error'))
      } else {
        yield call(delay, backoff, true)
        yield call(processSagaLoadRegistrationEntityDetails, action, backoff * 5)
      }
    }
  }
}

function* processSagaRetrieveEntityAgreement(action: Action, backoff?: number) {
  try {
    const inviteToken = action.payload
    if (inviteToken) {
      const entityAgreement = yield call([API, API.retrieveEntityAgreement], inviteToken)
      if (entityAgreement.key === 'OPERATION_PROCESSED') {
        yield put(ProcessInstanceActionCreators.StoreSetVisitorAgreement.create(entityAgreement.agreementDetails))
      } else {
        const processInstanceState = yield select(getProcessInstanceState)
        yield call(
          processTokenError,
          entityAgreement.key,
          entityAgreement.type,
          !!processInstanceState.registrationEntity.sourceDeviceToken
        )
      }
    }
  } catch (e) {
    if (e && e.key && e.type) {
      if (e.key === 'TOKEN_ERROR' && e.type === 'GENERIC') {
        yield put(push('/error'))
      }
    }
    if (!backoff) {
      yield call(processSagaLoadRegistrationEntityDetails, action, 100)
    } else {
      if (backoff && backoff > 2500) {
        yield put(push('/error'))
      } else {
        yield call(delay, backoff, true)
        yield call(processSagaLoadRegistrationEntityDetails, action, backoff * 5)
      }
    }
  }
}

function* processSagaRequestEventBasedInviteToken(action: Action, backoff?: number) {
  try {
    const eventToken = action.payload
    if (eventToken) {
      const inviteTokenRetrieval = yield call([API, API.getEventBasedInviteToken], eventToken)
      if (inviteTokenRetrieval.key === 'OPERATION_PROCESSED') {
        bootstrapAnalytics(inviteTokenRetrieval.inviteToken)
        yield put(ProcessInstanceActionCreators.StoreSetInviteToken.create(inviteTokenRetrieval.inviteToken))
      } else {
        const processInstanceState = yield select(getProcessInstanceState)
        yield call(
          processTokenError,
          inviteTokenRetrieval.key,
          inviteTokenRetrieval.type,
          !!processInstanceState.registrationEntity.sourceDeviceToken
        )
      }
    }
  } catch (e) {
    if (e && e.key && e.type) {
      if (e.key === 'TOKEN_ERROR' && e.type === 'GENERIC') {
        yield put(push('/error'))
      }
    }
    if (!backoff) {
      yield call(processSagaRequestEventBasedInviteToken, action, 100)
    } else {
      if (backoff && backoff > 2500) {
        yield put(push('/error'))
      } else {
        yield call(delay, backoff, true)
        yield call(processSagaRequestEventBasedInviteToken, action, backoff * 5)
      }
    }
  }
}

function* processSagaRequestDeviceBasedInviteToken(action: Action, backoff?: number) {
  try {
    const deviceToken = action.payload
    if (deviceToken) {
      const inviteTokenRetrieval = yield call([API, API.getDeviceBasedInviteToken], deviceToken)
      if (inviteTokenRetrieval.key === 'OPERATION_PROCESSED') {
        bootstrapAnalytics(inviteTokenRetrieval.inviteToken)
        yield put(ProcessInstanceActionCreators.StoreSetInviteToken.create(inviteTokenRetrieval.inviteToken))
      } else {
        yield call(processTokenError, inviteTokenRetrieval.key, inviteTokenRetrieval.type, true)
      }
    }
  } catch (e) {
    if (e && e.key && e.type) {
      if (e.key === 'TOKEN_ERROR' && e.type === 'GENERIC') {
        yield put(push('/error'))
      }
    }
    if (!backoff) {
      yield call(processSagaRequestDeviceBasedInviteToken, action, 100)
    } else {
      if (backoff && backoff > 2500) {
        yield put(push('/error'))
      } else {
        yield call(delay, backoff, true)
        yield call(processSagaRequestDeviceBasedInviteToken, action, backoff * 5)
      }
    }
  }
}

function* watchLookupsSagas() {
  yield takeLeading(ActionCreators.SagaLoadRegistrationEntityDetails.type, processSagaLoadRegistrationEntityDetails)
  yield takeLeading(ActionCreators.SagaRetrieveEntityAgreement.type, processSagaRetrieveEntityAgreement)
  yield takeLeading(ActionCreators.SagaRequestEventBasedInviteToken.type, processSagaRequestEventBasedInviteToken)
  yield takeLeading(ActionCreators.SagaRequestDeviceBasedInviteToken.type, processSagaRequestDeviceBasedInviteToken)
  yield null
}

export default function* lookupsSagas() {
  yield all([fork(watchLookupsSagas)])
}

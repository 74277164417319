import React from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import { FormattedMessage } from 'react-intl'
import { MESSAGE_STRUCTURE as IMessageStructure } from '~/typing/KENAI/interfaces.d.ts'

const ErrorImg = '/images/error_exclamation.svg'
const ProcessedImg = '/images/error_processed.svg'

type ViewProps = {
  className?: string
  titleText?: IMessageStructure //for provided text id
  errorMessage?: IMessageStructure //for provided text id
  providedTitle?: string //for fully resolved text from alert
  providedMessage?: string //for fully resolved text from alert
}

const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
  .landing-details {
    margin: 80px 0;
    width: 700px;
    max-width: 80vw;
    padding: 0 60px;
    * {
      /* color: white !important; */
    }
    h1 {
      font-size: 2.5rem;
    }
  }
  .error-img {
    max-width: 100%;
    width: 150px;
  }
  @media screen and (max-width: 800px) {
    .landing-details {
      margin: 20px 0;
      padding: 0 5px;
      max-width: 95vw;
    }
    h1 {
      font-size: 1.5rem !important;
      font-size: 5vw !important;
    }
  }
  @media screen and (max-width: 600px) {
    h1 {
      font-size: 7vw !important;
    }
  }
`

const ErrorPageSFC = (props: ViewProps) => {
  if (props.titleText && props.errorMessage) {
    const processed = props.titleText.id.includes('alreadyprocessed')
    return (
      <Animated isVisible={true} animationIn='bounceInDown' animationOut='bounceOutUp'>
        <ErrorContent className={props.className}>
          <img src={processed ? ProcessedImg : ErrorImg} alt={'Error'} className='error-img' />
          <div className='landing-details'>
            <h1>
              <FormattedMessage id={props.titleText.id} />
            </h1>
            <p>
              <FormattedMessage id={props.errorMessage.id} />
            </p>
          </div>
        </ErrorContent>
      </Animated>
    )
  } else if (props.providedTitle && props.providedMessage) {
    return (
      <Animated isVisible={true} animationIn='bounceInDown' animationOut='bounceOutUp'>
        <ErrorContent className={props.className}>
          <img src={ErrorImg} alt={'Error'} className='error-img' />
          <div className='landing-details'>
            <h1>{props.providedTitle}</h1>
            <p dangerouslySetInnerHTML={{ __html: props.providedMessage as string }} />
          </div>
        </ErrorContent>
      </Animated>
    )
  } else {
    return null
  }
}

export default ErrorPageSFC

import React from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import { FormattedMessage } from 'react-intl'

import * as interfaces from '~/typing/KENAI/interfaces.d.ts'
import EnterButton from '~/app/components/EnterButton'
import CornerImages from '~/app/components/CornerImages'
import StyledCheckbox from '~/app/components/StyledCheckBox'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

const LandingContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  .landing-details {
    margin: 80px 0;
    max-width: 600;
    padding: 0 60px;
  }
  .start-button {
    height: 40px;
  }
  @media screen and (max-width: 500px) {
    .landing-details {
      margin: 20px 0;
      padding: 0 5px;
    }
    h1 {
      font-size: 1.5rem;
    }
  }
  .touch & {
    .start-button {
      width: 100vw;
      position: fixed;
      bottom: 40px;
      left: 0;
      height: 40px;
      button {
        width: 100vw;
        border-radius: 0;
        height: 80px;
      }
      .landing-details {
        margin: 20px 0 80px 0;
      }
    }
    .btn-enter-text {
      display: none;
    }
  }
`

type ViewProps = {
  onRegistrationClicked: () => void
  onVisitorAgreementChecked: (checkboxChangeEvent: CheckboxChangeEvent) => void
  onVisitorAgreementTextClicked: () => void
  onOpenAgreementModal: () => void
  registrationEntity: interfaces.REGISTRATION_ENTITY
  images: interfaces.CornerImages
  className?: string
  agreementChecked: boolean
}
export const WelcomePageSFC: React.FC<ViewProps> = props => {
  return (
    <LandingContent className={props.className}>
      <CornerImages showBottomImages={true} images={props.images} />
      <Animated animationIn='bounceInDown' animationOut='bounceOutUp' isVisible={true}>
        <img src={props.registrationEntity.logo} alt={props.registrationEntity.entityName} style={{ width: '400px', maxWidth: '50%' }} />
        <div className='landing-details'>
          <h1>
            <FormattedMessage id='page.welcome.title' />
          </h1>
          <p>
            <StyledCheckbox
              checked={props.agreementChecked}
              onChange={props.agreementChecked ? props.onVisitorAgreementChecked : props.onOpenAgreementModal}
            />{' '}
            <span onClick={props.onOpenAgreementModal}>
              <FormattedMessage
                id='page.welcome.subtitle'
                values={{
                  AGREEMENT_TEXT: (
                    <span onClick={props.onOpenAgreementModal} role='link'>
                      <FormattedMessage id='page.welcome.subtitle.agreement' />
                    </span>
                  ),
                }}
              />
            </span>
          </p>
        </div>
        <div className='start-button'>
          {props.agreementChecked && (
            <Animated animationIn='bounceInUp' animationOut='bounceOutDown' isVisible={true}>
              <EnterButton size='large' onClick={props.onRegistrationClicked}>
                <FormattedMessage id='page.welcome.button' />
              </EnterButton>
            </Animated>
          )}
        </div>
      </Animated>
    </LandingContent>
  )
}

export default WelcomePageSFC

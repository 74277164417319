import { all, fork } from "redux-saga/effects";
import registrationMetaData from "./registrationmetadata";
import registrationProcessing from "./registrationprocessing";
import setupProcessing from "./setupprocessing";

export default function* root() {
  yield all([
    fork(registrationMetaData),
    fork(registrationProcessing),
    fork(setupProcessing)
  ]);
}

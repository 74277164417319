// import ReactFullpage from "~/app/utility/FullPage/"
import ReactFullpage from '@fullpage/react-fullpage'
import CornerImages from '~/app/components/CornerImages'
import { hasGetUserMedia } from '~/app/utility/Webcam'
import { ActionCreators as RegistrationProcessingActionCreators } from '~/sagas/registrationprocessing'
import { getProcessInstanceState } from '~/store/processinstance'
import { ActionCreators as RegistrationStoreActionCreators, getRegistrationInstanceState } from '~/store/registrationinstance'
import * as enums from '~/typing/KENAI/enums.d.ts'
import { ExtendedWrappedFormUtils } from '~/typing/vendor/antd-form-untyped-public'
import constants from '~/utils/constants'
import getEventTiming from '~/utils/getEventTiming'
import {
  Button,
  notification,
  Progress,
  // Modal
} from 'antd'
import { push } from 'connected-react-router'
import update from 'immutability-helper'
import { parsePhoneNumberFromString } from 'libphonenumber-js/core'
import defaultMetadata from 'libphonenumber-js/metadata.min.json'
import { chain, forOwn, omit, isArray } from 'lodash'
import React from 'react'
import { isIE, isIOS, isMobile, isMobileSafari } from 'react-device-detect'
import { connect } from 'react-redux'
import { getReturnOfExpression } from 'utility-types'
import PageLoadingIndicator from '../PageLoadingIndicator'
import CustomForm from './subComponents/CustomForm'
import SubmissionFooter from './subComponents/SubmissionFooter'
import { CheckInField } from '~/typing/KENAI/interfaces'
import { DataSourceItemObject } from 'antd/lib/auto-complete'
import { SelectValue } from 'antd/lib/select'
import { injectIntl } from 'react-intl'
import filterProcessor from '~/utils/filterProcessor'

const messages = {
  SUCCESS_MESSAGE: {
    id: 'form.registration.faceValidation.successMSG',
  },
  SUCCESS_TEXT: {
    id: 'form.registration.faceValidation.successTEXT',
  },
  SUCCESS: {
    id: 'form.registration.faceValidation.success',
  },
}

const lookup = async () => {
  try {
    const res = await fetch('https://ipinfo.kenai.co.za')
    if (!res.ok) throw new Error('ip fetch api error')
    const data = await res.json()
    if (data && data.country_code) {
      return data.country_code
    } else {
      throw new Error('ip fetch api response missing country code')
    }
  } catch (e) {
    console.error(e)
    return 'ZA'
  }
}

const mapStateToProps = state => {
  return {
    processinstance: getProcessInstanceState(state),
    registrationinstance: getRegistrationInstanceState(state),
  }
}

const mapDispatchToProps = {
  SagaRequestOTPCode: RegistrationProcessingActionCreators.SagaRequestOTPCode.create,
  SagaValidateOTPCode: RegistrationProcessingActionCreators.SagaValidateOTPCode.create,
  SagaValidateFace: RegistrationProcessingActionCreators.SagaValidateFace.create,
  SagaSubmitProfile: RegistrationProcessingActionCreators.SagaSubmitProfile.create,
  SagaSubmitUpdatePostRegistrationCompletion: RegistrationProcessingActionCreators.SagaSubmitUpdatePostRegistrationCompletion.create,
  SagaConfirmLocalProfile: RegistrationProcessingActionCreators.SagaConfirmLocalProfile.create,
  SagaRegistrationSearchHost: RegistrationProcessingActionCreators.SagaRegistrationSearchHost.create,
  StoreSetOTPInstanceCode: RegistrationStoreActionCreators.StoreSetOTPInstanceCode.create,
  push: push,
}

const stateProps = getReturnOfExpression(mapStateToProps)

type LocalCompProps = {
  className?: string
  intl: any
}

type CompProps = typeof stateProps & typeof mapDispatchToProps & LocalCompProps

export interface FlowControl {
  inviteCreationFieldsControl: {
    hasChangedPhoneNumber: boolean
  }
  otpRequest: {
    otpRequesTimeStamp: number
    otpRequestSuccessfull: boolean
    otpRequestErrorMessage: string | undefined
    otpRequestInProcess: boolean
    country: string
  }
  otpValidation: {
    otpValidationTimeStamp: number
    otpValidationSuccessfull: boolean
    otpValidationErrorMessage: string | undefined
    otpValidationErrorType: enums.OTP_VALIDATION_ERRORS | undefined
    otpValidationInProcess: boolean
  }
  parking: {
    parkingProcessingEnabled: boolean
    parkingProcessingSkipped: boolean
    parkingTermsAccepted: boolean
  }
  faceData: {
    faceValidationTimeStamp: number
    faceValidationSuccessfull: boolean
    faceValidationErrorMessage: string | undefined
    faceValidationErrorType: enums.FACE_VALIDATION_ERRORS | undefined
    faceValidationInProcess: boolean
    faceDataProcessingEnabled: boolean
    faceDataProcessingSkipped: boolean
    faceCaptureType: enums.FACE_CAPTURE_TYPE
  }
  inductionVideo: {
    inductionVideoCompleted: boolean
    inductionVideoSkipped: boolean
    confirmInductionSkipVisible: boolean
  }
  inductionQuestions: {
    inductionQuestionsCompleted: boolean
    inductionQuestionsSkipped: boolean
    confirmInductionSkipVisible: boolean
    inductionQuestionsProcessingEnabled: boolean
  }
  profileSubmission: {
    submitEnabledState: boolean
    profileSubmissionInProcess: boolean
    profileSubmissionTimeStamp: number
    profileSubmissionSuccessfull: boolean
    profileSubmissionErrorMessage: string | undefined
    profileSubmissionErrorType: enums.PROFILE_SUBMISSION_ERRORS | undefined
    profileSubmissionTriggeredAlert?: {
      blockFurtherProcessing: boolean
      headerText: string
      bodyText: string
    }
    profileSubmissionAccessPassDetails?: object
  }
  globalProfileConfirmation: {
    globalProfileConfirmationVisible: boolean
    shouldArchiveExisting: boolean
    hasExistingLocalProfile: boolean
    globalFirstName: string
    globalLastName: string
    hasGlobalProfile: boolean
    localProfileConfirmed: boolean
    globalProfileConfirmed: boolean
    isConfirmingLocalProfile: boolean
  }
  postInitialFlowSelection: {
    induction: boolean
    parking: boolean
    checkin: boolean
    postFlowSelectionComplete: boolean
    postFlowUserCanSelect: boolean
  }
  hostSearchRequest: {
    hostSearchTimeStamp: number
    hostSearchSuccessfull: boolean
    hostSearchErrorMessage: string | undefined
    hostSearchErrorType: enums.SEARCH_SETUP_HOSTS_ERRORS | undefined
    hostSearchInProcess: boolean
    hostSearchSecondaryInProcess: boolean
  }
  email: {
    emailSkipped: boolean
  }
  rebuildFormTimeStamp: number
  slideEnterTimeStamp: number
  isCameraEnabled: boolean
}

export type FormValue = {
  value: string | string[]
  isValid: boolean
  data?: object
}

export interface FormValues {
  phoneNumber: FormValue
  otpCode: FormValue
  firstName: FormValue
  lastName: FormValue
  email: FormValue
  company: FormValue
  personalIdentificationNr: FormValue
  dietaryRequirements: FormValue
  faceData: FormValue
  selectedHost: FormValue
  [key: string]: FormValue
}
export interface DataSources {
  hosts: Array<DataSourceItemObject & { sourceObject: object }>
}

type State = {
  formValues: FormValues
  flowControl: FlowControl
  debug: boolean
  dataSources: DataSources
}

// const debug = window.location.search.includes("?debug");
// const isLocalhost = Boolean(
//   window.location.hostname === "localhost" ||
//     // [::1] is the IPv6 localhost address.
//     window.location.hostname === "[::1]" ||
//     // 127.0.0.1/8 is considered localhost for IPv4.
//     window.location.hostname.match(
//       /^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
//     )
// );

const debug = false
const userPostFlowSelectionEnabled = false //if needs be get this from config
// isLocalhost && false;

const maskPhoneNumber = (phoneNumber: string) => {
  let maskedNumber = phoneNumber.substr(0, 4)
  let maskChars = phoneNumber.length - 8 //first 4 chars (incl +) and last 4 chars
  for (let i = 0; i < maskChars; i++) {
    maskedNumber += '*'
  }
  maskedNumber += phoneNumber.substr(phoneNumber.length - 4, 4)
  return maskedNumber
}

class RegistrationForm extends React.Component<CompProps, State> {
  formInputs: object = {}
  formRef: ExtendedWrappedFormUtils | any
  phoneNumberRef: any
  fullPageRef: any
  // formItems: Array<> = []
  constructor(props) {
    super(props)
    const isCameraEnabled =
      isIE ||
      (isIOS && !isMobileSafari) ||
      !hasGetUserMedia() ||
      props.processinstance.registrationEntity.inviteOnlyFieldEnablement.faceCapture === false
        ? false
        : true
    this.state = {
      formValues: {
        phoneNumber: {
          value: debug
            ? '+27828512563'
            : props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.isPostInitialRegistration
            ? maskPhoneNumber(props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.tokenPhoneNumber)
            : props.processinstance.registrationEntity.inviteCreationFields.invitePhoneNumber
            ? maskPhoneNumber(props.processinstance.registrationEntity.inviteCreationFields.invitePhoneNumber)
            : '', //@override - ""
          isValid: debug
            ? true
            : props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.isPostInitialRegistration
            ? true
            : props.processinstance.registrationEntity.inviteCreationFields.invitePhoneNumber
            ? true
            : false, //@override - false
        },
        otpCode: {
          value: debug ? '0000' : '', //@override - ""
          isValid: debug ? true : false, //@override - false
        },
        firstName: {
          value: '',
          isValid: false,
        },
        lastName: {
          value: '',
          isValid: false,
        },
        email: {
          value: '',
          isValid: false,
        },
        company: {
          value: '',
          isValid: false,
        },
        personalIdentificationNr: {
          value: '',
          isValid: false,
        },
        dietaryRequirements: {
          value: '',
          isValid: props.processinstance.registrationEntity.inviteOnlyFieldEnablement.dietaryRequirements
            ? props.processinstance.registrationEntity.inviteOnlyFieldEnablement.dietaryRequirements
            : false, //optional invite only field - always valid
        },
        faceData: {
          value: '',
          isValid: false,
        },
        selectedHost: {
          value: '',
          isValid: false,
          object: undefined,
        },
        ...(this.props.processinstance.registrationEntity.parkingDetails.parkingAvailable && {
          ...chain(
            this.props.processinstance.registrationEntity.parkingDetails.captureFields.map(field => ({
              fieldTechnicalName: field.fieldTechnicalName,
              value: '',
              isValid: false,
            }))
          )
            .keyBy('fieldTechnicalName')
            .mapValues(formVal => omit(formVal, 'fieldTechnicalName'))
            .value(),
        }),
        ...(this.props.processinstance.registrationEntity.parkingDetails.parkingAvailable &&
          this.props.processinstance.registrationEntity.parkingDetails.availableClassifications &&
          this.props.processinstance.registrationEntity.parkingDetails.availableClassifications.length && {
            preferredParkingClassification: {
              value: '',
              isValid: false,
            },
          }),
        ...(this.props.processinstance.registrationEntity.checkInFieldDetails.checkInFieldsAvailable && {
          ...chain(
            this.props.processinstance.registrationEntity.checkInFieldDetails.captureFields.map(field => ({
              fieldTechnicalName: field.id,
              value: undefined,
              isValid: false,
            }))
          )
            .keyBy('fieldTechnicalName')
            .mapValues(formVal => omit(formVal, 'fieldTechnicalName'))
            .value(),
        }),
      },
      flowControl: {
        inviteCreationFieldsControl: {
          hasChangedPhoneNumber: false,
        },
        otpRequest: {
          otpRequesTimeStamp: 0, //@override - 0
          otpRequestSuccessfull: debug ? true : false, //@override - false
          otpRequestErrorMessage: undefined,
          otpRequestInProcess: false,
          country: debug ? 'ZA' : '',
        },
        otpValidation: {
          otpValidationTimeStamp: 0, //@override - 0
          otpValidationSuccessfull: debug ? true : false, //@override - false
          otpValidationErrorMessage: undefined,
          otpValidationErrorType: undefined,
          otpValidationInProcess: false,
        },
        parking: {
          parkingProcessingEnabled: false,
          parkingProcessingSkipped: false,
          parkingTermsAccepted: false,
        },
        faceData: {
          faceValidationTimeStamp: 0,
          faceValidationSuccessfull: false,
          faceValidationErrorMessage: undefined,
          faceValidationErrorType: undefined,
          faceValidationInProcess: false,
          faceDataProcessingEnabled: false,
          faceDataProcessingSkipped: !isCameraEnabled,
          faceCaptureType: enums.FACE_CAPTURE_TYPE.NONE,
        },
        inductionVideo: {
          inductionVideoCompleted: false,
          inductionVideoSkipped: false,
          confirmInductionSkipVisible: false,
        },
        inductionQuestions: {
          inductionQuestionsCompleted: false,
          inductionQuestionsSkipped: false,
          confirmInductionSkipVisible: false,
          inductionQuestionsProcessingEnabled: false,
        },
        profileSubmission: {
          submitEnabledState: false,
          profileSubmissionInProcess: false,
          profileSubmissionTimeStamp: 0,
          profileSubmissionSuccessfull: false,
          profileSubmissionErrorMessage: undefined,
          profileSubmissionErrorType: undefined,
        },
        globalProfileConfirmation: {
          globalProfileConfirmationVisible: false,
          shouldArchiveExisting: false,
          hasExistingLocalProfile: false,
          globalFirstName: '',
          globalLastName: '',
          hasGlobalProfile: false,
          localProfileConfirmed: false,
          globalProfileConfirmed: false,
          isConfirmingLocalProfile: false,
        },
        postInitialFlowSelection: {
          induction: props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.completeInduction.available,
          parking: props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.updateParking.available,
          checkin: props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.captureCheckInFields.available,
          postFlowSelectionComplete: !userPostFlowSelectionEnabled, //false with postFlowUserCanSelect = true. true with postFlowUserCanSelect = false we skip step 3 and lead directly into processing available flows
          postFlowUserCanSelect: userPostFlowSelectionEnabled, //in future this can come from config
        },
        hostSearchRequest: {
          hostSearchTimeStamp: 0,
          hostSearchSuccessfull: false,
          hostSearchErrorMessage: undefined,
          hostSearchErrorType: undefined,
          hostSearchInProcess: false,
          hostSearchSecondaryInProcess: false,
        },
        email: {
          emailSkipped: false,
        },
        rebuildFormTimeStamp: 0,
        slideEnterTimeStamp: 0,
        isCameraEnabled,
      },
      dataSources: {
        hosts: [],
      },
      debug: debug,
    }
  }

  async componentDidMount() {
    let country = 'ZA'
    if (!!this.props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.isPostInitialRegistration) {
      setTimeout(this.addPostRegistrationFlowPhoneEventListener, 0)
      const phoneNumber = parsePhoneNumberFromString(
        this.props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.tokenPhoneNumber,
        defaultMetadata as any
      )
      if (phoneNumber && phoneNumber.country) {
        country = phoneNumber.country
      }
    } else if (!!this.props.processinstance.registrationEntity.inviteCreationFields.invitePhoneNumber) {
      const phoneNumber = parsePhoneNumberFromString(
        this.props.processinstance.registrationEntity.inviteCreationFields.invitePhoneNumber,
        defaultMetadata as any
      )
      if (phoneNumber && phoneNumber.country) {
        country = phoneNumber.country
      }
    } else {
      country = await lookup()
    }
    this.setState(
      update(this.state, {
        flowControl: {
          otpRequest: {
            otpRequesTimeStamp: {
              $set: debug ? Date.now() : this.props.registrationinstance.otpRequestTimeStamp,
            },
            country: {
              $set: country,
            },
          },
          otpValidation: {
            otpValidationTimeStamp: {
              $set: debug ? Date.now() : this.props.registrationinstance.otpValidationTimeStamp,
            },
          },
          faceData: {
            faceValidationTimeStamp: {
              $set: this.props.registrationinstance.faceValidationTimeStamp,
            },
          },
        },
      })
    )
  }

  // tslint:disable-next-line:member-ordering
  static getDerivedStateFromProps(nextProps: CompProps, prevState: State) {
    if (
      nextProps.registrationinstance.otpRequestTimeStamp !== prevState.flowControl.otpRequest.otpRequesTimeStamp &&
      prevState.flowControl.otpRequest.otpRequestInProcess === true
    ) {
      return {
        flowControl: {
          ...prevState.flowControl,
          otpRequest: {
            ...prevState.flowControl.otpRequest,
            otpRequesTimeStamp: nextProps.registrationinstance.otpRequestTimeStamp,
            otpRequestSuccessfull: nextProps.registrationinstance.otpRequestErrorMessage === undefined,
            otpRequestErrorMessage: nextProps.registrationinstance.otpRequestErrorMessage,
            otpRequestInProcess: false,
          },
        },
      }
    } else if (
      nextProps.registrationinstance.otpValidationTimeStamp !== prevState.flowControl.otpValidation.otpValidationTimeStamp &&
      prevState.flowControl.otpValidation.otpValidationInProcess === true
    ) {
      const otpValidationSuccessfull = nextProps.registrationinstance.otpValidationErrorMessage === undefined
      const hasGlobalProfile =
        nextProps.registrationinstance.otpValidationGlobalFirstName.length > 0 ||
        nextProps.registrationinstance.otpValidationGlobalLastName.length > 0
      const otpValidationHasValidInduction = nextProps.registrationinstance.otpValidationHasValidInduction === true
      let prePopulatedFields = {}
      if (otpValidationSuccessfull) {
        forOwn(nextProps.registrationinstance.otpValidationPrePopulatedFieldValues, (fieldValue, key) => {
          let isValid = fieldValue.length > 0 //TODO: should validate this - for now these fields are all length > 0
          let value = fieldValue
          if (
            nextProps.processinstance.registrationEntity.checkInFieldDetails.checkInFieldsAvailable &&
            nextProps.processinstance.registrationEntity.checkInFieldDetails.captureFields
          ) {
            const checkInFieldDef = nextProps.processinstance.registrationEntity.checkInFieldDetails.captureFields.find(
              captureFields => captureFields.id === key
            )
            if (checkInFieldDef && checkInFieldDef.inputSettings.inputType === 'staticDropDownListMultiSelect' && fieldValue) {
              value = fieldValue.split(';')
            }
          }
          prePopulatedFields[key] = {
            isValid,
            value,
          }
        })
      }

      // debug-point
      return {
        flowControl: {
          ...prevState.flowControl,
          otpValidation: {
            ...prevState.flowControl.otpValidation,
            otpValidationTimeStamp: nextProps.registrationinstance.otpValidationTimeStamp,
            otpValidationSuccessfull: otpValidationSuccessfull,
            otpValidationErrorMessage: nextProps.registrationinstance.otpValidationErrorMessage,
            otpValidationErrorType: nextProps.registrationinstance.otpValidationErrorType,
            otpValidationInProcess: false,
          },
          globalProfileConfirmation: {
            globalProfileConfirmationVisible: hasGlobalProfile,
            globalProfileConfirmed: false,
            localProfileConfirmed: false,
            shouldArchiveExisting: false,
            hasExistingLocalProfile: nextProps.registrationinstance.otpValidationHasExistingLocalProfile,
            globalFirstName: nextProps.registrationinstance.otpValidationGlobalFirstName,
            globalLastName: nextProps.registrationinstance.otpValidationGlobalLastName,
            hasGlobalProfile: hasGlobalProfile,
          },
          // ...(nextProps.processinstance.registrationEntity.parkingDetails && {
          //   parking: nextProps.processinstance.registrationEntity.parkingDetails
          // }),
          ...(otpValidationSuccessfull &&
            !hasGlobalProfile && {
              rebuildFormTimeStamp: Date.now(),
            }),
          ...(otpValidationHasValidInduction &&
            nextProps.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.isPostInitialRegistration && {
              postInitialFlowSelection: {
                ...prevState.flowControl.postInitialFlowSelection,
                induction: false,
              },
            }),
          ...(nextProps.registrationinstance.otpValidationHasActiveParkingBookingOnThisToken && {
            parking: {
              ...prevState.flowControl.parking,
              parkingProcessingEnabled: true,
              parkingTermsAccepted: true,
            },
          }),
        },
        ...(otpValidationSuccessfull && {
          formValues: {
            ...prevState.formValues,
            ...prePopulatedFields,
          },
        }),
      }
    } else if (
      nextProps.registrationinstance.faceValidationTimeStamp !== prevState.flowControl.faceData.faceValidationTimeStamp &&
      prevState.flowControl.faceData.faceValidationInProcess === true
    ) {
      return {
        flowControl: {
          ...prevState.flowControl,
          faceData: {
            ...prevState.flowControl.faceData,
            faceValidationTimeStamp: nextProps.registrationinstance.faceValidationTimeStamp,
            faceValidationSuccessfull: nextProps.registrationinstance.faceValidationErrorMessage === undefined,
            faceValidationErrorMessage: nextProps.registrationinstance.faceValidationErrorMessage,
            faceValidationErrorType: nextProps.registrationinstance.faceValidationErrorType,
            faceValidationInProcess: false,
          },
        },
      }
    } else if (
      nextProps.registrationinstance.profileSubmissionTimeStamp !== prevState.flowControl.profileSubmission.profileSubmissionTimeStamp &&
      prevState.flowControl.profileSubmission.profileSubmissionInProcess === true
    ) {
      return {
        flowControl: {
          ...prevState.flowControl,
          profileSubmission: {
            ...prevState.flowControl.profileSubmission,
            profileSubmissionTimeStamp: nextProps.registrationinstance.profileSubmissionTimeStamp,
            profileSubmissionSuccessfull: nextProps.registrationinstance.profileSubmissionErrorMessage === undefined,
            profileSubmissionErrorMessage: nextProps.registrationinstance.profileSubmissionErrorMessage,
            profileSubmissionErrorType: nextProps.registrationinstance.profileSubmissionErrorType,
            profileSubmissionInProcess: false,
            profileSubmissionTriggeredAlert: nextProps.registrationinstance.profileSubmissionTriggeredAlert,
            profileSubmissionAccessPassDetails: nextProps.registrationinstance.profileSubmissionAccessPassDetails,
          },
        },
      }
    } else if (
      nextProps.registrationinstance.localProfileConfirmed !== prevState.flowControl.globalProfileConfirmation.localProfileConfirmed
    ) {
      const otpValidationHasValidInduction = nextProps.registrationinstance.otpValidationHasValidInduction === true
      let prePopulatedFields = {}
      forOwn(nextProps.registrationinstance.otpValidationPrePopulatedFieldValues, (fieldValue, key) => {
        let isValid = fieldValue.length > 0 //TODO: should validate this - for now these fields are all length > 0
        let value = fieldValue
        if (
          nextProps.processinstance.registrationEntity.checkInFieldDetails.checkInFieldsAvailable &&
          nextProps.processinstance.registrationEntity.checkInFieldDetails.captureFields
        ) {
          const checkInFieldDef = nextProps.processinstance.registrationEntity.checkInFieldDetails.captureFields.find(
            captureFields => captureFields.id === key
          )
          if (checkInFieldDef && checkInFieldDef.inputSettings.inputType === 'staticDropDownListMultiSelect' && fieldValue) {
            value = fieldValue.split(';')
          }
        }
        prePopulatedFields[key] = {
          isValid,
          value,
        }
      })
      return {
        flowControl: {
          ...prevState.flowControl,
          globalProfileConfirmation: {
            ...prevState.flowControl.globalProfileConfirmation,
            globalProfileConfirmationVisible: false,
            localProfileConfirmed: nextProps.registrationinstance.localProfileConfirmed,
            isConfirmingLocalProfile: false,
          },
          rebuildFormTimeStamp: Date.now(),
          postInitialFlowSelection: {
            ...prevState.flowControl.postInitialFlowSelection,
            postFlowSelectionComplete: true,
            postFlowUserCanSelect: false,
            parking: nextProps.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.updateParking.available,
            checkin: nextProps.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.captureCheckInFields.available,
            induction: otpValidationHasValidInduction
              ? false
              : nextProps.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.completeInduction.available,
          },
        },
        formValues: {
          ...prevState.formValues,
          ...prePopulatedFields,
        },
      }
    } else {
      return null
    }
  }
  componentDidUpdate(prevProps: CompProps, prevState: State) {
    let stateUpdate: any = {}
    let hasStateUpdate = false
    if (this.fullPageRef && this.fullPageRef.moveSectionDown && this.fullPageRef.getActiveSection()) {
      let scrollSection = false
      const activeSection = this.fullPageRef.getActiveSection()
      if (activeSection.anchor) {
        const fieldName = activeSection.anchor.replace('invite-', '')
        if (
          prevProps.registrationinstance.otpRequestTimeStamp !== this.props.registrationinstance.otpRequestTimeStamp &&
          this.state.flowControl.otpRequest.otpRequestSuccessfull === true &&
          fieldName === 'phoneNumber' &&
          !this.props.processinstance.registrationEntity.sourceDeviceTokenUnverifiedProcessing
        ) {
          scrollSection = true
        } else if (
          prevProps.registrationinstance.otpValidationTimeStamp !== this.props.registrationinstance.otpValidationTimeStamp &&
          this.state.flowControl.otpValidation.otpValidationSuccessfull === true &&
          (!this.state.flowControl.globalProfileConfirmation.hasGlobalProfile ||
            (this.state.flowControl.globalProfileConfirmation.hasGlobalProfile &&
              this.state.flowControl.globalProfileConfirmation.globalProfileConfirmed) ||
            this.state.flowControl.globalProfileConfirmation.localProfileConfirmed) &&
          ((!this.props.processinstance.registrationEntity.sourceDeviceTokenUnverifiedProcessing && fieldName === 'otpCode') ||
            (this.props.processinstance.registrationEntity.sourceDeviceTokenUnverifiedProcessing && fieldName === 'phoneNumber'))
        ) {
          scrollSection = true
        } else if (prevProps.registrationinstance.faceValidationTimeStamp !== this.props.registrationinstance.faceValidationTimeStamp) {
          hasStateUpdate = true
          stateUpdate = {
            ...stateUpdate,
            formValues: {
              faceData: {
                isValid: {
                  $set: this.state.flowControl.faceData.faceValidationSuccessfull,
                },
                ...(this.state.flowControl.faceData.faceCaptureType === enums.FACE_CAPTURE_TYPE.UPLOAD && {
                  value: {
                    $set: this.props.registrationinstance.faceB64 as string,
                  },
                }),
              },
            },
          }
          if (this.props.registrationinstance.faceValidationErrorMessage) {
            notification.error({
              message: 'Oops!',
              description: this.props.registrationinstance.faceValidationErrorMessage,
            })
          } else {
            const { formatMessage } = this.props.intl
            let SUCCESS_MESSAGE = formatMessage(messages.SUCCESS_MESSAGE)

            if (this.state.flowControl.faceData.faceCaptureType === enums.FACE_CAPTURE_TYPE.CAMERA) {
              SUCCESS_MESSAGE += formatMessage(messages.SUCCESS_TEXT)
            }
            notification.success({
              message: formatMessage(messages.SUCCESS),
              description: SUCCESS_MESSAGE,
            })
          }
        }
        if (this.getSubmitEnabled()) {
          // TODO: handle special cases for returning flows e.g. only enable submit on last item on screen etc
          if (!this.state.flowControl.profileSubmission.submitEnabledState) {
            let preSubmitEnabledChecksPassed = this.performPreSubmitEnabledChecks()
            if (preSubmitEnabledChecksPassed) {
              hasStateUpdate = true
              stateUpdate = {
                ...stateUpdate,
                flowControl: {
                  ...(stateUpdate.flowControl && {
                    ...stateUpdate.flowControl,
                  }),
                  profileSubmission: {
                    ...(stateUpdate.flowControl &&
                      stateUpdate.flowControl.profileSubmission && {
                        ...stateUpdate.flowControl.profileSubmission,
                      }),
                    submitEnabledState: {
                      $set: true,
                    },
                  },
                },
              }
              const bottomLeftBrand = document.getElementById('bottom-left-branding')
              const bottomRightBrand = document.getElementById('bottom-right-branding')
              if (bottomLeftBrand && bottomLeftBrand.classList && bottomLeftBrand.classList.contains('bottom-margin') === false) {
                bottomLeftBrand.classList.add('bottom-margin')
              }
              if (bottomRightBrand && bottomRightBrand.classList && bottomRightBrand.classList.contains('bottom-margin') === false) {
                bottomRightBrand.classList.add('bottom-margin')
              }
            }
          } else {
            let preSubmitEnabledChecksPassed = this.performPreSubmitEnabledChecks()
            if (!preSubmitEnabledChecksPassed) {
              hasStateUpdate = true
              stateUpdate = {
                ...stateUpdate,
                flowControl: {
                  ...(stateUpdate.flowControl && {
                    ...stateUpdate.flowControl,
                  }),
                  profileSubmission: {
                    ...(stateUpdate.flowControl &&
                      stateUpdate.flowControl.profileSubmission && {
                        ...stateUpdate.flowControl.profileSubmission,
                      }),
                    submitEnabledState: {
                      $set: false,
                    },
                  },
                },
              }
            }
          }
        } else {
          if (this.state.flowControl.profileSubmission.submitEnabledState) {
            hasStateUpdate = true
            stateUpdate = {
              ...stateUpdate,
              flowControl: {
                ...(stateUpdate.flowControl && {
                  ...stateUpdate.flowControl,
                }),
                profileSubmission: {
                  ...(stateUpdate.flowControl &&
                    stateUpdate.flowControl.profileSubmission && {
                      ...stateUpdate.flowControl.profileSubmission,
                    }),
                  submitEnabledState: {
                    $set: false,
                  },
                },
              },
            }
            const bottomLeftBrand = document.getElementById('bottom-left-branding')
            const bottomRightBrand = document.getElementById('bottom-right-branding')
            if (bottomLeftBrand && bottomLeftBrand.classList && bottomLeftBrand.classList.contains('bottom-margin')) {
              bottomLeftBrand.classList.remove('bottom-margin')
            }
            if (bottomRightBrand && bottomRightBrand.classList && bottomRightBrand.classList.contains('bottom-margin')) {
              bottomRightBrand.classList.remove('bottom-margin')
            }
          }
        }

        if (
          prevProps.registrationinstance.profileSubmissionTimeStamp !== this.state.flowControl.profileSubmission.profileSubmissionTimeStamp
        ) {
          if (this.state.flowControl.profileSubmission.profileSubmissionSuccessfull) {
            if (this.state.flowControl.profileSubmission.profileSubmissionTriggeredAlert) {
              if (this.state.flowControl.profileSubmission.profileSubmissionTriggeredAlert.blockFurtherProcessing) {
                this.props.push('/error')
              } else {
                this.props.push('/confirmation')
              }
            } else if (this.props.processinstance.registrationEntity.sourceDeviceToken) {
              if (this.state.flowControl.profileSubmission.profileSubmissionAccessPassDetails) {
                this.props.push('/accesspass')
              } else {
                this.props.push('/confirmation')
              }
            } else {
              this.props.push('/confirmation')
            }
          } else {
            if (
              this.state.flowControl.profileSubmission.profileSubmissionErrorType === enums.PROFILE_SUBMISSION_ERRORS.PROFILE_ALREADY_EXISTS
            ) {
              if (this.state.flowControl.profileSubmission.profileSubmissionAccessPassDetails) {
                this.props.push('/accesspass')
              } else {
                this.props.push('/confirmation')
              }
            } else if (
              this.state.flowControl.profileSubmission.profileSubmissionErrorType === enums.PROFILE_SUBMISSION_ERRORS.GENERIC_ERROR
            ) {
              notification.error({
                message: 'Oops!',
                description: this.state.flowControl.profileSubmission.profileSubmissionErrorMessage,
              })
            }
          }
        }

        const postStateUpdate = () => {
          if (
            prevState.flowControl.globalProfileConfirmation.globalProfileConfirmationVisible !==
            this.state.flowControl.globalProfileConfirmation.globalProfileConfirmationVisible
          ) {
            if (this.state.flowControl.globalProfileConfirmation.globalProfileConfirmationVisible === true) {
              // this._onGlobalNotMe()
            } else if (this.state.flowControl.globalProfileConfirmation.globalProfileConfirmationVisible === false) {
              scrollSection = true
            }
          }

          if (
            prevState.flowControl.parking.parkingProcessingSkipped !== this.state.flowControl.parking.parkingProcessingSkipped &&
            this.state.flowControl.parking.parkingProcessingSkipped === true
          ) {
            scrollSection = true
          }

          if (
            prevState.flowControl.parking.parkingProcessingEnabled !== this.state.flowControl.parking.parkingProcessingEnabled &&
            this.state.flowControl.parking.parkingProcessingEnabled === true
          ) {
            scrollSection = true
          }

          if (
            prevState.flowControl.parking.parkingTermsAccepted !== this.state.flowControl.parking.parkingTermsAccepted &&
            this.state.flowControl.parking.parkingTermsAccepted === true
          ) {
            scrollSection = true
          }

          if (prevState.flowControl.rebuildFormTimeStamp !== this.state.flowControl.rebuildFormTimeStamp) {
            setTimeout(() => {
              this.fullPageRef.reBuild()
              if (scrollSection) {
                this.fullPageRef.moveSectionDown()
              }
            }, 700) //TODO - OTP bug - possible error location e.g. something happens user side prior to this timeout firing
          } else {
            if (scrollSection) {
              this.fullPageRef.moveSectionDown()
            }
          }
        }
        if (hasStateUpdate) {
          this.setState(update(this.state, stateUpdate), () => postStateUpdate())
        } else {
          postStateUpdate()
        }
      } else {
        console.log('ERROR: NO ACTIVE SLIDE AT THIS POINT')
      }
    }
  }

  requestOTP = (channelReceived: 'sms' | 'whatsapp' = 'sms') => {
    let phoneNumberToUse = this.state.formValues.phoneNumber.value
    if (this.props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.isPostInitialRegistration) {
      if (phoneNumberToUse.indexOf('*') > -1) {
        phoneNumberToUse = this.props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.tokenPhoneNumber
      }
    } else if (
      this.props.processinstance.registrationEntity.inviteCreationFields.invitePhoneNumber &&
      !this.state.flowControl.inviteCreationFieldsControl.hasChangedPhoneNumber
    ) {
      phoneNumberToUse = this.props.processinstance.registrationEntity.inviteCreationFields.invitePhoneNumber
    }
    if (phoneNumberToUse.length > 0) {
      if (this.props.processinstance.registrationEntity.sourceDeviceTokenUnverifiedProcessing) {
        this.setState(
          update(this.state, {
            flowControl: {
              otpRequest: {
                otpRequestInProcess: {
                  $set: false,
                },
                otpRequestSuccessfull: { $set: true },
              },
              otpValidation: {
                otpValidationSuccessfull: { $set: true },
                otpValidationErrorMessage: { $set: undefined },
                otpValidationErrorType: { $set: undefined },
                otpValidationInProcess: { $set: false },
              },
              globalProfileConfirmation: {
                globalProfileConfirmationVisible: { $set: false },
                globalProfileConfirmed: { $set: false },
                localProfileConfirmed: { $set: false },
                isConfirmingLocalProfile: { $set: false },
                shouldArchiveExisting: { $set: false },
                hasExistingLocalProfile: { $set: false },
                globalFirstName: { $set: '' },
                globalLastName: { $set: '' },
                hasGlobalProfile: { $set: false },
              },
            },
            formValues: {
              otpCode: {
                value: {
                  $set: '0000',
                },
                isValid: {
                  $set: true,
                },
              },
            },
          }),
          () => {
            this.fullPageRef.moveSectionDown()
            this.props.StoreSetOTPInstanceCode({
              otpInstanceCode: '9999999999999',
              otpRequestErrorMessage: undefined,
            })
          }
        )
      } else {
        if (!this.props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.isPostInitialRegistration) {
          let instance = this.formRef.getFieldInstance('phoneNumber')
          if (instance) {
            try {
              instance.input.input.number_input.input.input.blur()
            } catch (e) {
              console.log(e)
              try {
                instance.input.input.number_input.input.input.input.blur()
              } catch (einner) {
                console.log(e)
              }
            }
          }
        }
        this.setState(
          update(this.state, {
            flowControl: {
              otpRequest: {
                otpRequestInProcess: {
                  $set: true,
                },
              },
              otpValidation: {
                otpValidationSuccessfull: { $set: false },
                otpValidationErrorMessage: { $set: undefined },
                otpValidationErrorType: { $set: undefined },
                otpValidationInProcess: { $set: false },
              },
              globalProfileConfirmation: {
                globalProfileConfirmationVisible: { $set: false },
                globalProfileConfirmed: { $set: false },
                localProfileConfirmed: { $set: false },
                isConfirmingLocalProfile: { $set: false },
                shouldArchiveExisting: { $set: false },
                hasExistingLocalProfile: { $set: false },
                globalFirstName: { $set: '' },
                globalLastName: { $set: '' },
                hasGlobalProfile: { $set: false },
              },
            },
            formValues: {
              otpCode: {
                value: {
                  $set: '',
                },
                isValid: {
                  $set: false,
                },
              },
            },
          }),
          () => {
            this.props.SagaRequestOTPCode({
              phoneNumber: phoneNumberToUse as string,
              channel: typeof channelReceived === 'string' ? channelReceived : 'sms',
            })
          }
        )
      }
    } else {
      //form validation has failed - consider triggering error notification from here if this is found to be possible
    }
  }

  validateOTP = () => {
    let phoneNumberToUse = this.state.formValues.phoneNumber.value
    if (this.props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.isPostInitialRegistration) {
      if (phoneNumberToUse.indexOf('*') > -1) {
        phoneNumberToUse = this.props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.tokenPhoneNumber
      }
    } else if (
      this.props.processinstance.registrationEntity.inviteCreationFields.invitePhoneNumber &&
      !this.state.flowControl.inviteCreationFieldsControl.hasChangedPhoneNumber
    ) {
      phoneNumberToUse = this.props.processinstance.registrationEntity.inviteCreationFields.invitePhoneNumber
    }
    if (phoneNumberToUse.length > 0) {
      this.setState(
        update(this.state, {
          flowControl: {
            otpValidation: {
              otpValidationInProcess: {
                $set: true,
              },
              otpValidationSuccessfull: { $set: false },
              otpValidationErrorMessage: { $set: undefined },
              otpValidationErrorType: { $set: undefined },
            },
            globalProfileConfirmation: {
              globalProfileConfirmationVisible: { $set: false },
              globalProfileConfirmed: { $set: false },
              localProfileConfirmed: { $set: false },
              isConfirmingLocalProfile: { $set: false },
              shouldArchiveExisting: { $set: false },
              hasExistingLocalProfile: { $set: false },
              globalFirstName: { $set: '' },
              globalLastName: { $set: '' },
              hasGlobalProfile: { $set: false },
            },
          },
        }),
        () => {
          this.props.SagaValidateOTPCode({
            phoneNumber: phoneNumberToUse as string,
            otpCode: this.state.formValues.otpCode.value as string,
          })
          if (
            isMobile &&
            !this.props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.isPostInitialRegistration &&
            !this.props.processinstance.registrationEntity.sourceDeviceTokenUnverifiedProcessing
          ) {
            let instance = this.formRef.getFieldInstance('otpCode')
            if (instance) {
              try {
                instance.blur()
              } catch (e) {
                console.log(e)
              }
            }
          }
        }
      )
    } else {
      //form validation has failed - consider triggering error notification from here if this is found to be possible
    }
  }

  _setFormRef = (formRef: any) => {
    this.formRef = formRef as ExtendedWrappedFormUtils
  }

  handleAfterSlide = (origin, destination, direction) => {
    //TODO - move this same code to enter handler
    if (origin && origin.anchor) {
      const fieldName = origin.anchor.replace('invite-', '')
      if (this.formRef) {
        let instance = this.formRef.getFieldInstance(fieldName)
        if (instance && instance.blur) {
          if (
            document &&
            document.activeElement &&
            ((instance.input && document.activeElement === instance.input) ||
              (instance.rcSelect && instance.rcSelect.inputRef && document.activeElement === instance.rcSelect.inputRef))
          ) {
            instance.blur()
          }
        }
      }
    }
    if (destination && destination.anchor && !isMobile) {
      const fieldName = destination.anchor.replace('invite-', '')
      if (this.formRef) {
        let instance = this.formRef.getFieldInstance(fieldName)
        if (instance && instance.focus) {
          instance.focus()
        }
      }
    }
    this.setState(
      update(this.state, {
        flowControl: {
          slideEnterTimeStamp: {
            $set: Date.now(),
          },
        },
      })
    )
  }

  postRegistrationFlowPhoneEventListener = event => {
    if (event.keyCode === 13) {
      this.requestOTP()
    }
  }

  addInductionEventListener = () => {
    window.addEventListener('keypress', this.inductionEventListener, false)
  }

  removeInductionEventListener = () => {
    window.removeEventListener('keypress', this.inductionEventListener, false)
  }

  addPostRegistrationFlowPhoneEventListener = () => {
    window.addEventListener('keypress', this.postRegistrationFlowPhoneEventListener, false)
  }

  removePostRegistrationFlowPhoneEventListener = () => {
    window.removeEventListener('keypress', this.postRegistrationFlowPhoneEventListener, false)
  }

  handleSlideLeave = (origin, destination, direction) => {
    if (origin && origin.anchor) {
      const fieldName = origin.anchor.replace('invite-', '')
      if (fieldName === 'induction' && this.state.flowControl.inductionQuestions.inductionQuestionsProcessingEnabled) {
        return false
      } else if (
        fieldName === 'induction' &&
        direction === 'down' &&
        this.state &&
        this.state.flowControl.inductionVideo.inductionVideoCompleted === false &&
        this.state.flowControl.inductionVideo.inductionVideoSkipped === false &&
        this.state.flowControl.inductionQuestions.inductionQuestionsCompleted === false &&
        this.state.flowControl.inductionQuestions.inductionQuestionsSkipped === false &&
        (this.props.registrationinstance.otpValidationHasValidInduction !== true ||
          (this.props.registrationinstance.otpValidationHasValidInduction === true &&
            this.state.flowControl.globalProfileConfirmation.globalProfileConfirmed === true &&
            this.state.flowControl.globalProfileConfirmation.shouldArchiveExisting === true))
      ) {
        this.setState(
          update(this.state, {
            flowControl: {
              inductionVideo: { confirmInductionSkipVisible: { $set: true } },
            },
          })
        )
        return false
      } else {
        this.removeInductionEventListener()
        this.removePostRegistrationFlowPhoneEventListener()

        if (destination && destination.anchor) {
          const destFieldName = destination.anchor.replace('invite-', '')
          if (destFieldName === 'induction') {
            setTimeout(this.addInductionEventListener, 0) //push to end so as to not catch the enter key on previous field
          } else if (
            destFieldName === 'phoneNumber' &&
            !!this.props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.isPostInitialRegistration
          ) {
            setTimeout(this.addPostRegistrationFlowPhoneEventListener, 0) //TODO - OTP bug - possible other canditate
          }
        }

        return true
      }
    } else {
      console.log('no origin or anchor', { origin, destination, direction })
      return true
    }
  }

  _onFieldsChange = (changedFields: object): void => {
    const formValuesUpdates: any = {}
    const flowControlUpdates: any = {}
    let isDirtyValues = false
    let isDirtyFlow = false
    let hasSelectedSingleUnqiueSDDLGroupItem = false
    forOwn(changedFields, (changeDetails, formValue) => {
      if (changeDetails.validating === false || !changeDetails.hasOwnProperty('validating')) {
        isDirtyValues = true
        let isValid = true
        if (changeDetails.errors && changeDetails.errors.length > 0) {
          isValid = false
        }
        let value = changeDetails.value
        if (
          isArray(value) && //multi select
          this.props.processinstance.registrationEntity.checkInFieldDetails.checkInFieldsAvailable &&
          this.props.processinstance.registrationEntity.checkInFieldDetails.captureFields
        ) {
          let newItemKey: string | undefined = undefined
          const existingSelections =
            this.state.formValues[formValue] && this.state.formValues[formValue].value ? this.state.formValues[formValue].value : []
          if (existingSelections) {
            value.forEach(newPart => {
              if ((existingSelections as string[]).findIndex(existingSelection => existingSelection === newPart) === -1) {
                newItemKey = newPart
              }
            })
            if (newItemKey) {
              const checkInField = this.props.processinstance.registrationEntity.checkInFieldDetails.captureFields.find(
                captureField => captureField.id === formValue
              )
              if (checkInField) {
                const newItemDef = checkInField.staticDropDownListItems!.find(
                  staticDropDownListItem => staticDropDownListItem.dataKey === newItemKey
                )
                if (checkInField && newItemDef) {
                  value = value.filter(newPart => {
                    if (newItemKey === newPart) return true
                    const existingItemDef = checkInField.staticDropDownListItems!.find(
                      staticDropDownListItem => staticDropDownListItem.dataKey === newPart
                    )
                    if (!existingItemDef) return false
                    if (newItemDef.itemGroupings) {
                      //new item has groupings - check whether existing item overlaps
                      if (!existingItemDef.itemGroupings) return false
                      let hasOverlap = false
                      newItemDef.itemGroupings.forEach(itemGrouping => {
                        if (existingItemDef.itemGroupings!.indexOf(itemGrouping) > -1) {
                          hasOverlap = true
                        }
                      })
                      return hasOverlap
                    } else if (existingItemDef.itemGroupings && existingItemDef.itemGroupings.length > 0) {
                      //existing item has a grouping and new item does not - this implies existing item should be removed as there is no overlap
                      return false
                    } else {
                      return true //no groupings
                    }
                  })
                }
                hasSelectedSingleUnqiueSDDLGroupItem = true //default to true and then set to false in conditions
                if (newItemDef && newItemDef.itemGroupings) {
                  if (newItemDef.itemGroupings.length === 1) {
                    const newItemDefSingleGroup = newItemDef.itemGroupings[0]
                    checkInField.staticDropDownListItems!.forEach(staticDropDownListItem => {
                      if (staticDropDownListItem.dataKey !== newItemDef.dataKey && staticDropDownListItem.itemGroupings) {
                        staticDropDownListItem.itemGroupings.forEach(itemGroup => {
                          if (newItemDefSingleGroup === itemGroup) {
                            hasSelectedSingleUnqiueSDDLGroupItem = false
                          }
                        })
                      }
                    })
                  } else {
                    hasSelectedSingleUnqiueSDDLGroupItem = false
                  }
                } else if (newItemDef) {
                  checkInField.staticDropDownListItems!.forEach(staticDropDownListItem => {
                    if (!staticDropDownListItem.itemGroupings) {
                      hasSelectedSingleUnqiueSDDLGroupItem = false
                    }
                  })
                }
              }
            }
          }
        }
        // let capValue = value
        //this is not consistent on mobile -leave it to the user
        // if (document.activeElement) {
        //   const autoCapSettings = document.activeElement['autocapitalize']
        //   if (autoCapSettings && value && value.length) {
        //     if (autoCapSettings === 'characters') {
        //       capValue = value.toUpperCase()
        //     } else if (autoCapSettings === 'words') {
        //       const wordParts = value.split(' ')
        //       const capParts: string[] = []
        //       wordParts.forEach(part => {
        //         capParts.push(`${part.slice(0, 1).toUpperCase()}${part.slice(1, part.length)}`)
        //       })
        //       capValue = capParts.join(' ')
        //     } else if (autoCapSettings === 'sentences') {
        //       const sentanceParts = value.split('.')
        //       const capParts: string[] = []
        //       sentanceParts.forEach(part => {
        //         if (part.slice(0, 1) !== ' ') {
        //           capParts.push(`${part.slice(0, 1).toUpperCase()}${part.slice(1, part.length)}`)
        //         } else {
        //           capParts.push(` ${part.slice(1, 2).toUpperCase()}${part.slice(2, part.length)}`)
        //         }
        //       })
        //       capValue = capParts.join('.')
        //     }
        //   }
        // }
        formValuesUpdates[formValue] = {
          $set: {
            ...this.state.formValues[formValue],
            // value: capValue,
            value,
            isValid,
          },
        }
        if (changeDetails.name === 'phoneNumber') {
          isDirtyFlow = true
          if (!this.state.flowControl.inviteCreationFieldsControl.hasChangedPhoneNumber) {
            flowControlUpdates['inviteCreationFieldsControl'] = {
              hasChangedPhoneNumber: {
                $set: true,
              },
            }
            formValuesUpdates['phoneNumber'] = {
              $set: {
                isValid: false,
                value: '',
              },
            }
          }
          flowControlUpdates['otpRequest'] = {
            otpRequestSuccessfull: {
              $set: false,
            },
            otpRequestErrorMessage: {
              $set: undefined,
            },
          }
          formValuesUpdates['otpCode'] = {
            $set: {
              isValid: false,
              value: '',
            },
          }
          flowControlUpdates['otpValidation'] = {
            otpValidationSuccessfull: { $set: false },
            otpValidationErrorMessage: { $set: undefined },
            otpValidationErrorType: { $set: undefined },
            otpValidationInProcess: { $set: false },
          }
          if (this.state.flowControl.otpValidation.otpValidationSuccessfull) {
            flowControlUpdates['rebuildFormTimeStamp'] = { $set: Date.now() }
          }
        } else if (changeDetails.name === 'otpCode') {
          // formValuesUpdates["otpCode"] = {
          //   $set: {
          //     isValid: changeDetails.isValid,
          //     value: changeDetails.value,
          //   },
          // }
          // console.log("otp should update")
          isDirtyFlow = true
          flowControlUpdates['otpValidation'] = {
            otpValidationSuccessfull: { $set: false },
            otpValidationErrorMessage: { $set: undefined },
            otpValidationErrorType: { $set: undefined },
            otpValidationInProcess: { $set: false },
          }
        } else if (changeDetails.name === 'email') {
          // if (capValue && capValue.length) {
          //   flowControlUpdates['email'] = {
          //     emailSkipped: { $set: false },
          //   }
          // }
          if (value && value.length) {
            flowControlUpdates['email'] = {
              emailSkipped: { $set: false },
            }
          }
        }
      }
    })
    if (isDirtyValues || isDirtyFlow) {
      this.setState(
        update(this.state, {
          ...(isDirtyValues
            ? {
                formValues: {
                  ...formValuesUpdates,
                },
              }
            : {}),
          ...(isDirtyFlow
            ? {
                flowControl: {
                  ...flowControlUpdates,
                },
              }
            : {}),
        }),
        () => {
          //handle check in field select
          if (
            this.props.processinstance.registrationEntity.checkInFieldDetails &&
            this.props.processinstance.registrationEntity.checkInFieldDetails.checkInFieldsAvailable
          ) {
            const changedFieldsKeys = Object.keys(changedFields)
            if (changedFieldsKeys && !!changedFieldsKeys.length) {
              const changeFieldKey = changedFieldsKeys[0]
              if (this.state.formValues[changeFieldKey].isValid === true) {
                const checkInField = this.props.processinstance.registrationEntity.checkInFieldDetails.captureFields.find(
                  checkInField => checkInField.id === changeFieldKey
                )
                if (checkInField && checkInField.inputSettings.inputType === 'staticDropDownListSingleSelect') {
                  //this is a check in field select and it is valid - nav to next input
                  setTimeout(() => {
                    this.fullPageRef.moveSectionDown()
                    this._processOnLastFieldCheck()
                  }, 100) //give the blur a chance to process
                } else if (
                  checkInField &&
                  checkInField.inputSettings.inputType === 'staticDropDownListMultiSelect' &&
                  hasSelectedSingleUnqiueSDDLGroupItem
                ) {
                  //this is a check in field multi select and it is valid and user has selected a single group item - nav to next input
                  const fieldName = this.fullPageRef.getActiveSection().anchor.replace('invite-', '')
                  if (this.formRef) {
                    let instance = this.formRef.getFieldInstance(fieldName)
                    if (instance && instance.blur) {
                      if (
                        document &&
                        document.activeElement &&
                        ((instance.input && document.activeElement === instance.input) ||
                          (instance.rcSelect && instance.rcSelect.inputRef && document.activeElement === instance.rcSelect.inputRef))
                      ) {
                        instance.blur()
                      }
                    }
                    setTimeout(() => {
                      this.fullPageRef.moveSectionDown()
                      this._processOnLastFieldCheck()
                    }, 100) //give the blur a chance to process
                  }
                }
              }
            }
          }
        }
      )
    }
  }

  _onPhoneNumberChange = (): void => {
    this._onFieldsChange({
      phoneNumber: {
        validating: false,
        errors: [],
        name: 'phoneNumber',
      },
    })
  }

  _onGlobalNotMe = (): void => {
    this.setState(
      update(this.state, {
        flowControl: {
          globalProfileConfirmation: {
            globalProfileConfirmationVisible: {
              $set: false,
            },
            globalProfileConfirmed: {
              $set: true,
            },
            localProfileConfirmed: {
              $set: false,
            },
            isConfirmingLocalProfile: {
              $set: false,
            },
            shouldArchiveExisting: {
              $set: true,
            },
            globalFirstName: {
              $set: '',
            },
            globalLastName: {
              $set: '',
            },
            hasGlobalProfile: {
              $set: false,
            },
            hasExistingLocalProfile: {
              $set: false,
            },
          },
          rebuildFormTimeStamp: {
            $set: Date.now(),
          },
        },
        formValues: {
          firstName: {
            $set: {
              value: '',
              isValid: false,
            },
          },
          lastName: {
            $set: {
              value: '',
              isValid: false,
            },
          },
          email: {
            $set: {
              value: '',
              isValid: false,
            },
          },
          company: {
            $set: {
              value: '',
              isValid: false,
            },
          },
          personalIdentificationNr: {
            $set: {
              value: '',
              isValid: false,
            },
          },
          dietaryRequirements: {
            $set: {
              value: '',
              isValid: false,
            },
          },
          faceData: {
            $set: {
              value: '',
              isValid: false,
            },
          },
          ...(this.props.processinstance.registrationEntity.parkingDetails.parkingAvailable && {
            ...chain(
              this.props.processinstance.registrationEntity.parkingDetails.captureFields.map(field => ({
                fieldTechnicalName: field.fieldTechnicalName,
                $set: {
                  value: '',
                  isValid: false,
                },
              }))
            )
              .keyBy('fieldTechnicalName')
              .mapValues(formVal => omit(formVal, 'fieldTechnicalName'))
              .value(),
          }),
          ...(this.props.processinstance.registrationEntity.parkingDetails.parkingAvailable &&
            this.props.processinstance.registrationEntity.parkingDetails.availableClassifications &&
            this.props.processinstance.registrationEntity.parkingDetails.availableClassifications.length && {
              preferredParkingClassification: {
                $set: {
                  value: '',
                  isValid: false,
                },
              },
            }),
        },
      })
    )
  }

  _onGlobalConfirmed = (): void => {
    if (this.state.flowControl.globalProfileConfirmation.hasExistingLocalProfile) {
      this.setState(
        update(this.state, {
          flowControl: {
            globalProfileConfirmation: {
              isConfirmingLocalProfile: {
                $set: true,
              },
            },
          },
        }),
        () => {
          this.props.SagaConfirmLocalProfile()
        }
      )
    } else {
      this.setState(
        update(this.state, {
          flowControl: {
            globalProfileConfirmation: {
              globalProfileConfirmationVisible: {
                $set: false,
              },
              globalProfileConfirmed: {
                $set: true,
              },
              shouldArchiveExisting: {
                $set: false,
              },
            },
            rebuildFormTimeStamp: {
              $set: Date.now(),
            },
          },
          formValues: {
            firstName: {
              value: {
                $set: this.state.flowControl.globalProfileConfirmation.globalFirstName,
              },
              isValid: {
                $set: true,
              },
            },
            lastName: {
              value: {
                $set: this.state.flowControl.globalProfileConfirmation.globalLastName,
              },
              isValid: {
                $set: true,
              },
            },
          },
        })
      )
    }
  }

  _submitProfile = (): void => {
    if (debug) {
      console.log("Can't submit profile when debugging")
    } else if (
      !this.props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.isPostInitialRegistration //if induction is completed on post flow then dont disable submission, update when we add parking update
    ) {
      this.setState(
        update(this.state, {
          flowControl: {
            profileSubmission: {
              profileSubmissionInProcess: {
                $set: true,
              },
            },
          },
        }),
        () => {
          const eventTiming = getEventTiming()
          this.props.SagaSubmitProfile({
            firstName: this.state.formValues.firstName.value as string,
            lastName: this.state.formValues.lastName.value as string,
            ...(!this.state.flowControl.email.emailSkipped
              ? { email: this.state.formValues.email.value as string }
              : this.props.processinstance.registrationEntity.optionalFieldsEnabled.email
              ? { email: `${this.props.processinstance.inviteToken}@nomail.kenai.co.za` } //for email enabled but skipped generate a nomail address
              : { email: '' }),
            company: this.state.formValues.company.value as string,
            submittedWithFace:
              !this.state.flowControl.faceData.faceDataProcessingSkipped &&
              this.state.formValues.faceData.isValid === true &&
              this.state.formValues.faceData.value.length > 0,
            archiveExisting: this.state.flowControl.globalProfileConfirmation.shouldArchiveExisting,
            inviteOnlyFieldValues: {
              ...(this.props.processinstance.registrationEntity.inviteOnlyFieldEnablement.dietaryRequirements && {
                dietaryRequirements: this.state.formValues.dietaryRequirements.value as string,
              }),
            },
            creationEventTiming: eventTiming,
            ...(this.props.processinstance.registrationEntity.optionalFieldsEnabled.personalIdentificationNr
              ? {
                  personalIdentificationNr: this.state.formValues.personalIdentificationNr.value as string,
                }
              : {}),
            ...(this.props.processinstance.registrationEntity.parkingDetails.parkingAvailable &&
              !this.state.flowControl.parking.parkingProcessingSkipped && {
                parkingData: {
                  eventTiming: eventTiming,
                  ...chain(this.props.processinstance.registrationEntity.parkingDetails.captureFields)
                    .keyBy('fieldTechnicalName')
                    .mapValues(value => this.state.formValues[value.fieldTechnicalName].value as string)
                    .value(),
                  ...(this.state.formValues.preferredParkingClassification && {
                    preferredParkingClassification: this.state.formValues.preferredParkingClassification.value,
                  }),
                },
              }),
            ...((this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionVideo ||
              this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions) &&
            (this.props.registrationinstance.otpValidationHasValidInduction !== true ||
              (this.props.registrationinstance.otpValidationHasValidInduction === true &&
                this.state.flowControl.globalProfileConfirmation.globalProfileConfirmed === true &&
                this.state.flowControl.globalProfileConfirmation.shouldArchiveExisting === true))
              ? {
                  inductionData: {
                    eventTiming: eventTiming,
                    inductionType: 'preRegistration',
                    version: this.props.processinstance.registrationEntity.inductionContent.version,
                    completionStatus:
                      (this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionVideo &&
                        !this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions &&
                        this.state.flowControl.inductionVideo.inductionVideoCompleted) ||
                      (!this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionVideo &&
                        this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions &&
                        this.state.flowControl.inductionQuestions.inductionQuestionsCompleted) ||
                      (this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionVideo &&
                        this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions &&
                        this.state.flowControl.inductionVideo.inductionVideoCompleted &&
                        this.state.flowControl.inductionQuestions.inductionQuestionsCompleted)
                        ? 'completed'
                        : (this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionVideo &&
                            !this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions &&
                            this.state.flowControl.inductionVideo.inductionVideoSkipped) ||
                          (!this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionVideo &&
                            this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions &&
                            this.state.flowControl.inductionQuestions.inductionQuestionsSkipped) ||
                          (this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionVideo &&
                            this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions &&
                            (this.state.flowControl.inductionVideo.inductionVideoSkipped ||
                              this.state.flowControl.inductionQuestions.inductionQuestionsSkipped))
                        ? 'skipped'
                        : 'unknown',
                  },
                }
              : {}),
            ...(this.props.processinstance.registrationEntity.checkInFieldDetails.checkInFieldsAvailable && {
              checkInFieldValues: {
                eventTiming: eventTiming,
                ...chain(
                  this.props.processinstance.registrationEntity.checkInFieldDetails.captureFields.map(field => {
                    return {
                      fieldTechnicalName: field.id,
                      value: this.state.formValues[field.id].value,
                      includeInSubmissionIfNotVisible: field.includeInSubmissionIfNotVisible,
                      availableForConditionInput: field.availableForConditionInput,
                    }
                  })
                )
                  .filter(entry => {
                    let shouldInclude = true
                    if (typeof entry.value !== 'undefined' && `${entry.value}`.length > 0) {
                      const rootCondition = this.props.processinstance.registrationEntity.checkInFieldDetails.fieldConditions[
                        entry.fieldTechnicalName
                      ]
                      if (rootCondition) {
                        const { isConditionalVisible } = filterProcessor(rootCondition, this.state.formValues)
                        if (!isConditionalVisible && !entry.includeInSubmissionIfNotVisible) {
                          shouldInclude = false //don't return a field that is invisible
                        }
                      } else if (entry.availableForConditionInput) {
                        shouldInclude = false //condition input fields are never visible and never have own conditions - they should not be incliuded in the invite submission
                      }
                    } else {
                      shouldInclude = false //exclude zero length fields
                    }
                    return shouldInclude
                  })
                  .keyBy('fieldTechnicalName')
                  .mapValues(formVal => {
                    if (isArray(formVal.value)) {
                      return formVal.value.join(';') as string
                    } else {
                      return formVal.value as string
                    }
                  })
                  .value(),
              },
            }),
            ...(this.props.processinstance.registrationEntity.optionalFieldsEnabled.hostSearch && {
              selectedHost: this.state.formValues.selectedHost.data,
            }),
            ...(this.props.processinstance.registrationEntity.sourceDeviceTokenUnverifiedProcessing && {
              phoneNumber: this.state.formValues.phoneNumber.value as string,
            }),
          })
        }
      )
    } else {
      //post flow - handle parking or induction state update
      this.setState(
        update(this.state, {
          flowControl: {
            profileSubmission: {
              profileSubmissionInProcess: {
                $set: true,
              },
            },
          },
        }),
        () => {
          const eventTiming = getEventTiming()
          const submission: any = {}
          if (
            this.props.processinstance.registrationEntity.parkingDetails.parkingAvailable &&
            !this.state.flowControl.parking.parkingProcessingSkipped &&
            this.state.flowControl.postInitialFlowSelection.parking
          ) {
            submission.parkingData = {
              eventTiming: eventTiming,
              ...chain(this.props.processinstance.registrationEntity.parkingDetails.captureFields)
                .keyBy('fieldTechnicalName')
                .mapValues(value => this.state.formValues[value.fieldTechnicalName].value)
                .value(),
              ...(this.state.formValues.preferredParkingClassification && {
                preferredParkingClassification: this.state.formValues.preferredParkingClassification.value,
              }),
            }
          }
          if (
            (this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionVideo ||
              this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions) &&
            (this.props.registrationinstance.otpValidationHasValidInduction !== true ||
              (this.props.registrationinstance.otpValidationHasValidInduction === true &&
                this.state.flowControl.globalProfileConfirmation.globalProfileConfirmed === true &&
                this.state.flowControl.globalProfileConfirmation.shouldArchiveExisting === true)) &&
            this.state.flowControl.postInitialFlowSelection.induction
          ) {
            submission.inductionData = {
              eventTiming: eventTiming,
              inductionType: 'preRegistration',
              version: this.props.processinstance.registrationEntity.inductionContent.version,
              completionStatus:
                (this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionVideo &&
                  !this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions &&
                  this.state.flowControl.inductionVideo.inductionVideoCompleted) ||
                (!this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionVideo &&
                  this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions &&
                  this.state.flowControl.inductionQuestions.inductionQuestionsCompleted) ||
                (this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionVideo &&
                  this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions &&
                  this.state.flowControl.inductionVideo.inductionVideoCompleted &&
                  this.state.flowControl.inductionQuestions.inductionQuestionsCompleted)
                  ? 'completed'
                  : (this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionVideo &&
                      !this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions &&
                      this.state.flowControl.inductionVideo.inductionVideoSkipped) ||
                    (!this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionVideo &&
                      this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions &&
                      this.state.flowControl.inductionQuestions.inductionQuestionsSkipped) ||
                    (this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionVideo &&
                      this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions &&
                      (this.state.flowControl.inductionVideo.inductionVideoSkipped ||
                        this.state.flowControl.inductionQuestions.inductionQuestionsSkipped))
                  ? 'skipped'
                  : 'unknown',
            }
          }
          if (
            this.props.processinstance.registrationEntity.checkInFieldDetails.checkInFieldsAvailable &&
            this.state.flowControl.postInitialFlowSelection.checkin
          ) {
            submission.checkInFieldValues = {
              eventTiming: eventTiming,
              ...chain(
                this.props.processinstance.registrationEntity.checkInFieldDetails.captureFields.map(field => {
                  return {
                    fieldTechnicalName: field.id,
                    value: this.state.formValues[field.id].value,
                    includeInSubmissionIfNotVisible: field.includeInSubmissionIfNotVisible,
                    availableForConditionInput: field.availableForConditionInput,
                  }
                })
              )
                .filter(entry => {
                  let shouldInclude = true
                  if (typeof entry.value !== 'undefined' && `${entry.value}`.length > 0) {
                    const rootCondition = this.props.processinstance.registrationEntity.checkInFieldDetails.fieldConditions[
                      entry.fieldTechnicalName
                    ]
                    if (rootCondition) {
                      const { isConditionalVisible } = filterProcessor(rootCondition, this.state.formValues)
                      if (!isConditionalVisible && !entry.includeInSubmissionIfNotVisible) {
                        shouldInclude = false //don't return a field that is invisible
                      }
                    } else if (entry.availableForConditionInput) {
                      shouldInclude = false //condition input fields are never visible and never have own conditions - they should not be incliuded in the invite submission
                    }
                  } else {
                    shouldInclude = false //exclude zero length fields
                  }
                  return shouldInclude
                })
                .keyBy('fieldTechnicalName')
                .mapValues(formVal => {
                  if (isArray(formVal.value)) {
                    return formVal.value.join(';') as string
                  } else {
                    return formVal.value as string
                  }
                })
                .value(),
            }
          }
          if (this.props.processinstance.registrationEntity.optionalFieldsEnabled.hostSearch) {
            submission.selectedHost = this.state.formValues.selectedHost.data
          }
          submission.submittedWithFace =
            !this.state.flowControl.faceData.faceDataProcessingSkipped &&
            this.state.formValues.faceData.isValid === true &&
            this.state.formValues.faceData.value.length > 0

          this.props.SagaSubmitUpdatePostRegistrationCompletion(submission)
        }
      )
    }
  }

  _onFieldBlur = (activeSection: any): void => {
    if (activeSection && activeSection.anchor && !isMobile) {
      const instance = this.formRef.getFieldInstance(activeSection.anchor.replace('invite-', ''))
      if (instance && instance.focus) {
        instance.focus()
      } else if (instance && instance.tel && instance.tel.focus) {
        instance.tel.focus()
      }
    }
  }

  _setPhoneNumberRef = (ref: any) => {
    if (ref) {
      this.phoneNumberRef = ref
    }
  }

  _togglePostFlow = (postFlow: enums.POST_INITIAL_FLOW_SELECTION_STATE) => {
    const newPostFlow = { ...this.state.flowControl.postInitialFlowSelection }
    if (postFlow === enums.POST_INITIAL_FLOW_SELECTION_STATE.PARKING) {
      newPostFlow.parking = !newPostFlow.parking
    } else if (postFlow === enums.POST_INITIAL_FLOW_SELECTION_STATE.INDUCTION) {
      newPostFlow.induction = !newPostFlow.induction
    } else if (postFlow === enums.POST_INITIAL_FLOW_SELECTION_STATE.CHECK_IN) {
      newPostFlow.checkin = !newPostFlow.checkin
    }
    this.setState(
      update(this.state, {
        flowControl: {
          postInitialFlowSelection: {
            $set: newPostFlow,
          },
        },
      }),
      () => {
        setTimeout(() => {
          this.fullPageRef.reBuild()
        }, 250)
      }
    )
  }

  _onCompletePostFlowSelection = (): void => {
    this.setState(
      update(this.state, {
        flowControl: {
          postInitialFlowSelection: {
            $set: {
              ...this.state.flowControl.postInitialFlowSelection,
              postFlowSelectionComplete: true,
            },
          },
        },
      }),
      () => {
        setTimeout(() => {
          this.fullPageRef.reBuild()
          this.fullPageRef.moveSectionDown()
        }, 250)
      }
    )
  }

  // parking functions start
  _toggleParkingData = (): void => {
    this.setState(
      update(this.state, {
        flowControl: {
          parking: {
            parkingProcessingEnabled: {
              $set: true,
            },
            parkingTermsAccepted: {
              $set: false,
            },
          },
          rebuildFormTimeStamp: {
            $set: Date.now(),
          },
        },
      })
    )
  }

  _toggleSkipParkingData = (): void => {
    this.setState(
      update(this.state, {
        flowControl: {
          parking: {
            parkingProcessingSkipped: {
              $set: true,
            },
          },
        },
      })
    )
  }

  _toggleReEnabledParkingData = (): void => {
    this.setState(
      update(this.state, {
        flowControl: {
          parking: {
            parkingProcessingEnabled: {
              $set: false,
            },
            parkingProcessingSkipped: {
              $set: false,
            },
          },
        },
      })
    )
  }

  _onToggleParkingTerms = (): void => {
    this.setState(
      update(this.state, {
        flowControl: {
          parking: {
            parkingTermsAccepted: {
              $set: !this.state.flowControl.parking.parkingTermsAccepted,
            },
          },
          rebuildFormTimeStamp: {
            $set: Date.now(),
          },
        },
      })
    )
  }
  //parking functions end

  //induction start
  inductionEventListener = event => {
    if (event.keyCode === 13) {
      if (this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions) {
        if (!this.state.flowControl.inductionQuestions.inductionQuestionsProcessingEnabled) {
          if (this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionVideo) {
            if (this.state.flowControl.inductionVideo.inductionVideoCompleted !== true) {
              this.setState(
                update(this.state, {
                  flowControl: {
                    inductionVideo: { confirmInductionSkipVisible: { $set: true } },
                  },
                })
              )
            } else {
              this.setState(
                update(this.state, {
                  flowControl: {
                    inductionQuestions: { confirmInductionSkipVisible: { $set: true } },
                  },
                })
              )
            }
          } else {
            if (this.state && this.state.flowControl.inductionQuestions.inductionQuestionsCompleted === true) {
              this.fullPageRef.moveSectionDown()
            } else {
              this.setState(
                update(this.state, {
                  flowControl: {
                    inductionQuestions: { confirmInductionSkipVisible: { $set: true } },
                  },
                })
              )
            }
          }
        }
      } else {
        if (this.state && this.state.flowControl.inductionVideo.inductionVideoCompleted === true) {
          this.fullPageRef.moveSectionDown()
        } else {
          this.setState(
            update(this.state, {
              flowControl: {
                inductionVideo: { confirmInductionSkipVisible: { $set: true } },
              },
            })
          )
        }
      }
    }
  }

  _setInductionVideoCompleted = () => {
    this.setState(
      update(this.state, {
        flowControl: {
          inductionVideo: {
            inductionVideoCompleted: { $set: true },
            inductionVideoSkipped: { $set: false },
          },
        },
      }),
      () => {
        if (!this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions) {
          if (
            !this.props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.isPostInitialRegistration //if induction is completed on post flow then dont disable submission, update when we add parking update
          ) {
            this.fullPageRef.moveSectionDown()
          } else if (
            this.props.processinstance.registrationEntity.checkInFieldDetails &&
            this.props.processinstance.registrationEntity.checkInFieldDetails.checkInFieldsAvailable &&
            this.state.flowControl.postInitialFlowSelection.checkin
          ) {
            this.fullPageRef.moveSectionDown() //move down if there are check in fields available and check in is seleted
          }
        }
      }
    )
  }

  _toggleConfirmInductionVideoSkipVisible = () => {
    this.setState(
      update(this.state, {
        flowControl: {
          inductionVideo: {
            confirmInductionSkipVisible: {
              $set: !this.state.flowControl.inductionVideo.confirmInductionSkipVisible,
            },
          },
        },
      })
    )
  }

  _toggleConfirmInductionQuestionsSkipVisible = () => {
    this.setState(
      update(this.state, {
        flowControl: {
          inductionQuestions: {
            confirmInductionSkipVisible: {
              $set: !this.state.flowControl.inductionQuestions.confirmInductionSkipVisible,
            },
          },
        },
      })
    )
  }

  _handleInductionVideoSkipped = () => {
    this.setState(
      update(this.state, {
        flowControl: {
          inductionVideo: {
            confirmInductionSkipVisible: {
              $set: !this.state.flowControl.inductionVideo.confirmInductionSkipVisible,
            },
            inductionVideoSkipped: { $set: true },
          },
        },
      }),
      () => {
        this.fullPageRef.moveSectionDown()
      }
    )
  }

  _handleInductionQuestionsSkipped = () => {
    this.setState(
      update(this.state, {
        flowControl: {
          inductionQuestions: {
            confirmInductionSkipVisible: {
              $set: !this.state.flowControl.inductionQuestions.confirmInductionSkipVisible,
            },
            inductionQuestionsSkipped: { $set: true },
          },
        },
      }),
      () => {
        this.fullPageRef.moveSectionDown()
      }
    )
  }

  _onToggleInductionQuestionsProcessingEnabled = () => {
    this.setState(
      update(this.state, {
        flowControl: {
          inductionQuestions: {
            inductionQuestionsProcessingEnabled: {
              $set: !this.state.flowControl.inductionQuestions.inductionQuestionsProcessingEnabled,
            },
          },
        },
      })
    )
  }

  _onToggleReEnabledInductionQuestions = () => {
    this.setState(
      update(this.state, {
        flowControl: {
          inductionQuestions: {
            confirmInductionSkipVisible: {
              $set: false,
            },
            inductionQuestionsSkipped: { $set: false },
          },
        },
      })
    )
  }

  _onCompleteInductionQuestions = () => {
    this.setState(
      update(this.state, {
        flowControl: {
          inductionQuestions: {
            inductionQuestionsCompleted: { $set: true },
            inductionQuestionsSkipped: { $set: false },
            inductionQuestionsProcessingEnabled: { $set: false },
          },
        },
      }),
      () => {
        if (
          !this.props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.isPostInitialRegistration //if induction is completed on post flow then dont disable submission, update when we add parking update
        ) {
          this.fullPageRef.moveSectionDown()
        } else if (
          this.props.processinstance.registrationEntity.checkInFieldDetails &&
          this.props.processinstance.registrationEntity.checkInFieldDetails.checkInFieldsAvailable &&
          this.state.flowControl.postInitialFlowSelection.checkin
        ) {
          this.fullPageRef.moveSectionDown() //move down if there are check in fields available and check in is seleted
        }
      }
    )
  }

  //induction functions end

  //Face capture functions start
  _toggleFaceData = (): void => {
    this.setState(
      update(this.state, {
        flowControl: {
          faceData: {
            faceDataProcessingEnabled: {
              $set: !this.state.flowControl.faceData.faceDataProcessingEnabled,
            },
          },
        },
        ...(this.state.flowControl.faceData.faceDataProcessingEnabled && {
          formValues: {
            faceData: {
              isValid: {
                $set: false,
              },
              value: {
                $set: '',
              },
            },
          },
        }),
      })
    )
  }

  _toggleSkipFaceData = (): void => {
    this.setState(
      update(this.state, {
        flowControl: {
          faceData: {
            faceDataProcessingSkipped: {
              $set: !this.state.flowControl.faceData.faceDataProcessingSkipped,
            },
          },
        },
      }),
      () => {
        if (
          this.state.flowControl.faceData.faceDataProcessingSkipped &&
          this.props.processinstance.registrationEntity.optionalFieldsEnabled.hostSearch
        ) {
          this.fullPageRef.moveSectionDown()
        }
      }
    )
  }

  _validateFace = (faceB64: string, captureType: enums.FACE_CAPTURE_TYPE) => {
    this.setState(
      update(this.state, {
        flowControl: {
          faceData: {
            faceValidationInProcess: {
              $set: true,
            },
            faceCaptureType: {
              $set: captureType,
            },
          },
        },
        formValues: {
          faceData: {
            isValid: {
              $set: false,
            },
          },
        },
      }),
      () => {
        this.props.SagaValidateFace(faceB64)
      }
    )
  }

  acceptCapturedFace = () => {
    this.setState(
      update(this.state, {
        formValues: {
          faceData: {
            value: {
              $set: this.props.registrationinstance.faceB64 as string,
            },
          },
        },
      }),
      () => {
        if (this.props.processinstance.registrationEntity.optionalFieldsEnabled.hostSearch) {
          this.fullPageRef.moveSectionDown()
        }
      }
    )
  }

  changeCapturedFace = () => {
    this.setState(
      update(this.state, {
        formValues: {
          faceData: {
            isValid: {
              $set: false,
            },
          },
        },
      })
    )
  }

  //Face capture functions end

  //validity checking start
  _processOnLastFieldCheck = (): void => {
    //check whether we are on a reprocess and the action occurs on the last field - if so trigger a forced update to check validity
    if (this.props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.isPostInitialRegistration) {
      const anchors = this.fullPageRef.getFullpageData().options.anchors
      const activeAnchor = this.fullPageRef.getActiveSection()
      if (anchors[anchors.length - 1] === activeAnchor.anchor) {
        this.forceUpdate() //trigger a validity check
      }
    }
  }

  getParkingValidityState = () => {
    let state = false
    if (this.props.processinstance.registrationEntity.parkingDetails.parkingAvailable) {
      if (this.state.flowControl.parking.parkingProcessingSkipped === true) {
        state = true
      } else {
        if (this.state.flowControl.parking.parkingProcessingEnabled === true) {
          if (this.props.processinstance.registrationEntity.parkingDetails.hasTerms) {
            if (this.state.flowControl.parking.parkingTermsAccepted) {
              state = true
            } else {
              state = false
            }
          } else {
            state = true
          }
          if (state === true) {
            if (this.props.processinstance.registrationEntity.parkingDetails.parkingAvailable) {
              this.props.processinstance.registrationEntity.parkingDetails.captureFields.forEach(field => {
                if (this.state.formValues[field.fieldTechnicalName].isValid !== true) {
                  state = false
                }
              })
              if (
                this.props.processinstance.registrationEntity.parkingDetails.availableClassifications &&
                this.props.processinstance.registrationEntity.parkingDetails.availableClassifications.length > 0
              ) {
                if (this.state.formValues.preferredParkingClassification.isValid !== true) {
                  state = false
                }
              }
            }
          }
        } else {
          state = false
        }
      }
    } else {
      state = true
    }
    return state
  }

  getInductionValidityState = () => {
    let state = false
    if (this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionVideo) {
      if (
        this.props.registrationinstance.otpValidationHasValidInduction !== true ||
        (this.props.registrationinstance.otpValidationHasValidInduction === true &&
          this.state.flowControl.globalProfileConfirmation.globalProfileConfirmed === true &&
          this.state.flowControl.globalProfileConfirmation.shouldArchiveExisting === true)
      ) {
        if (this.state.flowControl.inductionVideo.inductionVideoSkipped) {
          state = true
        } else if (this.state.flowControl.inductionVideo.inductionVideoCompleted) {
          if (this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions) {
            if (this.state.flowControl.inductionQuestions.inductionQuestionsSkipped) {
              state = true
            } else if (this.state.flowControl.inductionQuestions.inductionQuestionsCompleted) {
              state = true
            }
          } else {
            state = true
          }
        }
      } else {
        state = true
      }
    } else if (this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions) {
      if (
        this.props.registrationinstance.otpValidationHasValidInduction !== true ||
        (this.props.registrationinstance.otpValidationHasValidInduction === true &&
          this.state.flowControl.globalProfileConfirmation.globalProfileConfirmed === true &&
          this.state.flowControl.globalProfileConfirmation.shouldArchiveExisting === true)
      ) {
        if (this.state.flowControl.inductionQuestions.inductionQuestionsSkipped) {
          state = true
        } else if (this.state.flowControl.inductionQuestions.inductionQuestionsCompleted) {
          state = true
        }
      } else {
        state = true
      }
    } else {
      state = true
    }
    return state
  }

  getRequiredProfileFieldsValidityState = () => {
    let state =
      this.state.formValues.firstName.value.length > 0 &&
      this.state.formValues.lastName.value.length > 0 &&
      this.state.formValues.phoneNumber.isValid &&
      this.state.flowControl.otpRequest.otpRequestSuccessfull &&
      this.state.formValues.otpCode.isValid &&
      this.state.flowControl.otpValidation.otpValidationSuccessfull
    return state
  }

  getOptionalProfileFieldsValidityState = () => {
    /** 
     Dietry requirements are not required 
     - the rest of these fields are optional in the sense that they can be added in config but are required if added for the tenant
     - TODO: we should push the optionality of capture of this to config
    */
    let state =
      (this.props.processinstance.registrationEntity.optionalFieldsEnabled.company ? this.state.formValues.company.isValid : true) &&
      (this.props.processinstance.registrationEntity.optionalFieldsEnabled.email
        ? this.state.formValues.email.isValid
          ? this.state.formValues.email.isValid
          : this.state.flowControl.email.emailSkipped
          ? true
          : false
        : true) &&
      (this.props.processinstance.registrationEntity.optionalFieldsEnabled.personalIdentificationNr
        ? this.state.formValues.personalIdentificationNr.isValid
        : true)

    return state
  }

  getFaceValidityState = () => {
    let state =
      (this.state.formValues.faceData.isValid &&
        this.state.formValues.faceData.value.length > 0 &&
        this.state.flowControl.faceData.faceValidationSuccessfull) ||
      this.state.flowControl.faceData.faceDataProcessingSkipped
    return state
  }

  getCheckInFieldsValidityState = () => {
    let state = true
    this.props.processinstance.registrationEntity.checkInFieldDetails.captureFields.forEach(checkInField => {
      const rootCondition = this.props.processinstance.registrationEntity.checkInFieldDetails.fieldConditions[checkInField.id]
      const { isConditionalVisible, isConditionalEditable } = filterProcessor(rootCondition, this.state.formValues)
      if (
        checkInField.inputSettings.mandatory === true &&
        isConditionalVisible &&
        isConditionalEditable &&
        !checkInField.availableForConditionInput
      ) {
        if (this.state.formValues[checkInField.id].isValid !== true) {
          state = false
        }
      }
    })
    return state
  }

  getSubmitEnabled = () => {
    let enabled = true
    if (!this.state.flowControl.otpValidation.otpValidationSuccessfull) {
      return false
    }
    if (!this.props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.isPostInitialRegistration) {
      //Parking is always checked as it can be skipped
      enabled = this.getParkingValidityState()
      if (!enabled) {
        return enabled
      }

      //Induction is always checked as it can be skipped
      enabled = this.getInductionValidityState()
      if (!enabled) {
        return enabled
      }

      //for a creation flow required profile fields are checked
      enabled = this.getRequiredProfileFieldsValidityState()
      if (!enabled) {
        return enabled
      }

      //depending on camera availability state of face is checked
      if (this.state.flowControl.isCameraEnabled) {
        enabled = this.getFaceValidityState()
        if (!enabled) {
          return enabled
        }
      }

      //certain optional fields have special cases e.g. email for an event token and induction which is checked separately, dietry requirements not reequired etc hence a seperate method - this should be pusshed to config
      enabled = this.getOptionalProfileFieldsValidityState()
      if (!enabled) {
        return enabled
      }

      //if there are check in fields check there mandatorhy flags
      if (this.props.processinstance.registrationEntity.checkInFieldDetails.checkInFieldsAvailable) {
        enabled = this.getCheckInFieldsValidityState()
        if (!enabled) {
          return enabled
        }
      }
    } else {
      if (this.state.flowControl.postInitialFlowSelection.parking === true) {
        enabled = this.getParkingValidityState()
        if (!enabled) {
          return enabled
        }
      }
      if (this.state.flowControl.postInitialFlowSelection.induction === true) {
        enabled = this.getInductionValidityState()
        if (!enabled) {
          return enabled
        }
      }
      if (this.state.flowControl.postInitialFlowSelection.checkin === true) {
        enabled = this.getCheckInFieldsValidityState()
        if (!enabled) {
          return enabled
        }
      }
    }

    //device token based checks
    if (!!this.props.processinstance.registrationEntity.sourceDeviceToken) {
      if (this.state.flowControl.isCameraEnabled) {
        enabled = this.getFaceValidityState()
        if (!enabled) {
          return enabled
        }
      }
      if (this.props.processinstance.registrationEntity.optionalFieldsEnabled.hostSearch) {
        enabled = this.state.formValues.selectedHost.isValid
        if (!enabled) {
          return enabled
        }
      }
    }

    return enabled
  }

  performPreSubmitEnabledChecks = () => {
    let canEnableSubmit = true

    //initial case - special handling for a returning flow - only trigger submit on processing of last screen element
    const activeSection = this.fullPageRef.getActiveSection()
    const fieldName = activeSection.anchor.replace('invite-', '')
    if (fieldName && this.props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.isPostInitialRegistration) {
      if (
        this.state.flowControl.postInitialFlowSelection.postFlowSelectionComplete &&
        (this.state.flowControl.postInitialFlowSelection.parking ||
          this.state.flowControl.postInitialFlowSelection.induction ||
          this.state.flowControl.postInitialFlowSelection.checkin)
      ) {
        if (
          this.state.flowControl.postInitialFlowSelection.parking &&
          (!this.state.flowControl.postInitialFlowSelection.induction && !this.state.flowControl.postInitialFlowSelection.checkin)
        ) {
          if (this.state.flowControl.parking.parkingProcessingSkipped === true) {
            canEnableSubmit = true //we can submit a parking only flow if skipped as this could be a removal of previous parking or just a registration completion
          } else {
            const lastField = this.props.processinstance.registrationEntity.parkingDetails.captureFields[
              this.props.processinstance.registrationEntity.parkingDetails.captureFields.length - 1
            ]
            const parkingValidityState = this.getParkingValidityState()
            if (
              parkingValidityState &&
              ((!!this.props.processinstance.registrationEntity.sourceDeviceToken &&
                ((this.props.processinstance.registrationEntity.optionalFieldsEnabled.hostSearch && fieldName === 'hostSearch') ||
                  (!this.props.processinstance.registrationEntity.optionalFieldsEnabled.hostSearch &&
                    ((this.state.flowControl.isCameraEnabled && fieldName === 'faceData') || !this.state.flowControl.isCameraEnabled)))) ||
                !this.props.processinstance.registrationEntity.sourceDeviceToken)
            ) {
              canEnableSubmit = true
            } else if (fieldName !== lastField.fieldTechnicalName || !parkingValidityState) {
              canEnableSubmit = false
            }
          }
        } else if (this.state.flowControl.postInitialFlowSelection.induction && !this.state.flowControl.postInitialFlowSelection.checkin) {
          if (
            (this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionVideo ||
              this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions) &&
            (this.props.registrationinstance.otpValidationHasValidInduction !== true ||
              (this.props.registrationinstance.otpValidationHasValidInduction === true &&
                this.state.flowControl.globalProfileConfirmation.globalProfileConfirmed === true &&
                this.state.flowControl.globalProfileConfirmation.shouldArchiveExisting === true))
          ) {
            //induction enabled - check we are on it and it is valid
            const inductionValidityState = this.getInductionValidityState()
            if (
              inductionValidityState &&
              ((!!this.props.processinstance.registrationEntity.sourceDeviceToken &&
                ((this.props.processinstance.registrationEntity.optionalFieldsEnabled.hostSearch && fieldName === 'hostSearch') ||
                  (!this.props.processinstance.registrationEntity.optionalFieldsEnabled.hostSearch &&
                    ((this.state.flowControl.isCameraEnabled && fieldName === 'faceData') || !this.state.flowControl.isCameraEnabled)))) ||
                !this.props.processinstance.registrationEntity.sourceDeviceToken)
            ) {
              canEnableSubmit = true
            } else if (fieldName !== 'induction' || !inductionValidityState) {
              canEnableSubmit = false
            }
          }
        } else if (this.state.flowControl.postInitialFlowSelection.checkin) {
          const visibleCustomFields: CheckInField[] = []
          this.props.processinstance.registrationEntity.checkInFieldDetails.captureFields.forEach(captureField => {
            const rootCondition = this.props.processinstance.registrationEntity.checkInFieldDetails.fieldConditions[captureField.id]
            const { isConditionalVisible, isConditionalEditable } = filterProcessor(rootCondition, this.state.formValues)
            if (isConditionalVisible) {
              visibleCustomFields.push({
                ...captureField,
                readOnly: isConditionalEditable,
              })
            }
          })
          const lastField = visibleCustomFields[visibleCustomFields.length - 1]
          const checkinValidityState = this.getCheckInFieldsValidityState()
          if (
            checkinValidityState &&
            ((!!this.props.processinstance.registrationEntity.sourceDeviceToken &&
              ((this.props.processinstance.registrationEntity.optionalFieldsEnabled.hostSearch && fieldName === 'hostSearch') ||
                (!this.props.processinstance.registrationEntity.optionalFieldsEnabled.hostSearch &&
                  ((this.state.flowControl.isCameraEnabled && fieldName === 'faceData') || !this.state.flowControl.isCameraEnabled)))) ||
              !this.props.processinstance.registrationEntity.sourceDeviceToken)
          ) {
            canEnableSubmit = true
          } else if (fieldName !== lastField.id || !checkinValidityState) {
            canEnableSubmit = false
          }
        }
      } else {
        canEnableSubmit = true //no flows, only agreement acceptance and otp validation
      }
    }
    if (
      (!!this.props.processinstance.registrationEntity.sourceDeviceToken &&
        (this.props.processinstance.registrationEntity.optionalFieldsEnabled.hostSearch && fieldName !== 'hostSearch')) ||
      (!this.props.processinstance.registrationEntity.optionalFieldsEnabled.hostSearch &&
        (!this.props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.isPostInitialRegistration &&
          this.state.flowControl.isCameraEnabled &&
          fieldName !== 'faceData'))
    ) {
      canEnableSubmit = false
    }

    return canEnableSubmit
  }
  //validity checking end

  //host search begin
  _onHostSearch = (value: string): void => {
    this.setState(
      update(this.state, {
        formValues: {
          selectedHost: {
            value: {
              $set: value,
            },
            isValid: {
              $set: false,
            },
            data: {
              $set: undefined,
            },
          },
        },
        flowControl: {
          hostSearchRequest: {
            hostSearchInProcess: {
              $set: !!(value && value.length > 2),
            },
          },
        },
      }),
      () => {
        if (value && value.length > 2) {
          this.props.SagaRegistrationSearchHost({
            searchString: value,
            cb: ({ succesfull, message, hostSearchResults }) => {
              try {
                if (succesfull) {
                  this.setState(
                    update(this.state, {
                      flowControl: {
                        hostSearchRequest: {
                          hostSearchInProcess: {
                            $set: false,
                          },
                        },
                      },
                      dataSources: {
                        hosts: {
                          $set: hostSearchResults.map(host => ({
                            value: host.uniqueAttributeValue,
                            text: host.name,
                            sourceObject: host,
                          })),
                        },
                      },
                    })
                  )
                } else {
                  this.setState(
                    update(this.state, {
                      formValues: {
                        selectedHost: {
                          value: {
                            $set: '',
                          },
                          isValid: {
                            $set: false,
                          },
                          data: {
                            $set: undefined,
                          },
                        },
                      },
                      flowControl: {
                        hostSearchRequest: {
                          hostSearchInProcess: {
                            $set: false,
                          },
                        },
                      },
                      dataSources: {
                        hosts: {
                          $set: [],
                        },
                      },
                    }),
                    () => {
                      notification.error({
                        message: 'ERROR',
                        description: message,
                      })
                    }
                  )
                }
              } catch (e) {
                this.setState(
                  update(this.state, {
                    formValues: {
                      selectedHost: {
                        value: {
                          $set: '',
                        },
                        isValid: {
                          $set: false,
                        },
                        data: {
                          $set: undefined,
                        },
                      },
                    },
                    flowControl: {
                      hostSearchRequest: {
                        hostSearchInProcess: {
                          $set: false,
                        },
                      },
                    },
                    dataSources: {
                      hosts: {
                        $set: [],
                      },
                    },
                  }),
                  () => {
                    notification.error({
                      message: 'ERROR',
                      description: '',
                    })
                  }
                )
              }
            },
          })
        }
      }
    )
  }

  _onHostSelect = (value: SelectValue, option: any): void => {
    const dataSourceObject = this.state.dataSources.hosts.find(host => host.value === value)
    if (dataSourceObject) {
      this.setState(
        update(this.state, {
          formValues: {
            selectedHost: {
              value: {
                $set: dataSourceObject.text,
              },
              isValid: {
                $set: true,
              },
              data: {
                $set: dataSourceObject.sourceObject,
              },
            },
          },
        }),
        () => {
          let instance = this.formRef.getFieldInstance('hostSearch')
          if (instance) {
            try {
              instance.blur()
            } catch (e) {
              console.log(e)
            }
          }
          this._processOnLastFieldCheck()
        }
      )
    }
  }
  //host search end

  _onSkipEmail = () => {
    this.setState(
      update(this.state, {
        flowControl: {
          email: {
            emailSkipped: {
              $set: true,
            },
          },
        },
      }),
      () => {
        this.fullPageRef.moveSectionDown()
      }
    )
  }

  render() {
    const fpFormRenderer = ({ state, fullpageApi }) => {
      this.fullPageRef = fullpageApi

      return (
        <ReactFullpage.Wrapper>
          <CustomForm
            className={isMobile ? 'mobile-form' : 'web-form'}
            ref={this._setFormRef}
            fullpageApi={fullpageApi}
            formValues={this.state.formValues}
            flowControl={this.state.flowControl}
            dataSources={this.state.dataSources}
            isDeviceTokenBased={!!this.props.processinstance.registrationEntity.sourceDeviceToken}
            isMobile={isMobile}
            onFieldsChange={this._onFieldsChange}
            onNumberOkClick={this.requestOTP}
            onOTPOkClick={this.validateOTP}
            onFieldBlur={this._onFieldBlur}
            onToggleFaceData={this._toggleFaceData}
            onToggleSkipFaceData={this._toggleSkipFaceData}
            onInductionCompleted={this._setInductionVideoCompleted}
            setPhoneNumberRef={this._setPhoneNumberRef}
            onToggleParkingData={this._toggleParkingData}
            onToggleSkipParkingData={this._toggleSkipParkingData}
            onToggleReEnabledParkingData={this._toggleReEnabledParkingData}
            onToggleParkingTerms={this._onToggleParkingTerms}
            country={this.state.flowControl.otpRequest.country}
            validateFace={this._validateFace}
            acceptCapturedFace={this.acceptCapturedFace}
            changeCapturedFace={this.changeCapturedFace}
            optionalFieldsEnabled={{
              ...this.props.processinstance.registrationEntity.optionalFieldsEnabled,
              inductionVideo:
                this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionVideo &&
                (this.props.registrationinstance.otpValidationHasValidInduction !== true ||
                  (this.props.registrationinstance.otpValidationHasValidInduction === true &&
                    this.state.flowControl.globalProfileConfirmation.globalProfileConfirmed === true &&
                    this.state.flowControl.globalProfileConfirmation.shouldArchiveExisting === true)),
              inductionQuestions:
                this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions &&
                (this.props.registrationinstance.otpValidationHasValidInduction !== true ||
                  (this.props.registrationinstance.otpValidationHasValidInduction === true &&
                    this.state.flowControl.globalProfileConfirmation.globalProfileConfirmed === true &&
                    this.state.flowControl.globalProfileConfirmation.shouldArchiveExisting === true)),
            }}
            inviteOnlyFieldEnablement={this.props.processinstance.registrationEntity.inviteOnlyFieldEnablement}
            inductionContent={this.props.processinstance.registrationEntity.inductionContent}
            parkingDetails={this.props.processinstance.registrationEntity.parkingDetails}
            postInitialRegistrationFlowAvailability={this.props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability}
            onGlobalNotMe={this._onGlobalNotMe}
            onGlobalConfirmed={this._onGlobalConfirmed}
            handleInductionVideoSkipped={this._handleInductionVideoSkipped}
            handleInductionQuestionsSkipped={this._handleInductionQuestionsSkipped}
            toggleConfirmInductionVideoSkip={this._toggleConfirmInductionVideoSkipVisible}
            toggleConfirmInductionQuestionsSkip={this._toggleConfirmInductionQuestionsSkipVisible}
            onToggleInductionQuestionsProcessing={this._onToggleInductionQuestionsProcessingEnabled}
            onToggleReEnabledInductionQuestions={this._onToggleReEnabledInductionQuestions}
            onCompleteInductionQuestions={this._onCompleteInductionQuestions}
            confirmInductionVideoSkipVisible={this.state.flowControl.inductionVideo.confirmInductionSkipVisible}
            confirmInductionQuestionsSkipVisible={this.state.flowControl.inductionQuestions.confirmInductionSkipVisible}
            inviteCreationFields={this.props.processinstance.registrationEntity.inviteCreationFields}
            checkInFieldDetails={this.props.processinstance.registrationEntity.checkInFieldDetails}
            processOnLastFieldCheck={this._processOnLastFieldCheck}
            togglePostFlow={this._togglePostFlow}
            onCompletePostFlowSelection={this._onCompletePostFlowSelection}
            sourceDeviceTokenUnverifiedProcessing={this.props.processinstance.registrationEntity.sourceDeviceTokenUnverifiedProcessing}
            hasValidInduction={
              !(
                this.props.registrationinstance.otpValidationHasValidInduction !== true ||
                (this.props.registrationinstance.otpValidationHasValidInduction === true &&
                  this.state.flowControl.globalProfileConfirmation.globalProfileConfirmed === true &&
                  this.state.flowControl.globalProfileConfirmation.shouldArchiveExisting === true)
              )
            }
            onHostSearch={this._onHostSearch}
            onHostSelect={this._onHostSelect}
            onSkipEmail={this._onSkipEmail}
          />
        </ReactFullpage.Wrapper>
      )
    }

    let anchors: Array<string> = [
      'invite-phoneNumber',
      ...(!this.props.processinstance.registrationEntity.sourceDeviceTokenUnverifiedProcessing ? ['invite-otpCode'] : []),
    ]
    if (this.state.flowControl.otpValidation.otpValidationSuccessfull) {
      if (!this.props.processinstance.registrationEntity.postInitialRegistrationFlowAvailability.isPostInitialRegistration) {
        if (
          this.state.flowControl.globalProfileConfirmation.hasGlobalProfile &&
          !this.state.flowControl.globalProfileConfirmation.globalProfileConfirmed &&
          !this.state.flowControl.globalProfileConfirmation.localProfileConfirmed
        ) {
          //showing modal - no extras
        } else if (
          this.state.flowControl.globalProfileConfirmation.hasGlobalProfile &&
          (this.state.flowControl.globalProfileConfirmation.globalProfileConfirmed ||
            this.state.flowControl.globalProfileConfirmation.localProfileConfirmed)
        ) {
          if (this.props.processinstance.registrationEntity.optionalFieldsEnabled.email) {
            anchors.push('invite-email')
          }
          if (this.props.processinstance.registrationEntity.optionalFieldsEnabled.company) {
            anchors.push('invite-company')
          }
          if (this.props.processinstance.registrationEntity.optionalFieldsEnabled.personalIdentificationNr) {
            anchors.push('invite-personalIdentificationNr')
          }
          if (this.props.processinstance.registrationEntity.inviteOnlyFieldEnablement.dietaryRequirements) {
            anchors.push('invite-dietaryRequirements')
          }
          if (this.props.processinstance.registrationEntity.parkingDetails.parkingAvailable) {
            anchors.push('invite-parkingAvailable')
            if (this.state.flowControl.parking.parkingProcessingEnabled) {
              if (this.props.processinstance.registrationEntity.parkingDetails.hasTerms) {
                anchors.push('invite-parkingTerms')
                if (this.state.flowControl.parking.parkingTermsAccepted) {
                  if (
                    this.props.processinstance.registrationEntity.parkingDetails.availableClassifications &&
                    this.props.processinstance.registrationEntity.parkingDetails.availableClassifications.length > 0
                  ) {
                    anchors.push('invite-parkingclassification')
                  }
                  this.props.processinstance.registrationEntity.parkingDetails.captureFields.forEach(field => {
                    anchors.push(`invite-${field.fieldTechnicalName}`)
                  })
                }
              } else {
                if (
                  this.props.processinstance.registrationEntity.parkingDetails.availableClassifications &&
                  this.props.processinstance.registrationEntity.parkingDetails.availableClassifications.length > 0
                ) {
                  anchors.push('invite-parkingclassification')
                }
                this.props.processinstance.registrationEntity.parkingDetails.captureFields.forEach(field => {
                  anchors.push(`invite-${field.fieldTechnicalName}`)
                })
              }
            }
          }
          if (
            (this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionVideo ||
              this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions) &&
            (this.props.registrationinstance.otpValidationHasValidInduction !== true ||
              (this.props.registrationinstance.otpValidationHasValidInduction === true &&
                this.state.flowControl.globalProfileConfirmation.globalProfileConfirmed === true &&
                this.state.flowControl.globalProfileConfirmation.shouldArchiveExisting === true))
          ) {
            anchors.push('invite-induction')
          }
          if (this.props.processinstance.registrationEntity.checkInFieldDetails.checkInFieldsAvailable) {
            const visibleCustomFields: CheckInField[] = []
            this.props.processinstance.registrationEntity.checkInFieldDetails.captureFields
              .filter(captureField => !captureField.availableForConditionInput)
              .forEach(captureField => {
                const rootCondition = this.props.processinstance.registrationEntity.checkInFieldDetails.fieldConditions[captureField.id]
                const { isConditionalVisible, isConditionalEditable } = filterProcessor(rootCondition, this.state.formValues)
                if (isConditionalVisible) {
                  visibleCustomFields.push({
                    ...captureField,
                    readOnly: isConditionalEditable,
                  })
                }
              })
            visibleCustomFields.forEach(field => {
              anchors.push(`invite-checkin-${field.id}`)
            })
          }
          if (this.state.flowControl.isCameraEnabled) {
            anchors.push('invite-faceData')
          }
        } else if (!this.state.flowControl.globalProfileConfirmation.hasGlobalProfile) {
          anchors.push('invite-firstName')
          anchors.push('invite-lastName')
          if (this.props.processinstance.registrationEntity.optionalFieldsEnabled.email) {
            anchors.push('invite-email')
          }
          if (this.props.processinstance.registrationEntity.optionalFieldsEnabled.company) {
            anchors.push('invite-company')
          }
          if (this.props.processinstance.registrationEntity.optionalFieldsEnabled.personalIdentificationNr) {
            anchors.push('invite-personalIdentificationNr')
          }
          if (this.props.processinstance.registrationEntity.inviteOnlyFieldEnablement.dietaryRequirements) {
            anchors.push('invite-dietaryRequirements')
          }
          if (this.props.processinstance.registrationEntity.parkingDetails.parkingAvailable) {
            anchors.push('invite-parkingData')
            if (!this.state.flowControl.parking.parkingProcessingSkipped && this.state.flowControl.parking.parkingProcessingEnabled) {
              if (this.props.processinstance.registrationEntity.parkingDetails.hasTerms) {
                anchors.push('invite-parkingTerms')
                if (this.state.flowControl.parking.parkingTermsAccepted) {
                  if (
                    this.props.processinstance.registrationEntity.parkingDetails.availableClassifications &&
                    this.props.processinstance.registrationEntity.parkingDetails.availableClassifications.length > 0
                  ) {
                    anchors.push('invite-parkingClassification')
                  }
                  this.props.processinstance.registrationEntity.parkingDetails.captureFields.forEach(field => {
                    anchors.push(`invite-${field.fieldTechnicalName}`)
                  })
                }
              } else {
                if (
                  this.props.processinstance.registrationEntity.parkingDetails.availableClassifications &&
                  this.props.processinstance.registrationEntity.parkingDetails.availableClassifications.length > 0
                ) {
                  anchors.push('invite-parkingClassification')
                }
                this.props.processinstance.registrationEntity.parkingDetails.captureFields.forEach(field => {
                  anchors.push(`invite-${field.fieldTechnicalName}`)
                })
              }
            }
          }
          if (
            (this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionVideo ||
              this.props.processinstance.registrationEntity.optionalFieldsEnabled.inductionQuestions) &&
            (this.props.registrationinstance.otpValidationHasValidInduction !== true ||
              (this.props.registrationinstance.otpValidationHasValidInduction === true &&
                this.state.flowControl.globalProfileConfirmation.globalProfileConfirmed === true &&
                this.state.flowControl.globalProfileConfirmation.shouldArchiveExisting === true))
          ) {
            anchors.push('invite-induction')
          }
          if (this.props.processinstance.registrationEntity.checkInFieldDetails.checkInFieldsAvailable) {
            const visibleCustomFields: CheckInField[] = []
            this.props.processinstance.registrationEntity.checkInFieldDetails.captureFields
              .filter(captureField => !captureField.availableForConditionInput)
              .forEach(captureField => {
                const rootCondition = this.props.processinstance.registrationEntity.checkInFieldDetails.fieldConditions[captureField.id]
                const { isConditionalVisible, isConditionalEditable } = filterProcessor(rootCondition, this.state.formValues)
                if (isConditionalVisible) {
                  visibleCustomFields.push({
                    ...captureField,
                    readOnly: isConditionalEditable,
                  })
                }
              })
            visibleCustomFields.forEach(field => {
              anchors.push(`invite-${field.id}`)
            })
          }
          if (this.state.flowControl.isCameraEnabled) {
            anchors.push('invite-faceData')
          }
        }
      } else {
        if (this.state.flowControl.postInitialFlowSelection.postFlowUserCanSelect) {
          anchors.push('invite-flowSelection') // flow selection present if user can select
        }
        if (this.state.flowControl.postInitialFlowSelection.parking === true) {
          anchors.push('invite-parkingAvailable')
          if (this.state.flowControl.parking.parkingProcessingEnabled) {
            if (this.props.processinstance.registrationEntity.parkingDetails.hasTerms) {
              anchors.push('invite-parkingTerms')
              if (this.state.flowControl.parking.parkingTermsAccepted) {
                if (
                  this.props.processinstance.registrationEntity.parkingDetails.availableClassifications &&
                  this.props.processinstance.registrationEntity.parkingDetails.availableClassifications.length > 0
                ) {
                  anchors.push('invite-parkingClassification')
                }
                this.props.processinstance.registrationEntity.parkingDetails.captureFields.forEach(field => {
                  anchors.push(`invite-${field.fieldTechnicalName}`)
                })
              }
            } else {
              if (
                this.props.processinstance.registrationEntity.parkingDetails.availableClassifications &&
                this.props.processinstance.registrationEntity.parkingDetails.availableClassifications.length > 0
              ) {
                anchors.push('invite-parkingClassification')
              }
              this.props.processinstance.registrationEntity.parkingDetails.captureFields.forEach(field => {
                anchors.push(`invite-${field.fieldTechnicalName}`)
              })
            }
          }
        }
        if (this.state.flowControl.postInitialFlowSelection.induction === true) {
          anchors.push('invite-induction')
        }
        if (this.state.flowControl.postInitialFlowSelection.checkin === true) {
          const visibleCustomFields: CheckInField[] = []
          this.props.processinstance.registrationEntity.checkInFieldDetails.captureFields
            .filter(captureField => !captureField.availableForConditionInput)
            .forEach(captureField => {
              const rootCondition = this.props.processinstance.registrationEntity.checkInFieldDetails.fieldConditions[captureField.id]
              const { isConditionalVisible, isConditionalEditable } = filterProcessor(rootCondition, this.state.formValues)
              if (isConditionalVisible) {
                visibleCustomFields.push({
                  ...captureField,
                  readOnly: isConditionalEditable,
                })
              }
            })
          visibleCustomFields.forEach(field => {
            anchors.push(`invite-${field.id}`)
          })
        }
      }
      if (
        this.state.flowControl.isCameraEnabled &&
        !!this.props.processinstance.registrationEntity.sourceDeviceToken &&
        !anchors.includes('invite-faceData')
      ) {
        anchors.push('invite-faceData')
      }
      if (this.props.processinstance.registrationEntity.optionalFieldsEnabled.hostSearch) {
        anchors.push('invite-hostSearch')
      }
    }
    return (
      <React.Fragment>
        {debug && (
          <>
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                color: 'white',
                background: 'orange',
                padding: '0 10px',
                opacity: 0.5,
              }}
            >
              DEBUG MODE
            </div>
          </>
        )}
        {/* {isMobile ? (
          <>
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                color: "white",
                background: "orange",
                padding: "0 10px",
                opacity: 0.5
              }}
            >
              MOBILE DEVICE
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                color: "white",
                background: "orange",
                padding: "0 10px",
                opacity: 0.5
              }}
            >
              DESKTOP DEVICE
            </div>
          </>
        )} */}
        <CornerImages showBottomImages={!isMobile} images={this.props.processinstance.registrationEntity.cornerImages} />

        {this.state.flowControl.otpRequest.country && (
          <PageLoadingIndicator loading={this.state.flowControl.profileSubmission.profileSubmissionInProcess}>
            <ReactFullpage
              anchors={anchors}
              animateAnchor={false}
              licenseKey={constants.LIC_KEYS.FP}
              dragAndMove={true}
              fadingEffect={true}
              afterLoad={this.handleAfterSlide}
              onLeave={this.handleSlideLeave}
              render={fpFormRenderer}
              navigation={true}
              className={this.props.className}
              normalScrollElements='.allow-scroll'
              fixedFooter={({ fullpageApi, state }) => {
                const { sectionCount = 0, destination = {}, origin = {} } = state
                const currentIndex =
                  state.lastEvent === 'afterLoad'
                    ? (destination && destination.index ? destination.index : 0) + 1
                    : (origin && origin.index ? origin.index : 0) + 1
                const completePercentage = (currentIndex / sectionCount) * 100
                if (sectionCount > 1) {
                  return (
                    <Navigation
                      currentIndex={currentIndex}
                      sectionCount={sectionCount}
                      completePercentage={completePercentage}
                      isFirst={destination.isFirst}
                      isLast={destination.isLast}
                      moveSectionUp={fullpageApi.moveSectionUp}
                      moveSectionDown={fullpageApi.moveSectionDown}
                    />
                  )
                }
                return null
              }}
            />
            {this.state.flowControl.profileSubmission.submitEnabledState && (
              <SubmissionFooter
                submitRegistration={this._submitProfile}
                visible={this.state.flowControl.profileSubmission.submitEnabledState}
              />
            )}
          </PageLoadingIndicator>
        )}
      </React.Fragment>
    )
  }
}

const Navigation = ({ currentIndex = 0, sectionCount = 0, completePercentage, isFirst, isLast, moveSectionUp, moveSectionDown }) => {
  return (
    <div className='flex space-x-2  ml-auto justify-center max-w-xs px-2'>
      <div className='flex-grow backdrop-filter'>
        <span>
          Question {currentIndex}/{sectionCount}
        </span>
        <Progress percent={completePercentage} size='small' showInfo={false} trailColor='#d8d8d8' className='question-progress' />
      </div>
      <div className='flex'>
        <Button.Group className='question-buttons'>
          <Button
            disabled={isFirst}
            onClick={() => {
              if (moveSectionUp) moveSectionUp()
            }}
            size='large'
            icon='up'
          />
          <Button
            disabled={isLast}
            onClick={() => {
              if (moveSectionDown) moveSectionDown()
            }}
            size='large'
            icon='down'
          />
        </Button.Group>
      </div>
      <style jsx global>{`
        .question-progress .ant-progress-bg {
          background-color: var(--primary);
        }
        .question-buttons button:not([disabled]):hover,
        .question-buttons button:not([disabled]):focus {
          border-color: var(--primary) !important;
          color: var(--primary) !important;
        }
      `}</style>
    </div>
  )
}

const IntlRegistrationForm = injectIntl(RegistrationForm)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntlRegistrationForm)

import { Alert } from "antd"
import styled from "styled-components"

export default styled(Alert)`
  && {
    &.ant-alert-success {
      border-top: 1px solid #b7eb8f;
    }
    &.ant-alert-info {
      border-top: 1px solid #91d5ff;
    }
    &.ant-alert-warning {
      border-top: 1px solid #ffe58f;
    }
    &.ant-alert-error {
      border-top: 1px solid #ffa39e;
    }
    background-color: transparent;
  }
`

import React from 'react'
import StyledSelectSFC, { SelectOption } from '~/app/components/StyledSelect'
import { SelectValue } from 'antd/lib/select'

export interface SelectData {
  key: string
  value: string
}

export type SelectionProps = {
  data: Array<SelectData>
  placeholder?: string
  onSelect: (value: SelectValue, option: React.ReactElement<any>) => any
  value: string
}

const Selection: React.FC<SelectionProps> = props => {
  return (
    <StyledSelectSFC placeholder={props.placeholder} onSelect={props.onSelect} value={props.value} className='bg-red-800'>
      {props.data &&
        props.data.map(item => {
          return <SelectOption key={item.key}>{item.value}</SelectOption>
        })}
    </StyledSelectSFC>
  )
}

export default Selection

import 'core-js/shim'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import React from 'react'
import bootstrapAnalytics from './utils/bootstrapAnalytics'
// import * as serviceWorker from '~/utils/serviceWorker'
import { default as createStore } from '~/utils/createStore'
import { isMobile } from 'react-device-detect'

//local stylesheets
import './app/styles/tailwind.output.css'
import './index.css'
import './static/css/animated.min.css'
import { isIE } from 'react-device-detect'
import getUrlParameter from './utils/getUrlParameter'
import CONSTANTS from './utils/constants'
import awsExports from './aws-exports'
import { Modal } from 'antd'
import Title from 'antd/lib/typography/Title'

if (isMobile) {
  document.body.classList.add('mobile-device')
}

//for dev - ensure hash removed on launch
if (window.location.hash.length > 0 && !window.location.hash.includes('debug')) {
  window.location.replace(window.location.href.replace(window.location.hash, ''))
}

console.log('%c 🚀 Kenai Pre-Registration', 'font-size:1.5rem;font-weight:500')

if (process.env.NODE_ENV === 'production') {
  window.console = {
    log: () => {
      return
    },
    error: () => {
      return
    },
    warn: () => {
      return
    },
  } as any
}

const setupEnv = environment => {
  let awsExportsJSON = JSON.stringify(awsExports)
  Object.keys(environment).forEach(key => {
    awsExportsJSON = awsExportsJSON.replace(new RegExp(`__${key}__`, 'g'), environment[key])
  })
  const updatedawsExports = JSON.parse(awsExportsJSON)
  Object.keys(updatedawsExports).forEach(key => {
    awsExports[key] = updatedawsExports[key]
  })
}

const fetchEnvironment = () => {
  return new Promise(resolve => {
    try {
      const envLookup = `invites-${CONSTANTS.ENV_QUALIFIER}-${window.location.host.split(':')[0]}`
      fetch(`https://envdiscovery.kenai.co.za/${envLookup}.json?x=${Date.now()}`)
        .then(res => {
          return res.json()
        })
        .then(environment => {
          setupEnv(environment)
          resolve(true)
        })
        .catch(e => {
          const envLookupFallback = `invites-${CONSTANTS.ENV_QUALIFIER}-default`
          fetch(`https://envdiscovery.kenai.co.za/${envLookupFallback}.json?x=${Date.now()}`)
            .then(res => {
              return res.json()
            })
            .then(environment => {
              setupEnv(environment)
              resolve(true)
            })
            .catch(e => {
              console.log(e)
              Modal.destroyAll()
              Modal.confirm({
                title: <Title className='my-0'>Error</Title>,
                content: 'There was an error loading configuration for the site. Would you like to retry?',
                maskClosable: false,
                onOk: () => {
                  window.location.reload()
                },
                okText: 'Retry',
                cancelButtonProps: { style: { display: 'none' } },
                centered: true,
              })
            })
        })
    } catch (e) {
      console.log(e)
      Modal.destroyAll()
      Modal.confirm({
        title: <Title className='my-0'>Error</Title>,
        content: 'There was an error loading configuration for the site. Would you like to retry?',
        maskClosable: false,
        onOk: () => {
          window.location.reload()
        },
        okText: 'Retry',
        cancelButtonProps: { style: { display: 'none' } },
        centered: true,
      })
    }
  })
}

const bootstrap = async () => {
  if (isIE) {
    require('whatwg-fetch')
  }
  const environmentFetched = await fetchEnvironment()

  if (environmentFetched === true) {
    //bootstrap anayltics
    if (window.location.search && window.location.search.indexOf('token=') > -1 && window.location.search.length > 7) {
      const inviteToken = getUrlParameter('token')
      bootstrapAnalytics(inviteToken)
    }

    //register a service worker for running async tasks
    // serviceWorker.register()
    if ('serviceWorker' in navigator) {
      //? console.log('1. has service worker')
      navigator.serviceWorker.getRegistrations().then(function(registrations) {
        //? console.log('2. get service worker registration')
        for (let registration of registrations) {
          //? console.log('3. unregister service worker')
          registration.unregister()
        }
      })
    }

    let storeRefs = createStore()

    //render the base Router component
    let render = () => {
      const AppRouterCTR = require('~/app/containers/AppRouterCTR').default
      ReactDOM.render(
        <Provider store={storeRefs.store}>
          <AppRouterCTR storeRefs={storeRefs} />
        </Provider>,
        document.getElementById('root')
      )
    }

    // if (module.hot) {
    //   const renderApp = render
    //   render = () => {
    //     try {
    //       renderApp()
    //     } catch (e) {
    //       //no handling as dev only, however consider redbox
    //       throw new Error(e)
    //     }
    //   }

    //   module.hot.accept('~/app/containers/AppRouterCTR', () => {
    //     setTimeout(render)
    //   })
    // }
    render()
  }
}

bootstrap()

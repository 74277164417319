import awsexports from '~/aws-exports'
import * as interfaces from '~/typing/KENAI/interfaces.d.ts'
import * as enums from '~/typing/KENAI/enums.d.ts'

const getApiEndpoint = (apiName: string): string => {
  let endpoint = ''
  awsexports.aws_cloud_logic_custom.forEach(api => {
    if (api.name === apiName) {
      endpoint = api.endpoint
    }
  })
  return endpoint
}

export default class API {
  static getEventBasedInviteToken(eventToken: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const endpoint = getApiEndpoint(enums.API_NAMES.INVITATION_OPERTATIONS)
      fetch(`${endpoint}/${enums.INVITATION_OPERATIONS.EVENT_BASED_INVITE_PROCESSOR}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          eventToken: eventToken,
          operation: enums.INVITATION_REGISTRATION_OPERATIONS.GET_EVENT_BASED_INVITE_TOKEN,
        }),
      })
        .then(response => response.json())
        .then(jsonResponse => resolve(jsonResponse))
        .catch(e => reject(e))
    })
  }

  static getDeviceBasedInviteToken(deviceToken: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const endpoint = getApiEndpoint(enums.API_NAMES.INVITATION_OPERTATIONS)
      fetch(`${endpoint}/${enums.INVITATION_OPERATIONS.DEVICE_BASED_INVITE_PROCESSOR}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          deviceToken: deviceToken,
          operation: enums.INVITATION_REGISTRATION_OPERATIONS.GET_DEVICE_BASED_INVITE_TOKEN,
        }),
      })
        .then(response => response.json())
        .then(jsonResponse => resolve(jsonResponse))
        .catch(e => reject(e))
    })
  }

  static getEntityConfig(
    inviteToken: string
  ): Promise<{
    key: string
    type: string
    configData: interfaces.REGISTRATION_ENTITY
  }> {
    return new Promise((resolve, reject) => {
      const endpoint = getApiEndpoint(enums.API_NAMES.INVITATION_OPERTATIONS)
      fetch(`${endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_REGISTRATION_PROCESSOR}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inviteToken: inviteToken,
          operation: enums.INVITATION_REGISTRATION_OPERATIONS.GET_ENTITY_CONFIG,
        }),
      })
        .then(response => response.json())
        .then(jsonResponse => resolve(jsonResponse))
        .catch(e => reject(e))
    })
  }

  static retrieveEntityAgreement(inviteToken: string): Promise<interfaces.VISITOR_AGREEMENT> {
    return new Promise((resolve, reject) => {
      const endpoint = getApiEndpoint(enums.API_NAMES.INVITATION_OPERTATIONS)
      fetch(`${endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_REGISTRATION_PROCESSOR}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inviteToken: inviteToken,
          operation: enums.INVITATION_REGISTRATION_OPERATIONS.RETRIEVE_ENTITY_AGREEMENT,
        }),
      })
        .then(response => response.json())
        .then(jsonResponse => resolve(jsonResponse))
        .catch(e => reject(e))
    })
  }
  static requestOTP(inviteToken: string, phoneNumber: string, channel: 'sms' | 'whatsapp' = 'sms'): Promise<any> {
    return new Promise((resolve, reject) => {
      const endpoint = getApiEndpoint(enums.API_NAMES.INVITATION_OPERTATIONS)
      fetch(`${endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_REGISTRATION_PROCESSOR}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inviteToken: inviteToken,
          operation: enums.INVITATION_REGISTRATION_OPERATIONS.REQUEST_OTP,
          phoneNumber,
          channel,
        }),
      })
        .then(response => response.json())
        .then(jsonResponse => resolve(jsonResponse))
        .catch(e => reject(e))
    })
  }
  static validateOTP(inviteToken: string, phoneNumber: string, instanceCode: string, otpCode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const endpoint = getApiEndpoint(enums.API_NAMES.INVITATION_OPERTATIONS)
      fetch(`${endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_REGISTRATION_PROCESSOR}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inviteToken: inviteToken,
          operation: enums.INVITATION_REGISTRATION_OPERATIONS.VALIDATE_OTP,
          phoneNumber,
          instanceCode: parseInt(instanceCode, 10),
          otpCode: parseInt(otpCode, 10),
        }),
      })
        .then(response => response.json())
        .then(jsonResponse => resolve(jsonResponse))
        .catch(e => reject(e))
    })
  }
  static validateFace(inviteToken: string, instanceCode: string, faceBase64: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const endpoint = getApiEndpoint(enums.API_NAMES.INVITATION_OPERTATIONS)
      fetch(`${endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_REGISTRATION_PROCESSOR}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inviteToken: inviteToken,
          instanceCode: parseInt(instanceCode, 10),
          operation: enums.INVITATION_REGISTRATION_OPERATIONS.VALIDATE_FACE,
          faceBase64,
        }),
      })
        .then(response => response.json())
        .then(jsonResponse => resolve(jsonResponse))
        .catch(e => reject(e))
    })
  }
  static submitProfile(
    inviteToken: string,
    instanceCode: string,
    firstName: string,
    lastName: string,
    email: string,
    company: string,
    submittedWithFace: boolean,
    archiveExisting: boolean,
    inviteOnlyFieldValues: object,
    creationEventTiming: interfaces.EventTiming,
    personalIdentificationNr?: string,
    parkingData?: object,
    inductionData?: object,
    checkInFieldValues?: object,
    inProcessProfileId?: string,
    selectedHost?: object,
    phoneNumber?: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const endpoint = getApiEndpoint(enums.API_NAMES.INVITATION_OPERTATIONS)
      fetch(`${endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_REGISTRATION_PROCESSOR}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inviteToken: inviteToken,
          operation: enums.INVITATION_REGISTRATION_OPERATIONS.SUBMIT_PROFILE,
          instanceCode: parseInt(instanceCode, 10),
          profileSubmission: {
            profileValues: {
              firstName,
              lastName,
              ...(email && email.length > 0 && { email }),
              ...(company && company.length > 0 && { company }),
              ...(personalIdentificationNr &&
                personalIdentificationNr.length > 0 && {
                  personalIdentificationNr,
                }),
              ...(inProcessProfileId && {
                ProfileId: inProcessProfileId,
              }),
              ...(phoneNumber && phoneNumber.length > 0 && { phoneNumber }),
            },
            flags: {
              submittedWithFace,
              archiveExisting,
            },
            inviteOnlyFieldValues,
            ...(parkingData && { parkingData }),
            ...(inductionData && { inductionData }),
            ...(checkInFieldValues && { checkInFieldValues }),
            creationEventTiming,
            selectedHost,
          },
        }),
      })
        .then(response => response.json())
        .then(jsonResponse => resolve(jsonResponse))
        .catch(e => reject(e))
    })
  }

  static confirmLocalProfile(inviteToken: string, instanceCode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const endpoint = getApiEndpoint(enums.API_NAMES.INVITATION_OPERTATIONS)
      fetch(`${endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_REGISTRATION_PROCESSOR}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inviteToken: inviteToken,
          instanceCode: parseInt(instanceCode, 10),
          operation: enums.INVITATION_REGISTRATION_OPERATIONS.CONFIRM_LOCAL_PROFILE,
        }),
      })
        .then(response => response.json())
        .then(jsonResponse => resolve(jsonResponse))
        .catch(e => reject(e))
    })
  }

  static devArchiveProfileAndResetToken(inviteToken: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const endpoint = getApiEndpoint(enums.API_NAMES.INVITATION_OPERTATIONS)
      fetch(`${endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_REGISTRATION_PROCESSOR}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inviteToken: inviteToken,
          operation: enums.INVITATION_REGISTRATION_OPERATIONS.DEV_ARCHIVE_AND_RESET,
        }),
      })
        .then(response => response.json())
        .then(jsonResponse => resolve(jsonResponse))
        .catch(e => reject(e))
    })
  }

  static requestSetupOTP(setupToken: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const endpoint = getApiEndpoint(enums.API_NAMES.INVITATION_OPERTATIONS)
      fetch(`${endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_SETUP_PROCESSOR}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          setupToken,
          operation: enums.INVITATION_SETUP_OPERATIONS.REQUEST_SETUP_OTP,
          requestContent: {},
        }),
      })
        .then(response => response.json())
        .then(jsonResponse => resolve(jsonResponse))
        .catch(e => reject(e))
    })
  }

  static validateSetupOTP(setupToken: string, instanceCode: string, otpCode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const endpoint = getApiEndpoint(enums.API_NAMES.INVITATION_OPERTATIONS)
      fetch(`${endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_SETUP_PROCESSOR}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          setupToken,
          operation: enums.INVITATION_SETUP_OPERATIONS.VALIDATE_SETUP_OTP,
          requestContent: {
            instanceCode: parseInt(instanceCode, 10),
            otpCode: parseInt(otpCode, 10),
          },
        }),
      })
        .then(response => response.json())
        .then(jsonResponse => resolve(jsonResponse))
        .catch(e => reject(e))
    })
  }

  static searchSetupHost(setupToken: string, instanceCode: string, searchString: string, entityHierarchy: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const endpoint = getApiEndpoint(enums.API_NAMES.INVITATION_OPERTATIONS)
      fetch(`${endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_SETUP_PROCESSOR}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          setupToken,
          operation: enums.INVITATION_SETUP_OPERATIONS.SEARCH_SETUP_HOST,
          requestContent: {
            instanceCode: parseInt(instanceCode, 10),
            searchString: searchString,
            entityHierarchy: entityHierarchy,
          },
        }),
      })
        .then(response => response.json())
        .then(jsonResponse => resolve(jsonResponse))
        .catch(e => reject(e))
    })
  }

  static getSetupAttendeeDetailsForLocation(setupToken: string, instanceCode: string, entityHierarchy: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const endpoint = getApiEndpoint(enums.API_NAMES.INVITATION_OPERTATIONS)
      fetch(`${endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_SETUP_PROCESSOR}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          setupToken,
          operation: enums.INVITATION_SETUP_OPERATIONS.GET_ATTENDEE_DETAILS_FOR_LOCATION,
          requestContent: {
            instanceCode: parseInt(instanceCode, 10),
            SelectedLocation: entityHierarchy,
          },
        }),
      })
        .then(response => response.json())
        .then(jsonResponse => resolve(jsonResponse))
        .catch(e => reject(e))
    })
  }

  static submitSetupProcessingUpdate(setupToken: string, instanceCode: string, setupProcessingDetailsSubmission: object): Promise<any> {
    return new Promise((resolve, reject) => {
      const endpoint = getApiEndpoint(enums.API_NAMES.INVITATION_OPERTATIONS)
      fetch(`${endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_SETUP_PROCESSOR}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          setupToken,
          operation: enums.INVITATION_SETUP_OPERATIONS.SUBMIT_PROCESSING_UPDATE,
          requestContent: {
            instanceCode: parseInt(instanceCode, 10),
            setupProcessingDetailsSubmission: setupProcessingDetailsSubmission,
          },
        }),
      })
        .then(response => response.json())
        .then(jsonResponse => resolve(jsonResponse))
        .catch(e => reject(e))
    })
  }

  static submitResendAction(
    setupToken: string,
    instanceCode: string,
    email: string,
    resendAction: enums.SETUP_ATTENDEE_RESEND_ACTIONS
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const endpoint = getApiEndpoint(enums.API_NAMES.INVITATION_OPERTATIONS)
      let operation = ''
      if (resendAction === enums.SETUP_ATTENDEE_RESEND_ACTIONS.INVITE) {
        operation = enums.INVITATION_SETUP_OPERATIONS.SUBMIT_RESEND_INVITE
      } else if (resendAction === enums.SETUP_ATTENDEE_RESEND_ACTIONS.PRE_REG) {
        operation = enums.INVITATION_SETUP_OPERATIONS.SUBMIT_RESEND_PREREG
      }
      fetch(`${endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_SETUP_PROCESSOR}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          setupToken,
          operation: operation,
          requestContent: {
            instanceCode: parseInt(instanceCode, 10),
            email: email,
          },
        }),
      })
        .then(response => response.json())
        .then(jsonResponse => resolve(jsonResponse))
        .catch(e => reject(e))
    })
  }

  static submitUpdatePostRegistrationCompletion(
    inviteToken: string,
    instanceCode: string,
    inductionData?: object,
    parkingData?: object,
    checkInFieldValues?: object,
    selectedHost?: object,
    submittedWithFace?: boolean
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const endpoint = getApiEndpoint(enums.API_NAMES.INVITATION_OPERTATIONS)
      fetch(`${endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_REGISTRATION_PROCESSOR}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inviteToken: inviteToken,
          instanceCode: parseInt(instanceCode, 10),
          operation: enums.INVITATION_REGISTRATION_OPERATIONS.SUBMIT_UPDATE_POST_REGISTRATION_COMPLETION,
          profileSubmission: {
            inductionData,
            parkingData,
            checkInFieldValues,
            selectedHost,
            flags: {
              submittedWithFace,
            },
          },
        }),
      })
        .then(response => response.json())
        .then(jsonResponse => resolve(jsonResponse))
        .catch(e => reject(e))
    })
  }

  static searchRegistrationHost(inviteToken: string, instanceCode: string, searchString: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const endpoint = getApiEndpoint(enums.API_NAMES.INVITATION_OPERTATIONS)
      fetch(`${endpoint}/${enums.INVITATION_OPERATIONS.INVITATION_REGISTRATION_PROCESSOR}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inviteToken,
          operation: enums.INVITATION_REGISTRATION_OPERATIONS.SEARCH_REGISTRATION_HOST,
          instanceCode: parseInt(instanceCode, 10),
          hostSearchString: searchString,
        }),
      })
        .then(response => response.json())
        .then(jsonResponse => resolve(jsonResponse))
        .catch(e => reject(e))
    })
  }

  // generateVisitorAgreementRequest(EntityHierarchy, ProfileId, AgreementVersion, currentUserCredentials) {
  //   return new Promise((resolve, reject) => {
  //     let options = {
  //       headers: {
  //         Authorization: `Bearer ${currentUserCredentials.idToken}`,
  //       },
  //       body: {
  //         responseType: "PDF",
  //         EntityHierarchy,
  //         ProfileId,
  //         AgreementVersion: `${AgreementVersion}`,
  //       },
  //     }
  //     AmplifyAPI.post("profileOperations", "/generateVisitorAgreement", options)
  //       .then(data => {
  //         resolve(data)
  //         // data = {
  //         //   key: 'PDF_GENERATED',
  //         //   pdfBase64,
  //         //   companyName: pdfValues.companyName,
  //         //   signatoryEmail: signedAgreement.signatoryEmail,
  //         //   signatoryFullName: signedAgreement.signatoryFullName,
  //         //   signatureTimeStamp: moment(signedAgreement.signatureTimeStamp).format("MMMM D, YYYY")
  //         // }
  //       })
  //       .catch(err => {
  //         if (err && err.response && err.response.data) {
  //           reject(err.response)
  //         } else {
  //           reject(err)
  //         }
  //       })
  //   })
  // }
}

import { css } from 'styled-components'

export default css`
  .induction-confirmskip-popup {
    .ant-popover-buttons,
    i.anticon {
      display: none;
    }
    .ant-popover-message-title {
      padding-left: 0px;
    }
  }
  .registration-host-ddl {
    li {
      height: 40px;
      font-size: 16px;
      padding: 5px 12px;
      padding-top: 0px;
      line-height: 40px;
      color: rgba(16, 45, 61, 0.65);
      background-color: #fafafa;
      cursor: pointer;
    }
  }
`

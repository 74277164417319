// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons"
import EnterButton from '~/app/components/EnterButton'
import AddonButton from '~/app/components/SharedFormComponents/AddonButtonSFC'
import CustomFormItem from '~/app/components/SharedFormComponents/CustomFormItem'
import FormLabel from '~/app/components/SharedFormComponents/FormLabelSFC'
import StyledInputAlert from '~/app/components/StyledInputAlert'
import { Input } from 'antd'
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form'
import React from 'react'
import { Animated } from 'react-animated-css'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import { FlowControl, FormValues } from '../../component'

const messages = {
  ERROR_INPUT_REQUIRED: { id: 'form.registration.input.error.required' },
}

type Props = {
  getFieldDecorator: <T>(id: keyof T, options?: GetFieldDecoratorOptions | undefined) => (node: React.ReactNode) => React.ReactNode
  formValues: FormValues
  flowControl: FlowControl
  isMobile: boolean
  inputProps?: object

  fieldName: string
  fieldNumber: string
  fieldLabel: React.ReactNode | string
  fieldRules?: Array<object>
  fieldSubLabel?: string
  fieldRequired?: boolean
  fieldRequiredText?: string
  fieldPlaceHolder: string
  intl?: any
  processOnPressEnter: (event: React.FormEvent<HTMLInputElement>) => void
  processOnInputBlur: () => void
  onTextInputKeyDown: (event: any) => void
  nextItemFocus: () => void
}

const autoCompleteRandomizer = Date.now()

const Wrapper = styled.div`
  position: relative;
  .ant-form-extra {
    position: absolute;
  }
  .ant-form-item {
    margin-bottom: 5px;
  }
`

const DefaultInput = (props: Props) => {
  const { formatMessage } = useIntl()
  const rules: Array<object> = []

  if (props.fieldRules && props.fieldRules.length > 0) {
    props.fieldRules.forEach((item: object) => {
      rules.push(item)
    })
  }
  if (props.fieldRequired === true) {
    rules.push({
      required: true,
      message: (
        <>
          <Animated animationIn='fadeInDown' animationOut='fadeOutUp' animateOnMount={true} isVisible={true} animationInDelay={0}>
            <StyledInputAlert
              className={'input-error'}
              type='error'
              message={
                props.fieldRequiredText && props.fieldRequiredText.length > 0
                  ? props.fieldRequiredText
                  : formatMessage(messages.ERROR_INPUT_REQUIRED)
              }
              banner={true}
            />
          </Animated>
        </>
      ),
    })
  }

  return (
    <Wrapper>
      <CustomFormItem
        className={props.fieldRequired ? 'required-form-item' : ''}
        label={
          <FormLabel number={props.fieldNumber} {...props.fieldSubLabel && { sublabel: props.fieldSubLabel }}>
            {props.fieldLabel}
          </FormLabel>
        }
        extra={
          <Animated
            animationIn='fadeInUp'
            animationOut='fadeOutDown'
            animateOnMount={false}
            isVisible={props.formValues[props.fieldName].isValid && !props.isMobile}
            style={{ marginTop: '-10px !important' }}
          >
            <EnterButton size='large' onClick={props.nextItemFocus}>
              <FormattedMessage id='form.registration.button.ok' />
            </EnterButton>
          </Animated>
        }
      >
        {props.getFieldDecorator(props.fieldName, {
          rules: rules,
        })(
          <Input
            data-hj-suppress={true}
            autoComplete={`kenai-${props.fieldName}-${autoCompleteRandomizer}`}
            onPressEnter={props.processOnPressEnter}
            onBlur={props.processOnInputBlur}
            onKeyDown={props.onTextInputKeyDown}
            placeholder={props.fieldPlaceHolder}
            size='large'
            {...props.inputProps}
            {...props.isMobile && {
              addonAfter: (
                <AddonButton
                  nextItemFocus={props.nextItemFocus}
                  isValid={props.formValues[props.fieldName].isValid}
                  value={props.formValues[props.fieldName].value as string}
                />
              ),
            }}
          />
        )}
      </CustomFormItem>
    </Wrapper>
  )
}

export default DefaultInput

import { css } from "styled-components";
import { isIE, isEdge } from "react-device-detect";

const __dropdown_item = css`
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      opacity: 0.1;
      transition: all 0.3s;
    }
    :hover {
      background-color: transparent;
      &::before {
        background-color: ${props => props.theme.branding.primary};
      }
    }
  }
`;

export default css`
  .locale-button {
    border: none !important;
    font-size: 14px !important;
    opacity: 0.8 !important;
    transition: opacity 0.3s;
    &:hover {
      opacity: 1;
    }
    &.ant-btn:not(.ant-btn-primary):hover,
    &.ant-btn:not(.ant-btn-primary):focus {
      border-color: ${props => props.theme.branding.primary};
      color: ${props => props.theme.branding.primary};
    }
    &.ant-btn-primary {
      background-color: ${props => props.theme.branding.primary};
      border-color: ${props => props.theme.branding.primary};
      &[disabled] {
        background-color: #fafafa;
        border-color: #fafafa;
      }
    }
    &.ant-btn-background-ghost {
      &.ant-btn-primary {
        color: ${props => props.theme.branding.primary};
        border-color: ${props => props.theme.branding.primary};
        &[disabled] {
          background-color: #fafafa;
          color: #fafafa;
          border-color: #fafafa;
        }
      }
    }
  }

  .locale-dropdown {
    .ant-dropdown-menu {
      box-shadow: none;
      text-align: center;

      ${!isIE && !isEdge && __dropdown_item}
    }
  }
`;

import { Form as AntForm } from 'antd'
import styled, { css } from 'styled-components'
import { isMobileSafari, isIOS, isTablet, isMobile, isChrome } from 'react-device-detect'
const AntFormItem = AntForm.Item

const isMobileIOSChrome = isMobile && isIOS && isChrome && !isTablet

const inputFocusMarginBottom = !isMobileIOSChrome && !isMobileSafari ? `${window.outerHeight * 0.4}px` : '0px'

const _MobileStyles = css`
  max-width: 95vw;
  justify-content: space-evenly;
  display: flex;
  .ant-input,
  input.form-control {
    height: 60px !important;
    padding-left: 10px;
    ::-webkit-input-placeholder {
      color: transparent;
    }
  }
  .ant-select-selection {
    height: 60px !important;
    ::-webkit-input-placeholder {
      color: transparent;
    }
    .ant-select-selection__rendered,
    .ant-select-selection-selected-value {
      height: 60px !important;
      line-height: 60px !important;
    }
  }
  .ant-input.react-phone-number-input {
    display: flex;
    padding-left: 6px;
    padding-right: 6px;
    .react-phone-number-input__row {
      width: 100%;
    }

    .react-phone-number-input__ext {
      display: grid;
      color: transparent;
      grid-template-areas: 'button .';
      grid-template-columns: 1fr 0px;
      margin-left: 0px;
      button,
      .keyboard-icon {
        grid-area: button;
      }
      .keyboard-icon {
        color: rgba(128, 128, 128, 0.49);
        height: 48px;
        width: 3em;
      }
    }
    &:focus-within {
      .input-focus-hidden {
        opacity: 0;
      }
    }
  }
  .input-focus-hidden {
    transition: opacity 0.3s ease-in-out;
  }
  .react-phone-number-input {
    border: 2px dashed rgba(128, 128, 128, 0.49);
    border-radius: 4px;
    /* overflow: hidden; */
    &:focus,
    &:hover {
      border-right-width: 2px !important;
    }
  }

  .ant-form-item-control:not(.has-success) {
    .react-phone-number-input {
      &:focus-within {
        .react-phone-number-input__ext {
          width: 0px;
          opacity: 0;
        }
      }
    }
  }

  .ant-input-group,
  &.host-search .ant-form-item-control-wrapper {
    display: flex;
    border: 2px dashed rgba(128, 128, 128, 0.49);
    border-radius: 4px;
    overflow: hidden;
    -ms-overflow-style: none;
    .ant-input,
    input.form-control {
      /* border: 2px dashed rgba(128, 128, 128, 0.49); */
      border: none !important;
      max-width: 84%;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .ant-form-item-control {
      width: 100%;
    }
    .ant-input-group-addon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 6px;
      height: 60px !important;
      /* border: 2px dashed rgba(128, 128, 128, 0.49); */
      border: none !important;
      border-left-width: 0px !important;
      width: auto;
      line-height: 2.6;
      /* border-top-right-radius: 4px;
        border-bottom-right-radius: 4px; */
      background-color: transparent;
      box-shadow: none;
      outline: none;

      > svg {
        font-size: 40px;
        color: rgba(128, 128, 128, 0.49);
      }
      button {
        padding: 0 10px;
      }
    }
  }
  .has-error {
    .ant-input-group,
    .react-phone-number-input {
      border-color: #f5222d !important;
    }
  }
  .ant-form-item-label {
    padding: 0 0 20px;
  }
  :not(.no-focus-adjustment) .ant-form-item-control-wrapper {
    transition: all 0.3s ease-in-out;
    margin: 0px;
    max-width: 100%;
    &:focus-within {
      margin-bottom: ${inputFocusMarginBottom}!important;
    }
  }
`

/* margin-bottom: ${!isMobileSafari ? `${window.outerHeight * 0.4}px` : "0px"} !important; */

const CustomFormItem = Component => styled(Component)`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 70vw;
  margin: auto;
  .ant-form-explain,
  .ant-form-extra {
    /* margin-top: -40px; */
    color: red;
  }
  .ant-form-item-label {
    text-align: left;
    label {
      display: inline-box;
      &::after {
        content: '';
      }
      &.ant-form-item-required {
        &::before,
        &::after {
          content: '';
        }
      }
    }
  }
  .ant-form-item-control-wrapper {
    margin-left: 30px;
    max-width: calc(100% - 60px);
    .intl-tel-input {
      width: 100%;
      /* margin-bottom: 10px; */
      border-radius: 4px;
      &.allow-dropdown {
        &.separate-dial-code {
          .selected-flag {
            background: transparent;
          }
        }
      }
      .country-list {
        overflow: auto;
      }
      .flag-container {
        padding: 0px;
        .selected-flag {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          &:focus {
            outline: none;
          }
        }
      }
      input {
        width: 100%;
        border-radius: 4px;
        padding-bottom: 3px;
      }
    }
    /* input:not(.react-phone-number-input__input) {
       margin-bottom: 10px; 
    } */
    input.react-phone-number-input__input {
      border-bottom: none;
    }
  }
  .ant-form-item-required {
    &::before {
      margin-right: 0px;
      position: absolute;
      right: 0px;
      bottom: 16px;
    }
  }

  .ant-input,
  input.form-control,
  input.react-phone-number-input__input {
    border: none;
    outline: none;
    box-shadow: none;
    color: ${props => props.theme.branding.primary};
    font-size: 24px;
    padding-left: 0px;
    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }
    &::-webkit-input-placeholder {
      color: rgb(226, 225, 225);
    }
    &.ant-input-disabled {
      background-color: transparent;
    }
  }
  .has-error & {
    .ant-input,
    input.form-control {
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
      &:focus {
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
      }
    }
  }
  @media screen and (max-width: 400px) {
    max-width: 95vw;
    .ant-input,
    input,
    .react-phone-number-input__input {
      font-size: 18px !important;
    }
  }

  .mobile-form &,
  .mobile-device & {
    ${_MobileStyles};
  }
  &.no-focus &,
  .no-focus & {
    .ant-form-item-control-wrapper {
      &:focus-within {
        margin-bottom: 0px !important;
      }
    }
  }
  .image-uploader > .ant-upload,
  .face-image {
    width: 110px;
    height: 110px;
    object-fit: cover;
    margin: 0;
  }

  .flip-image {
    transform: scaleX(-1);
  }

  .ant-radio-button-wrapper {
    border-color: ${props => props.theme.branding.primary};
    box-shadow: none !important;
    color: ${props => props.theme.branding.primary};
  }
  .flex-container {
    display: flex;
    > * {
      margin-right: 10px;
    }
  }
  .react-phone-number-input__icon {
    border: none;
    .react-phone-number-input__icon-image {
      vertical-align: top;
    }
    .react-phone-number-input__country-select-arrow {
      margin-top: 0.1em;
    }
  }
  .ant-input-wrapper {
    &:focus-within {
      .input-focus-hidden {
        opacity: 0;
        pointer-events: none;
      }
    }
    .input-focus-hidden {
      transition: opacity 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }
`
export default CustomFormItem(AntFormItem)

import React from 'react'
import { Popover } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons'
import * as interfaces from '~/typing/KENAI/interfaces.d.ts'
import * as enums from '~/typing/KENAI/enums.d.ts'

import StyledTable from './TableComponents/StyledTableSFC'
import StyledSwitch from '~/app/components/StyledSwitch'
import StyledCheckbox from '~/app/components/StyledCheckBox'
import RenderStatus from './TableComponents/RenderStatusSFC'
import PreviewEmail from './TableComponents/PreviewEmail'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import UpdatePopupContent from './TableComponents/UpdatesPopupContentSFC'
import StyledButtonSFC from '../../StyledButton'
import FlexItem from './FlexItemSFC'
import { FormattedMessage } from 'react-intl'

type TableProps = {
  attendees: Array<interfaces.SETUP_ATTENDEE_DETAILS>
  attendeeDetailsFlowControl: any
  onInviteAttendeeSwitchChange: (checked: boolean, email: string) => void
  onInviteAttendeeSendChange: (checkboxChangeEvent: CheckboxChangeEvent, email: string) => void
  onInviteAttendeeBulkSendChange: (checkboxChangeEvent: CheckboxChangeEvent) => void
  onInviteAttendeeProcessPopupConfirmation: (confirmed: boolean, record: any) => void
}

type TableState = {
  previewEmailModal: boolean
}

class SetupTable extends React.Component<TableProps, TableState> {
  columns: any
  state = {
    previewEmailModal: false,
  }
  constructor(props) {
    super(props)
    this.columns = [
      {
        title: (
          <span>
            <FormattedMessage id='form.setup.table.create-invite.title' />{' '}
            <Popover
              content={<FormattedMessage id='form.setup.table.create-invite.tooltip' tagName='p' />}
              trigger='hover'
              overlayClassName='dark-popover'
              overlayStyle={{ width: '280px' }}
            >
              <i title='Filter menu' className='anticon ant-dropdown-trigger'>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </i>
            </Popover>
          </span>
        ),
        dataIndex: 'shouldCreateInvite',
        width: '130px',
        align: 'center',
        className: 'switch-cell',
        render: (value, record) => {
          const processChange = (checked: boolean) => {
            this.props.onInviteAttendeeSwitchChange(checked, record.email)
          }
          return this.props.attendees.length >= 1 ? (
            <StyledSwitch
              defaultChecked={value}
              disabled={!record.inviteToggleEnabled}
              className={record.inviteToggleEnabled ? 'applicable' : 'not-applicable'}
              onChange={processChange}
            />
          ) : null
        },
      },
      {
        title: <FormattedMessage id='form.setup.table.email-address.title' />,
        dataIndex: 'email',
        editable: false,
        width: '250px',
        className: 'email-cell',
        // sorter: true,
      },
      {
        title: <FormattedMessage id='form.setup.table.visitor-registration.title' />,
        dataIndex: 'registrationStatusText',
        align: 'center',
        width: '200px',
        render: (value, record) => {
          return this.props.attendees.length >= 1 ? <RenderStatus status={value} /> : null
        },
        // sorter: true,
      },
      {
        title: (
          <span>
            <FormattedMessage id='form.setup.table.email-invites.title' />{' '}
            <Popover
              content={<FormattedMessage id='form.setup.table.email-invites.tooltip' tagName='p' />}
              trigger='hover'
              overlayClassName='dark-popover'
              overlayStyle={{ width: '280px' }}
            >
              <i title='Filter menu' className='anticon ant-dropdown-trigger'>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </i>
            </Popover>
          </span>
        ),
        dataIndex: 'inviteSendText',
        align: 'center',
        width: '200px',
        render: (value, record) => {
          const processChange = (checkboxChangeEvent: CheckboxChangeEvent) => {
            this.props.onInviteAttendeeSendChange(checkboxChangeEvent, record.email)
          }
          const onClickCancel = () => {
            this.props.onInviteAttendeeProcessPopupConfirmation(false, record)
          }
          const onClickYes = () => {
            this.props.onInviteAttendeeProcessPopupConfirmation(true, record)
          }
          return this.props.attendees.length >= 1 ? (
            <Popover
              key={record.inviteSendPopoverKey}
              content={
                <UpdatePopupContent
                  changes={
                    record.inviteSendPopoverChanges &&
                    record.inviteSendPopoverChanges.map((inviteSendPopoverChange, index) => <p key={index}>{inviteSendPopoverChange}</p>)
                  }
                  question={record.inviteSendPopoverQuestion}
                  actions={
                    <FlexItem>
                      <StyledButtonSFC type='primary' ghost={true} size='small' onClick={onClickCancel} style={{ marginRight: '5px' }}>
                        <FormattedMessage id='global.text.cancel' />
                      </StyledButtonSFC>
                      <StyledButtonSFC type='primary' size='small' onClick={onClickYes}>
                        <FormattedMessage id='global.text.yes' />
                      </StyledButtonSFC>
                    </FlexItem>
                  }
                />
              }
              trigger='click'
              visible={record.inviteSendPopoverVisible}
              placement={'topRight'}
            >
              <StyledCheckbox
                checked={record.inviteEmailCommand === enums.ATTENDEE_EMAIL_COMMANDS.SEND && record.shouldCreateInvite}
                onChange={processChange}
                disabled={!record.shouldCreateInvite}
              >
                {value}
              </StyledCheckbox>
            </Popover>
          ) : null
        },
      },
    ]
  }

  onTogglePreviewEmail = () => {
    this.setState({ previewEmailModal: !this.state.previewEmailModal })
  }

  render() {
    return (
      <React.Fragment>
        <StyledTable
          dataSource={this.props.attendees}
          columns={this.columns}
          rowKey={record => record.email}
          scroll={{ x: true }}
          loading={this.props.attendeeDetailsFlowControl.attendeeDetailsInProcess}
        />
        <PreviewEmail visible={this.state.previewEmailModal} onCancel={this.onTogglePreviewEmail} onOk={this.onTogglePreviewEmail} />
      </React.Fragment>
    )
  }
}

export default SetupTable

import React, { Fragment } from 'react'
import styled from 'styled-components'
import { CornerImages as ICornerImages } from '~/typing/KENAI/interfaces.d.ts'

const TLImage = styled.img`
  position: fixed !important;
  top: 0;
  left: 0;
  max-width: 25%;
  z-index: 99;
  pointer-events: none;
`

const TRImage = styled.img`
  position: fixed !important;
  top: 0;
  right: 0;
  max-width: 25%;
  z-index: 99;
  pointer-events: none;
`

const BLImage = styled.img`
  position: fixed !important;
  bottom: 0;
  left: 0;
  max-width: 25%;
  transition: all 0.3s ease-in-out;
  animation-delay: 0.3s;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  z-index: 99;
  &.bottom-margin {
    transform: translateY(-67px);
  }
  pointer-events: none;
`

const BRImage = styled.img`
  position: fixed !important;
  bottom: 0;
  right: 0;
  max-width: 25%;
  transition: all 0.3s ease-in-out;
  animation-delay: 0.3s;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  z-index: 99;
  &.bottom-margin {
    transform: translateY(-77px);
  }
  pointer-events: none;
`

const CornerImagesSFC: React.FC<{
  showBottomImages: boolean
  images: ICornerImages
}> = props => {
  return (
    <Fragment>
      {props.images.tl && <TLImage src={props.images.tl} />}
      {props.images.tr && <TRImage src={props.images.tr} />}
      {props.showBottomImages && (
        <Fragment>
          {props.images.bl && <BLImage src={props.images.bl} id='bottom-left-branding' />}
          {props.images.br && <BRImage src={props.images.br} id='bottom-right-branding' />}
        </Fragment>
      )}
    </Fragment>
  )
}

export default CornerImagesSFC

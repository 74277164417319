import React from 'react'
import { withRouter } from 'react-router-dom'
import LocalProfileConfirmedPage from '~/app/components/LocalProfileConfirmedPage'
import { connect } from 'react-redux'
import { getProcessInstanceState } from '~/store/processinstance'
import styled, { ThemeProvider } from 'styled-components'
import { getReturnOfExpression } from 'utility-types'

import LottieCompleteIndicator from '~/app/components/LocalProfileConfirmedPage/subComponents/LottieCompleteIndicator'
import GlobalStyle from '~/app/styles/base'

const CompletedLottieContainer = styled.div`
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const mapStateToProps = state => {
  return {
    processinstance: getProcessInstanceState(state),
  }
}
const mapDispatchToProps = {}

const stateProps = getReturnOfExpression(mapStateToProps)

type LocalCompProps = {}

type CompProps = typeof stateProps & typeof mapDispatchToProps & LocalCompProps

type State = {}

class LocalProfileConfirmedCTR extends React.Component<CompProps, State> {
  render() {
    return (
      <ThemeProvider theme={this.props.processinstance.registrationEntity}>
        <React.Fragment>
          <GlobalStyle />
          <CompletedLottieContainer>
            <LottieCompleteIndicator />
          </CompletedLottieContainer>
          <LocalProfileConfirmedPage entityName={this.props.processinstance.registrationEntity.entityName} />
        </React.Fragment>
      </ThemeProvider>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LocalProfileConfirmedCTR)
)

import EnterButton from '~/app/components/EnterButton'
import CustomFormItem from '~/app/components/SharedFormComponents/CustomFormItem'
import FormLabel from '~/app/components/SharedFormComponents/FormLabelSFC'
import StyledCheckBox from '~/app/components/StyledCheckBox/CheckBoxSFC'
import StyledInputAlert from '~/app/components/StyledInputAlert'
import { POST_INITIAL_FLOW_SELECTION_STATE as PostInitialFlowSelectionState } from '~/typing/KENAI/enums.d.ts'
import { PostInitialRegistrationFlowAvailability } from '~/typing/KENAI/interfaces'
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Animated } from 'react-animated-css'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import { FlowControl } from '../../component'

const messages = {
  ERROR_INPUT_REQUIRED: { id: 'form.registration.input.error.required' },
}

type Props = {
  className: string
  getFieldDecorator: <T>(id: keyof T, options?: GetFieldDecoratorOptions | undefined) => (node: React.ReactNode) => React.ReactNode
  togglePostFlow: (selectedPostFlow: PostInitialFlowSelectionState) => void
  onCompletePostFlowSelection: () => void
  isMobile: boolean
  postInitialRegistrationFlowAvailability: PostInitialRegistrationFlowAvailability
  flowControl: FlowControl
  fieldName: string
  fieldNumber: string
  fieldLabel: React.ReactNode | string
  fieldRules?: Array<object>
  fieldSubLabel?: string
  fieldRequired?: boolean
  activeSection: any
  hasValidInduction: boolean
}

const Wrapper = styled.div`
  position: relative;
  .ant-form-extra {
    position: absolute;
  }
  .ant-form-item {
    margin-bottom: 5px;
  }
`

const PostFlowSelectionSFC = ({
  onCompletePostFlowSelection,
  flowControl,
  fieldName,
  activeSection,
  className = '',
  ...restProps
}: Props) => {
  const { formatMessage } = useIntl()
  const [enterListenerActive, setEnterListenerActive] = useState(false)
  const onInputKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (
        event.keyCode === 13 &&
        (flowControl.postInitialFlowSelection.parking ||
          flowControl.postInitialFlowSelection.induction ||
          flowControl.postInitialFlowSelection.checkin)
      ) {
        onCompletePostFlowSelection()
      }
    },
    [
      onCompletePostFlowSelection,
      flowControl.postInitialFlowSelection.parking,
      flowControl.postInitialFlowSelection.induction,
      flowControl.postInitialFlowSelection.checkin,
    ]
  )
  const savedHandler = useRef(onInputKeyDown)
  useEffect(() => {
    if (activeSection && activeSection.anchor) {
      const sectionfieldName = activeSection.anchor.replace('invite-', '')
      if (sectionfieldName === fieldName) {
        if (!enterListenerActive) {
          savedHandler.current = onInputKeyDown
          window.addEventListener('keypress', savedHandler.current)
          setEnterListenerActive(true)
        }
      } else {
        if (enterListenerActive) {
          window.removeEventListener('keypress', savedHandler.current)
          setEnterListenerActive(false)
        }
      }
    } else {
      if (enterListenerActive) {
        window.removeEventListener('keypress', savedHandler.current)
        setEnterListenerActive(false)
      }
    }
  }, [activeSection, fieldName, enterListenerActive, setEnterListenerActive, onInputKeyDown])

  const rules: Array<object> = []

  if (restProps.fieldRules && restProps.fieldRules.length > 0) {
    restProps.fieldRules.forEach((item: object) => {
      rules.push(item)
    })
  }
  if (restProps.fieldRequired === true) {
    rules.push({
      required: true,
      message: (
        <>
          <Animated animationIn='fadeInDown' animationOut='fadeOutUp' animateOnMount={true} isVisible={true} animationInDelay={0}>
            <StyledInputAlert className={'input-error'} type='error' message={formatMessage(messages.ERROR_INPUT_REQUIRED)} banner={true} />
          </Animated>
        </>
      ),
    })
  }

  return (
    <Wrapper>
      <CustomFormItem
        className={[restProps.fieldRequired ? 'required-form-item' : '', className]}
        label={
          <FormLabel
            number={restProps.fieldNumber}
            {...restProps.fieldSubLabel && {
              sublabel: restProps.fieldSubLabel,
            }}
          >
            {restProps.fieldLabel}
          </FormLabel>
        }
        extra={
          <Animated
            animationIn='fadeInUp'
            animationOut='fadeOutDown'
            animateOnMount={false}
            isVisible={
              flowControl.postInitialFlowSelection.parking ||
              flowControl.postInitialFlowSelection.induction ||
              flowControl.postInitialFlowSelection.checkin
            }
            style={{ marginTop: '-10px !important' }}
          >
            <EnterButton size='large' onClick={onCompletePostFlowSelection}>
              <FormattedMessage id='form.registration.button.ok' />
            </EnterButton>
          </Animated>
        }
      >
        {restProps.getFieldDecorator(fieldName, {
          rules: rules,
        })(
          <>
            {restProps.postInitialRegistrationFlowAvailability.updateParking.available && (
              <StyledCheckBox
                checked={flowControl.postInitialFlowSelection.parking}
                defaultChecked={true}
                onChange={() => restProps.togglePostFlow(PostInitialFlowSelectionState.PARKING)}
                autoFocus={false}
              >
                <FormattedMessage id='form.registration.input.postflow.cb.parking' />
              </StyledCheckBox>
            )}
            {restProps.postInitialRegistrationFlowAvailability.completeInduction.available && !restProps.hasValidInduction && (
              <StyledCheckBox
                checked={flowControl.postInitialFlowSelection.induction}
                defaultChecked={true}
                onChange={() => restProps.togglePostFlow(PostInitialFlowSelectionState.INDUCTION)}
                autoFocus={false}
              >
                <FormattedMessage id='form.registration.input.postflow.cb.induction' />
              </StyledCheckBox>
            )}
            {restProps.postInitialRegistrationFlowAvailability.captureCheckInFields.available && (
              <StyledCheckBox
                checked={flowControl.postInitialFlowSelection.checkin}
                defaultChecked={true}
                onChange={() => restProps.togglePostFlow(PostInitialFlowSelectionState.CHECK_IN)}
                autoFocus={false}
              >
                <FormattedMessage id='form.registration.input.postflow.cb.checkin' />
              </StyledCheckBox>
            )}
          </>
        )}
      </CustomFormItem>
    </Wrapper>
  )
}

export default PostFlowSelectionSFC

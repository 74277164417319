import React from 'react'
import { Button, Dropdown, Menu } from 'antd'
import { useLocale } from './LocaleContext'

const LocaleSwitch = props => {
  const locale = useLocale()
  const enabled = false // globally disabled

  // console.log(locale);
  return (
    <>
      {enabled ? (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item disabled={locale.current === 'en'} onClick={locale.switchToEnglish}>
                English
              </Menu.Item>
              <Menu.Item disabled={locale.current === 'fr'} onClick={locale.switchToFrench}>
                Français
              </Menu.Item>
              <Menu.Item disabled={locale.current === 'af'} onClick={locale.switchToAfrikaans}>
                Afrikaans
              </Menu.Item>
            </Menu>
          }
          placement='topCenter'
          trigger={['click', 'contextMenu']}
          overlayStyle={{ paddingBottom: 25, paddingLeft: 10 }}
        >
          <Button
            type='primary'
            ghost
            className='border-primary text-primary fixed bottom-0 left-0 ml-2 mb-2 text-white w-12 opacity-75 hover:opacity-100'
            size='large'
          >
            {locale.current}
          </Button>
        </Dropdown>
      ) : null}
    </>
  )
}

export default LocaleSwitch

//LOTTIE ANIMATION INDICATOR FOR FORM DONE
const indicator = {
  v: "4.6.3",
  fr: 24,
  ip: 0,
  op: 21,
  w: 320,
  h: 320,
  nm: "checklist",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Shape Layer 13",
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 300 },
        p: { a: 0, k: [160, 159.5, 0] },
        a: { a: 0, k: [0, -34, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    n: ["0p667_1_0p167_0p167", "0p667_1_0p167_0p167"],
                    t: 6,
                    s: [15.021, 15.021],
                    e: [0, 0],
                  },
                  { t: 17 },
                ],
              },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 6,
                    s: [0.0823529, 0.6784314, 0.3843137, 1],
                    e: [0, 0.7921569, 0.4470588, 1],
                  },
                  { t: 17 },
                ],
              },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    n: "0p667_1_0p167_0p167",
                    t: 6,
                    s: [-8.142, -92.147],
                    e: [-7.675, -162.544],
                    to: [0.07779947668314, -11.7327470779419],
                    ti: [-0.07779947668314, 11.7327470779419],
                  },
                  { t: 17 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [83.981, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.167], y: [0.167] },
                    n: ["0p667_1_0p167_0p167"],
                    t: 6,
                    s: [20.367],
                    e: [6.367],
                  },
                  { t: 17 },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 2",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    n: ["0p833_0p833_0p167_0p167", "0p833_0p833_0p167_0p167"],
                    t: 6,
                    s: [15.021, 15.021],
                    e: [0, 0],
                  },
                  { t: 21 },
                ],
              },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 6,
                    s: [0.0823529, 0.6784314, 0.3843137, 1],
                    e: [0, 0.9070925, 0.5119235, 1],
                  },
                  { t: 17 },
                ],
              },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    n: "0p833_0p833_0p167_0p167",
                    t: 6,
                    s: [16.585, -99.759],
                    e: [28.521, -187.495],
                    to: [1.9892578125, -14.6227216720581],
                    ti: [-1.9892578125, 14.6227216720581],
                  },
                  { t: 21 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [97.419, 116], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    n: ["0p833_0p833_0p167_0p167"],
                    t: 6,
                    s: [14.733],
                    e: [8.733],
                  },
                  { t: 21 },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 2,
          mn: "ADBE Vector Group",
        },
      ],
      ip: 6,
      op: 22,
      st: -21,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Shape Layer 12",
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 250 },
        p: { a: 0, k: [160, 159.5, 0] },
        a: { a: 0, k: [0, -34, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    n: ["0p667_1_0p167_0p167", "0p667_1_0p167_0p167"],
                    t: 6,
                    s: [15.021, 15.021],
                    e: [0, 0],
                  },
                  { t: 17 },
                ],
              },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 6,
                    s: [0.0823529, 0.6784314, 0.3843137, 1],
                    e: [0, 0.7921569, 0.4470588, 1],
                  },
                  { t: 17 },
                ],
              },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    n: "0p667_1_0p167_0p167",
                    t: 6,
                    s: [-8.142, -92.147],
                    e: [-7.675, -162.544],
                    to: [0.07779947668314, -11.7327470779419],
                    ti: [-0.07779947668314, 11.7327470779419],
                  },
                  { t: 17 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [83.981, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.167], y: [0.167] },
                    n: ["0p667_1_0p167_0p167"],
                    t: 6,
                    s: [20.367],
                    e: [6.367],
                  },
                  { t: 17 },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 2",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    n: ["0p833_0p833_0p167_0p167", "0p833_0p833_0p167_0p167"],
                    t: 6,
                    s: [15.021, 15.021],
                    e: [0, 0],
                  },
                  { t: 21 },
                ],
              },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 6,
                    s: [0.0823529, 0.6784314, 0.3843137, 1],
                    e: [0, 0.9070925, 0.5119235, 1],
                  },
                  { t: 17 },
                ],
              },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    n: "0p833_0p833_0p167_0p167",
                    t: 6,
                    s: [16.585, -99.759],
                    e: [28.521, -187.495],
                    to: [1.9892578125, -14.6227216720581],
                    ti: [-1.9892578125, 14.6227216720581],
                  },
                  { t: 21 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [97.419, 116], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    n: ["0p833_0p833_0p167_0p167"],
                    t: 6,
                    s: [14.733],
                    e: [8.733],
                  },
                  { t: 21 },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 2,
          mn: "ADBE Vector Group",
        },
      ],
      ip: 6,
      op: 22,
      st: -21,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Shape Layer 11",
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 200 },
        p: { a: 0, k: [160, 159.5, 0] },
        a: { a: 0, k: [0, -34, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    n: ["0p667_1_0p167_0p167", "0p667_1_0p167_0p167"],
                    t: 6,
                    s: [15.021, 15.021],
                    e: [0, 0],
                  },
                  { t: 17 },
                ],
              },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 6,
                    s: [0.0823529, 0.6784314, 0.3843137, 1],
                    e: [0, 0.7921569, 0.4470588, 1],
                  },
                  { t: 17 },
                ],
              },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    n: "0p667_1_0p167_0p167",
                    t: 6,
                    s: [-8.142, -92.147],
                    e: [-7.675, -162.544],
                    to: [0.07779947668314, -11.7327470779419],
                    ti: [-0.07779947668314, 11.7327470779419],
                  },
                  { t: 17 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [83.981, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.167], y: [0.167] },
                    n: ["0p667_1_0p167_0p167"],
                    t: 6,
                    s: [20.367],
                    e: [6.367],
                  },
                  { t: 17 },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 2",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    n: ["0p833_0p833_0p167_0p167", "0p833_0p833_0p167_0p167"],
                    t: 6,
                    s: [15.021, 15.021],
                    e: [0, 0],
                  },
                  { t: 21 },
                ],
              },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 6,
                    s: [0.0823529, 0.6784314, 0.3843137, 1],
                    e: [0, 0.9070925, 0.5119235, 1],
                  },
                  { t: 17 },
                ],
              },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    n: "0p833_0p833_0p167_0p167",
                    t: 6,
                    s: [16.585, -99.759],
                    e: [28.521, -187.495],
                    to: [1.9892578125, -14.6227216720581],
                    ti: [-1.9892578125, 14.6227216720581],
                  },
                  { t: 21 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [97.419, 116], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    n: ["0p833_0p833_0p167_0p167"],
                    t: 6,
                    s: [14.733],
                    e: [8.733],
                  },
                  { t: 21 },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 2,
          mn: "ADBE Vector Group",
        },
      ],
      ip: 6,
      op: 22,
      st: -21,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "Shape Layer 10",
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 150 },
        p: { a: 0, k: [160, 159.5, 0] },
        a: { a: 0, k: [0, -34, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    n: ["0p667_1_0p167_0p167", "0p667_1_0p167_0p167"],
                    t: 6,
                    s: [15.021, 15.021],
                    e: [0, 0],
                  },
                  { t: 17 },
                ],
              },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 6,
                    s: [0.0823529, 0.6784314, 0.3843137, 1],
                    e: [0, 0.7921569, 0.4470588, 1],
                  },
                  { t: 17 },
                ],
              },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    n: "0p667_1_0p167_0p167",
                    t: 6,
                    s: [-8.142, -92.147],
                    e: [-7.675, -162.544],
                    to: [0.07779947668314, -11.7327470779419],
                    ti: [-0.07779947668314, 11.7327470779419],
                  },
                  { t: 17 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [83.981, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.167], y: [0.167] },
                    n: ["0p667_1_0p167_0p167"],
                    t: 6,
                    s: [20.367],
                    e: [6.367],
                  },
                  { t: 17 },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 2",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    n: ["0p833_0p833_0p167_0p167", "0p833_0p833_0p167_0p167"],
                    t: 6,
                    s: [15.021, 15.021],
                    e: [0, 0],
                  },
                  { t: 21 },
                ],
              },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 6,
                    s: [0.0823529, 0.6784314, 0.3843137, 1],
                    e: [0, 0.9070925, 0.5119235, 1],
                  },
                  { t: 17 },
                ],
              },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    n: "0p833_0p833_0p167_0p167",
                    t: 6,
                    s: [16.585, -99.759],
                    e: [28.521, -187.495],
                    to: [1.9892578125, -14.6227216720581],
                    ti: [-1.9892578125, 14.6227216720581],
                  },
                  { t: 21 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [97.419, 116], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    n: ["0p833_0p833_0p167_0p167"],
                    t: 6,
                    s: [14.733],
                    e: [8.733],
                  },
                  { t: 21 },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 2,
          mn: "ADBE Vector Group",
        },
      ],
      ip: 6,
      op: 22,
      st: -21,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "Shape Layer 9",
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 100 },
        p: { a: 0, k: [160, 159.5, 0] },
        a: { a: 0, k: [0, -34, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    n: ["0p667_1_0p167_0p167", "0p667_1_0p167_0p167"],
                    t: 6,
                    s: [15.021, 15.021],
                    e: [0, 0],
                  },
                  { t: 17 },
                ],
              },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 6,
                    s: [0.0823529, 0.6784314, 0.3843137, 1],
                    e: [0, 0.7921569, 0.4470588, 1],
                  },
                  { t: 17 },
                ],
              },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    n: "0p667_1_0p167_0p167",
                    t: 6,
                    s: [-8.142, -92.147],
                    e: [-7.675, -162.544],
                    to: [0.07779947668314, -11.7327470779419],
                    ti: [-0.07779947668314, 11.7327470779419],
                  },
                  { t: 17 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [83.981, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.167], y: [0.167] },
                    n: ["0p667_1_0p167_0p167"],
                    t: 6,
                    s: [20.367],
                    e: [6.367],
                  },
                  { t: 17 },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 2",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    n: ["0p833_0p833_0p167_0p167", "0p833_0p833_0p167_0p167"],
                    t: 6,
                    s: [15.021, 15.021],
                    e: [0, 0],
                  },
                  { t: 21 },
                ],
              },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 6,
                    s: [0.0823529, 0.6784314, 0.3843137, 1],
                    e: [0, 0.9070925, 0.5119235, 1],
                  },
                  { t: 17 },
                ],
              },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    n: "0p833_0p833_0p167_0p167",
                    t: 6,
                    s: [16.585, -99.759],
                    e: [28.521, -187.495],
                    to: [1.9892578125, -14.6227216720581],
                    ti: [-1.9892578125, 14.6227216720581],
                  },
                  { t: 21 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [97.419, 116], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    n: ["0p833_0p833_0p167_0p167"],
                    t: 6,
                    s: [14.733],
                    e: [8.733],
                  },
                  { t: 21 },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 2,
          mn: "ADBE Vector Group",
        },
      ],
      ip: 6,
      op: 22,
      st: -21,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: "Shape Layer 8",
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 50 },
        p: { a: 0, k: [160, 159.5, 0] },
        a: { a: 0, k: [0, -34, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    n: ["0p667_1_0p167_0p167", "0p667_1_0p167_0p167"],
                    t: 6,
                    s: [15.021, 15.021],
                    e: [0, 0],
                  },
                  { t: 17 },
                ],
              },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 6,
                    s: [0.0823529, 0.6784314, 0.3843137, 1],
                    e: [0, 0.7921569, 0.4470588, 1],
                  },
                  { t: 17 },
                ],
              },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    n: "0p667_1_0p167_0p167",
                    t: 6,
                    s: [-8.142, -92.147],
                    e: [-7.675, -162.544],
                    to: [0.07779947668314, -11.7327470779419],
                    ti: [-0.07779947668314, 11.7327470779419],
                  },
                  { t: 17 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [83.981, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.167], y: [0.167] },
                    n: ["0p667_1_0p167_0p167"],
                    t: 6,
                    s: [20.367],
                    e: [6.367],
                  },
                  { t: 17 },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 2",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    n: ["0p833_0p833_0p167_0p167", "0p833_0p833_0p167_0p167"],
                    t: 6,
                    s: [15.021, 15.021],
                    e: [0, 0],
                  },
                  { t: 21 },
                ],
              },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 6,
                    s: [0.0823529, 0.6784314, 0.3843137, 1],
                    e: [0, 0.9070925, 0.5119235, 1],
                  },
                  { t: 17 },
                ],
              },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    n: "0p833_0p833_0p167_0p167",
                    t: 6,
                    s: [16.585, -99.759],
                    e: [28.521, -187.495],
                    to: [1.9892578125, -14.6227216720581],
                    ti: [-1.9892578125, 14.6227216720581],
                  },
                  { t: 21 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [97.419, 116], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    n: ["0p833_0p833_0p167_0p167"],
                    t: 6,
                    s: [14.733],
                    e: [8.733],
                  },
                  { t: 21 },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 2,
          mn: "ADBE Vector Group",
        },
      ],
      ip: 6,
      op: 22,
      st: -21,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: "Shape Layer 7",
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [160, 159.5, 0] },
        a: { a: 0, k: [0, -34, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    n: ["0p667_1_0p167_0p167", "0p667_1_0p167_0p167"],
                    t: 6,
                    s: [15.021, 15.021],
                    e: [0, 0],
                  },
                  { t: 17 },
                ],
              },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 6,
                    s: [0.0823529, 0.6784314, 0.3843137, 1],
                    e: [0, 0.7921569, 0.4470588, 1],
                  },
                  { t: 17 },
                ],
              },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    n: "0p667_1_0p167_0p167",
                    t: 6,
                    s: [-8.142, -92.147],
                    e: [-7.675, -162.544],
                    to: [0.07779947668314, -11.7327470779419],
                    ti: [-0.07779947668314, 11.7327470779419],
                  },
                  { t: 17 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [83.981, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.167], y: [0.167] },
                    n: ["0p667_1_0p167_0p167"],
                    t: 6,
                    s: [20.367],
                    e: [6.367],
                  },
                  { t: 17 },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 2",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    n: ["0p833_0p833_0p167_0p167", "0p833_0p833_0p167_0p167"],
                    t: 6,
                    s: [15.021, 15.021],
                    e: [0, 0],
                  },
                  { t: 21 },
                ],
              },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 6,
                    s: [0.0823529, 0.6784314, 0.3843137, 1],
                    e: [0, 0.9070925, 0.5119235, 1],
                  },
                  { t: 17 },
                ],
              },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    n: "0p833_0p833_0p167_0p167",
                    t: 6,
                    s: [16.585, -99.759],
                    e: [28.521, -187.495],
                    to: [1.9892578125, -14.6227216720581],
                    ti: [-1.9892578125, 14.6227216720581],
                  },
                  { t: 21 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [97.419, 116], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    n: ["0p833_0p833_0p167_0p167"],
                    t: 6,
                    s: [14.733],
                    e: [8.733],
                  },
                  { t: 21 },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 2,
          mn: "ADBE Vector Group",
        },
      ],
      ip: 6,
      op: 22,
      st: -21,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: "Shape Layer 5",
      parent: 11,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: -44 },
        p: { a: 0, k: [0.378, -0.641, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [7.39, 7.39, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: { a: 0, k: { i: [[0, 0]], o: [[0, 0]], v: [[-274.219, -254.097]], c: false } },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
            },
            {
              ty: "st",
              c: { a: 0, k: [1, 1, 1, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 8 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Shape 2",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [0, 0]],
                  o: [[0, 0], [0, 0], [0, 0]],
                  v: [[-17, -16], [-17, 10.5], [41, 10.5]],
                  c: false,
                },
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
            },
            {
              ty: "st",
              c: { a: 0, k: [1, 1, 1, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 8 },
              lc: 2,
              lj: 1,
              ml: 5,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Shape 1",
          np: 3,
          cix: 2,
          ix: 2,
          mn: "ADBE Vector Group",
        },
      ],
      ip: 7,
      op: 22,
      st: -21,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: "Shape Layer 6",
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ["0p667_1_0p333_0"],
              t: 4,
              s: [50],
              e: [0],
            },
            { t: 14 },
          ],
        },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [160, 160, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 0.667] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0.333] },
              n: ["0p667_1_0p333_0", "0p667_1_0p333_0", "0p667_0p667_0p333_0p333"],
              t: 4,
              s: [100, 100, 100],
              e: [1085, 1085, 100],
            },
            { t: 14 },
          ],
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [19.779, 19.779] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0.7921569, 0.4470588, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: { a: 0, k: [-0.068, 0.036], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
      ],
      ip: 4,
      op: 22,
      st: -23,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: "Shape Layer 4",
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ["0p667_1_0p333_0"],
              t: 6,
              s: [30],
              e: [100],
            },
            { t: 9 },
          ],
        },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [160.312, 161.188, 0] },
        a: { a: 0, k: [0.812, -0.562, 0] },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 0.667] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0.333] },
              n: ["0p667_1_0p333_0", "0p667_1_0p333_0", "0p667_0p667_0p333_0p333"],
              t: 6,
              s: [100, 100, 100],
              e: [1087, 1087, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 0.667] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0.333] },
              n: ["0p667_1_0p333_0", "0p667_1_0p333_0", "0p667_0p667_0p333_0p333"],
              t: 11,
              s: [1087, 1087, 100],
              e: [866, 866, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0.333] },
              n: ["0p833_0p833_0p333_0", "0p833_0p833_0p333_0", "0p833_0p833_0p333_0p333"],
              t: 13,
              s: [866, 866, 100],
              e: [878, 878, 100],
            },
            { t: 16 },
          ],
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [10.068, 10.068] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0.7921569, 0.4470588, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: { a: 0, k: [0.784, -0.716], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
      ],
      ip: 6,
      op: 22,
      st: -19,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: "Shape Layer 3",
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [161, 160, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 0.667] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0.333] },
              n: ["0p667_1_0p333_0", "0p667_1_0p333_0", "0p667_0p667_0p333_0p333"],
              t: 3,
              s: [100, 100, 100],
              e: [224, 224, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 0.667] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0.333] },
              n: ["0p667_1_0p333_0", "0p667_1_0p333_0", "0p667_0p667_0p333_0p333"],
              t: 4,
              s: [224, 224, 100],
              e: [476, 476, 100],
            },
            { t: 8 },
          ],
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [6.009, 6.009] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "st",
              c: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 4,
                    s: [0.0558609, 0.688557, 0.3778246, 1],
                    e: [0.1089485, 0.6693168, 0.3941063, 1],
                  },
                  { t: 8 },
                ],
              },
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 4,
                    s: [0],
                    e: [100],
                  },
                  { t: 5 },
                ],
              },
              w: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 4,
                    s: [3],
                    e: [0],
                  },
                  { t: 8 },
                ],
              },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0.7921569, 0.4470588, 1] },
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 3,
                    s: [100],
                    e: [99],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 4,
                    s: [99],
                    e: [0],
                  },
                  { t: 5 },
                ],
              },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: { a: 0, k: [-0.338, 0.065], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [649.112, 649.112], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 2",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
      ],
      ip: 3,
      op: 22,
      st: -21,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: "Shape Layer 2",
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [160.142, 159.987, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [377.603, 377.603, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [22.315, 22.315] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "st",
              c: { a: 0, k: [0.8352941, 0.8352941, 0.8352941, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 1 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: { a: 0, k: [-0.038, 0.003], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
      ],
      ip: -21,
      op: 22,
      st: -21,
      bm: 0,
      sr: 1,
    },
  ],
}

export default indicator

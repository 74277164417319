import update from 'immutability-helper'
import { createSelector } from 'reselect'
import { ActionCreator } from '~/store/store-action-creator'
import * as enums from '~/typing/KENAI/enums.d.ts'

// Action Creators
export const ActionCreators = {
  StoreSetOTPInstanceCode: new ActionCreator<
    'StoreSetOTPInstanceCode',
    { otpInstanceCode: string; otpRequestErrorMessage: string | undefined }
  >('StoreSetOTPInstanceCode'),
  StoreSetNumberValid: new ActionCreator<
    'StoreSetNumberValid',
    {
      message: string | undefined
      type: enums.OTP_VALIDATION_ERRORS | undefined
      hasExistingLocalProfile: boolean
      hasActiveParkingBookingOnThisToken: boolean
      hasValidInduction: boolean
      globalFirstName: string
      globalLastName: string
      prePopulatedFieldValues: object
    }
  >('StoreSetNumberValid'),
  StoreSetFaceData: new ActionCreator<
    'StoreSetFaceData',
    {
      message: string | undefined
      type: enums.FACE_VALIDATION_ERRORS | undefined
      faceB64: string
    }
  >('StoreSetFaceData'),
  StoreSetProfileSubmissionData: new ActionCreator<
    'StoreSetProfileSubmissionData',
    {
      message: string | undefined
      type: enums.PROFILE_SUBMISSION_ERRORS | undefined
      triggeredAlert?: any
      accessPassDetails?: {
        accessPassCode: string
        accessPassValidToTs: number
        email: string
        phoneNumber: string
      }
    }
  >('StoreSetProfileSubmissionData'),
  StoreSetLocalProfileConfirmationData: new ActionCreator<
    'StoreSetLocalProfileConfirmationData',
    {
      hasValidInduction: boolean
      prePopulatedFieldValues: object
    }
  >('StoreSetLocalProfileConfirmationData'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export interface State {
  readonly otpInstanceCode: string
  readonly otpRequestTimeStamp: number
  readonly otpRequestErrorMessage: string | undefined
  readonly otpValidationTimeStamp: number
  readonly otpValidationErrorMessage: string | undefined
  readonly otpValidationErrorType: enums.OTP_VALIDATION_ERRORS | undefined
  readonly otpValidationHasExistingLocalProfile: boolean
  readonly otpValidationHasActiveParkingBookingOnThisToken: boolean
  readonly otpValidationHasValidInduction: boolean
  readonly otpValidationGlobalFirstName: string
  readonly otpValidationGlobalLastName: string
  readonly otpValidationPrePopulatedFieldValues: object
  readonly faceValidationTimeStamp: number
  readonly faceValidationErrorMessage: string | undefined
  readonly faceValidationErrorType: enums.FACE_VALIDATION_ERRORS | undefined
  readonly faceB64: string
  readonly profileSubmissionTimeStamp: number
  readonly profileSubmissionErrorMessage: string | undefined
  readonly profileSubmissionErrorType: enums.PROFILE_SUBMISSION_ERRORS | undefined
  readonly localProfileConfirmed: boolean
  readonly profileSubmissionTriggeredAlert?: {
    blockFurtherProcessing: boolean
    headerText: string
    bodyText: string
  }
  profileSubmissionAccessPassDetails?: {
    accessPassCode: string
    accessPassValidToTs: number
    email: string
    phoneNumber: string
  }
}

const initialState = (): State => {
  return {
    otpInstanceCode: '',
    otpRequestTimeStamp: 0,
    otpRequestErrorMessage: undefined,
    otpValidationTimeStamp: 0,
    otpValidationErrorMessage: undefined,
    otpValidationErrorType: undefined,
    otpValidationHasExistingLocalProfile: false,
    otpValidationHasActiveParkingBookingOnThisToken: false,
    otpValidationHasValidInduction: false,
    otpValidationGlobalFirstName: '',
    otpValidationGlobalLastName: '',
    otpValidationPrePopulatedFieldValues: {},
    faceValidationTimeStamp: 0,
    faceValidationErrorMessage: undefined,
    faceValidationErrorType: undefined,
    faceB64: '',
    profileSubmissionTimeStamp: 0,
    profileSubmissionErrorMessage: undefined,
    profileSubmissionErrorType: undefined,
    localProfileConfirmed: false,
    profileSubmissionTriggeredAlert: undefined,
    profileSubmissionAccessPassDetails: undefined,
  }
}

// Reducer
export default function reducer(state: State = initialState(), action: Action): State {
  if (action.type === ActionCreators.StoreSetOTPInstanceCode.type) {
    const { otpInstanceCode, otpRequestErrorMessage } = action.payload as {
      otpInstanceCode: string
      otpRequestErrorMessage: string | undefined
    }
    return update(state, {
      otpInstanceCode: {
        $set: otpInstanceCode,
      },
      otpRequestTimeStamp: {
        $set: Date.now(),
      },
      otpRequestErrorMessage: {
        $set: otpRequestErrorMessage,
      },
    })
  }
  if (action.type === ActionCreators.StoreSetNumberValid.type) {
    const {
      message,
      type,
      hasExistingLocalProfile,
      hasActiveParkingBookingOnThisToken,
      hasValidInduction,
      globalFirstName,
      globalLastName,
      prePopulatedFieldValues,
    } = action.payload as {
      message: string | undefined
      type: enums.OTP_VALIDATION_ERRORS | undefined
      hasExistingLocalProfile: boolean
      hasActiveParkingBookingOnThisToken: boolean
      hasValidInduction: boolean
      globalFirstName: string
      globalLastName: string
      prePopulatedFieldValues: object
    }
    return update(state, {
      otpValidationTimeStamp: {
        $set: Date.now(),
      },
      otpValidationErrorMessage: {
        $set: message,
      },
      otpValidationErrorType: {
        $set: type,
      },
      otpValidationHasExistingLocalProfile: {
        $set: hasExistingLocalProfile,
      },
      otpValidationHasActiveParkingBookingOnThisToken: {
        $set: hasActiveParkingBookingOnThisToken,
      },
      otpValidationHasValidInduction: {
        $set: hasValidInduction,
      },
      otpValidationGlobalFirstName: {
        $set: globalFirstName,
      },
      otpValidationGlobalLastName: {
        $set: globalLastName,
      },
      otpValidationPrePopulatedFieldValues: {
        $set: prePopulatedFieldValues,
      },
    })
  }
  if (action.type === ActionCreators.StoreSetFaceData.type) {
    const { message, type, faceB64 } = action.payload as {
      message: string | undefined
      type: enums.FACE_VALIDATION_ERRORS | undefined
      faceB64: string
    }
    return update(state, {
      faceValidationTimeStamp: {
        $set: Date.now(),
      },
      faceValidationErrorMessage: {
        $set: message,
      },
      faceValidationErrorType: {
        $set: type,
      },
      faceB64: {
        $set: faceB64,
      },
    })
  }
  if (action.type === ActionCreators.StoreSetProfileSubmissionData.type) {
    const { message, type, triggeredAlert, accessPassDetails } = action.payload as {
      message: string | undefined
      type: enums.PROFILE_SUBMISSION_ERRORS | undefined
      triggeredAlert?: any
      accessPassCode?: string
      accessPassDetails?: {
        accessPassCode: string
        accessPassValidToTs: number
        email: string
        phoneNumber: string
      }
    }
    return update(state, {
      profileSubmissionTimeStamp: {
        $set: Date.now(),
      },
      profileSubmissionErrorMessage: {
        $set: message,
      },
      profileSubmissionErrorType: {
        $set: type,
      },
      profileSubmissionTriggeredAlert: {
        $set: triggeredAlert,
      },
      profileSubmissionAccessPassDetails: {
        $set: accessPassDetails,
      },
    })
  }

  if (action.type === ActionCreators.StoreSetLocalProfileConfirmationData.type) {
    const { hasValidInduction, prePopulatedFieldValues } = action.payload as {
      hasValidInduction: boolean
      prePopulatedFieldValues: object
    }
    return update(state, {
      otpValidationHasExistingLocalProfile: {
        $set: true, //always true as we have confirmed it as part of this operation
      },
      otpValidationHasActiveParkingBookingOnThisToken: {
        $set: false, //never true as we are confirming on first processing prior to a parking being booked (post booking phone number is not changable anymore)
      },
      otpValidationHasValidInduction: {
        $set: hasValidInduction,
      },
      otpValidationPrePopulatedFieldValues: {
        $set: prePopulatedFieldValues,
      },
      localProfileConfirmed: {
        $set: true,
      },
    })
  }

  return state
}

// Selector
const getStateRegistrationInstance = state => state.registrationinstance as State
export const getRegistrationInstanceState = createSelector<any, any, State>(
  [getStateRegistrationInstance],
  returnedState => ({ ...returnedState } as State)
)

import React from 'react'
import styled from 'styled-components'

const ContentWrapper = styled.div`
  p {
    margin-bottom: 0;
  }
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 120px 120px 120px;
  grid-template-areas:
    'changes question question'
    'changes ..... actions';
  text-align: left;
  .changes {
    grid-area: changes;
    border-right: 1px solid rgba(0, 0, 0, 0.32);
  }
  .question {
    grid-area: question;
  }
  .actions {
    text-align: right;
    grid-area: actions;
  }
`

type Props = {
  changes: any
  question: any
  actions: any
}

const UpdatePopupContent: React.FC<Props> = props => {
  return (
    <ContentWrapper>
      <div className='changes'>
        <p>
          <strong>Invite Updates:</strong>
        </p>
        {props.changes}
      </div>
      <div className='question'>{props.question}</div>
      <div className='actions'>{props.actions}</div>
    </ContentWrapper>
  )
}

export default UpdatePopupContent

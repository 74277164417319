import update from 'immutability-helper'
import { createSelector } from 'reselect'
import { ActionCreator } from '~/store/store-action-creator'
import * as interfaces from '~/typing/KENAI/interfaces.d.ts'
import * as enums from '~/typing/KENAI/enums.d.ts'

// Action Creators
export const ActionCreators = {
  StoreSetupSetOTPInstanceCode: new ActionCreator<
    'StoreSetupSetOTPInstanceCode',
    { otpInstanceCode: string; otpRequestErrorMessage: string | undefined }
  >('StoreSetupSetOTPInstanceCode'),
  StoreSetSetupOtpValid: new ActionCreator<
    'StoreSetSetupOtpValid',
    {
      message: string | undefined
      type: enums.OTP_VALIDATION_ERRORS | undefined
      eventDetails: interfaces.SETUP_EVENT_DETAILS | undefined
    }
  >('StoreSetSetupOtpValid'),
  StoreSetSetupHostResults: new ActionCreator<
    'StoreSetSetupHostResults',
    {
      message: string | undefined
      type: enums.SEARCH_SETUP_HOSTS_ERRORS | undefined
      hostSearchResults: Array<interfaces.SEARCH_SETUP_HOST_RESULT> | undefined
    }
  >('StoreSetSetupHostResults'),
  StoreSetSetupAttendeeDetails: new ActionCreator<
    'StoreSetSetupAttendeeDetails',
    {
      message: string | undefined
      type: enums.SETUP_GET_ATTENDEE_ERRORS | undefined
      attendeeDetails: Array<interfaces.SETUP_ATTENDEE_DETAILS> | undefined
    }
  >('StoreSetSetupAttendeeDetails'),
  StoreSetSetupCreateInvites: new ActionCreator<
    'StoreSetSetupCreateInvites',
    {
      message: string | undefined
      type: enums.SETUP_CREATE_INVITES_ERRORS | undefined
    }
  >('StoreSetSetupCreateInvites'),
  StoreSetResendAction: new ActionCreator<
    'StoreSetResendAction',
    {
      message: string | undefined
      type: enums.SETUP_RESEND_ACTION_ERRORS | undefined
    }
  >('StoreSetResendAction'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export interface State {
  readonly otpInstanceCode: string
  readonly otpRequestTimeStamp: number
  readonly otpRequestErrorMessage: string | undefined
  readonly otpValidationTimeStamp: number
  readonly otpValidationErrorMessage: string | undefined
  readonly otpValidationErrorType: enums.OTP_VALIDATION_ERRORS | undefined
  readonly eventDetails: interfaces.SETUP_EVENT_DETAILS | undefined
  readonly hostSearchResults: Array<interfaces.SEARCH_SETUP_HOST_RESULT> | undefined
  readonly hostSearchTimeStamp: number
  readonly hostSearchErrorMessage: string | undefined
  readonly hostSearchErrorType: enums.SEARCH_SETUP_HOSTS_ERRORS | undefined
  readonly attendeeDetailsTimeStamp: number
  readonly attendeeDetailsErrorMessage: string | undefined
  readonly attendeeDetailsErrorType: enums.SETUP_GET_ATTENDEE_ERRORS | undefined
  readonly attendeeDetails: Array<interfaces.SETUP_ATTENDEE_DETAILS> | undefined
  readonly createInvitesTimeStamp: number
  readonly createInvitesErrorMessage: string | undefined
  readonly createInvitesErrorType: enums.SETUP_CREATE_INVITES_ERRORS | undefined
  readonly resendActionTimeStamp: number
  readonly resendActionErrorMessage: string | undefined
  readonly resendActionErrorType: enums.SETUP_RESEND_ACTION_ERRORS | undefined
}

const initialState = (): State => {
  return {
    otpInstanceCode: '',
    otpRequestTimeStamp: 0,
    otpRequestErrorMessage: undefined,
    otpValidationTimeStamp: 0,
    otpValidationErrorMessage: undefined,
    otpValidationErrorType: undefined,
    eventDetails: undefined,
    hostSearchResults: undefined,
    hostSearchTimeStamp: 0,
    hostSearchErrorMessage: undefined,
    hostSearchErrorType: undefined,
    attendeeDetailsTimeStamp: 0,
    attendeeDetailsErrorMessage: undefined,
    attendeeDetailsErrorType: undefined,
    attendeeDetails: undefined,
    createInvitesTimeStamp: 0,
    createInvitesErrorMessage: undefined,
    createInvitesErrorType: undefined,
    resendActionTimeStamp: 0,
    resendActionErrorMessage: undefined,
    resendActionErrorType: undefined,
  }
}

// Reducer
export default function reducer(state: State = initialState(), action: Action): State {
  if (action.type === ActionCreators.StoreSetupSetOTPInstanceCode.type) {
    const { otpInstanceCode, otpRequestErrorMessage } = action.payload as {
      otpInstanceCode: string
      otpRequestErrorMessage: string | undefined
    }
    return update(state, {
      otpInstanceCode: {
        $set: otpInstanceCode,
      },
      otpRequestTimeStamp: {
        $set: Date.now(),
      },
      otpRequestErrorMessage: {
        $set: otpRequestErrorMessage,
      },
      otpValidationTimeStamp: {
        $set: 0,
      },
      otpValidationErrorMessage: {
        $set: undefined,
      },
      otpValidationErrorType: {
        $set: undefined,
      },
      eventDetails: {
        $set: undefined,
      },
    })
  }
  if (action.type === ActionCreators.StoreSetSetupOtpValid.type) {
    const { message, type, eventDetails } = action.payload as {
      message: string | undefined
      type: enums.OTP_VALIDATION_ERRORS | undefined
      eventDetails: interfaces.SETUP_EVENT_DETAILS | undefined
    }
    return update(state, {
      otpValidationTimeStamp: {
        $set: Date.now(),
      },
      otpValidationErrorMessage: {
        $set: message,
      },
      otpValidationErrorType: {
        $set: type,
      },
      eventDetails: {
        $set: eventDetails,
      },
    })
  }
  if (action.type === ActionCreators.StoreSetSetupHostResults.type) {
    const { message, type, hostSearchResults } = action.payload as {
      message: string | undefined
      type: enums.SEARCH_SETUP_HOSTS_ERRORS | undefined
      hostSearchResults: Array<interfaces.SEARCH_SETUP_HOST_RESULT> | undefined
    }
    return update(state, {
      hostSearchTimeStamp: {
        $set: Date.now(),
      },
      hostSearchErrorMessage: {
        $set: message,
      },
      hostSearchErrorType: {
        $set: type,
      },
      hostSearchResults: {
        $set: hostSearchResults,
      },
    })
  }
  if (action.type === ActionCreators.StoreSetSetupAttendeeDetails.type) {
    const { message, type, attendeeDetails } = action.payload as {
      message: string | undefined
      type: enums.SETUP_GET_ATTENDEE_ERRORS | undefined
      attendeeDetails: Array<interfaces.SETUP_ATTENDEE_DETAILS> | undefined
    }
    return update(state, {
      attendeeDetailsTimeStamp: {
        $set: Date.now(),
      },
      attendeeDetailsErrorMessage: {
        $set: message,
      },
      attendeeDetailsErrorType: {
        $set: type,
      },
      attendeeDetails: {
        $set: attendeeDetails,
      },
    })
  }
  if (action.type === ActionCreators.StoreSetSetupCreateInvites.type) {
    const { message, type } = action.payload as {
      message: string | undefined
      type: enums.SETUP_CREATE_INVITES_ERRORS | undefined
    }
    return update(state, {
      createInvitesTimeStamp: {
        $set: Date.now(),
      },
      createInvitesErrorMessage: {
        $set: message,
      },
      createInvitesErrorType: {
        $set: type,
      },
    })
  }
  if (action.type === ActionCreators.StoreSetResendAction.type) {
    const { message, type } = action.payload as {
      message: string | undefined
      type: enums.SETUP_RESEND_ACTION_ERRORS | undefined
    }
    return update(state, {
      resendActionTimeStamp: {
        $set: Date.now(),
      },
      resendActionErrorMessage: {
        $set: message,
      },
      resendActionErrorType: {
        $set: type,
      },
    })
  }
  return state
}

// Selector
const getStateSetupInstance = state => state.setupinstance as State
export const getSetupInstanceState = createSelector<any, any, State>(
  [getStateSetupInstance],
  returnedState => ({ ...returnedState } as State)
)

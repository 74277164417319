import filterProcessorAlias from '@kenai/utils.filter-processor'

const filterProcessor = (rootCondition, values) => {
  const normalizedValue = Object.keys(values).reduce((acc, key) => {
    let fieldValue = values[key]

    if ('value' in fieldValue) {
      fieldValue = fieldValue.value
    }

    return {
      ...acc,
      key: fieldValue,
    }
  }, {})

  return filterProcessorAlias(rootCondition, normalizedValue)
}

export default filterProcessor

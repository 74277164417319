import React from 'react'
import { Modal as AntModal } from 'antd'
import styled from 'styled-components'
import Handlebars from 'handlebars'
// import moment from "moment"

import emailTemplate from './mockEmailExample'

const Iframe = styled.iframe`
  width: 100%;
  box-shadow: none;
  border: none;
  outline: none;
  max-height: 70vh;
  height: 640px;
  /* pointer-events: none; */
`

const ModalStyle = Component => styled(Component)`
  .ant-modal-content {
    border-radius: 20px;
    overflow: hidden;
    -ms-overflow-style: none;
  }
`
const Modal = ModalStyle(AntModal)

type ExampleContainerProps = {
  content: string
  stylesheets: string[]
}

type exampleContainerStates = {}

class ExampleContainer extends React.Component<ExampleContainerProps, exampleContainerStates> {
  iframe!: HTMLIFrameElement

  componentDidMount() {
    this._updateIframe()
  }
  componentDidUpdate() {
    this._updateIframe()
  }
  _updateIframe() {
    if (this.iframe) {
      const document = this.iframe.contentDocument
      if (document) {
        const head = document.getElementsByTagName('head')[0]

        const template = Handlebars.compile(this.props.content)
        const mailData = {}
        const result = template(mailData)
        document.body.innerHTML = result

        if (this.props.stylesheets) {
          this.props.stylesheets.forEach(url => {
            const ref = document.createElement('link')
            ref.rel = 'stylesheet'
            ref.type = 'text/css'
            ref.href = url
            head.appendChild(ref)
          })
        }
      }
    }
  }

  setIframeRef = ref => {
    this.iframe = ref
  }

  render() {
    return <Iframe ref={this.setIframeRef} />
  }
}

// const EmnailPreview = require("./email-preview.jpg") as string

type PreviewEmailProps = {
  visible: boolean
  onOk?: () => void
  onCancel?: () => void
}

const PreviewEmail: React.FC<PreviewEmailProps> = props => {
  return (
    <Modal
      // title="Invite email Example"
      visible={props.visible}
      onOk={props.onOk}
      onCancel={props.onCancel}
      className='no-padding'
      width='820px'
      style={{ top: 20, maxWidth: '100vw' }}
      maskClosable={true}
      destroyOnClose={true}
      centered={true}
      footer={null}
    >
      {/* <img src={EmnailPreview} alt="preview image" width="100%" /> */}
      <ExampleContainer content={emailTemplate} stylesheets={['']} />
    </Modal>
  )
}

export default PreviewEmail

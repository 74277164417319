import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { Animated } from 'react-animated-css'
import styled from 'styled-components'

const StyledAnimatedMessage = styled(Animated)`
  color: rgba(0, 0, 0, 0.85);
  margin-top: 5px;
  .icon {
    color: green;
    margin-right: 5px;
    margin-top: 2px;
  }
  .message {
    display: flex;
    line-height: 19px;
  }
`

type Props = {
  icon?: any
  message: any
  visible: boolean
  direction?: 'down' | 'up' | 'left' | 'right'
  style?: object
}

const CustomSuccess: React.FC<Props> = props => (
  <StyledAnimatedMessage
    animationIn='fadeInDown'
    animationOut='fadeOutUp'
    animateOnMount={true}
    isVisible={props.visible}
    style={{
      ...props.style,
      ...(props.visible === false && { position: 'absolute' }),
    }}
  >
    <div className='message'>
      <FontAwesomeIcon className='icon' icon={faCheckCircle} /> {props.message}
    </div>
  </StyledAnimatedMessage>
)

export default CustomSuccess

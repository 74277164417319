import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faExpand, faRepeat } from '@fortawesome/pro-solid-svg-icons'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'

import LottieCompleteIndicator from '~/app/components/LottieCompleteIndicator/LottieCompleteIndicator'

const messages = {
  inductionLoading: { id: 'form.registration.field.induction.loading' },
}

const toHHMMSS = function(value: string) {
  const secNum = parseInt(value, 10)
  const minutes = Math.floor(secNum / 60)
  let seconds: string | number = secNum - minutes * 60

  if (seconds < 10) {
    seconds = '0' + seconds
  }
  const time = minutes + ':' + seconds
  return time
}

const launchIntoFullscreen = function(element) {
  if (element.requestFullscreen) {
    element.requestFullscreen()
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen()
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen()
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen()
  }
}

const exitFullscreen = function() {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen()
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen()
  }
}

const StyledVideoWrapper = styled.div`
  width: 100%;
  height: 45vh;
  background: #111;
  overflow: hidden;
  -ms-overflow-style: none;
  position: relative;
  border-radius: 4px;
  &.controls-hidden {
    .player {
      cursor: none;
    }
    .controls {
      opacity: 0;
    }
  }
  .player {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .player,
  .controls {
    pointer-events: none;
  }
  .controls {
    transition: all 1s ease-in-out;
    position: absolute;
    bottom: 0;
    padding-top: 12px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    height: 50px;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  }
  .progressholder {
    height: 5px;
    border-radius: 4px;
    background: rgba(61, 61, 61, 0.7);
    margin-bottom: 2px;
    /* cursor: pointer; */
  }
  .buffered {
    height: 5px;
    border-radius: 4px;
    background: rgba(160, 160, 160, 0.4);
    width: 0;
  }
  .progress {
    position: relative;
    bottom: 5px;
    height: 5px;
    border-radius: 4px;
    background: rgba(255, 0, 0, 0.7);
    width: 0;
  }
  .progressholder:hover .progressorb {
    display: block;
  }
  .progressorb {
    position: relative;
    bottom: 14px;
    width: 14px;
    height: 14px;
    border-radius: 10px;
    background: rgba(255, 0, 0, 0.7);
    display: none;
  }
  .buffered,
  .progressorb {
    pointer-events: none;
  }

  .action-bar {
    display: flex;
    z-index: 999;
    margin-left: 10px;
    margin-right: 10px;
    .playpause {
      flex-grow: 1;
      font-size: 14pt;
      color: #fff;
      cursor: pointer;
      margin-right: 10px;
      &:hover {
        color: #d3d3d3;
      }
    }
    .progresstime {
      font-size: 10pt;
      line-height: 20pt;
      color: #fff;
      font-weight: bold;
      margin-right: 10px;
    }
    .fullscreen {
      color: #fff;
      font-size: 14pt;
      cursor: pointer;
    }
  }
  .complete-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

interface LocalCompProps extends WrappedComponentProps {
  completed: boolean
  skipped: boolean
  onVideoCompleted: () => void
  inductionVideoSrc: string
}

interface State {
  controlsEnabled: boolean
  controlsHidden: boolean
  videoPaused: boolean
  videoEnded: boolean
  over: boolean
  full: boolean
  progressPos: number
  progress: number
  buffered: number
  videoDuration: string
}

class Video extends React.Component<LocalCompProps, State> {
  videoRef: any
  videoLoader: any
  videoProgress: any
  constructor(props: LocalCompProps) {
    super(props)
    this.state = {
      controlsEnabled: false,
      controlsHidden: false,
      videoPaused: true,
      videoEnded: false,
      over: false,
      full: false,
      progressPos: 0,
      progress: 0,
      buffered: 0,
      videoDuration: this.props.intl.formatMessage(messages.inductionLoading),
    }
  }

  componentDidMount() {
    this.videoRef.addEventListener('loadeddata', this.updateTime)
    this.videoLoader = setInterval(() => {
      if (this.videoRef.duration > 0) {
        this.setState({ controlsEnabled: true }, () => {
          clearInterval(this.videoLoader)
          this.videoProgress = setInterval(this.updateProgress, 100)
        })
      }
    }, 100)
  }

  componentWillUnmount() {
    clearInterval(this.videoProgress)
    clearInterval(this.videoLoader)
  }

  setVideoRef = (ref: any) => {
    this.videoRef = ref
  }

  updateTime = () => {
    if (this.videoRef !== null) {
      const { currentTime, duration } = this.videoRef
      this.setState({
        videoDuration: `${toHHMMSS(currentTime)} / ${toHHMMSS(duration)}`,
      })
    }
  }

  updateProgress = () => {
    const bp = 0
    // this.videoRef.buffered.end(this.videoRef.buffered.length - 1) / this.videoRef.duration
    const buffered = bp * 100

    const p = this.videoRef.currentTime / this.videoRef.duration
    const progress = p * 100
    this.updateTime()

    if (this.videoRef.ended) {
      this.props.onVideoCompleted()
      this.setState({
        buffered: buffered,
        progress: progress,
        videoEnded: true,
        videoPaused: true,
      })
      clearInterval(this.videoProgress)
      clearInterval(this.videoLoader)
    } else {
      this.setState({ buffered: buffered, progress: progress })
    }
  }

  playPauseVideo = () => {
    if (this.videoRef !== null) {
      if (this.videoRef.paused) {
        this.setState({ videoPaused: false, videoEnded: false }, () => {
          this.videoRef.play()
        })
      } else {
        this.setState({ videoPaused: true, videoEnded: false }, () => {
          this.videoRef.pause()
        })
      }
    }
  }

  onProgressMove = e => {
    const pos = e.nativeEvent.offsetX - 8
    this.setState({ progressPos: pos })
  }
  onProgressEnter = () => {
    this.setState({ over: true })
  }
  onProgressLeave = () => {
    this.setState({ over: true })
  }
  onProgressClick = e => {
    const pos = e.nativeEvent.offsetX - 8
    const prop = (pos + 1) / e.currentTarget.offsetWidth
    const prog = prop * this.videoRef.duration
    this.videoRef.currentTime = prog
    this.setState({ videoEnded: false })
  }

  toggleFullScreen = () => {
    if (!this.state.full) {
      launchIntoFullscreen(document.getElementById('playercontainer'))
    } else {
      exitFullscreen()
    }
    this.setState({ full: !this.state.full })
  }

  render() {
    const {
      controlsEnabled,
      videoPaused,
      controlsHidden,
      // progressPos,
      videoDuration,
      videoEnded,
      progress,
      buffered,
    } = this.state
    return (
      <StyledVideoWrapper id='playercontainer' className={controlsHidden ? 'controls-hidden' : ''}>
        <video
          id='inductionVideoDom'
          className='player'
          ref={this.setVideoRef}
          width='100%'
          controls={false}
          style={{ pointerEvents: controlsEnabled ? 'auto' : 'none' }}
          onClick={this.playPauseVideo}
        >
          <source src={this.props.inductionVideoSrc} type='video/mp4' />

          <FormattedMessage id='form.registration.field.induction.noSupport' />
        </video>
        <div className='controls' style={{ pointerEvents: controlsEnabled ? 'auto' : 'none' }}>
          <div
            className='progressholder'
            onMouseEnter={this.onProgressEnter}
            onMouseLeave={this.onProgressLeave}
            // onClick={this.onProgressClick}
            // onMouseMove={this.onProgressMove}
          >
            <div className='buffered' style={{ width: `${buffered}%` }} />
            <div className='progress' style={{ width: `${progress}%` }} />
            {/* <div className="progressorb" style={{ marginLeft: `${progressPos}px` }} /> */}
          </div>
          <div className='action-bar'>
            <div className='playpause' onClick={this.playPauseVideo}>
              <FontAwesomeIcon icon={videoEnded ? faRepeat : videoPaused ? faPlay : faPause} />
            </div>
            <div className='progresstime'>{videoDuration}</div>
            <div className='fullscreen' onClick={this.toggleFullScreen}>
              <FontAwesomeIcon icon={faExpand} />
            </div>
          </div>
        </div>
        {this.props.completed && videoPaused && (
          <div className='complete-indicator'>
            <LottieCompleteIndicator />
          </div>
        )}
      </StyledVideoWrapper>
    )
  }
}

export default injectIntl(Video)

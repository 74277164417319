import React from 'react'
import { Animated } from 'react-animated-css'
import 'react-phone-number-input/style.css'
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import EnterButton from '~/app/components/EnterButton'
import * as enums from '~/typing/KENAI/enums.d.ts'
import CustomFormItem from '~/app/components/SharedFormComponents/CustomFormItem'
import CustomError from '~/app/components/SharedFormComponents/CustomErrorSFC'

import OtpInput from './InputComponents/OtpInput'
import { FlowControl, FormValues } from '../component'
import translate from '~/translations/translate'

const ActionWrapper = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'action';
  .action {
    margin-top: 20px;
    grid-area: action;
  }
`

const AuthLockIcon = '/images/custom-icons/auth-lock.svg'

type Props = {
  onResendOTP: () => void
  formValues: FormValues
  flowControl: FlowControl
  isMobile: boolean
  otpProcesingComplete: boolean
  onOTPOkClick: () => void
  onFieldsChange: (changedFields: object) => void
  getFieldDecorator: <T>(id: keyof T, options?: GetFieldDecoratorOptions | undefined) => (node: React.ReactNode) => React.ReactNode
}

const AuthenticatePage: React.FC<Props> = props => {
  let hasOTPFeedback: boolean = false
  if (
    (!props.flowControl.otpRequest.otpRequestSuccessfull &&
      !props.flowControl.otpRequest.otpRequestInProcess &&
      props.flowControl.otpRequest.otpRequestErrorMessage) ||
    (!props.flowControl.otpValidation.otpValidationSuccessfull &&
      !props.flowControl.otpValidation.otpValidationInProcess &&
      props.flowControl.otpValidation.otpValidationErrorMessage)
  ) {
    hasOTPFeedback = true
  }

  const getOTPErrorMessage = () => {
    let linkSuffix = ''
    let eventHandler: Function | undefined = undefined
    if (
      props.flowControl.otpRequest.otpRequestErrorMessage === undefined ||
      props.flowControl.otpValidation.otpValidationErrorType === enums.OTP_VALIDATION_ERRORS.OTP_INCORRECT_OR_EXPIRED ||
      props.flowControl.otpValidation.otpValidationErrorType === enums.OTP_VALIDATION_ERRORS.TECHNICAL_ERROR
    ) {
      linkSuffix = translate({ id: 'form.registration.input.otp.toResend' })
      eventHandler = props.onResendOTP
    }

    return (
      <CustomError
        visible={hasOTPFeedback}
        message={
          props.flowControl.otpValidation.otpValidationErrorMessage
            ? props.flowControl.otpValidation.otpValidationErrorMessage
            : props.flowControl.otpRequest.otpRequestErrorMessage
        }
        linkEvent={eventHandler as () => void}
        linkSuffix={linkSuffix}
      />
    )
  }

  const onOtpFieldChange = (e: string) => {
    const changeFields = {
      otpCode: {
        dirty: true,
        errors: undefined,
        isValid: false,
        name: 'otpCode',
        touched: true,
        value: e,
      },
    }
    if (e.length === 4) {
      changeFields.otpCode.isValid = true
    }
    props.onFieldsChange(changeFields)
  }

  return (
    <>
      <h1>
        <FormattedMessage id='form.setup.authentication.title' />
      </h1>
      <CustomFormItem
        className='required-form-item'
        label={
          <div
            style={{
              whiteSpace: 'pre-wrap',
              textAlign: 'center',
              maxWidth: 600,
              lineHeight: '23px',
            }}
          >
            <FormattedMessage id='form.setup.authentication.description' />
          </div>
        }
        // form.setup.input.otp.title
        hasFeedback={hasOTPFeedback}
        extra={getOTPErrorMessage()}
      >
        <div style={{ marginTop: '30px', marginBottom: '50px' }}>
          <img src={AuthLockIcon} alt='auth locks' style={{ width: '115px' }} />
        </div>
        <OtpInput
          onChange={onOtpFieldChange}
          numInputs={4}
          {...props.formValues.otpCode.isValid &&
            !props.isMobile &&
            !hasOTPFeedback &&
            !props.otpProcesingComplete && { onPressEnter: props.onOTPOkClick }}
        />

        <ActionWrapper>
          <Animated
            animationIn='fadeInDown'
            animationOut='fadeOutUp'
            animateOnMount={true}
            className='action'
            isVisible={
              (props.formValues.otpCode.value.length && props.formValues.otpCode.value.length < 4) ||
              props.formValues.otpCode.value.length === 0
                ? true
                : false
            }
          >
            <div>
              <FormattedMessage
                id='form.setup.resend.text'
                values={{
                  RESEND_BUTTON: (
                    <span onClick={props.onResendOTP} role='link'>
                      <FormattedMessage id='form.setup.button.resend' />
                    </span>
                  ),
                }}
              />
            </div>
          </Animated>
          <Animated
            animationIn='fadeInUp'
            animationOut='fadeOutDown'
            animateOnMount={true}
            className='action'
            isVisible={props.formValues.otpCode.isValid && !props.isMobile && !hasOTPFeedback && !props.otpProcesingComplete}
          >
            <EnterButton size='large' onClick={props.onOTPOkClick} loading={props.flowControl.otpValidation.otpValidationInProcess}>
              <FormattedMessage id='form.registration.button.ok' />
            </EnterButton>
          </Animated>
        </ActionWrapper>
      </CustomFormItem>
    </>
  )
}

export default AuthenticatePage

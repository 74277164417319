import update from 'immutability-helper'
import { createSelector } from 'reselect'
import { ActionCreator } from '~/store/store-action-creator'
import * as interfaces from '~/typing/KENAI/interfaces.d.ts'
// Action Creators
export const ActionCreators = {
  StoreSetSetupToken: new ActionCreator<'StoreSetSetupToken', string>('StoreSetSetupToken'),
  StoreSetInviteToken: new ActionCreator<'StoreSetInviteToken', string>('StoreSetInviteToken'),
  StoreSetEventToken: new ActionCreator<'StoreSetEventToken', string>('StoreSetEventToken'),
  StoreSetDeviceToken: new ActionCreator<'StoreSetDeviceToken', string>('StoreSetDeviceToken'),
  StoreSetRegistrationEntity: new ActionCreator<'StoreSetRegistrationEntity', interfaces.REGISTRATION_ENTITY>('StoreSetRegistrationEntity'),
  StoreSetPostInitialRegistrationFlowAvailability: new ActionCreator<
    'StoreSetPostInitialRegistrationFlowAvailability',
    interfaces.REGISTRATION_ENTITY['postInitialRegistrationFlowAvailability']
  >('StoreSetPostInitialRegistrationFlowAvailability'),
  StoreSetVisitorAgreement: new ActionCreator<'StoreSetVisitorAgreement', interfaces.VISITOR_AGREEMENT>('StoreSetVisitorAgreement'),
  StoreSetRegistrationTokenError: new ActionCreator<'StoreSetRegistrationTokenError', interfaces.MESSAGE_STRUCTURE>(
    'StoreSetRegistrationTokenError'
  ),
  StoreSetRegistrationTokenBlockingPreviousSubmissionAlert: new ActionCreator<
    'StoreSetRegistrationTokenBlockingPreviousSubmissionAlert',
    any
  >('StoreSetRegistrationTokenBlockingPreviousSubmissionAlert'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

export interface State {
  readonly setupToken: string
  readonly inviteToken: string
  readonly eventToken: string
  readonly deviceToken: string
  readonly registrationEntity: interfaces.REGISTRATION_ENTITY
  readonly tokenErrorMessage: interfaces.MESSAGE_STRUCTURE
  readonly blockingPreviousSubmissionAlert: any
  readonly visitorAgreement: interfaces.VISITOR_AGREEMENT | undefined
}

const initialState = (): State => {
  return {
    setupToken: '',
    inviteToken: '',
    eventToken: '',
    deviceToken: '',
    registrationEntity: {
      sourceDeviceToken: undefined,
      sourceDeviceTokenUnverifiedProcessing: false,
      branding: {
        primary: 'rgba(106,180,100,1)',
        secondary: 'rgba(17,38,56,1)',
        tertiary: 'rgba(17,38,56,1)',
        text: 'rgba(255,255,255,1)',
      },
      logo: '',
      entityName: 'Kenai',
      companyName: 'Kenai',
      eventText: undefined,
      optionalFieldsEnabled: {
        email: false,
        emailRequired: false,
        personalIdentificationNr: false,
        company: false,
        inductionVideo: false,
        inductionQuestions: false,
        hostSearch: false,
      },
      inviteOnlyFieldEnablement: {
        dietaryRequirements: false,
      },
      hasBeenLoaded: false,
      cornerImages: {
        tl: null,
        tr: null,
        bl: null,
        br: null,
      },
      inductionContent: {
        inductionVideoLink: '',
        inductionQuestions: [],
        version: 0,
      },
      parkingDetails: {
        parkingAvailable: false,
        parkingProviderName: '',
        hasTerms: false,
        termsSingleLines: [],
        captureFields: [],
      },
      checkInFieldDetails: {
        checkInFieldsAvailable: false,
        fieldConditions: {},
        captureFields: [],
      },
      postInitialRegistrationFlowAvailability: {
        isPostInitialRegistration: false,
        tokenHasBeenPreviouslyProcessed: false,
        tokenPhoneNumber: '',
        completeInduction: {
          available: false,
          previousData: undefined,
        },
        updateParking: {
          available: false,
        },
        captureCheckInFields: {
          available: false,
        },
      },
      inviteCreationFields: {},
      whiteLabeled: false,
    },
    blockingPreviousSubmissionAlert: undefined,
    tokenErrorMessage: {
      id: '',
    },
    visitorAgreement: undefined,
  }
}

// Reducer
export default function reducer(state: State = initialState(), action: Action): State {
  if (action.type === ActionCreators.StoreSetSetupToken.type) {
    const payload = action.payload as string
    return update(state, {
      setupToken: {
        $set: payload,
      },
    })
  }
  if (action.type === ActionCreators.StoreSetInviteToken.type) {
    const payload = action.payload as string
    return update(state, {
      inviteToken: {
        $set: payload,
      },
    })
  }
  if (action.type === ActionCreators.StoreSetEventToken.type) {
    const payload = action.payload as string
    return update(state, {
      eventToken: {
        $set: payload,
      },
    })
  }
  if (action.type === ActionCreators.StoreSetDeviceToken.type) {
    const payload = action.payload as string
    return update(state, {
      deviceToken: {
        $set: payload,
      },
    })
  }
  if (action.type === ActionCreators.StoreSetRegistrationEntity.type) {
    const payload = action.payload as interfaces.REGISTRATION_ENTITY
    return update(state, {
      registrationEntity: {
        $set: payload,
      },
    })
  }
  if (action.type === ActionCreators.StoreSetPostInitialRegistrationFlowAvailability.type) {
    const payload = action.payload as interfaces.REGISTRATION_ENTITY['postInitialRegistrationFlowAvailability']
    return update(state, {
      registrationEntity: {
        postInitialRegistrationFlowAvailability: {
          $set: payload,
        },
      },
    })
  }
  if (action.type === ActionCreators.StoreSetVisitorAgreement.type) {
    const payload = action.payload as interfaces.VISITOR_AGREEMENT
    return update(state, {
      visitorAgreement: {
        $set: payload,
      },
    })
  }
  if (action.type === ActionCreators.StoreSetRegistrationTokenError.type) {
    const payload = action.payload as interfaces.MESSAGE_STRUCTURE
    return update(state, {
      tokenErrorMessage: {
        $set: payload,
      },
    })
  }
  if (action.type === ActionCreators.StoreSetRegistrationTokenBlockingPreviousSubmissionAlert.type) {
    const payload = action.payload as any
    return update(state, {
      blockingPreviousSubmissionAlert: {
        $set: payload,
      },
    })
  }
  return state
}

// Selector
const getStateProcessInstance = state => state.processinstance as State
export const getProcessInstanceState = createSelector<any, any, State>(
  [getStateProcessInstance],
  returnedState => ({ ...returnedState } as State)
)

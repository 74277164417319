import { combineReducers } from 'redux'

import { default as ProcessInstanceReducer, State as ProcessInstanceState } from '~/store/processinstance'

import { default as RegistrationInstanceReducer, State as RegistrationInstanceState } from '~/store/registrationinstance'

import { default as SetupInstanceReducer, State as SetupInstanceState } from '~/store/setupinstance'

export interface RootState {
  processinstance: ProcessInstanceState
  registrationinstance: RegistrationInstanceState
  setupinstance: SetupInstanceState
}

const rootReducer = combineReducers<RootState>({
  processinstance: ProcessInstanceReducer,
  registrationinstance: RegistrationInstanceReducer,
  setupinstance: SetupInstanceReducer,
})
export default rootReducer

import { Slider } from 'antd'
import EnterButton from '~/app/components/EnterButton'
import CustomFormItem from '~/app/components/SharedFormComponents/CustomFormItem'
import FormLabel from '~/app/components/SharedFormComponents/FormLabelSFC'
import StyledInputAlert from '~/app/components/StyledInputAlert'
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form'
import React from 'react'
import { Animated } from 'react-animated-css'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import { FlowControl, FormValues } from '../../component'
import { CheckInFieldType } from '~/typing/KENAI/interfaces'

const messages = {
  ERROR_INPUT_REQUIRED: { id: 'form.registration.input.error.required' },
}
type Props = {
  getFieldDecorator: <T>(id: keyof T, options?: GetFieldDecoratorOptions | undefined) => (node: React.ReactNode) => React.ReactNode
  formValues: FormValues
  flowControl: FlowControl
  isMobile: boolean
  fieldName: string
  fieldNumber: string
  fieldLabel: React.ReactNode | string
  fieldSubLabel?: string
  fieldRequired?: boolean
  fieldRequiredText?: string
  fieldRules?: Array<object>
  sliderConfig: {
    max: number
    min: number
    step: number
    unit?: string
  }
  defaultValue?: number | [number, number]
  inputSettings: {
    icon?: string
    iconType: string
    inputPlaceholder: string
    inputType: CheckInFieldType
    mandatory: boolean
    defaultValue?: string | number | string[]
  }
  nextItemFocus: () => void
}

const Wrapper = styled.div`
  position: relative;
  .ant-form-extra {
    position: absolute;
  }
  .ant-form-item {
    margin-bottom: 5px;
  }
  .ant-slider-handle {
    width: 36px;
    height: 36px;
    margin-left: -21px;
    margin-top: -15px;
  }
`

const SliderSFC = (props: Props) => {
  const { formatMessage } = useIntl()
  const inputSettingsDefault = props.inputSettings.defaultValue
  const defaultValue = Number(props.defaultValue || inputSettingsDefault || 0)
  const rules: Array<object> = []

  if (props.fieldRules && props.fieldRules.length > 0) {
    props.fieldRules.forEach((item: object) => {
      rules.push(item)
    })
  }
  if (props.fieldRequired === true) {
    rules.push({
      required: true,
      message: (
        <>
          <Animated animationIn='fadeInDown' animationOut='fadeOutUp' animateOnMount={true} isVisible={true} animationInDelay={0}>
            <StyledInputAlert
              className={'input-error'}
              type='error'
              message={
                props.fieldRequiredText && props.fieldRequiredText.length > 0
                  ? props.fieldRequiredText
                  : formatMessage(messages.ERROR_INPUT_REQUIRED)
              }
              banner={true}
            />
          </Animated>
        </>
      ),
    })
  }

  return (
    <Wrapper>
      <CustomFormItem
        className={props.fieldRequired ? 'required-form-item' : ''}
        label={
          <FormLabel number={props.fieldNumber} {...props.fieldSubLabel && { sublabel: props.fieldSubLabel }}>
            {props.fieldLabel}
          </FormLabel>
        }
        extra={
          <Animated
            animationIn='fadeInUp'
            animationOut='fadeOutDown'
            animateOnMount={false}
            isVisible={props.formValues[props.fieldName].isValid}
            style={{ marginTop: '-10px !important' }}
          >
            <EnterButton size='large' onClick={props.nextItemFocus}>
              <FormattedMessage id='form.registration.button.ok' />
            </EnterButton>
          </Animated>
        }
      >
        <div>
          {'Use the slider to input your '}
          {props.fieldLabel}
        </div>
        {props.getFieldDecorator(props.fieldName, {
          rules: rules,
        })(<Slider {...props.sliderConfig} defaultValue={defaultValue} className={'allow-scroll'} />)}
        <div style={{ textAlign: 'center' }}>
          {`${props.formValues[props.fieldName].isValid ? props.formValues[props.fieldName].value : '--.-'}${
            props.sliderConfig.unit ? ' ' + props.sliderConfig.unit : ''
          }`}
        </div>
      </CustomFormItem>
    </Wrapper>
  )
}

export default SliderSFC

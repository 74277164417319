import { Button as AntButton } from "antd"

import styled from "styled-components"

const ButtonStyle = (Component) => styled(Component)`
  &.ant-btn:not(.ant-btn-primary):hover,
  &.ant-btn:not(.ant-btn-primary):focus {
    border-color: ${(props) => props.theme.branding.primary};
    color: ${(props) => props.theme.branding.primary};
  }
  &.ant-btn-primary {
    background-color: ${(props) => props.theme.branding.primary};
    border-color: ${(props) => props.theme.branding.primary};
    &[disabled] {        
      background-color: #fafafa;
        border-color: #fafafa;
    }
  }
  &.ant-btn-background-ghost {
    &.ant-btn-primary {
      color: ${(props) => props.theme.branding.primary};
      border-color: ${(props) => props.theme.branding.primary};
      &[disabled] {        
        background-color: #fafafa;
        color: #fafafa;
        border-color: #fafafa;
      }
    }
  }
`
export default ButtonStyle(AntButton)

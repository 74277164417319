const awsmobile = {
  aws_project_region: "__AWS_REGION__",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "__AWS_INVITE_OPERATIONS_API_ID__",
      name: "invitationOperations",
      description: "",
      endpoint: "https://__AWS_INVITE_OPERATIONS_API_ID__.execute-api.__AWS_REGION__.amazonaws.com/LATEST",
      region: "__AWS_REGION__",
      paths: ["/invitationRegistrationProcessor", "/eventBasedInviteProcessor", "/invitationSetupProcessor"]
    }
  ]
}

export default awsmobile

import * as interfaces from '~/typing/KENAI/interfaces.d.ts'
import moment, { Moment } from 'moment'
import momentTZ from 'moment-timezone'
import 'moment/locale/fr'
import 'moment/locale/af'

// in the config function
function getLocale(name: string) {
  moment.locale(name) // apply it to moment
  momentTZ.defineLocale(name, (moment.localeData() as any)._config) // copy locale to moment-timezone
  momentTZ.locale(name) // apply it to moment-timezone
}

export default function getTimeStringFromEventMetaData(eventMetaData: interfaces.eventMetaData): string {
  debugger
  let allDay = eventMetaData.duration.days > 0 //all day should have at least 1 day
  const locale: string | null = localStorage.getItem('locale')
  getLocale(locale !== null ? locale : 'en')
  if (eventMetaData.duration.hours > 0 || eventMetaData.duration.minutes > 0) {
    allDay = false
  }
  const eventDefaultTimezoneString = eventMetaData.eventDefaultTimezoneString
  const eventTimezoneUTCOffset = eventMetaData.eventTimezoneUTCOffset
  const eventStartTime = eventMetaData.eventStartTime
  let start: Moment | undefined = undefined
  if (eventTimezoneUTCOffset !== -999) {
    const tzAdjustment = eventTimezoneUTCOffset * 60 * 1000
    start = moment.utc(eventStartTime + tzAdjustment)
  } else if (eventDefaultTimezoneString !== 'N/A') {
    const utcMoment: any = momentTZ.utc(eventStartTime)
    start = utcMoment.tz(eventDefaultTimezoneString)
  } else {
    start = moment.utc(eventStartTime)
  }
  if (start) {
    let formatedOutput = start.format('dddd, MMM D, YYYY')
    if (!allDay) {
      formatedOutput = start.format('dddd, MMM D, YYYY [a]t hh:mm a')
    }
    return formatedOutput
  } else {
    return 'Unknown'
  }
}

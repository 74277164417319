import React from 'react'
import StyledButton from '~/app/components/StyledButton'
import styled, { keyframes } from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { isMobile, isIE } from 'react-device-detect'

import LottieSubmitIndicatorSFC from './LottieSubmitIndicatorSFC'
// import { Animated } from "react-animated-css"

type Props = {
  submitRegistration: () => void
  visible: boolean
}

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }

`

const UploadProfileFooter = styled.div`
  position: fixed;
  bottom: 0;
  width: 100vw;
  left: 0;
  animation-name: ${fadeInUp};
  animation-delay: 0s;
  animation-duration: 1s;
  animation-fill-mode: both;
  transition: all 0.3s ease-in-out;
  button {
    height: 70px;
  }
`
const StyledContainer = styled.div`
  pointer-events: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
`

const FormLabelSFC: React.FC<Props> = props => {
  return (
    <UploadProfileFooter>
      {!isMobile && !isIE && (
        <StyledContainer>
          <LottieSubmitIndicatorSFC />
        </StyledContainer>
      )}
      <StyledButton type='primary' size='large' block={true} style={{ borderRadius: 0 }} onClick={props.submitRegistration}>
        <FormattedMessage id='form.registration.button.submit' />
      </StyledButton>
    </UploadProfileFooter>
  )
}

export default FormLabelSFC

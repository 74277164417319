import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import LottieCompleteIndicator from '../LottieCompleteIndicator/LottieCompleteIndicator'
import { FormattedMessage } from 'react-intl'
import getTimeStringFromTs from '~/utils/getTimeStringFromTs'

type ViewProps = {
  className?: string
  entityName: string
  eventText?: string
  isUnverified?: boolean
}

const CompletionContainer = styled.div`
  width: 50%;
  height: 100vh;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-x: none;
  -ms-overflow-y: auto;
  .wrapper {
    position: relative;
    max-height: 100%;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1.5em;
    .lower {
      max-width: 700px;
      margin: -100px auto 0;
    }
  }
  @media screen and (max-width: 800px) {
    width: 95%;
  }
`
const ConfirmationPageSFC = ({ eventText, entityName, isUnverified }: ViewProps) => {
  return (
    <Fragment>
      <CompletionContainer>
        <div className='wrapper'>
          <div className='upper'>
            <LottieCompleteIndicator />
          </div>
          <div className='lower'>
            <Animated isVisible={true} animationIn='bounceInUp' animationOut='bounceOutDown' style={{ opacity: 1 }}>
              <span>
                {eventText ? eventText : <FormattedMessage id='page.confirmation.text' />}
                <br />
                <strong>{entityName}</strong>
                {isUnverified && (
                  <>
                    <br />
                    <span>
                      <strong>Completed on: </strong>
                      {getTimeStringFromTs(Date.now())}
                    </span>
                    <br />
                    <br />
                    <span>
                      <strong>Please present this at reception as proof of your check-in.</strong>
                    </span>
                  </>
                )}
              </span>
            </Animated>
          </div>
        </div>
      </CompletionContainer>
    </Fragment>
  )
}

export default ConfirmationPageSFC

import React from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'

import StyledButton from '~/app/components/StyledButton'

const EnterButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: ${props => props.theme.branding.primary};
    border-color: ${props => props.theme.branding.primary};
  }
  button {
    align-self: center;
  }
  .btn-enter-text {
    margin-left: 10px;
    opacity: 0.5;
    color: ${props => props.theme.branding.primary};
    white-space: nowrap;
    align-self: center;
  }
`
type Props = {
  size: string
  onClick?: () => void
  loading?: boolean
  ghost?: boolean
}

export const EnterButtonSFC: React.FC<Props> = props => {
  if (isMobile) {
    return (
      <StyledButton type='primary' {...props}>
        {props.children}
      </StyledButton>
    )
  } else {
    return (
      <EnterButtonContainer>
        <div>
          <StyledButton type='primary' {...props}>
            <span>{props.children}</span>
          </StyledButton>
          <span className='btn-enter-text'>
            <FormattedMessage
              id='component.enterbutton.press'
              values={{
                ENTER_TEXT: (
                  <strong>
                    <FormattedMessage id='component.enterbutton.enter' />
                  </strong>
                ),
              }}
            />
          </span>
        </div>
      </EnterButtonContainer>
    )
  }
}

export default EnterButtonSFC

import { AutoComplete as AntAutoComplete } from "antd"

import styled from "styled-components"

const AutoCompleteStyle = Component => styled(Component)`
  width: 100%;
  &&,
  &.ant-select-auto-complete.ant-select {
    &:hover {
      .ant-input:not(.ant-input-disabled) {
        border-color: ${props => props.theme.branding.primary};
      }
    }
    .ant-input,
    &.ant-input {
      color: ${props => props.theme.branding.primary};
      &:focus,
      &:hover {
        border-color: ${props => props.theme.branding.primary};
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
`

export default AutoCompleteStyle(AntAutoComplete)

import EnterButton from '~/app/components/EnterButton'
import CustomFormItem from '~/app/components/SharedFormComponents/CustomFormItem'
import FormLabel from '~/app/components/SharedFormComponents/FormLabelSFC'
import StyledButton from '~/app/components/StyledButton'
import StyledInputAlert from '~/app/components/StyledInputAlert'
import StyledSelectSFC, { SelectOption } from '~/app/components/StyledSelect'
import { getSelectWidth, getTextWidth } from '~/app/utility/getTextWidth'
import useIsTouchDevice from '~/app/utility/useIsTouchDevice'
import { Divider } from 'antd'
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form'
import { SelectValue } from 'antd/lib/select'
import React, { useMemo, useState } from 'react'
import { Animated } from 'react-animated-css'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import { FlowControl, FormValue, FormValues } from '../../component'

const messages = {
  ERROR_INPUT_REQUIRED: { id: 'form.registration.input.error.required' },
}

export interface SelectItem {
  dataKey: string
  text: string
}

type Props = {
  getFieldDecorator: <T>(id: keyof T, options?: GetFieldDecoratorOptions | undefined) => (node: React.ReactNode) => React.ReactNode
  formValues: FormValues
  flowControl: FlowControl
  isMobile: boolean
  inputProps?: object

  fieldName: string
  fieldNumber: string
  fieldLabel: React.ReactNode | string
  fieldRules?: Array<object>
  fieldSubLabel?: string
  fieldRequired?: boolean
  fieldRequiredText?: string
  fieldPlaceHolder: string

  processOnPressEnter: (event: React.FormEvent<HTMLInputElement>) => void
  processOnInputBlur: () => void
  // onTextInputKeyDown: (event: any) => void;
  nextItemFocus: () => void
  onSelect?: (value: SelectValue, option: React.ReactElement<any>) => any
  selectItems: SelectItem[]

  onTextInputKeyDown?: (event: any) => void

  open?: boolean
}

const Wrapper = styled.div`
  position: relative;
  .ant-form-extra {
    position: absolute;
  }
  .ant-form-item {
    margin-bottom: 5px;
  }
  div .ant-select-selection {
    height: 40px;
    font-size: 16px;
    .ant-select-selection__rendered {
      line-height: 40px;
      .ant-select-search {
        height: 0px;
        line-height: 0px;
        .ant-select-search__field {
          cursor: none;
          color: transparent;
        }
      }
      .ant-select-selection__choice {
        height: 32px;
        line-height: 32px;
      }
    }
    .ant-select-selection-selected-value {
      line-height: 40px;
    }
  }
`

const MultiSelectionSFC = (props: Props) => {
  const { formatMessage } = useIntl()
  const touchDevice = useIsTouchDevice()
  const [visible, setVisible] = useState(false)

  const selectWidth = useMemo(() => {
    if (touchDevice) {
      return '100%'
    } else {
      let calculatedWidth = getSelectWidth(`${props.fieldPlaceHolder ? props.fieldPlaceHolder : ''}`, props.selectItems)
      if (props.formValues[props.fieldName]) {
        const formValue: FormValue = props.formValues[props.fieldName]
        let cumulativeText = ''
        if (formValue && formValue.value && formValue.value.length) {
          const stringArr = formValue.value as string[]
          stringArr.forEach(dataKey => {
            const selectItem = props.selectItems.find(selectItem => selectItem.dataKey === dataKey)
            if (selectItem && selectItem.text) {
              cumulativeText += `${selectItem.text}, `
            }
          })
        }
        if (cumulativeText.length) {
          const cumulativeWidth = getTextWidth(cumulativeText, 'normal 16px Arial, Helvetica, sans-serif')
          if (cumulativeWidth > parseInt(calculatedWidth.replace('px', ''))) {
            calculatedWidth = `${cumulativeWidth}px`
          }
        }
      }
      return calculatedWidth
    }
  }, [props.fieldPlaceHolder, props.selectItems, props.formValues, props.fieldName, touchDevice])

  const rules: Array<object> = []

  if (props.fieldRules && props.fieldRules.length > 0) {
    props.fieldRules.forEach((item: object) => {
      rules.push(item)
    })
  }
  if (props.fieldRequired === true) {
    rules.push({
      required: true,
      message: (
        <>
          <Animated animationIn='fadeInDown' animationOut='fadeOutUp' animateOnMount={true} isVisible={true} animationInDelay={0}>
            <StyledInputAlert
              className={'input-error'}
              type='error'
              message={
                props.fieldRequiredText && props.fieldRequiredText.length > 0
                  ? props.fieldRequiredText
                  : formatMessage(messages.ERROR_INPUT_REQUIRED)
              }
              banner={true}
            />
          </Animated>
        </>
      ),
    })
  }

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === 13) {
      props.processOnPressEnter({
        currentTarget: {
          id: props.fieldName,
        },
      } as React.FormEvent<HTMLInputElement>)
    }
  }

  return (
    <Wrapper>
      <CustomFormItem
        className={props.fieldRequired ? 'required-form-item no-focus-adjustment' : 'no-focus-adjustment'}
        label={
          <FormLabel number={props.fieldNumber} {...props.fieldSubLabel && { sublabel: props.fieldSubLabel }}>
            {props.fieldLabel}
          </FormLabel>
        }
        extra={
          <Animated
            animationIn='fadeInUp'
            animationOut='fadeOutDown'
            animateOnMount={false}
            isVisible={props.formValues[props.fieldName].isValid}
            style={{ marginTop: '-10px !important' }}
          >
            <EnterButton size='large' onClick={props.nextItemFocus}>
              <FormattedMessage id='form.registration.button.ok' />
            </EnterButton>
          </Animated>
        }
      >
        <div onKeyDown={onInputKeyDown}>
          {props.getFieldDecorator(props.fieldName, {
            rules: rules,
          })(
            <StyledSelectSFC
              mode='multiple'
              open={visible}
              onDropdownVisibleChange={setVisible}
              style={{ minWidth: selectWidth, maxWidth: '100%' }}
              data-hj-suppress={true}
              placeholder={props.fieldPlaceHolder}
              onSelect={props.onSelect}
              className={props.formValues[props.fieldName].isValid ? 'no-open-on-enter' : ''}
              dropdownClassName='allow-scroll'
              dropdownRender={menu => (
                <div>
                  {menu}
                  <Divider className='m-0' />
                  <div className='px-3 py-2 flex justify-end'>
                    <StyledButton
                      type='primary'
                      onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        setVisible(false)
                        props.nextItemFocus()
                      }}
                    >
                      Done
                    </StyledButton>
                  </div>
                </div>
              )}
            >
              {props.selectItems &&
                props.selectItems.map(item => {
                  return (
                    <SelectOption
                      style={{
                        height: '40px',
                        fontSize: '16px',
                        paddingTop: '0px',
                        lineHeight: '40px',
                      }}
                      key={item.dataKey}
                    >
                      {item.text}
                    </SelectOption>
                  )
                })}
            </StyledSelectSFC>
          )}
        </div>
      </CustomFormItem>
    </Wrapper>
  )
}

export default MultiSelectionSFC

import React, { Fragment } from 'react'
import { Modal, message } from 'antd'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'

import StyledButton from '~/app/components/StyledButton'
import Spin from '~/app/components/StyledSpin'

import Webcam from '~/app/utility/Webcam'
import { Animated } from 'react-animated-css'
import translate from '~/translations/translate'

const messages = {
  CAMERA_ERROR: {
    id: 'form.registration.field.camera.modal.error.noAccess',
  },
}

const CameraModal = styled(Modal)`
  .ant-modal-content {
    overflow: hidden;
    -ms-overflow-style: none;
  }
  .ant-modal-body {
    padding: 0;
    line-height: 0;
    .image-container {
      object-fit: cover;
    }
    .capture-button {
      position: absolute;
      bottom: 10px;
      right: 10px;
      > * {
        margin-right: 10px;
      }
    }
    .hidden {
      display: none;
    }
  }
  .ant-modal-footer {
    margin-top: -38px;
  }
  .ant-modal-close-x {
    color: ${props => props.theme.branding.primary};
  }
  .preview-container {
    position: absolute;
    top: 0;
  }
`

const videoConstraints = {
  width: 640,
  facingMode: 'user',
}

interface Props {
  checkCapturedFace: () => void
  onCloseModal: () => void
  faceIsBeingValidated: boolean
  setCameraRef: (ref: any) => void
  cameraFaceThatIsBeingValidated: string
  isFaceValid: boolean
  acceptCapturedFace: () => void
  changeCapturedFace: () => void
  setCameraLoaded: () => void
  isCameraLoaded: boolean
}
const CameraModalSFC: React.FC<Props> = props => {
  const cameraError = event => {
    message.error(translate(messages.CAMERA_ERROR))
    props.onCloseModal()
  }
  // if (props.isCameraLoaded) {
  //   console.log("camera loaded")
  // }
  // if (props.faceIsBeingValidated) {
  //   console.log("face validating")
  // }
  return (
    <CameraModal {...isMobile && { style: { top: 5 } }} visible={true} footer={null} onCancel={props.onCloseModal} destroyOnClose={true}>
      <Spin spinning={props.faceIsBeingValidated || !props.isCameraLoaded}>
        <Fragment>
          <Animated animationIn='fadeInDown' animationOut='fadeOutUp' isVisible={!props.faceIsBeingValidated && !props.isFaceValid}>
            <Webcam
              key='camera'
              className={`image-container`}
              ref={props.setCameraRef}
              screenshotFormat='image/jpeg'
              width={'100%'}
              height={'100%'}
              videoConstraints={videoConstraints}
              onUserMediaError={cameraError}
              onUserMedia={props.setCameraLoaded}
              mirror={{ camera: true, snapshot: true }}
              audio={false}
            />
          </Animated>
          <Animated
            className='preview-container'
            animationIn='fadeInUp'
            animationOut='fadeOutDown'
            isVisible={props.faceIsBeingValidated || props.isFaceValid}
            animateOnMount={false}
          >
            <img
              data-hj-suppress={true}
              className='image-container'
              src={props.cameraFaceThatIsBeingValidated}
              alt='user face'
              width={'100%'}
              height={'100%'}
            />
          </Animated>
          <div className='capture-button'>
            {!props.isFaceValid ? (
              <StyledButton type='primary' icon='camera' onClick={props.checkCapturedFace} loading={props.faceIsBeingValidated}>
                {props.faceIsBeingValidated ? (
                  <FormattedMessage id='form.registration.field.camera.modal.button.validating' />
                ) : (
                  <FormattedMessage id='form.registration.field.camera.modal.button.capture' />
                )}
              </StyledButton>
            ) : (
              <Fragment>
                <StyledButton type='primary' icon='camera' onClick={props.changeCapturedFace}>
                  <FormattedMessage id='form.registration.field.camera.modal.button.change' />
                </StyledButton>
                <StyledButton type='primary' icon='cloud-upload' onClick={props.acceptCapturedFace}>
                  <FormattedMessage id='form.registration.field.camera.modal.button.accept' />
                </StyledButton>
              </Fragment>
            )}
          </div>
        </Fragment>
      </Spin>
    </CameraModal>
  )
}

export default CameraModalSFC

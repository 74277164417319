import { useCallback, useEffect, useState } from 'react'

export default function useCountdown(initialStart: number, delay: number = 1000) {
  const [startFrom, setStartFrom] = useState(initialStart)
  const [countdown, setCountdown] = useState(startFrom)

  useEffect(() => {
    setCountdown(startFrom)
  }, [startFrom])

  useEffect(() => {
    let interval
    interval = setInterval(() => {
      setCountdown(count => {
        if (count > 0) return count - 1
        else {
          clearInterval(interval)
          return 0
        }
      })
    }, delay)
    return () => {
      clearInterval(interval)
    }
  }, [delay, countdown])

  const restart = useCallback(() => setCountdown(startFrom), [startFrom])

  const start = useCallback(
    (count: number) => {
      setStartFrom(count)
      restart()
    },
    [restart]
  )

  const isDone = countdown === 0

  return { countdown, restart, start, isDone }
}

import React from 'react'
import StyledAutoCompleteSFC from '~/app/components/StyledAutoComplete'
import { Icon, Spin } from 'antd'
import StyledInputSFC from '../../../StyledInput'
import { SelectValue } from 'antd/lib/select'
import { DataSourceItemObject } from 'antd/lib/auto-complete'
import translate from '~/translations/translate'
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form'
import CustomFormItem from '~/app/components/SharedFormComponents/CustomFormItem'
import FormLabel from '~/app/components/SharedFormComponents/FormLabelSFC'
import { FormattedMessage } from 'react-intl'

type ViewProps = {
  data: Array<DataSourceItemObject>
  onSearch: (value: string) => void
  onSelect: (value: SelectValue, option: Object) => void
  disabled?: boolean
  value: SelectValue
  loading: boolean
  fieldName: string
  fieldNumber: string
  getFieldDecorator: <T>(id: keyof T, options?: GetFieldDecoratorOptions | undefined) => (node: React.ReactNode) => React.ReactNode
}

export const HostSearch: React.FC<ViewProps> = props => {
  return (
    <CustomFormItem
      className='required-form-item host-search'
      label={
        <FormLabel sublabel={<FormattedMessage id='form.registration.meta.host.sublabel' />} number={props.fieldNumber}>
          <FormattedMessage id='form.registration.meta.host.label' />
        </FormLabel>
      }
    >
      <div className='flex-item'>
        <Spin spinning={props.loading}>
          <StyledAutoCompleteSFC
            dataSource={props.data}
            onSearch={props.onSearch}
            onSelect={props.onSelect}
            placeholder={translate({
              id: 'form.registration.meta.host.search.placeholder',
            })}
            disabled={props.disabled}
            value={props.value}
            dropdownClassName='registration-host-ddl'
            width='100%'
            // autoCapitalize={'sentences'}
          >
            {props.getFieldDecorator(props.fieldName, {
              rules: [],
            })(
              <StyledInputSFC suffix={<Icon type='search' className='certain-category-icon' style={{ color: 'rgba(0, 0, 0, 0.25)' }} />} />
            )}
          </StyledAutoCompleteSFC>
        </Spin>
      </div>
    </CustomFormItem>
  )
}

export default HostSearch

import React from 'react'
import { Spin, Icon } from 'antd'
import styled from 'styled-components'

type localCompProps = {
  loading?: boolean
}

export const PageLoadingStyle = Component => styled(Component)`
  && {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    &.ant-spin {
      color: rgba(16, 45, 61, 0.65) !important;
    }
    > i {
      color: rgba(16, 45, 61, 0.65);
      > svg {
        fill: rgba(16, 45, 61, 0.65);
      }
    }
  }
`
const PageLoading = PageLoadingStyle(Spin)

const PageLoadingIndicatorSFC: React.FC<localCompProps> = props => {
  if (props.children) {
    return (
      <PageLoading
        indicator={<Icon type='loading' style={{ fontSize: 35 }} spin={true} />}
        spinning={props.loading === undefined ? true : props.loading}
      >
        {props.children}
      </PageLoading>
    )
  } else {
    return (
      <PageLoading
        indicator={<Icon type='loading' style={{ fontSize: 35 }} spin={true} />}
        spinning={props.loading === undefined ? true : props.loading}
      />
    )
  }
}

export default PageLoadingIndicatorSFC

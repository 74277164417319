const emailTemplate = `
<!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
	<meta charset="utf-8"> <!-- utf-8 works for most cases -->
	<meta name="viewport" content="width=device-width"> <!-- Forcing initial-scale shouldn't be necessary -->
	<meta http-equiv="X-UA-Compatible" content="IE=edge"> <!-- Use the latest (edge) version of IE rendering engine -->
	<meta name="x-apple-disable-message-reformatting"> <!-- Disable auto-scale in iOS 10 Mail entirely -->
	<title>Kenai invitation</title> <!-- The title tag shows in email notifications, like Android 4.4. -->

	<!-- Web Font / @font-face : BEGIN -->
	<!-- NOTE: If web fonts are not required, lines 10 - 27 can be safely removed. -->

	<!-- Desktop Outlook chokes on web font references and defaults to Times New Roman, so we force a safe fallback font. -->
	<!--[if mso]>
        <style>
            * {
                font-family: sans-serif !important;
            }
        </style>
    <![endif]-->

	<!-- All other clients get the webfont reference; some will render the font and others will silently fail to the fallbacks. More on that here: http://stylecampaign.com/blog/2015/02/webfont-support-in-email/ -->
	<!--[if !mso]><!-->
	<!-- insert web font reference, eg: <link href='https://fonts.googleapis.com/css?family=Roboto:400,700' rel='stylesheet' type='text/css'> -->
	<!--<![endif]-->

	<!-- Web Font / @font-face : END -->

	<!-- CSS Reset : BEGIN -->
	<style>
		/* What it does: Remove spaces around the email design added by some email clients. */
		/* Beware: It can remove the padding / margin and add a background color to the compose a reply window. */
		html,
		body {
			margin: 0 auto !important;
			padding: 0 !important;
			height: 100% !important;
			width: 100% !important;
		}

		/* What it does: Stops email clients resizing small text. */
		* {
			-ms-text-size-adjust: 100%;
			-webkit-text-size-adjust: 100%;
		}

		/* What it does: Centers email on Android 4.4 */
		div[style*="margin: 16px 0"] {
			margin: 0 !important;
		}

		/* What it does: Stops Outlook from adding extra spacing to tables. */
		table,
		td {
			mso-table-lspace: 0pt !important;
			mso-table-rspace: 0pt !important;
		}

		/* What it does: Fixes webkit padding issue. Fix for Yahoo mail table alignment bug. Applies table-layout to the first 2 tables then removes for anything nested deeper. */
		table {
			border-spacing: 0 !important;
			border-collapse: collapse !important;
			table-layout: fixed !important;
			margin: 0 auto !important;
		}

		table table table {
			table-layout: auto;
		}

		/* What it does: Prevents Windows 10 Mail from underlining links despite inline CSS. Styles for underlined links should be inline. */
		a {
			text-decoration: none;
		}

		/* What it does: Uses a better rendering method when resizing images in IE. */
		img {
			-ms-interpolation-mode: bicubic;
		}

		/* What it does: A work-around for email clients meddling in triggered links. */
		*[x-apple-data-detectors],
		/* iOS */
		.unstyle-auto-detected-links *,
		.aBn {
			border-bottom: 0 !important;
			cursor: default !important;
			color: inherit !important;
			text-decoration: none !important;
			font-size: inherit !important;
			font-family: inherit !important;
			font-weight: inherit !important;
			line-height: inherit !important;
		}

		/* What it does: Prevents Gmail from changing the text color in conversation threads. */
		.im {
			color: inherit !important;
		}

		/* What it does: Prevents Gmail from displaying a download button on large, non-linked images. */
		.a6S {
			display: none !important;
			opacity: 0.01 !important;
		}

		/* If the above doesn't work, add a .g-img class to any image in question. */
		img.g-img+div {
			display: none !important;
		}

		/* What it does: Removes right gutter in Gmail iOS app: https://github.com/TedGoas/Cerberus/issues/89  */
		/* Create one of these media queries for each additional viewport size you'd like to fix */

		/* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
		@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
			u~div .email-container {
				min-width: 320px !important;
			}
		}

		/* iPhone 6, 6S, 7, 8, and X */
		@media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
			u~div .email-container {
				min-width: 375px !important;
			}
		}

		/* iPhone 6+, 7+, and 8+ */
		@media only screen and (min-device-width: 414px) {
			u~div .email-container {
				min-width: 414px !important;
			}
		}
	</style>
	<!-- CSS Reset : END -->
	<!-- Reset list spacing because Outlook ignores much of our inline CSS. -->
	<!--[if mso]>
	<style type="text/css">
		ul,
		ol {
			margin: 0 !important;
		}
		li {
			margin-left: 30px !important;
		}
		li.list-item-first {
			margin-top: 0 !important;
		}
		li.list-item-last {
			margin-bottom: 10px !important;
		}
	</style>
	<![endif]-->

	<!-- Progressive Enhancements : BEGIN -->
	<style>
		/* What it does: Hover styles for buttons */
		.button-td,
		.button-a {
			transition: all 100ms ease-in;
		}

		.button-td-primary:hover,
		.button-a-primary:hover {
			background: #555555 !important;
			border-color: #555555 !important;
		}

		/* Media Queries */
		@media screen and (max-width: 600px) {

			.email-container {
				width: 100% !important;
				margin: auto !important;
			}

			/* What it does: Forces elements to resize to the full width of their container. Useful for resizing images beyond their max-width. */
			.fluid {
				max-width: 100% !important;
				height: auto !important;
				margin-left: auto !important;
				margin-right: auto !important;
			}

			/* What it does: Forces table cells into full-width rows. */
			.stack-column,
			.stack-column-center {
				display: block !important;
				width: 100% !important;
				max-width: 100% !important;
				direction: ltr !important;
			}

			/* And center justify these ones. */
			.stack-column-center {
				text-align: center !important;
			}

			/* What it does: Generic utility class for centering. Useful for images, buttons, and nested tables. */
			.center-on-narrow {
				text-align: center !important;
				display: block !important;
				margin-left: auto !important;
				margin-right: auto !important;
				float: none !important;
			}

			table.center-on-narrow {
				display: inline-block !important;
			}

			/* What it does: Adjust typography on small screens to improve readability */
			.email-container p {
				font-size: 17px !important;
			}
		}
	</style>
	<!-- Progressive Enhancements : END -->

	<!-- What it does: Makes background images in 72ppi Outlook render at correct size. -->
	<!--[if gte mso 9]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->

</head>
<!--
	The email background color (#222222) is defined in three places:
	1. body tag: for most email clients
	2. center tag: for Gmail and Inbox mobile apps and web versions of Gmail, GSuite, Inbox, Yahoo, AOL, Libero, Comcast, freenet, Mail.ru, Orange.fr
	3. mso conditional: For Windows 10 Mail
-->

<body width="100%" style="margin: 0; padding: 0 !important; mso-line-height-rule: exactly; background-color: #EDF0F6; pointer-events:none;">
	<center style="width: 100%; background-color: #EDF0F6;">
		<!--[if mso | IE]>
    <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%" style="background-color: #EDF0F6;">
    <tr>
    <td>
    <![endif]-->

		<!-- Visually Hidden Preheader Text : BEGIN -->
		<!-- <div style="display: none; font-size: 1px; line-height: 1px; max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden; mso-hide: all; font-family: sans-serif;">
			Meeting with {{organizerName}}
		</div> -->
		<!-- Visually Hidden Preheader Text : END -->

		<!-- Create white space after the desired preview text so email clients don’t pull other distracting text into the inbox preview. Extend as necessary. -->
		<!-- Preview Text Spacing Hack : BEGIN -->
		<div style="display: none; font-size: 1px; line-height: 1px; max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden; mso-hide: all; font-family: sans-serif;">
			&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
		</div>
		<!-- Preview Text Spacing Hack : END -->

		<!-- Email Body : BEGIN -->
		<table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="600" style="margin: 0 auto;"
		 class="email-container">
			<!-- Email Header : BEGIN -->
			<tr>
				<td style="padding: 20px 0; text-align: center">
					<img src="https://i.ibb.co/CHfHBbb/icon-kenai-bot.png" width="64" height="61" alt="https://www.kenai.co.za" border="0" style="height: auto; background: transparent; font-family: sans-serif; font-size: 15px; line-height: 15px; color: #555555;">
				</td>
			</tr>
			<!-- Email Header : END -->

			<!-- 1 Column Text + Button : BEGIN -->
			<tr>
				<td style="background-color: #ffffff; border-top-left-radius: 5px; border-top-right-radius: 5px;">
					<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
						<tr>
							<td style="padding-top: 20px; padding-left: 20px; padding-right: 20px; font-family: Arial, sans-serif; font-size: 16px; line-height: 20px; color: #555555; text-align: center;">
								<img src="{{companyLogoImage}}" width="50%" alt="header" border="0">
								<p style="font-size: 24px; font-weight: normal;">Meeting with {{organizerName}}</p>
								<p style="margin: 0 0 10px;">at {{companyName}}, {{locationShortDescription}}</p>
							</td>
						</tr>
						<!-- Invite details : BEGIN -->
						<!-- Thumbnail Left, Text Right : BEGIN -->
						<tr>
							<!-- dir=ltr is where the magic happens. This can be changed to dir=rtl to swap the alignment on wide while maintaining stack order on narrow. -->
							<td dir="ltr" height="100%" valign="top" width="100%" style="padding-left: 20px; padding-right: 20px; font-family: Arial, sans-serif; font-size: 16px; line-height: 20px; color: #555555;">
								<!--[if mso]>
													<table align="center" role="presentation" border="0" cellspacing="0" cellpadding="0" width="560" style="width: 560px;">
													<tr>
													<td valign="top" width="560" style="width: 560px;">
													<![endif]-->
								<table align="center" role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:560px; border: 2px solid #EDF0F6; border-radius: 5px; border-collapse: separate!important">
									<tr>
										<td align="center" valign="top" style="font-size:0; padding: 10px 0;">
											<!--[if mso]>
																<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="560" style="width: 560px;">
																<tr>
																<td valign="top" width="32" style="width: 32px;">
																<![endif]-->
											<div style="display:inline-block; margin: 0 -2px; width: 32px; vertical-align:top;" class="stack-column">
												<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
													<tr>
														<td dir="ltr" style="padding: 10px">
															<img src="https://i.ibb.co/GF2Zvvk/parking.png" width="16" height="16" border="0" class="center-on-narrow" style="width: 16px; height: 16px;  font-family: sans-serif; font-size: 15px; line-height: 15px; color: #555555;">
														</td>
													</tr>
												</table>
											</div>
											<!--[if mso]>
																</td>
																<td valign="top" width="528" style="width: 528px;">
																<![endif]-->
											<div style="display:inline-block; margin: 0; width: 520px; vertical-align:top;" class="stack-column">
												<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
													<tr>
														<td dir="ltr" style="font-family: sans-serif; font-size: 15px; line-height: 20px; color: #555555; padding: 8px 10px 0; text-align: left;"
														 class="center-on-narrow">
															<h2 style="margin: 0; font-family: Arial, sans-serif; font-size: 16px; line-height: 22px; color: #333333; font-weight: bold;">Address</h2>
															<p style="margin: 0">{{locationFullAddress}}</p>
														</td>
													</tr>
												</table>
											</div>
											<!--[if mso]>
																</td>
																</tr>
																</table>
																<![endif]-->
										</td>
									</tr>
									<tr>
										<td align="center" valign="top" style="font-size:0; padding: 10px 0;">
											<!--[if mso]>
																	<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="560" style="width: 560px;">
																	<tr>
																	<td valign="top" width="32" style="width: 32px;">
																	<![endif]-->
											<div style="display:inline-block; margin: 0 -2px; width: 32px; vertical-align:top;" class="stack-column">
												<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
													<tr>
														<td dir="ltr" style="padding: 10px">
															<img src="https://i.ibb.co/g4yJ70f/clock.png" width="16" height="16" border="0" class="center-on-narrow" style="width: 16px; height: 16px;  font-family: sans-serif; font-size: 15px; line-height: 15px; color: #555555;">
														</td>
													</tr>
												</table>
											</div>
											<!--[if mso]>
																	</td>
																	<td valign="top" width="528" style="width: 528px;">
																	<![endif]-->
											<div style="display:inline-block; margin: 0; width: 520px; vertical-align:top;" class="stack-column">
												<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
													<tr>
														<td dir="ltr" style="font-family: sans-serif; font-size: 15px; line-height: 20px; color: #555555; padding: 8px 10px 0; text-align: left;"
														 class="center-on-narrow">
															<h2 style="margin: 0; font-family: Arial, sans-serif; font-size: 16px; line-height: 22px; color: #333333; font-weight: bold;">Date
																and
																Time</h2>
															<p style="margin: 0">{{eventDate}}</p>
														</td>
													</tr>
												</table>
											</div>
											<!--[if mso]>
																	</td>
																	</tr>
																	</table>
																	<![endif]-->
										</td>
									</tr>
									<tr>
										<td align="center" valign="top" style="font-size:0; padding: 10px 0;">
											<!--[if mso]>
																		<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="560" style="width: 560px;">
																		<tr>
																		<td valign="top" width="32" style="width: 32px;">
																		<![endif]-->
											<div style="display:inline-block; margin: 0 -2px; width: 32px; vertical-align:top;" class="stack-column">
												<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
													<tr>
														<td dir="ltr" style="padding: 10px">
															<img src="https://i.ibb.co/rvqsfbs/person.png" width="16" height="16" border="0" class="center-on-narrow" style="width: 16px; height: 16px;  font-family: sans-serif; font-size: 15px; line-height: 15px; color: #555555;">
														</td>
													</tr>
												</table>
											</div>
											<!--[if mso]>
																		</td>
																		<td valign="top" width="528" style="width: 528px;">
																		<![endif]-->
											<div style="display:inline-block; margin: 0; width: 520px; vertical-align:top;" class="stack-column">
												<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
													<tr>
														<td dir="ltr" style="font-family: sans-serif; font-size: 15px; line-height: 20px; color: #555555; padding: 8px 10px 0; text-align: left;"
														 class="center-on-narrow">
															<h2 style="margin: 0; font-family: Arial, sans-serif; font-size: 16px; line-height: 22px; color: #333333; font-weight: bold;">Host</h2>
															<p style="margin: 0">{{eventHost}}</p>
														</td>
													</tr>
												</table>
											</div>
											<!--[if mso]>
																		</td>
																		</tr>
																		</table>
																		<![endif]-->
										</td>
									</tr>
									{{#if showRegistrationDetails}}
									<tr>
										<td align="center" valign="top" style="font-size:0; padding: 10px 0;">
											<!--[if mso]>
																		<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="560" style="width: 560px;">
																		<tr>
																		<td valign="top" width="32" style="width: 32px;">
																		<![endif]-->
											<div style="display:inline-block; margin: 0 -2px; width: 32px; vertical-align:top;" class="stack-column">
												<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
													<tr>
														<td dir="ltr" style="padding: 10px">
															<img src="https://i.ibb.co/rvqsfbs/person.png" width="16" height="16" border="0" class="center-on-narrow" style="width: 16px; height: 16px;  font-family: sans-serif; font-size: 15px; line-height: 15px; color: #555555;">
														</td>
													</tr>
												</table>
											</div>
											<!--[if mso]>
																		</td>
																		<td valign="top" width="528" style="width: 528px;">
																		<![endif]-->
											<div style="display:inline-block; margin: 0; width: 520px; vertical-align:top;" class="stack-column">
												<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
													<tr>
														<td dir="ltr" style="font-family: sans-serif; font-size: 15px; line-height: 20px; color: #555555; padding: 8px 10px 0; text-align: left;"
														 class="center-on-narrow">
															<h2 style="margin: 0; font-family: Arial, sans-serif; font-size: 16px; line-height: 22px; color: #333333; font-weight: bold;">Visitor
																Registration</h2>
															<p style="margin: 0">{{registrationDetails}}</p>
														</td>
													</tr>
												</table>
											</div>
											<!--[if mso]>
																		</td>
																		</tr>
																		</table>
																		<![endif]-->
										</td>
									</tr>
									{{/if}}
									{{#if showParkingDetails}}
									<tr>
										<td align="center" valign="top" style="font-size:0; padding: 10px 0;">
											<!--[if mso]>
																		<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="560" style="width: 560px;">
																		<tr>
																		<td valign="top" width="32" style="width: 32px;">
																		<![endif]-->
											<div style="display:inline-block; margin: 0 -2px; width: 32px; vertical-align:top;" class="stack-column">
												<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
													<tr>
														<td dir="ltr" style="padding: 10px">
															<img src="https://i.ibb.co/GF2Zvvk/parking.pngv" width="16" height="16" border="0" class="center-on-narrow" style="width: 16px; height: 16px;  font-family: sans-serif; font-size: 15px; line-height: 15px; color: #555555;">
														</td>
													</tr>
												</table>
											</div>
											<!--[if mso]>
																		</td>
																		<td valign="top" width="528" style="width: 528px;">
																		<![endif]-->
											<div style="display:inline-block; margin: 0; width: 520px; vertical-align:top;" class="stack-column">
												<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
													<tr>
														<td dir="ltr" style="font-family: sans-serif; font-size: 15px; line-height: 20px; color: #555555; padding: 8px 10px 0; text-align: left;"
														 class="center-on-narrow">
															<h2 style="margin: 0; font-family: Arial, sans-serif; font-size: 16px; line-height: 22px; color: #333333; font-weight: bold;">Parking</h2>
															<p style="margin: 0">{{parkingDetails}}</p>
														</td>
													</tr>
												</table>
											</div>
											<!--[if mso]>
																		</td>
																		</tr>
																		</table>
																		<![endif]-->
										</td>
									</tr>
									{{/if}}
									{{#if showSpecialInstructions}}
									<tr>
										<td align="center" valign="top" style="font-size:0; padding: 10px 0;">
											<!--[if mso]>
																		<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="560" style="width: 560px;">
																		<tr>
																		<td valign="top" width="32" style="width: 32px;">
																		<![endif]-->
											<div style="display:inline-block; margin: 0 -2px; width: 32px; vertical-align:top;" class="stack-column">
												<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
													<tr>
														<td dir="ltr" style="padding: 10px">
															<img src="https://i.ibb.co/zGSSRyB/special-instructions.png" width="16" height="16" border="0" class="center-on-narrow" style="width: 16px; height: 16px;  font-family: sans-serif; font-size: 15px; line-height: 15px; color: #555555;">
														</td>
													</tr>
												</table>
											</div>
											<!--[if mso]>
																		</td>
																		<td valign="top" width="528" style="width: 528px;">
																		<![endif]-->
											<div style="display:inline-block; margin: 0; width: 520px; vertical-align:top;" class="stack-column">
												<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
													<tr>
														<td dir="ltr" style="font-family: sans-serif; font-size: 15px; line-height: 20px; color: #555555; padding: 8px 10px 0; text-align: left;"
														 class="center-on-narrow">
															<h2 style="margin: 0; font-family: Arial, sans-serif; font-size: 16px; line-height: 22px; color: #333333; font-weight: bold;">Special
																Instructions</h2>
															<p style="margin: 0">{{specialInstructionsText}}</p>
														</td>
													</tr>
												</table>
											</div>
											<!--[if mso]>
																		</td>
																		</tr>
																		</table>
																		<![endif]-->
										</td>
									</tr>
									{{/if}}
									<tr>

										<td align="center" valign="bottom" style="font-size:0; padding: 0;">
											<!--[if mso]>
																		<table role="presentation" border="0" cellspacing="0" cellpadding="0" width="560" style="width: 560px;">
																		<tr>
																		<td valign="bottom" width="528" style="width: 528px; line-height: 0px">
																		<![endif]-->
											<div style="display:inline-block; margin: 0; width: 100%; vertical-align:bottom;" class="stack-column">
												<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
													<tr>
														<td dir="ltr" style="font-family: sans-serif; line-height: 0px;  color: #555555; padding: 0; text-align: left;"
														 class="center-on-narrow">
															<a href="{{locationMapUrl}}">
																<img src="{{locationMapImage}}" width="100%" alt="https://www.kenai.co.za" border="0" style="background: transparent;">
															</a>
														</td>
													</tr>
												</table>
											</div>
											<!--[if mso]>
																		</td>
																		</tr>
																		</table>
																		<![endif]-->
										</td>
									</tr>
								</table>
								<!--[if mso]>
													</td>
													</tr>
													</table>
													<![endif]-->
							</td>
						</tr>
						<!-- Thumbnail Left, Text Right : END -->
						<!-- Invite details : END -->
						{{#if showActionLink}}
						<tr>
							<td style="padding: 20px 20px 0px;">
								<!-- Button : BEGIN -->
								<center>
									<div style="margin: 0 auto;">
										<!--[if mso]>
 <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="{{actionLinkRaw}}" arcsize="10%" style="height:50px;v-text-anchor:middle;width:280px;" strokecolor="{{primaryColor}}" fill="t">
    <v:fill type="tile" color="{{primaryColor}}" />
    <w:anchorlock/>
    <center style="color:#ffffff;font-family:sans-serif;font-size:18px;font-weight:bold;">{{actionButtonText}}</center>
  </v:roundrect>
<![endif]-->
										<div style="margin: 0 auto;mso-hide:all;">
											<table align="center" cellpadding="0" cellspacing="0" height="50" width="280" style="margin: 0 auto; mso-hide:all;">
												<tbody>
													<tr>
														<td align="center" bgcolor="#0B6A49" height="50" style="vertical-align:middle;color: #ffffff; display: block;background-color:{{primaryColor}};border:1px solid{{primaryColor}};mso-hide:all; border-radius: 4px;"
														 width="280">
															<a class="cta_button" href="{{actionLinkRaw}}" style="font-size:16px;-webkit-text-size-adjust:none; font-weight: bold; font-family:sans-serif; text-decoration: none; line-height:50px; width:280px; display:inline-block; color:#ffffff;"
															 title="Button">
																<span style="color:#ffffff">{{actionButtonText}}</span>
															</a>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</center>
								<!-- Button : END -->
							</td>
						</tr>
						<tr>
							<td style="padding: 20px; font-family: Arial, sans-serif; font-size: 16px; line-height: 20px; color: #555555;">
								<p style="margin: 0 0 10px;">Having trouble with the button? Copy and paste this link into your browser:</p>
								<p style="margin: 0 0 10px;">
									<font style="display: none;">'</font>{{{actionLinkFormatted}}}<font style="display: none;">'</font>
								</p>
							</td>
						</tr>
						{{/if}}
					</table>
				</td>
			</tr>
			<!-- 1 Column Text + Button : END -->

			<!-- Thumbnail Left, Text Right : BEGIN -->
			<tr>
				<td dir="ltr" valign="top" width="100%" style="padding: 10px; background-color:#F6F8FC; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px">
					<table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
						<tr>
							<!-- Column : BEGIN -->
							<td width="33.33%" class="stack-column-center">
								<table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
									<tr>
										<td dir="ltr" valign="top" style="padding: 0 10px;">
											<img src="https://i.ibb.co/DtNKhtB/icon-kenai-logo.png" width="93" height="57" border="0" class="center-on-narrow" style="height: auto; background: transparent; font-family: sans-serif; font-size: 15px; line-height: 15px; color: #555555;">
										</td>
									</tr>
								</table>
							</td>
							<!-- Column : END -->
							<!-- Column : BEGIN -->
							<td width="66.66%" class="stack-column-center">
								<table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
									<tr>
										<td dir="ltr" valign="top" style="font-family: sans-serif; font-size: 14px; line-height: 20px; color: #555555; padding: 10px; text-align: right;"
										 class="center-on-narrow">
											<a style="color: #B8BDC9;  margin: 0 0 10px 0; text-decoration: underline" href="https://www.kenai.co.za">Learn
												more about Kenai Visitor Management</a>
										</td>
									</tr>
								</table>
							</td>
							<!-- Column : END -->
						</tr>
					</table>
				</td>
			</tr>
			<!-- Thumbnail Left, Text Right : END -->

		</table>
		<!-- Email Body : END -->

		<!-- Email Footer : BEGIN -->
		<table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="600" style="margin: 0 auto;"
		 class="email-container">
			<tr>
				<td style="padding: 20px; font-family: sans-serif; font-size: 12px; line-height: 15px; text-align: center; color: #888888;">
					<br><br>
					Kenai Visitor Management (Pty) Ltd<br><span class="unstyle-auto-detected-links">3rd floor 2 Merchant Place<br> Cnr
						Rivonia Road and Fredman Drive<br> Sandton<br> Johannesburg<br> 2196</span>
					<br><br>
				</td>
			</tr>
		</table>
		<!-- Email Footer : END -->

		<!--[if mso | IE]>
    </td>
    </tr>
    </table>
    <![endif]-->
	</center>
</body>

</html>

  `

export default emailTemplate

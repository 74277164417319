import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

const StatusWrapper = styled.span`
  .text {
    vertical-align: middle;
    line-height: inherit;
  }
`
type StatusItemProps = {
  status: 'success' | 'processing' | 'default' | 'error' | 'warning'
  text: string
}

function translateStatusText(text: string) {
  switch (text) {
    case 'Complete':
      return 'global.text.completed'
    case 'Incomplete':
      return 'global.text.incomplete'
    default:
      return text
  }
}

export const StatusItem: React.FC<StatusItemProps> = props => {
  return (
    <StatusWrapper>
      <span className='text'>
        <FormattedMessage id={translateStatusText(props.text)} />
      </span>
    </StatusWrapper>
  )
}

type ViewProps = {
  status: string
}

export const RenderStatus: React.FC<ViewProps> = props => {
  return <StatusItem status='default' text={props.status} />
}

export default RenderStatus

import { Switch } from "antd"

import styled from "styled-components"

const SwitchStyle = (Component) => styled(Component)`
  &.ant-switch-checked {
    background-color: ${(props) => props.theme.branding.primary};
  }
  &.ant-switch.not-applicable {
    background-color: rgba(0, 0, 0, 0.25);
  }
  &.ant-switch.not-applicable:after {
    background-color: #424358
  }
`
export default SwitchStyle(Switch)

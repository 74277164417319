import { Checkbox } from "antd"

import styled from "styled-components"

const CheckboxStyle = Component => styled(Component)`
  && {
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: ${props => props.theme.branding.primary};
        background-color: ${props => props.theme.branding.primary};
      }
    }
    &:hover,
    .ant-checkbox-input:focus + {
      .ant-checkbox-inner {
        border-color: ${props => props.theme.branding.primary};
      }
    }
  }
`
export default CheckboxStyle(Checkbox)

import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { StoreRefs } from '~/utils/createStore'
import WelcomeCTR from '~/app/containers/WelcomeCTR'
import RegistrationCTR from '~/app/containers/RegistrationCTR'
import ErrorCTR from '~/app/containers/ErrorCTR'
import ConfirmationCTR from '~/app/containers/ConfirmationCTR'
import LocalProfileConfirmedCTR from '~/app/containers/LocalProfileConfirmedCTR'
import SetupInviteCTR from '~/app/containers/SetupInviteCTR'
import SetupCompleteCTR from '~/app/containers/SetupCompleteCTR'
import AccessPassCTR from '~/app/containers/AccessPassCTR'

interface SFCProps {
  storeRefs: StoreRefs
}

const AppRouter: React.FC<SFCProps> = props => {
  return (
    <ConnectedRouter history={props.storeRefs.history}>
      <Switch>
        <Route exact={true} path='/' component={WelcomeCTR} />
        <Route exact={true} path='/registration' component={RegistrationCTR} />
        <Route exact={true} path='/error' component={ErrorCTR} />
        <Route exact={true} path='/confirmation' component={ConfirmationCTR} />
        <Route exact={true} path='/localprofileconfirmed' component={LocalProfileConfirmedCTR} />
        <Route exact={true} path='/setup' component={SetupInviteCTR} />
        <Route exact={true} path='/setupcomplete' component={SetupCompleteCTR} />
        <Route exact={true} path='/accesspass' component={AccessPassCTR} />
        <Redirect exact={true} to='/' />
      </Switch>
    </ConnectedRouter>
  )
}

export default AppRouter

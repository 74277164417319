import React from 'react'
import { Upload as AntUpload, Icon } from 'antd'
import styled from 'styled-components'

const UploadStyle = Component => styled(Component)`
  .ant-upload.ant-upload-select {
    overflow: hidden;
    .text-container {
      transition: color 0.3s ease !important;
    }
    &:hover {
      border-color: ${props => props.theme.branding.primary};
      .text-container {
        color: ${props => props.theme.branding.primary};
      }
    }
    &.ant-upload-disabled {
      * {
        pointer-events: none;
      }
      .text-container {
        color: #d9d9d9;
      }
      &:hover {
        border-color: #d9d9d9;
        cursor: default;
        .text-container {
          color: #d9d9d9;
        }
      }
    }
  }
`

export default UploadStyle(AntUpload)

type UploadButtonProps = {
  icon: string
  loading?: boolean
  text?: string
}
export const UploadButton: React.FC<UploadButtonProps> = props => (
  <div className='text-container'>
    <Icon type={props.loading ? 'loading' : props.icon} />
    <div className='ant-upload-text'>{props.text || props.children}</div>
  </div>
)

const StaticUploadStyle = styled.span`
  .ant-upload.ant-upload-select {
    overflow: hidden;
    -ms-overflow-style: none;
    margin: 0;
    .text-container {
      transition: color 0.3s ease !important;
    }
    &:hover {
      border-color: ${props => props.theme.branding.primary};
      .text-container {
        color: ${props => props.theme.branding.primary};
      }
    }
    &.ant-upload-disabled {
      * {
        pointer-events: none;
      }
      .text-container {
        color: #d9d9d9;
      }
      &:hover {
        border-color: rgba(217, 217, 217, 0.94);
        cursor: default;
        .text-container {
          color: #d9d9d9;
        }
      }
    }
  }
`

type StaticUploadProps = {
  onClick?: () => void
  disabled?: boolean
}
export const StaticUpload: React.FC<StaticUploadProps> = props => {
  return (
    <StaticUploadStyle className='image-uploader'>
      <div
        className={`ant-upload ant-upload-select ant-upload-select-picture-card ${props.disabled ? 'ant-upload-disabled' : ''}`}
        onClick={props.onClick}
      >
        <span className='ant-upload' role='button'>
          {props.children}
        </span>
      </div>
    </StaticUploadStyle>
  )
}

import React from 'react'
import Lottie from 'react-lottie'
import { withTheme } from 'styled-components'
import { hexToRgb } from 'color-invert'

import { default as animationData } from '~/static/lottie/checked_done.js'

interface CompProps {
  theme?: any
}

interface State {
  animationData: any
  lottieMounted: boolean
}

function trim(str) {
  return str.replace(/^\s+|\s+$/gm, '')
}

function strLen(str) {
  if (str.length === 1) {
    str = `0${str}`
  }
  return str
}

function rgbaToRgbHex(rgba) {
  var parts = rgba.substring(rgba.indexOf('(')).split(','),
    r = parseInt(trim(parts[0].substring(1)), 10),
    g = parseInt(trim(parts[1]), 10),
    b = parseInt(trim(parts[2]), 10)
  return '#' + strLen(r.toString(16)) + strLen(g.toString(16)) + strLen(b.toString(16))
}

class LottieCompleteIndicator extends React.Component<CompProps, State> {
  constructor(props: CompProps) {
    super(props)
    this.state = {
      animationData: animationData,
      lottieMounted: false,
    }
  }

  componentDidMount() {
    const newColor = rgbaToRgbHex(this.props.theme.branding.primary)
    const newData = animationData
    const toUnitVector = (n: number): number => Math.round((n / 255) * 1000) / 1000
    const { r, g, b } = hexToRgb(newColor)
    const shapes = [
      {
        i: 8,
        j: 0,
        k: 1,
        a: 1,
      },
      {
        i: 9,
        j: 0,
        k: 1,
        a: 1,
      },
      {
        i: 10,
        j: 0,
        k: 2,
        a: 1,
      },
    ]
    shapes.forEach(entry => {
      newData.layers[entry.i]!.shapes[entry.j]!.it[entry.k]!.c!.k = [toUnitVector(r), toUnitVector(g), toUnitVector(b), entry.a]
    })
    this.setState({ animationData: newData, lottieMounted: true })
  }

  render() {
    const lottieOptions = {
      loop: false,
      autoplay: true,
      animationData: this.state.animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }
    if (this.state.lottieMounted) {
      return <Lottie options={lottieOptions} height={400} width={400} isStopped={false} isPaused={false} style={{ maxWidth: '100%' }} />
    } else {
      return null
    }
  }
}

export default withTheme(LottieCompleteIndicator)

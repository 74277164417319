import momentTZ from 'moment-timezone'
import * as interfaces from '~/typing/KENAI/interfaces.d.ts'

export default function getEventTiming(): interfaces.EventTiming {
  const timeSinceEpoch = Date.now()
  const timezoneOffset = new Date().getTimezoneOffset()
  const timeZone = momentTZ.tz.guess()
  return {
    timeSinceEpoch,
    timezoneOffset,
    timeZone,
  }
}

import React from 'react'
import { Form } from 'antd'
import styled from 'styled-components'
import { FormValues, FlowControl } from '../component'
import { ExtendedWrappedFormUtils } from '~/typing/vendor/antd-form-untyped-public'
import AuthenticatePage from './AuthenticatePageSFC'

interface FormProps {
  onFieldsChange: (changedFields: object) => void
  onResendOTP: () => void
  onOTPOkClick: () => void
  formValues: FormValues
  flowControl: FlowControl
  isMobile: boolean
  className: string
  ref: any
  form: ExtendedWrappedFormUtils
}

const ViewContainer = styled.div`
  overflow: hidden;
  -ms-overflow-style: none;
  max-width: 80vw;
  margin: auto;
  padding: 0px 20px;
  .mobile-device & {
    max-width: 95vw;
    padding: 9px;
  }
`

const CaptureOTPFormSFC: React.FC<FormProps> = props => {
  const { getFieldDecorator } = props.form
  let otpProcesingComplete: boolean = false
  if (props.flowControl.otpRequest.otpRequestSuccessfull && props.flowControl.otpValidation.otpValidationSuccessfull) {
    otpProcesingComplete = true
  }
  return (
    <ViewContainer>
      <div className={props.className}>
        <div className='section'>
          <AuthenticatePage
            otpProcesingComplete={otpProcesingComplete}
            onResendOTP={props.onResendOTP}
            isMobile={props.isMobile}
            formValues={props.formValues}
            flowControl={props.flowControl}
            onOTPOkClick={props.onOTPOkClick}
            getFieldDecorator={getFieldDecorator}
            onFieldsChange={props.onFieldsChange}
          />
        </div>
      </div>
    </ViewContainer>
  )
}

const CustomizedForm = Form.create<FormProps>({
  onFieldsChange(props, changedFields) {
    props.onFieldsChange(changedFields)
  },
  mapPropsToFields(props) {
    return {
      otpCode: Form.createFormField({
        ...props.formValues.otpCode,
        value: props.formValues.otpCode.value,
      }),
    }
  },
})(CaptureOTPFormSFC)

export default CustomizedForm

import React, {
  ReactChildren,
  ReactNode,
  useContext,
  useMemo,
  useState
} from "react";

const defaultProps = { initialValue: {} };

/**
 * Global State Store Hook
 */
type TStoreProviderProps = {
  initialValue?: { locale: string; messages: any };
  children: ReactChildren | ReactNode;
} & typeof defaultProps;

function makeStore(): [typeof StoreProvider, typeof useStore] {
  // Make a context for the store
  const Context = React.createContext({});

  // Make a provider that takes an initialValue
  const StoreProvider = (props: TStoreProviderProps) => {
    const { initialValue = defaultProps, children } = props;
    // Make a new state instance (could even use immer here!)
    const [state, setState] = useState(initialValue);

    // Memoize the context value to update when the state does
    const contextValue = useMemo(() => [state, setState], [state]);

    // Provide the store to children
    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
  };

  // A hook to help consume the store
  const useStore: any = () => useContext(Context);

  return [StoreProvider, useStore];
}

const [StoreProvider, useStore] = makeStore();

export { StoreProvider, useStore };

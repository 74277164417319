import React from 'react'
import Selection, { SelectionProps } from './SelectionSFC'

import translate from '~/translations/translate'
import { FormattedMessage } from 'react-intl'

type ViewProps = {} & SelectionProps

export const DistributionTypeSelect: React.FC<ViewProps> = props => {
  return (
    <div className='flex-item'>
      <strong>
        <FormattedMessage id='form.setup.meta.distro-type' />
      </strong>
      <Selection {...props} placeholder={translate({ id: 'form.setup.meta.distro-type' })} />
    </div>
  )
}

export default DistributionTypeSelect

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getReturnOfExpression } from 'utility-types'
import { push } from 'connected-react-router'
import { ThemeProvider } from 'styled-components'
import { isMobile } from 'react-device-detect'

import { ActionCreators as RegistrationMetadataSagaActionCreators } from '~/sagas/registrationmetadata'
import { ActionCreators as ProcessInstanceStoreActionCreators, getProcessInstanceState } from '~/store/processinstance'
import WelcomePage from '~/app/components/WelcomePage'
import PageLoadingIndicator from '~/app/components/PageLoadingIndicator'
import GlobalStyle from '~/app/styles/base'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import AgreementModal from '~/app/components/WelcomePage/subComponents/AgreementModalSFC'
import getUrlParameter from '~/utils/getUrlParameter'

const mapStateToProps = state => {
  return {
    processinstance: getProcessInstanceState(state),
  }
}

const mapDispatchToProps = {
  SagaLoadRegistrationEntityDetails: RegistrationMetadataSagaActionCreators.SagaLoadRegistrationEntityDetails.create,
  SagaRetrieveEntityAgreement: RegistrationMetadataSagaActionCreators.SagaRetrieveEntityAgreement.create,
  SagaRequestEventBasedInviteToken: RegistrationMetadataSagaActionCreators.SagaRequestEventBasedInviteToken.create,
  SagaRequestDeviceBasedInviteToken: RegistrationMetadataSagaActionCreators.SagaRequestDeviceBasedInviteToken.create,
  push: push,
  StoreSetSetupToken: ProcessInstanceStoreActionCreators.StoreSetSetupToken.create,
  StoreSetInviteToken: ProcessInstanceStoreActionCreators.StoreSetInviteToken.create,
  StoreSetEventToken: ProcessInstanceStoreActionCreators.StoreSetEventToken.create,
  StoreSetDeviceToken: ProcessInstanceStoreActionCreators.StoreSetDeviceToken.create,
}

const stateProps = getReturnOfExpression(mapStateToProps)

type LocalCompProps = {}

type CompProps = typeof stateProps & typeof mapDispatchToProps & LocalCompProps

type State = {
  tsBasedTrigger: number
  response: string
  isInitialising: boolean
  hasTriggeredInviteLoad: boolean
  hasTriggeredEventLoad: boolean
  agreementChecked: boolean
  agreementModalVisible: boolean
}

class RootScreenContainer extends React.Component<CompProps, State> {
  hasEventListener = false
  constructor(props) {
    super(props)
    this.state = {
      tsBasedTrigger: Date.now(),
      response: 'no response yet',
      isInitialising: true,
      hasTriggeredInviteLoad: false,
      hasTriggeredEventLoad: false,
      agreementChecked: false,
      agreementModalVisible: false,
    }
  }

  processEnterKey = event => {
    if (event.keyCode === 13 && this.state.agreementChecked) {
      this._handleRegistrationClicked()
    }
  }

  componentDidMount() {
    const queryString = window.location.search
    if (
      queryString &&
      ((queryString.indexOf('setup=') > -1 && queryString.length > 7) ||
        (queryString.indexOf('token=') > -1 && queryString.length > 7) ||
        (queryString.indexOf('event=') > -1 && queryString.length > 7) ||
        (queryString.indexOf('dt=') > -1 && queryString.length > 7))
    ) {
      if (queryString.indexOf('setup=') > -1) {
        const setupToken = getUrlParameter('setup')
        this.props.StoreSetSetupToken(setupToken)
        this.props.push('/setup')
      } else if (queryString.indexOf('token=') > -1) {
        const inviteToken = getUrlParameter('token')
        this.props.StoreSetInviteToken(inviteToken)
        if (!isMobile && window) {
          window.addEventListener('keypress', this.processEnterKey, false)
          this.hasEventListener = true
        }
      } else if (queryString.indexOf('event=') > -1) {
        const eventToken = getUrlParameter('event')
        this.props.StoreSetEventToken(eventToken)
      } else if (queryString.indexOf('dt=') > -1) {
        const deviceToken = getUrlParameter('dt')
        this.props.StoreSetDeviceToken(deviceToken)
      }
    } else {
      this.props.push('/error')
    }
  }

  componentWillUnmount() {
    if (this.hasEventListener) {
      window.removeEventListener('keypress', this.processEnterKey, false)
      this.hasEventListener = false
    }
  }

  componentDidUpdate(prevProps: CompProps, prevState: State) {
    if (this.props.processinstance.inviteToken.length > 0 && this.state.hasTriggeredInviteLoad === false) {
      this.setState(
        {
          hasTriggeredInviteLoad: true,
        },
        () => {
          this.props.SagaLoadRegistrationEntityDetails(this.props.processinstance.inviteToken)
        }
      )
    } else if (this.props.processinstance.eventToken.length > 0 && this.state.hasTriggeredEventLoad === false) {
      this.setState(
        {
          hasTriggeredEventLoad: true,
        },
        () => {
          this.props.SagaRequestEventBasedInviteToken(this.props.processinstance.eventToken)
        }
      )
    } else if (this.props.processinstance.deviceToken.length > 0 && this.state.hasTriggeredEventLoad === false) {
      this.setState(
        {
          hasTriggeredEventLoad: true,
        },
        () => {
          this.props.SagaRequestDeviceBasedInviteToken(this.props.processinstance.deviceToken)
        }
      )
    }
  }

  static getDerivedStateFromProps(newProps: CompProps, oldState: State) {
    if (newProps.processinstance.registrationEntity.hasBeenLoaded === true && oldState.isInitialising === true) {
      return {
        isInitialising: false,
      }
    } else {
      return null
    }
  }

  _handleRegistrationClicked = (): void => {
    this.props.push('/registration')
  }

  _handleVisitorAgreementChecked = (checkboxChangeEvent: CheckboxChangeEvent) => {
    this.setState({ agreementChecked: checkboxChangeEvent.target.checked })
  }

  _handleVisitorAgreementTextClicked = () => {
    this.setState({ agreementChecked: !this.state.agreementChecked })
  }

  _handleOpenAgreementModal = () => {
    this.props.SagaRetrieveEntityAgreement(this.props.processinstance.inviteToken)
    this.setState({ agreementModalVisible: true })
  }

  _handleCloseAgreementModal = () => {
    this.setState({ agreementModalVisible: false, agreementChecked: true })
  }
  render() {
    // console.group('updates')
    // console.log('isInitialising', this.state.isInitialising)
    // console.log('has loaded', this.props.processinstance.registrationEntity.hasBeenLoaded)
    // console.log('invite token length', this.props.processinstance.inviteToken.length)
    // console.log('hasTriggeredInviteLoad', this.state.hasTriggeredInviteLoad)
    // console.groupEnd()
    if (this.state.isInitialising) {
      return <PageLoadingIndicator />
    } else {
      return (
        <ThemeProvider theme={this.props.processinstance.registrationEntity}>
          <React.Fragment>
            <GlobalStyle />
            <WelcomePage
              onRegistrationClicked={this._handleRegistrationClicked}
              onVisitorAgreementChecked={this._handleVisitorAgreementChecked}
              onVisitorAgreementTextClicked={this._handleVisitorAgreementTextClicked}
              onOpenAgreementModal={this._handleOpenAgreementModal}
              registrationEntity={this.props.processinstance.registrationEntity}
              images={this.props.processinstance.registrationEntity.cornerImages}
              agreementChecked={this.state.agreementChecked}
            />
            <AgreementModal
              visible={this.state.agreementModalVisible}
              handleOk={this._handleCloseAgreementModal}
              visitorAgreement={this.props.processinstance.visitorAgreement}
              registrationEntityBranding={this.props.processinstance.registrationEntity.branding}
            />
          </React.Fragment>
        </ThemeProvider>
      )
    }
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RootScreenContainer)
)

// const HardcodedAgreement: AgreementTextPartsType = {
//   agreementTextParts: [
//     {
//       text: "Data Privacy Policy",
//       type: "heading",
//     },
//     {
//       text: `At ${
//         this.props.processinstance.registrationEntity.entityName
//       }, we attach great importance to the privacy of our visitors.`,
//       type: "paragraph",
//     },
//     {
//       text: `The personal data that you provide us with will only be used for our visitor management and will be processed in accordance with the highest safety and security standards.||||You have the right to request the removal of your personal data at any time.||||You agree to abide by the building rules and regulations as set out by ${
//         this.props.processinstance.registrationEntity.entityName
//       }`,
//       type: "list",
//     },
//     {
//       text: "Data Processing Addendum",
//       type: "heading",
//     },
//     {
//       text: `${
//         this.props.processinstance.registrationEntity.entityName
//       } uses Kenai Visitor Management software to ensure the safety and security of their offices.`,
//       type: "paragraph",
//     },
//     {
//       text: `You have the option to be remembered on the next screen, which allows you to quickly sign in to all ${
//         this.props.processinstance.registrationEntity.entityName
//       } locations upon being recognised with facial recognition or verifying your mobile number with a one-time pin.`,
//       type: "paragraph",
//     },
//     {
//       text: `As a minimum requirement to gain access to this building you consent to ${
//         this.props.processinstance.registrationEntity.entityName
//       } storing a log of your captured details, a photo of your face and the time of your visit for their records. ${
//         this.props.processinstance.registrationEntity.entityName
//       } will store such data for a reasonable period of time`,
//       type: "paragraph",
//     },
//   ],
// }

import { Spin as AntSpin } from "antd"

// import styled from "styled-components"

// const SpinStyle = (Component) => styled(Component)`
//   &.ant-spin-dot {
//     i {
//       background-color: ${(props) => props.theme.branding.primary};
//     }
//   }
// `
export default AntSpin

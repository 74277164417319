export const getTextWidth = (text, font) => {
  if (typeof window !== 'undefined') {
    // re-use canvas object for better performance
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (context) {
      context.font = font
      const metrics = context.measureText(text)
      return metrics.width + 60
    } else {
      return 0
    }
  } else return 0
}

const defaultFont = 'normal 16px Arial, Helvetica, sans-serif'

const getWindowWidth = () => (typeof window !== 'undefined' ? window.innerWidth : 0)

export const getSelectWidth = (placeholder: string, options: { text: string }[]) => {
  let largestWidth = getTextWidth(placeholder, defaultFont)
  options.forEach(option => {
    const itemWidth = getTextWidth(option.text, defaultFont)
    if (itemWidth > largestWidth) {
      largestWidth = itemWidth
    }
  })
  const windowWidth = getWindowWidth()
  if (largestWidth !== 0) {
    const width = largestWidth + 12 + 12 + 11
    return `${width < windowWidth ? width : windowWidth - 20}px`
  }
  return '25%'
}

import React from 'react'
import { withRouter } from 'react-router-dom'
import SetupCompletePage from '~/app/components/SetupCompletePage'
import { connect } from 'react-redux'
import { getProcessInstanceState } from '~/store/processinstance'
import { ThemeProvider } from 'styled-components'
import { getReturnOfExpression } from 'utility-types'
import GlobalStyle from '~/app/styles/base'

const mapStateToProps = state => {
  return {
    processinstance: getProcessInstanceState(state),
  }
}
const mapDispatchToProps = {}

const stateProps = getReturnOfExpression(mapStateToProps)

type LocalCompProps = {}

type CompProps = typeof stateProps & typeof mapDispatchToProps & LocalCompProps

type State = {}

class SetupCompleteCTR extends React.Component<CompProps, State> {
  render() {
    return (
      <ThemeProvider theme={this.props.processinstance.registrationEntity}>
        <div style={{ overflow: 'hidden' }}>
          <GlobalStyle />
          <SetupCompletePage />
        </div>
      </ThemeProvider>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SetupCompleteCTR)
)

import React from 'react'
import { Spin, Icon } from 'antd'
import StyledButton from '~/app/components/StyledButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKeyboard } from '@fortawesome/pro-solid-svg-icons'

type Props = {
  value: string
  isValid: boolean
  loading?: boolean
  nextItemFocus: () => void
  buttonProps?: object
}

const AddonButton: React.FC<Props> = props => {
  if (props.value && props.value.length > 0 && props.isValid) {
    const clickEvent = () => {
      if (!props.loading) {
        props.nextItemFocus()
      }
    }
    return (
      <StyledButton type='primary' onClick={clickEvent} ghost={props.loading} disabled={props.loading} size='large' {...props.buttonProps}>
        {props.loading ? (
          <Spin
            spinning={true}
            indicator={
              <Icon
                type='loading'
                style={{
                  width: '100%',
                  height: '100% ',
                }}
                spin={true}
              />
            }
          />
        ) : (
          'OK'
        )}
      </StyledButton>
    )
  } else {
    return <FontAwesomeIcon className='keyboard-icon input-focus-hidden' icon={faKeyboard} />
  }
}

export default AddonButton

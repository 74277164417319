import React from 'react'
import Lottie from 'react-lottie'

import { default as animationData } from '~/static/lottie/confetti'

const lottieOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const LottieSearchIndicatorSFC: React.FC<{}> = props => {
  return <Lottie options={lottieOptions} height={400} width={400} isStopped={false} isPaused={false} />
}

export default LottieSearchIndicatorSFC

import { createGlobalStyle } from 'styled-components'
import alpha from '~/app/utility/Alpha'
import _antd from './_antd'
import _customElements from './_customElements'

import _locale from './_locale'

export default createGlobalStyle`

body{
  --primary:${props => {
    return props?.theme?.branding?.primary || '#4cb75b'
  }}!important;
}

${_antd};
${_customElements};


  ::-moz-selection {
       background-color: ${props => props.theme.branding.primary}; 
  }
  ::selection {
        background-color: ${props => props.theme.branding.primary};
  }
  .ant-select-dropdown-menu-item-active, .ant-select-dropdown-menu-item:hover{
    background-color: ${props => alpha(props.theme.branding.primary, 0.1)}!important;
  }
  a{
    color: ${props => props.theme.branding.primary}; 
    transition: color 0.3s, opacity 0.3s;
    &:hover{
      color:${props => props.theme.branding.primary};
      opacity:0.8;
    }
  }
  div#_hj_feedback_container{
      [class*="_bottom_position_launcher"] {
        .path1:before {
          color: ${props => props.theme.branding.primary}!important;
        }
        .path2:before {
          color: ${props => props.theme.branding.text}!important;
        }
      }
      [id*="_feedback_open_close"] {
        background-color: ${props => props.theme.branding.primary}!important;
      }
      [class*="_widget_footer"] ,  [class*="_widget_content"] {
        [class*="_btn_primary"]:not([class*="_btn_disabled"]){
          background: ${props => props.theme.branding.primary}!important;
          &:hover{
            background: ${props => props.theme.branding.primary}!important; 
          }
        }
      }
      [id*="_feedback"] {
        [class*="_feedback_minimized_message"]{
          [class*="_feedback_minimized_message_close"]{
            background: ${props => props.theme.branding.primary}!important; 
            &:hover{
             background: ${props => props.theme.branding.primary}!important; 
            }
          }
        }
      }
  }

span[role="link"]{
    color: ${props => props.theme.branding.primary};
    transition: color 0.3s,opacity 0.3s;
    touch-action: manipulation;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    &:hover, &:active{
      text-decoration: none;
      outline: 0;
    }
    &:active{
      opacity: 0.4;
    }
    &:hover{
      opacity: 0.8;
    }
}


${_locale}
`

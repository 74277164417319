import React from 'react'
import { IntlProvider } from 'react-intl'
import { useStore } from '~/store'
import messages, { AntLocales } from '~/translations/messages'
import { LocaleProvider } from 'antd'

const LocaleContext = React.createContext({})

export const useLocale: any = () => React.useContext(LocaleContext)

export const MyLocaleProvider = props => {
  const [state, setState] = useStore()

  const switchToEnglish = () => {
    const locale = 'en'
    localStorage.setItem('messages', JSON.stringify(messages(locale)))
    setState(state => ({
      ...state,
      locale,
    }))
  }
  const switchToFrench = () => {
    const locale = 'fr'
    localStorage.setItem('messages', JSON.stringify(messages(locale)))
    setState(state => ({
      ...state,
      locale,
    }))
  }

  const switchToAfrikaans = () => {
    const locale = 'af'
    localStorage.setItem('messages', JSON.stringify(messages(locale)))
    setState(state => ({
      ...state,
      locale,
    }))
  }

  const current = state.locale

  localStorage.setItem('locale', state.locale)
  localStorage.setItem('messages', JSON.stringify(messages(state.locale)))
  return (
    <LocaleContext.Provider value={{ switchToEnglish, switchToFrench, switchToAfrikaans, current }}>
      <IntlProvider key={state.locale} locale={state.locale} messages={messages(state.locale)} defaultLocale={state.locale}>
        <LocaleProvider locale={AntLocales[state.locale]}>{props.children}</LocaleProvider>
      </IntlProvider>
    </LocaleContext.Provider>
  )
}

export default IntlProvider

import React from 'react'
import { Modal, Spin } from 'antd'
import * as interfaces from '~/typing/KENAI/interfaces.d.ts'

type ViewProps = {
  visible: boolean
  handleOk: () => void
  visitorAgreement: interfaces.VISITOR_AGREEMENT | undefined
  registrationEntityBranding: interfaces.REGISTRATION_ENTITY['branding']
}

const AgreementModal: React.FC<ViewProps> = ({ visible, handleOk, visitorAgreement, registrationEntityBranding }) => {
  const agreementContent = !!visitorAgreement ? (
    <div id='Agreement-Container' className='ng-scope pdfobject-container'>
      <div
        style={{
          width: '100%',
        }}
      >
        {visitorAgreement.agreementTextParts!.map((item, index) => {
          const key = `${item.type}-item-${index}`
          if (item.type === 'heading') {
            return <h1 key={key}>{item.text.replace(/{{companyName}}/g, visitorAgreement.companyName)}</h1>
          }
          if (item.type === 'paragraph') {
            return <p key={key}>{item.text.replace(/{{companyName}}/g, visitorAgreement.companyName)}</p>
          }
          if (item.type === 'list') {
            const listArray = item.text.split('||||')
            return (
              <ul key={key}>
                {listArray.map((listItem, listItemIndex) => (
                  <li key={`${listItemIndex}-item`}>{listItem.replace(/{{companyName}}/g, visitorAgreement.companyName)}</li>
                ))}
              </ul>
            )
          }
          if (item.type === 'image') {
            return (
              <div key={key}>
                <p>{item.text.replace(/{{companyName}}/g, visitorAgreement.companyName)}</p>
                <img style={{ width: '90%' }} src={item.imagePresignedLink} alt='presigned graphic' />
              </div>
            )
          }
          return null
        })}
      </div>
    </div>
  ) : null

  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      okButtonProps={{
        style: {
          backgroundColor: registrationEntityBranding.primary,
          color: registrationEntityBranding.text,
          borderColor: registrationEntityBranding.primary,
        },
      }}
      okText='AGREE'
      cancelButtonProps={{
        style: {
          display: 'none',
        },
      }}
      closable={false}
      className={'responsive-fullscreen'}
      width='700px'
      style={{ maxWidth: '75vw' }}
    >
      {visible ? <Spin spinning={!agreementContent}>{agreementContent}</Spin> : null}
    </Modal>
  )

  //   <div id="Agreement-Container" className="ng-scope pdfobject-container">
  //     <div
  //       style={{
  //         width: "100%",
  //       }}
  //     >
  //       {agreementData.map((item, index) => {
  //         const key = `${item.type}-item-${index}`
  //         if (item.type === "heading") {
  //           return <h1 key={key}>{item.text}</h1>
  //         }
  //         if (item.type === "paragraph") {
  //           return <p key={key}>{item.text}</p>
  //         }
  //         if (item.type === "list") {
  //           const listArray = item.text.split("||||")
  //           return (
  //             <ul key={key}>
  //               {listArray.map((listItem, listItemIndex) => (
  //                 <li key={`${listItemIndex}-item`}>{listItem}</li>
  //               ))}
  //             </ul>
  //           )
  //         }
  //         return null
  //       })}
  //     </div>
  //   </div>
  // </Modal>
  // )
}

export default AgreementModal

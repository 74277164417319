export const browserLocale = () => {
  let lang
  if (navigator.languages && navigator.languages.length) {
    // latest versions of Chrome and Firefox set this correctly
    lang = navigator.languages[0]
  } else if (window.navigator["userLanguage"]) {
    // IE only
    lang = window.navigator["userLanguage"]
  } else {
    // latest versions of Chrome, Firefox, and Safari set this correctly
    lang = navigator.language
  }
  if (lang.indexOf("-") > -1) {
    lang = lang.substring(0, lang.indexOf("-"))
  }
  // lang = "fr"
  return lang
}

import React, { ReactNode } from 'react'
import StyledAutoCompleteSFC from '~/app/components/StyledAutoComplete'
import { Icon, Spin } from 'antd'
import StyledInputSFC from '../../StyledInput'
import { SelectValue } from 'antd/lib/select'
import { DataSourceItemObject } from 'antd/lib/auto-complete'
import translate from '~/translations/translate'

type ViewProps = {
  data: Array<DataSourceItemObject>
  onSearch: (value: string) => void
  onSelect: (value: SelectValue, option: Object) => void
  disabled?: boolean
  title: string | ReactNode
  value: SelectValue
  loading: boolean
}

export const HostSearch: React.FC<ViewProps> = props => {
  return (
    <div className='flex-item'>
      <strong>{props.title}</strong>
      <Spin spinning={props.loading}>
        <StyledAutoCompleteSFC
          dataSource={props.data}
          onSearch={props.onSearch}
          onSelect={props.onSelect}
          placeholder={translate({
            id: 'form.setup.meta.host-search.placeholder',
          })}
          disabled={props.disabled}
          value={props.value}
          // filterOption={(inputValue, option) =>
          //   option.props.children &&
          //   typeof option.props.children === "string" &&
          //   option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          // }`
          width='100%'
        >
          <StyledInputSFC suffix={<Icon type='search' className='certain-category-icon' style={{ color: 'rgba(0, 0, 0, 0.25)' }} />} />
        </StyledAutoCompleteSFC>
      </Spin>
    </div>
  )
}

export default HostSearch

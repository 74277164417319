import { Modal, Radio } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import * as interfaces from '~/typing/KENAI/interfaces.d.ts'
import { RadioChangeEvent } from 'antd/lib/radio'
import Title from 'antd/lib/typography/Title'
import { cloneDeep } from 'lodash'
interface modalProps {
  isSubmitEnabled: boolean
}

const InductionQuestionsModal = styled(Modal)<modalProps>`
  .ant-modal-content {
    overflow: hidden;
    -ms-overflow-style: none;
  }
  .ant-modal-body {
    .hidden {
      display: none;
    }
  }
  .ant-btn.ant-btn-primary {
    background-color: ${props => (!props.isSubmitEnabled ? 'grey !important;' : '')};
    border-color: ${props => (!props.isSubmitEnabled ? 'grey !important;' : '')};
    color: ${props => (!props.isSubmitEnabled ? 'lightgrey !important;' : '')};
  }
  isSubmitEnabled .ant-modal-close-x {
    color: ${props => props.theme.branding.primary};
  }
  .preview-container {
    position: absolute;
    top: 0;
  }
`

interface CompProps {
  onCloseModal: () => void
  onCompleteInductionQuestions: () => void
  inductionQuestions: Array<interfaces.ConfigInductionQuestions>
}

const InductionQuestions: React.FC<CompProps> = props => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false)
  const [answers, setAnswers] = useState<{ [key: string]: any }>({})
  const inductionQuestions: interfaces.ConfigInductionQuestions[] = useMemo(() => {
    const visibleInductionQuestions: interfaces.ConfigInductionQuestions[] = []
    if (props.inductionQuestions) {
      cloneDeep(props.inductionQuestions)
        .sort((a, b) => a.sortIndex - b.sortIndex)
        .forEach(inductionQuestion => {
          visibleInductionQuestions.push(inductionQuestion)
        })
    }
    return visibleInductionQuestions
  }, [props.inductionQuestions])

  useEffect(() => {
    let isEnabled = true
    inductionQuestions.forEach(inductionQuestion => {
      if (!answers[inductionQuestion.questionId]) {
        isEnabled = false
      }
    })
    setIsSubmitEnabled(isEnabled)
  }, [answers, inductionQuestions])

  const renderQuestion = inductionQuestions => {
    const updateAnswerForQuestion = (e: RadioChangeEvent) => {
      setAnswers({ ...answers, [inductionQuestions.questionId]: e.target.value })
    }
    return (
      <div key={inductionQuestions.questionId}>
        <div>{inductionQuestions.label}</div>
        <Radio.Group onChange={updateAnswerForQuestion} value={answers[inductionQuestions.questionId]}>
          {inductionQuestions.staticDropDownListItems.map(staticDropDownListItem => (
            <Radio key={staticDropDownListItem.dataKey} value={staticDropDownListItem.dataKey}>
              {staticDropDownListItem.text}
            </Radio>
          ))}
        </Radio.Group>
      </div>
    )
  }

  const checkQuestions = () => {
    if (isSubmitEnabled) {
      let isCorrect = true
      inductionQuestions.forEach(inductionQuestion => {
        const sddlItems = inductionQuestion.staticDropDownListItems
        if (sddlItems) {
          const correctItem = sddlItems.find(staticDropDownListItem => staticDropDownListItem.correct)
          if (correctItem && correctItem.dataKey !== answers[inductionQuestion.questionId]) {
            isCorrect = false
          }
        }
      })
      if (isCorrect) {
        props.onCompleteInductionQuestions()
      } else {
        Modal.error({
          title: <Title className='my-0'>Incorrect answers</Title>,
          content: 'You got 1 or more questions incorrect, please retry.',
          maskClosable: false,
          onOk: () => {},
          okText: 'Retry',
          cancelButtonProps: { style: { display: 'none' } },
          centered: true,
        })
        setAnswers({})
        setIsSubmitEnabled(false)
      }
    }
  }

  return (
    <InductionQuestionsModal
      {...isMobile && { style: { top: window.outerHeight * 0.01, maxHeight: window.outerHeight * 0.9 } }}
      visible={true}
      destroyOnClose={true}
      closable={false}
      cancelText='CANCEL'
      onCancel={props.onCloseModal}
      okText='Submit'
      onOk={checkQuestions}
      title='Induction questions'
      isSubmitEnabled={isSubmitEnabled}
    >
      <div
        className='allow-scroll'
        style={{
          overflow: 'scroll',
          maxHeight: window.outerHeight * 0.65,
        }}
      >
        {inductionQuestions.map(inductionQuestion => renderQuestion(inductionQuestion))}
      </div>
    </InductionQuestionsModal>
  )
}

export default InductionQuestions

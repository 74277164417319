import { Table as AntTable } from 'antd'
import styled from 'styled-components'

import alpha from '~/app/utility/Alpha'

const TableStyle = Component => styled(Component)`
  && {
    .editable-cell {
      position: relative;
    }
    .switch-cell {
      min-width: 108px;
    }
    td.email-cell {
      @media only screen and (max-width: 430px) {
        white-space: pre-wrap !important;
        min-width: calc(100vw - 108px - 20px);
      }
    }

    .ant-table td {
      white-space: nowrap;
    }

    .editable-cell-value-wrap {
      padding: 5px 12px;
      cursor: pointer;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      padding: 4px 11px;
      min-height: 31px;
    }
    .ant-table-thead > tr > th {
      background: #f1f3f6;
    }
    .ant-table-thead > tr.ant-table-row-hover > td,
    .ant-table-tbody > tr.ant-table-row-hover > td,
    .ant-table-thead > tr:hover > td,
    .ant-table-tbody > tr:hover > td {
      background: ${props => alpha(props.theme.branding.primary, 0.05)};
    }
    .ant-input {
      color: ${props => props.theme.branding.primary};
      &:focus,
      &:hover {
        border-color: ${props => props.theme.branding.primary};
      }
      &:focus {
        box-shadow: none;
      }
    }
    .ant-pagination {
      @media only screen and (max-width: 349px) {
        float: none;
        text-align: center;
      }
      .ant-pagination-item-active {
        border-color: ${props => props.theme.branding.primary};
        a {
          color: ${props => props.theme.branding.primary};
        }
      }
    }
  }
`
export default TableStyle(AntTable)

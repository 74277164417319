import StyledButton from '~/app/components/StyledButton'
import { toJpeg } from 'html-to-image'
import QRCode from 'qrcode.react'
import React, { Fragment } from 'react'
import { Animated } from 'react-animated-css'
import { isIE, isIOS } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import LottieCompleteIndicator from '../LottieCompleteIndicator/LottieCompleteIndicator'
import getTimeStringFromTs from '~/utils/getTimeStringFromTs'

const WhatsAppIcon = '/images/custom-icons/whatsapp.png'

type ViewProps = {
  className?: string
  entityName: string
  accessPassDetails?: {
    accessPassCode: string
    accessPassValidToTs: number
    email: string
    phoneNumber: string
  }
  downloadEnabled: boolean
  mailSendEnabled?: boolean
  whatsAppSendEnabled?: boolean
}
const AccessPassContainer = styled.div`
  width: 50%;
  height: 100vh;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-x: none;
  -ms-overflow-y: auto;
  .wrapper {
    position: relative;
    max-height: 100%;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1.5em;
    .lower {
      max-width: 700px;
      margin: -100px auto 0;
    }
  }
  @media screen and (max-width: 800px) {
    width: 95%;
  }
`
const AccessPassPageSFC = ({ accessPassDetails, entityName, downloadEnabled, mailSendEnabled, whatsAppSendEnabled }: ViewProps) => {
  if (!accessPassDetails) return null

  const downloadToDevice = () => {
    if (isIOS) {
      alert('Due to iOS limitations download is not supported - please take a screen shot manually')
    } else if (isIE) {
      alert('Download is not supported on Internet Explorer - please take a screen shot manually')
    } else {
      const filter = node => {
        return (
          !node.className ||
          (node.className && !node.className.includes) ||
          (node.className && node.className.includes && !node.className.includes('dl-exlclude'))
        )
      }
      const rootNode = document.getElementsByClassName('access-pass-content')[0] as HTMLElement
      toJpeg(rootNode, {
        quality: 0.95,
        filter: filter,
        backgroundColor: '#FFFFFF',
        width: rootNode.clientWidth,
        height: rootNode.clientHeight,
      })
        .then(function(dataUrl) {
          var link = document.createElement('a')
          link.download = `${entityName.replace(/ /g, '_')}_ACCESS_PASS_${getTimeStringFromTs(
            accessPassDetails.accessPassValidToTs
          ).replace(/ /g, '_')}.jpeg`
          link.href = dataUrl
          link.click()
        })
        .catch(e => {
          debugger
          console.log(e)
          alert('There was an error on your device while saving the access pass.')
        })
    }
  }
  const sendEmail = () => {
    alert('Send email to ' + accessPassDetails.email)
  }
  const sendWhatsApp = () => {
    alert('Send whatsapp to ' + accessPassDetails.phoneNumber)
  }
  return (
    <Fragment>
      <AccessPassContainer>
        <div className='wrapper access-pass-content'>
          <div className={`upper ${isIOS ? 'dl-exlclude' : ''}`}>
            <LottieCompleteIndicator />
          </div>
          <div className='lower'>
            <Animated
              isVisible={true}
              animationIn='bounceInUp'
              animationOut='bounceOutDown'
              style={{ opacity: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <span style={{ fontSize: 18 }}>
                <strong>{entityName}</strong>
                <br />
                <span>
                  <FormattedMessage id='page.accesspass.instruction' />
                </span>
              </span>
              <br />
              <QRCode id={accessPassDetails.accessPassCode} value={accessPassDetails.accessPassCode} size={164} level={'M'} />
              <br />
              <span style={{ fontSize: 18 }}>
                <FormattedMessage
                  id={`page.accesspass.validuntil`}
                  values={{
                    VALID_TO: getTimeStringFromTs(accessPassDetails.accessPassValidToTs),
                  }}
                />
              </span>
              {downloadEnabled && (
                <>
                  <StyledButton className={'dl-exlclude'} type='primary' icon='download' onClick={downloadToDevice}>
                    <FormattedMessage id='page.accesspass.download' />
                  </StyledButton>
                  <span className={'dl-exlclude'} style={{ fontSize: 18 }}>
                    &nbsp;
                  </span>
                </>
              )}
              {mailSendEnabled && (
                <>
                  <StyledButton className={'dl-exlclude'} type='primary' icon='mail' onClick={sendEmail}>
                    <FormattedMessage id='page.accesspass.sendemail' />
                  </StyledButton>
                  <span className={'dl-exlclude'} style={{ fontSize: 18 }}>
                    <FormattedMessage
                      id={`page.accesspass.sendto`}
                      values={{
                        TO_VALUE: accessPassDetails.email,
                      }}
                    />
                  </span>
                </>
              )}
              {whatsAppSendEnabled && (
                <>
                  <StyledButton className={'dl-exlclude'} type='primary' onClick={sendWhatsApp}>
                    <img src={WhatsAppIcon} alt='whatsapp' style={{ width: '14px', marginBottom: '3px' }} />
                    <span style={{ marginLeft: '8px' }}>
                      <FormattedMessage id='page.accesspass.sendwhatsapp' />
                    </span>
                  </StyledButton>
                  <span className={'dl-exlclude'} style={{ fontSize: 18 }}>
                    <FormattedMessage
                      id={`page.accesspass.sendto`}
                      values={{
                        TO_VALUE: accessPassDetails.phoneNumber,
                      }}
                    />
                  </span>
                </>
              )}
            </Animated>
          </div>
        </div>
      </AccessPassContainer>
    </Fragment>
  )
}

export default AccessPassPageSFC

export class ActionCreator<T extends string, P> {
  readonly type: T

  readonly payload!: P

  constructor(type: T) {
    this.type = type
  }

  create = (payload?: P) => ({
    type: this.type,
    payload,
  })
}

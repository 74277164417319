import 'moment/locale/fr'
import 'moment/locale/af'

import ant_EN from 'antd/lib/locale-provider/en_GB'
import ant_FR from 'antd/lib/locale-provider/fr_FR'

import formatLocale from '~/translations/formatLocale'
import defaultLocale from '~/translations/en-ZA.json'

export enum ENUM_LOCALES {
  en = 'en',
  fr = 'fr',
  af = 'af',
}

export type TLocaleTypes = 'en' | 'fr' | 'af'

const messages = (locale: TLocaleTypes) => {
  switch (formatLocale(locale)) {
    case 'en':
      return defaultLocale
    case 'fr':
      return {
        ...defaultLocale,
        ...require('~/translations/fr-FR.json'),
      }
    case 'af':
      return {
        ...defaultLocale,
        ...require('~/translations/af-AF.json'),
      }
    default:
      return defaultLocale
  }
}

export const AntLocales = {
  en: ant_EN,
  fr: ant_FR,
  af: ant_EN,
}

export default messages

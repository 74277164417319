//LOTTIE ANIMATION INDICATOR FOR FORM DONE
const indicator = {
  v: "4.12.0",
  fr: 29.9700012207031,
  ip: 0,
  op: 59.0000024031193,
  w: 800,
  h: 800,
  nm: "confettis2",
  ddd: 0,
  assets: [
    {
      id: "comp_1",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "p20",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [1], y: [1] }, o: { x: [0.01], y: [0] }, n: ["1_1_0p01_0"], t: 4, s: [100], e: [0] },
                { t: 43.0000017514259 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.01, y: 0 },
                  n: "0_1_0p01_0",
                  t: 4,
                  s: [400, 400, 0],
                  e: [71, 152, 0],
                  to: [1.83333337306976, -220.66667175293, 0],
                  ti: [85.1666641235352, -23.3333339691162, 0],
                },
                { t: 43.0000017514259 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[7.855, 0], [0, -7.855], [-7.855, 0], [0, 7.855]],
                      o: [[-7.855, 0], [0, 7.855], [7.855, 0], [0, -7.855]],
                      v: [[0, -14.223], [-14.223, 0], [0, 14.223], [14.223, 0]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Tracé 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 0.901960790157, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fond 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [80, 80], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transformer ",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 4.00000016292334,
          op: 364.000014826024,
          st: 4.00000016292334,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "p19",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [1], y: [1] }, o: { x: [0.01], y: [0] }, n: ["1_1_0p01_0"], t: 3, s: [100], e: [0] },
                { t: 42.0000017106951 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.01, y: 0 },
                  n: "0_1_0p01_0",
                  t: 3,
                  s: [400, 400, 0],
                  e: [579, 746, 0],
                  to: [101.833335876465, 91.3333358764648, 0],
                  ti: [27.1666660308838, -153.33332824707, 0],
                },
                { t: 42.0000017106951 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[7.855, 0], [0, -7.855], [-7.855, 0], [0, 7.855]],
                      o: [[-7.855, 0], [0, 7.855], [7.855, 0], [0, -7.855]],
                      v: [[0, -14.223], [-14.223, 0], [0, 14.223], [14.223, 0]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Tracé 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fond 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [80, 80], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transformer ",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 3.00000012219251,
          op: 363.000014785293,
          st: 3.00000012219251,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "p18",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [1], y: [1] }, o: { x: [0.01], y: [0] }, n: ["1_1_0p01_0"], t: 2, s: [100], e: [0] },
                { t: 41.0000016699642 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.01, y: 0 },
                  n: "0_1_0p01_0",
                  t: 2,
                  s: [400, 400, 0],
                  e: [503, 238, 0],
                  to: [-84.1666641235352, -74.6666641235352, 0],
                  ti: [-88.8333358764648, -45.3333320617676, 0],
                },
                { t: 41.0000016699642 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[7.855, 0], [0, -7.855], [-7.855, 0], [0, 7.855]],
                      o: [[-7.855, 0], [0, 7.855], [7.855, 0], [0, -7.855]],
                      v: [[0, -14.223], [-14.223, 0], [0, 14.223], [14.223, 0]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Tracé 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.074509806931, 0.737254917622, 0.172549024224, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fond 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [80, 80], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transformer ",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 2.00000008146167,
          op: 362.000014744562,
          st: 2.00000008146167,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "p17",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [1], y: [1] }, o: { x: [0.01], y: [0] }, n: ["1_1_0p01_0"], t: 1, s: [100], e: [0] },
                { t: 40.0000016292334 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.01, y: 0 },
                  n: "0_1_0p01_0",
                  t: 1,
                  s: [400, 400, 0],
                  e: [91, 556, 0],
                  to: [-82.1666641235352, -112.666664123535, 0],
                  ti: [73.1666641235352, -199.33332824707, 0],
                },
                { t: 40.0000016292334 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[7.855, 0], [0, -7.855], [-7.855, 0], [0, 7.855]],
                      o: [[-7.855, 0], [0, 7.855], [7.855, 0], [0, -7.855]],
                      v: [[0, -14.223], [-14.223, 0], [0, 14.223], [14.223, 0]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Tracé 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fond 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [80, 80], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transformer ",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 1.00000004073083,
          op: 361.000014703831,
          st: 1.00000004073083,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: "p16",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [1], y: [1] }, o: { x: [0.01], y: [0] }, n: ["1_1_0p01_0"], t: 0, s: [100], e: [0] },
                { t: 39.0000015885026 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.01, y: 0 },
                  n: "0_1_0p01_0",
                  t: 0,
                  s: [400, 400, 0],
                  e: [511, 308, 0],
                  to: [77.8333358764648, 91.3333358764648, 0],
                  ti: [51.1666679382324, 60.6666679382324, 0],
                },
                { t: 39.0000015885026 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[7.855, 0], [0, -7.855], [-7.855, 0], [0, 7.855]],
                      o: [[-7.855, 0], [0, 7.855], [7.855, 0], [0, -7.855]],
                      v: [[0, -14.223], [-14.223, 0], [0, 14.223], [14.223, 0]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Tracé 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.270588248968, 0.529411792755, 0.952941179276, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fond 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [80, 80], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transformer ",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 360.000014663101,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: "p15",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [1], y: [1] }, o: { x: [0.01], y: [0] }, n: ["1_1_0p01_0"], t: 1, s: [100], e: [0] },
                { t: 40.0000016292334 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.01, y: 0 },
                  n: "0_1_0p01_0",
                  t: 1,
                  s: [400, 400, 0],
                  e: [155, 280, 0],
                  to: [-30.1666660308838, -122.666664123535, 0],
                  ti: [95.1666641235352, -53.3333320617676, 0],
                },
                { t: 40.0000016292334 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[7.855, 0], [0, -7.855], [-7.855, 0], [0, 7.855]],
                      o: [[-7.855, 0], [0, 7.855], [7.855, 0], [0, -7.855]],
                      v: [[0, -14.223], [-14.223, 0], [0, 14.223], [14.223, 0]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Tracé 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 0.901960790157, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fond 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [80, 80], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transformer ",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 1.00000004073083,
          op: 361.000014703831,
          st: 1.00000004073083,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: "p14",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [1], y: [1] }, o: { x: [0.01], y: [0] }, n: ["1_1_0p01_0"], t: 2, s: [100], e: [0] },
                { t: 41.0000016699642 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.01, y: 0 },
                  n: "0_1_0p01_0",
                  t: 2,
                  s: [400, 400, 0],
                  e: [681, 388.872, 0],
                  to: [143.83332824707, 49.3333320617676, 0],
                  ti: [-58.8333320617676, 48.6666679382324, 0],
                },
                { t: 41.0000016699642 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[7.855, 0], [0, -7.855], [-7.855, 0], [0, 7.855]],
                      o: [[-7.855, 0], [0, 7.855], [7.855, 0], [0, -7.855]],
                      v: [[0, -14.223], [-14.223, 0], [0, 14.223], [14.223, 0]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Tracé 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fond 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [80, 80], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transformer ",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 2.00000008146167,
          op: 362.000014744562,
          st: 2.00000008146167,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: "p13",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [1], y: [1] }, o: { x: [0.01], y: [0] }, n: ["1_1_0p01_0"], t: 3, s: [100], e: [0] },
                { t: 42.0000017106951 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.01, y: 0 },
                  n: "0_1_0p01_0",
                  t: 3,
                  s: [400, 400, 0],
                  e: [257, 284, 0],
                  to: [-24.1666660308838, -72.6666641235352, 0],
                  ti: [75.1666641235352, -5.33333349227905, 0],
                },
                { t: 42.0000017106951 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[7.855, 0], [0, -7.855], [-7.855, 0], [0, 7.855]],
                      o: [[-7.855, 0], [0, 7.855], [7.855, 0], [0, -7.855]],
                      v: [[0, -14.223], [-14.223, 0], [0, 14.223], [14.223, 0]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Tracé 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.074509806931, 0.737254917622, 0.172549024224, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fond 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [80, 80], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transformer ",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 3.00000012219251,
          op: 363.000014785293,
          st: 3.00000012219251,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: "p12",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [1], y: [1] }, o: { x: [0.01], y: [0] }, n: ["1_1_0p01_0"], t: 4, s: [100], e: [0] },
                { t: 43.0000017514259 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.01, y: 0 },
                  n: "0_1_0p01_0",
                  t: 4,
                  s: [400, 400, 0],
                  e: [301, 474, 0],
                  to: [-14.1666669845581, 31.3333339691162, 0],
                  ti: [65.1666641235352, -13.3333330154419, 0],
                },
                { t: 43.0000017514259 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[7.855, 0], [0, -7.855], [-7.855, 0], [0, 7.855]],
                      o: [[-7.855, 0], [0, 7.855], [7.855, 0], [0, -7.855]],
                      v: [[0, -14.223], [-14.223, 0], [0, 14.223], [14.223, 0]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Tracé 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fond 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [80, 80], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transformer ",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 4.00000016292334,
          op: 364.000014826024,
          st: 4.00000016292334,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: "p11",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [1], y: [1] }, o: { x: [0.01], y: [0] }, n: ["1_1_0p01_0"], t: 0, s: [100], e: [0] },
                { t: 39.0000015885026 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.01, y: 0 },
                  n: "0_1_0p01_0",
                  t: 0,
                  s: [400, 400, 0],
                  e: [499, 570, 0],
                  to: [-8.16666698455811, 63.3333320617676, 0],
                  ti: [-62.8333320617676, -29.3333339691162, 0],
                },
                { t: 39.0000015885026 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[7.855, 0], [0, -7.855], [-7.855, 0], [0, 7.855]],
                      o: [[-7.855, 0], [0, 7.855], [7.855, 0], [0, -7.855]],
                      v: [[0, -14.223], [-14.223, 0], [0, 14.223], [14.223, 0]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Tracé 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.270588248968, 0.529411792755, 0.952941179276, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fond 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [80, 80], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transformer ",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 360.000014663101,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: "p10",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [1], y: [1] }, o: { x: [0.01], y: [0] }, n: ["1_1_0p01_0"], t: 1, s: [100], e: [0] },
                { t: 40.0000016292334 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.01, y: 0 },
                  n: "0_1_0p01_0",
                  t: 1,
                  s: [400, 400, 0],
                  e: [557, 68, 0],
                  to: [81.8333358764648, -104.666664123535, 0],
                  ti: [-58.8333320617676, 104.666664123535, 0],
                },
                { t: 40.0000016292334 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[7.855, 0], [0, -7.855], [-7.855, 0], [0, 7.855]],
                      o: [[-7.855, 0], [0, 7.855], [7.855, 0], [0, -7.855]],
                      v: [[0, -14.223], [-14.223, 0], [0, 14.223], [14.223, 0]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Tracé 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 0.901960790157, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fond 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [80, 80], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transformer ",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 1.00000004073083,
          op: 361.000014703831,
          st: 1.00000004073083,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: "p9",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [1], y: [1] }, o: { x: [0.01], y: [0] }, n: ["1_1_0p01_0"], t: 2, s: [100], e: [0] },
                { t: 41.0000016699642 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.01, y: 0 },
                  n: "0_1_0p01_0",
                  t: 2,
                  s: [400, 400, 0],
                  e: [715, 138, 0],
                  to: [151.83332824707, -46.6666679382324, 0],
                  ti: [-26.8333339691162, 72.6666641235352, 0],
                },
                { t: 41.0000016699642 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[7.855, 0], [0, -7.855], [-7.855, 0], [0, 7.855]],
                      o: [[-7.855, 0], [0, 7.855], [7.855, 0], [0, -7.855]],
                      v: [[0, -14.223], [-14.223, 0], [0, 14.223], [14.223, 0]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Tracé 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fond 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [80, 80], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transformer ",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 2.00000008146167,
          op: 362.000014744562,
          st: 2.00000008146167,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: "p8",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [1], y: [1] }, o: { x: [0.01], y: [0] }, n: ["1_1_0p01_0"], t: 3, s: [100], e: [0] },
                { t: 42.0000017106951 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.01, y: 0 },
                  n: "0_1_0p01_0",
                  t: 3,
                  s: [400, 400, 0],
                  e: [679, 664, 0],
                  to: [107.833335876465, 89.3333358764648, 0],
                  ti: [-92.8333358764648, -103.333335876465, 0],
                },
                { t: 42.0000017106951 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[7.855, 0], [0, -7.855], [-7.855, 0], [0, 7.855]],
                      o: [[-7.855, 0], [0, 7.855], [7.855, 0], [0, -7.855]],
                      v: [[0, -14.223], [-14.223, 0], [0, 14.223], [14.223, 0]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Tracé 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.074509806931, 0.737254917622, 0.172549024224, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fond 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [80, 80], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transformer ",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 3.00000012219251,
          op: 363.000014785293,
          st: 3.00000012219251,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: "p7",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [1], y: [1] }, o: { x: [0.01], y: [0] }, n: ["1_1_0p01_0"], t: 4, s: [100], e: [0] },
                { t: 43.0000017514259 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.01, y: 0 },
                  n: "0_1_0p01_0",
                  t: 4,
                  s: [400, 400, 0],
                  e: [97, 686, 0],
                  to: [-36.1666679382324, 53.3333320617676, 0],
                  ti: [75.1666641235352, -39.3333320617676, 0],
                },
                { t: 43.0000017514259 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[7.855, 0], [0, -7.855], [-7.855, 0], [0, 7.855]],
                      o: [[-7.855, 0], [0, 7.855], [7.855, 0], [0, -7.855]],
                      v: [[0, -14.223], [-14.223, 0], [0, 14.223], [14.223, 0]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Tracé 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fond 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [80, 80], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transformer ",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 4.00000016292334,
          op: 364.000014826024,
          st: 4.00000016292334,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: "p6",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [1], y: [1] }, o: { x: [0.01], y: [0] }, n: ["1_1_0p01_0"], t: 5, s: [100], e: [0] },
                { t: 44.0000017921567 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.01, y: 0 },
                  n: "0_1_0p01_0",
                  t: 5,
                  s: [400, 400, 0],
                  e: [65, 340, 0],
                  to: [-148.16667175293, -132.66667175293, 0],
                  ti: [61.1666679382324, -65.3333358764648, 0],
                },
                { t: 44.0000017921567 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[7.855, 0], [0, -7.855], [-7.855, 0], [0, 7.855]],
                      o: [[-7.855, 0], [0, 7.855], [7.855, 0], [0, -7.855]],
                      v: [[0, -14.223], [-14.223, 0], [0, 14.223], [14.223, 0]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Tracé 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.270588248968, 0.529411792755, 0.952941179276, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fond 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [80, 80], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transformer ",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 5.00000020365417,
          op: 365.000014866755,
          st: 5.00000020365417,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: "p5",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [1], y: [1] }, o: { x: [0.01], y: [0] }, n: ["1_1_0p01_0"], t: 6, s: [100], e: [0] },
                { t: 45.0000018328876 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.004, y: 0.691 },
                  o: { x: 0.014, y: 0 },
                  n: "0p004_0p691_0p014_0",
                  t: 6,
                  s: [400, 400, 0],
                  e: [400, 23.613, 0],
                  to: [-55.3803939819336, -168.204071044922, 0],
                  ti: [27.0301876068115, 124.269813537598, 0],
                },
                { t: 45.0000018328876 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[7.855, 0], [0, -7.855], [-7.855, 0], [0, 7.855]],
                      o: [[-7.855, 0], [0, 7.855], [7.855, 0], [0, -7.855]],
                      v: [[0, -14.223], [-14.223, 0], [0, 14.223], [14.223, 0]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Tracé 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 0.901960790157, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fond 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [80, 80], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transformer ",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 6.00000024438501,
          op: 366.000014907486,
          st: 6.00000024438501,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 4,
          nm: "p4",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [1], y: [1] }, o: { x: [0.01], y: [0] }, n: ["1_1_0p01_0"], t: 7, s: [100], e: [0] },
                { t: 46.0000018736184 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.01, y: 0 },
                  n: "0_1_0p01_0",
                  t: 7,
                  s: [400, 400, 0],
                  e: [303, 660, 0],
                  to: [109.833335876465, 69.3333358764648, 0],
                  ti: [97.1666641235352, 0.66666668653488, 0],
                },
                { t: 46.0000018736184 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[7.855, 0], [0, -7.855], [-7.855, 0], [0, 7.855]],
                      o: [[-7.855, 0], [0, 7.855], [7.855, 0], [0, -7.855]],
                      v: [[0, -14.223], [-14.223, 0], [0, 14.223], [14.223, 0]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Tracé 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fond 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [80, 80], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transformer ",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 7.00000028511585,
          op: 367.000014948216,
          st: 7.00000028511585,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 18,
          ty: 4,
          nm: "p3",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [1], y: [1] }, o: { x: [0.01], y: [0] }, n: ["1_1_0p01_0"], t: 8, s: [100], e: [0] },
                { t: 47.0000019143492 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.01, y: 0 },
                  n: "0_1_0p01_0",
                  t: 8,
                  s: [400, 400, 0],
                  e: [663, 498, 0],
                  to: [41.8333320617676, 109.333335876465, 0],
                  ti: [-71.8333358764648, 39.6666679382324, 0],
                },
                { t: 47.0000019143492 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[7.855, 0], [0, -7.855], [-7.855, 0], [0, 7.855]],
                      o: [[-7.855, 0], [0, 7.855], [7.855, 0], [0, -7.855]],
                      v: [[0, -14.223], [-14.223, 0], [0, 14.223], [14.223, 0]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Tracé 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.076272718608, 0.735462605953, 0.171031266451, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fond 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [80, 80], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transformer ",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 8.00000032584668,
          op: 368.000014988947,
          st: 8.00000032584668,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 19,
          ty: 4,
          nm: "p2",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [1], y: [1] }, o: { x: [0.01], y: [0] }, n: ["1_1_0p01_0"], t: 9, s: [100], e: [0] },
                { t: 48.0000019550801 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.01, y: 0 },
                  n: "0_1_0p01_0",
                  t: 9,
                  s: [400, 400, 0],
                  e: [187, 88, 0],
                  to: [-0.16666667163372, -158.66667175293, 0],
                  ti: [89.1666641235352, 6.66666650772095, 0],
                },
                { t: 48.0000019550801 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[7.855, 0], [0, -7.855], [-7.855, 0], [0, 7.855]],
                      o: [[-7.855, 0], [0, 7.855], [7.855, 0], [0, -7.855]],
                      v: [[0, -14.223], [-14.223, 0], [0, 14.223], [14.223, 0]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Tracé 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0.271778345108, 0.528400123119, 0.952267169952, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fond 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [80, 80], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transformer ",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 9.00000036657752,
          op: 369.000015029678,
          st: 9.00000036657752,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 20,
          ty: 4,
          nm: "p1",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { i: { x: [1], y: [1] }, o: { x: [0.01], y: [0] }, n: ["1_1_0p01_0"], t: 0, s: [100], e: [0] },
                { t: 39.0000015885026 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.01, y: 0 },
                  n: "0_1_0p01_0",
                  t: 0,
                  s: [400, 400, 0],
                  e: [621, 234, 0],
                  to: [-0.16666667163372, -158.66667175293, 0],
                  ti: [-92.8333358764648, -103.333335876465, 0],
                },
                { t: 39.0000015885026 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[7.855, 0], [0, -7.855], [-7.855, 0], [0, 7.855]],
                      o: [[-7.855, 0], [0, 7.855], [7.855, 0], [0, -7.855]],
                      v: [[0, -14.223], [-14.223, 0], [0, 14.223], [14.223, 0]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Tracé 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 0.903676450253, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fond 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [80, 80], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transformer ",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 360.000014663101,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: "confettis1",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 42, ix: 10 },
        p: { a: 0, k: [396, 400, 0], ix: 2 },
        a: { a: 0, k: [400, 400, 0], ix: 1 },
        s: { a: 0, k: [91.5, 91.5, 100], ix: 6 },
      },
      ao: 0,
      w: 800,
      h: 800,
      ip: 9.00000036657752,
      op: 369.000015029678,
      st: 9.00000036657752,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: "confettis1",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 267, ix: 10 },
        p: { a: 0, k: [416, 420, 0], ix: 2 },
        a: { a: 0, k: [400, 400, 0], ix: 1 },
        s: { a: 0, k: [64.5, 64.5, 100], ix: 6 },
      },
      ao: 0,
      w: 800,
      h: 800,
      ip: 7.00000028511585,
      op: 367.000014948216,
      st: 7.00000028511585,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 0,
      nm: "confettis1",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 358, ix: 10 },
        p: { a: 0, k: [436, 400, 0], ix: 2 },
        a: { a: 0, k: [400, 400, 0], ix: 1 },
        s: { a: 0, k: [64.5, 64.5, 100], ix: 6 },
      },
      ao: 0,
      w: 800,
      h: 800,
      ip: 5.00000020365417,
      op: 365.000014866755,
      st: 5.00000020365417,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 0,
      nm: "confettis1",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 177, ix: 10 },
        p: { a: 0, k: [416, 380, 0], ix: 2 },
        a: { a: 0, k: [400, 400, 0], ix: 1 },
        s: { a: 0, k: [64.5, 64.5, 100], ix: 6 },
      },
      ao: 0,
      w: 800,
      h: 800,
      ip: 3.00000012219251,
      op: 363.000014785293,
      st: 3.00000012219251,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 0,
      nm: "confettis1",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 132, ix: 10 },
        p: { a: 0, k: [416, 400, 0], ix: 2 },
        a: { a: 0, k: [400, 400, 0], ix: 1 },
        s: { a: 0, k: [91.5, 91.5, 100], ix: 6 },
      },
      ao: 0,
      w: 800,
      h: 800,
      ip: 1.00000004073083,
      op: 361.000014703831,
      st: 1.00000004073083,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 0,
      nm: "confettis1",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -90, ix: 10 },
        p: { a: 0, k: [396, 400, 0], ix: 2 },
        a: { a: 0, k: [400, 400, 0], ix: 1 },
        s: { a: 0, k: [91.5, 91.5, 100], ix: 6 },
      },
      ao: 0,
      w: 800,
      h: 800,
      ip: 8.00000032584668,
      op: 368.000014988947,
      st: 8.00000032584668,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 0,
      nm: "confettis1",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 135, ix: 10 },
        p: { a: 0, k: [416, 420, 0], ix: 2 },
        a: { a: 0, k: [400, 400, 0], ix: 1 },
        s: { a: 0, k: [64.5, 64.5, 100], ix: 6 },
      },
      ao: 0,
      w: 800,
      h: 800,
      ip: 6.00000024438501,
      op: 366.000014907486,
      st: 6.00000024438501,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 0,
      nm: "confettis1",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 226, ix: 10 },
        p: { a: 0, k: [436, 400, 0], ix: 2 },
        a: { a: 0, k: [400, 400, 0], ix: 1 },
        s: { a: 0, k: [64.5, 64.5, 100], ix: 6 },
      },
      ao: 0,
      w: 800,
      h: 800,
      ip: 4.00000016292334,
      op: 364.000014826024,
      st: 4.00000016292334,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 0,
      nm: "confettis1",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 45, ix: 10 },
        p: { a: 0, k: [416, 380, 0], ix: 2 },
        a: { a: 0, k: [400, 400, 0], ix: 1 },
        s: { a: 0, k: [64.5, 64.5, 100], ix: 6 },
      },
      ao: 0,
      w: 800,
      h: 800,
      ip: 2.00000008146167,
      op: 362.000014744562,
      st: 2.00000008146167,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 0,
      nm: "confettis1",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [416, 400, 0], ix: 2 },
        a: { a: 0, k: [400, 400, 0], ix: 1 },
        s: { a: 0, k: [91.5, 91.5, 100], ix: 6 },
      },
      ao: 0,
      w: 800,
      h: 800,
      ip: 0,
      op: 360.000014663101,
      st: 0,
      bm: 0,
    },
  ],
}

export default indicator

import React from 'react'
import { withRouter } from 'react-router-dom'
import AccessPassPage from '~/app/components/AccessPassPage'
import { connect } from 'react-redux'
import { getProcessInstanceState } from '~/store/processinstance'
import { getRegistrationInstanceState } from '~/store/registrationinstance'
import { ThemeProvider } from 'styled-components'
import { getReturnOfExpression } from 'utility-types'
import CornerImages from '~/app/components/CornerImages'
import GlobalStyle from '~/app/styles/base'

const mapStateToProps = state => {
  return {
    processinstance: getProcessInstanceState(state),
    registrationinstance: getRegistrationInstanceState(state),
  }
}
const mapDispatchToProps = {}

const stateProps = getReturnOfExpression(mapStateToProps)

type LocalCompProps = {}

type CompProps = typeof stateProps & typeof mapDispatchToProps & LocalCompProps

type State = {}

class AccessPassCTR extends React.Component<CompProps, State> {
  render() {
    const downloadEnabled = this.props.processinstance.registrationEntity.accessPassDeviceDistribution!.download
    const mailSendEnabled =
      this.props.processinstance.registrationEntity.accessPassDeviceDistribution!.email &&
      this.props.registrationinstance.profileSubmissionAccessPassDetails!.email &&
      !this.props.registrationinstance.profileSubmissionAccessPassDetails!.email.includes('nomail.kenai.co.za')
    const whatsAppSendEnabled =
      this.props.processinstance.registrationEntity.accessPassDeviceDistribution!.whatsapp &&
      this.props.registrationinstance.profileSubmissionAccessPassDetails!.phoneNumber
    return (
      <ThemeProvider theme={this.props.processinstance.registrationEntity}>
        <div style={{ overflow: 'hidden' }}>
          <GlobalStyle />
          <CornerImages showBottomImages={true} images={this.props.processinstance.registrationEntity.cornerImages} />
          <AccessPassPage
            entityName={this.props.processinstance.registrationEntity.entityName}
            accessPassDetails={this.props.registrationinstance.profileSubmissionAccessPassDetails}
            downloadEnabled={downloadEnabled}
            mailSendEnabled={!!mailSendEnabled}
            whatsAppSendEnabled={!!whatsAppSendEnabled}
          />
        </div>
      </ThemeProvider>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AccessPassCTR)
)

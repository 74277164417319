import moment from 'moment'
import momentTZ from 'moment-timezone'
import 'moment/locale/fr'
import 'moment/locale/af'

// in the config function
function getLocale(name: string) {
  moment.locale(name) // apply it to moment
  momentTZ.defineLocale(name, (moment.localeData() as any)._config) // copy locale to moment-timezone
  momentTZ.locale(name) // apply it to moment-timezone
}

export default function getTimeStringFromTs(accessPassValidToTs: number): string {
  const locale: string | null = localStorage.getItem('locale')
  getLocale(locale !== null ? locale : 'en')
  return moment(accessPassValidToTs).format('dddd, MMM D, YYYY [a]t hh:mm a')
}

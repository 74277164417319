import EnterButton from '~/app/components/EnterButton'
import AddonButton from '~/app/components/SharedFormComponents/AddonButtonSFC'
import CustomError from '~/app/components/SharedFormComponents/CustomErrorSFC'
import CustomFormItem from '~/app/components/SharedFormComponents/CustomFormItem'
import CustomWarning from '~/app/components/SharedFormComponents/CustomWarningSFC'
import FormLabel from '~/app/components/SharedFormComponents/FormLabelSFC'
import StyledInputAlert from '~/app/components/StyledInputAlert'
import phoneLabels from '~/app/utility/phoneLabels'
import * as interfaces from '~/typing/KENAI/interfaces.d.ts'
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form'
import { ReactInput } from 'input-format'
// custom input component
import { AsYouType, parsePhoneNumberCharacter } from 'libphonenumber-js/core'
import defaultMetadata from 'libphonenumber-js/metadata.min.json'
import React, { Component, useMemo, useState } from 'react'
import { Animated } from 'react-animated-css'
import { isIOS } from 'react-device-detect'
// import SmartInput from "react-phone-number-input/smart-input";
import { FormattedMessage, useIntl } from 'react-intl'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import styled from 'styled-components'
// input comp
import { FlowControl, FormValues } from '../../component'

const messages = {
  INPUT_PHONE_PLACEHOLDER: { id: 'form.registration.input.phone.placeholder' },
}
const Title = styled.h1`
  color: ${props => props.theme.branding.primary};
  z-index: 100;
  margin-bottom: 30px;
`

type Props = {
  onNumberOkClick: () => void
  setPhoneNumberRef: (ref: any) => void
  formValues: FormValues
  flowControl: FlowControl
  isMobile: boolean
  // ref: any
  country: string
  otpChoiceComplete: boolean
  isPostInitialRegistration: boolean
  tokenHasBeenPreviouslyProcessed: boolean
  tokenPhoneNumber: string
  inviteCreationFields: interfaces.InviteCreationFields
  sourceDeviceTokenUnverifiedProcessing: boolean
  getFieldDecorator: <T>(id: keyof T, options?: GetFieldDecoratorOptions | undefined) => (node: React.ReactNode) => React.ReactNode
}

const autoCompleteRandomizer = Date.now()

//this handles mask output
class CustomInput extends Component<any, any> {
  static defaultProps = {
    metadata: defaultMetadata,
  }

  input: any

  focus = () => this.input.focus()

  storeInput = ref => (this.input = ref)

  format = value => {
    const { country, metadata } = this.props

    // "As you type" formatter.
    const formatter = new AsYouType(country, metadata)

    // Format the number.
    const text = value.indexOf('*') > -1 ? value : formatter.input(value)

    return { text, template: formatter.getTemplate() }
  }

  render() {
    const { country, metadata, style, ...rest } = this.props

    return (
      <ReactInput
        {...rest}
        style={{
          ...style,
          ...(isIOS && { height: '55px' }),
        }}
        ref={this.storeInput}
        parse={parsePhoneNumberCharacter}
        format={this.format}
      />
    )
  }
}

const PhoneNumberInput: React.FC<Props> = props => {
  const { formatMessage } = useIntl()

  const [defaultPhoneNumberValue] = useState(() => {
    if (!props.tokenHasBeenPreviouslyProcessed) {
      if (!!props.formValues.phoneNumber.value) {
        if (props.formValues.phoneNumber.value.indexOf('*') === -1) {
          return props.formValues.phoneNumber.value
        } else {
          if (props.isPostInitialRegistration) {
            return props.tokenPhoneNumber
          } else if (props.inviteCreationFields && props.inviteCreationFields.invitePhoneNumber) {
            return props.inviteCreationFields.invitePhoneNumber
          }
        }
      }
    }
    return undefined
  })
  const isChangingPhoneNumber = useMemo(() => {
    return !!defaultPhoneNumberValue && !!props.formValues.phoneNumber.value
      ? !!props.formValues.phoneNumber.value &&
          props.formValues.phoneNumber.value.indexOf('*') === -1 &&
          props.formValues.phoneNumber.value !== defaultPhoneNumberValue
      : !!defaultPhoneNumberValue
      ? props.formValues.phoneNumber.value !== defaultPhoneNumberValue
      : false
  }, [props.formValues.phoneNumber.value, defaultPhoneNumberValue])

  let hasPhoneNumberFeedback: boolean = false
  if (
    !props.flowControl.otpRequest.otpRequestSuccessfull &&
    !props.flowControl.otpRequest.otpRequestInProcess &&
    props.flowControl.otpRequest.otpRequestErrorMessage
  ) {
    hasPhoneNumberFeedback = true
  }
  const phoneValidation = (rule, value, callback) => {
    if (value && value.length > 0 && !isValidPhoneNumber(value, props.country)) {
      callback(<CustomError visible={true} message={<FormattedMessage id='form.registration.input.phone.error.invalid' />} />)
    }
    callback()
  }
  const phoneEnterKeypress = event => {
    if (
      !props.flowControl.otpRequest.otpRequestInProcess &&
      props.formValues.phoneNumber.isValid &&
      !hasPhoneNumberFeedback &&
      event &&
      event.nativeEvent &&
      event.nativeEvent.keyCode &&
      event.nativeEvent.keyCode === 13
    ) {
      //enter pressed
      props.onNumberOkClick()
    }
  }
  return (
    <React.Fragment>
      <Title>
        <FormattedMessage id='form.registration.startText' />,
      </Title>
      <CustomFormItem
        className='required-form-item'
        label={
          <FormLabel
            number='1'
            sublabel={
              <FormattedMessage
                id={`form.registration.input.phone.subLabel${props.isPostInitialRegistration ? '.postregistration' : ''}`}
              />
            }
          >
            <FormattedMessage
              id={`form.registration.input.phone.label${props.isPostInitialRegistration ? '.postregistration' : ''}`}
              values={{
                PHONE_TEXT: (
                  <strong>
                    <FormattedMessage id='form.registration.input.phone.label.phonenumber' />
                  </strong>
                ),
              }}
            />
          </FormLabel>
        }
        hasFeedback={hasPhoneNumberFeedback}
        extra={
          props.isMobile && props.otpChoiceComplete && !props.sourceDeviceTokenUnverifiedProcessing ? (
            <Animated
              animationIn='fadeInUp'
              animationOut='fadeOutDown'
              animateOnMount={false}
              isVisible={props.formValues.phoneNumber.isValid && !hasPhoneNumberFeedback}
              // style={{ marginTop: 10, position: 'absolute' }}
            >
              <EnterButton size='large' onClick={props.onNumberOkClick} loading={props.flowControl.otpRequest.otpRequestInProcess}>
                <FormattedMessage id='form.registration.input.phone.button.resend' />
              </EnterButton>
            </Animated>
          ) : (
            !props.isMobile && (
              <Animated
                animationIn='fadeInUp'
                animationOut='fadeOutDown'
                animateOnMount={false}
                isVisible={props.formValues.phoneNumber.isValid && !hasPhoneNumberFeedback}
                // style={{ marginTop: 10, position: 'absolute' }}
              >
                <EnterButton size='large' onClick={props.onNumberOkClick} loading={props.flowControl.otpRequest.otpRequestInProcess}>
                  {!props.otpChoiceComplete || props.sourceDeviceTokenUnverifiedProcessing ? (
                    <FormattedMessage id='form.registration.button.ok' />
                  ) : (
                    <FormattedMessage id='form.registration.input.phone.button.resend' />
                  )}
                </EnterButton>
              </Animated>
            )
          )
        }
      >
        {props.getFieldDecorator('phoneNumber', {
          rules: [
            {
              required: true,
              message: (
                <Animated animationIn='fadeInDown' animationOut='fadeOutUp' animateOnMount={true} isVisible={true} animationInDelay={0}>
                  <StyledInputAlert
                    className={'input-error'}
                    type='error'
                    message={<FormattedMessage id='form.registration.input.phone.error.required' />}
                    banner={true}
                  />
                </Animated>
              ),
            },
            {
              validator: phoneValidation,
            },
          ],
        })(
          <PhoneInput
            data-hj-suppress={true}
            autoComplete={`kenai-phoneNumber-${autoCompleteRandomizer}`}
            className='ant-input ant-input-lg'
            country={props.country}
            placeholder={formatMessage(messages.INPUT_PHONE_PLACEHOLDER)}
            limitMaxLength={true}
            onKeyPress={phoneEnterKeypress}
            inputComponent={CustomInput}
            labels={phoneLabels}
            disabled={props.tokenHasBeenPreviouslyProcessed}
            {...props.isMobile &&
              !props.otpChoiceComplete && {
                ext: (
                  <AddonButton
                    nextItemFocus={props.onNumberOkClick}
                    isValid={props.formValues.phoneNumber.isValid}
                    value={props.formValues.phoneNumber.value as string}
                    loading={props.flowControl.otpRequest.otpRequestInProcess}
                  />
                ),
              }}
          />
        )}
        <CustomWarning visible={isChangingPhoneNumber} message={'If you change your phone number you will replace your existing profile'} />
        <CustomError
          visible={hasPhoneNumberFeedback}
          message={props.flowControl.otpRequest.otpRequestErrorMessage}
          linkEvent={props.onNumberOkClick}
          linkSuffix={<FormattedMessage id='form.registration.input.otp.toResend' />}
        />
      </CustomFormItem>
    </React.Fragment>
  )
}

export default PhoneNumberInput

import { take, fork, cancel } from 'redux-saga/effects'
import { SagaMiddleware } from 'redux-saga'
import { Store } from 'redux'
import rootSaga from '~/sagas/rootSaga'
import { RootState } from '~/store/rootReducer'

const sagas = [rootSaga]

export const CANCEL_SAGAS_HMR = 'CANCEL_SAGAS_HMR'

const createAbortableSaga = (saga: typeof rootSaga) => {
  if (process.env.NODE_ENV === 'development') {
    return function* runMain() {
      const sagaTask = yield fork(saga)
      yield take(CANCEL_SAGAS_HMR)
      yield cancel(sagaTask)
    }
  } else {
    return saga
  }
}

const SagaManager = {
  startSagas(sagaMiddleware: SagaMiddleware) {
    sagas.map(createAbortableSaga).forEach(saga => sagaMiddleware.run(saga))
  },
  cancelSagas(store: Store<RootState>) {
    store.dispatch({
      type: CANCEL_SAGAS_HMR,
    })
  },
}

export default SagaManager

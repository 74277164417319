import React from 'react'
import Selection, { SelectionProps } from './SelectionSFC'

type ViewProps = {} & SelectionProps

export const VisitorTypeSelect: React.FC<ViewProps> = props => {
  return (
    <div className='flex-item'>
      <strong>Visitor Type</strong>
      <Selection {...props} placeholder='select a visitor type' />
    </div>
  )
}

export default VisitorTypeSelect

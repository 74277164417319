import React from "react";

const ParkingLegals = ({
  termsSingleLines
}: {
  termsSingleLines: Array<string>;
}) => {
  return (
    <div>
      {termsSingleLines.map((termLine, index) => {
        const termParts: Array<any> = termLine.split("||");
        const termRenderables: Array<any> = [];
        termParts.forEach((termPart, indexPart) => {
          if (termPart.indexOf("{'link':") > -1) {
            const linkObject = JSON.parse(termPart.replace(/'/g, '"'));
            termRenderables.push(
              <a
                key={indexPart}
                href={linkObject.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {linkObject.text}
              </a>
            );
          } else {
            termRenderables.push(<span key={indexPart}>{termPart}</span>);
          }
        });

        return (
          <div key={index}>{termRenderables.map(renderable => renderable)}</div>
        );
      })}
    </div>
  );
};

export default ParkingLegals;

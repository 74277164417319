import EnterButton from '~/app/components/EnterButton'
import AddonButton from '~/app/components/SharedFormComponents/AddonButtonSFC'
import CustomError from '~/app/components/SharedFormComponents/CustomErrorSFC'
import CustomFormItem from '~/app/components/SharedFormComponents/CustomFormItem'
import CustomSuccess from '~/app/components/SharedFormComponents/CustomSuccessSFC'
import FormLabel from '~/app/components/SharedFormComponents/FormLabelSFC'
import StyledInputAlert from '~/app/components/StyledInputAlert'
import useCountdown from '~/hooks/use-countdown'
import * as enums from '~/typing/KENAI/enums.d.ts'
import cn from '~/utils/classnames'
import { Input } from 'antd'
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form'
import React, { HTMLAttributes, memo, useCallback, useEffect } from 'react'
import { Animated } from 'react-animated-css'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons"
import { FormattedMessage, useIntl } from 'react-intl'
import 'react-phone-number-input/style.css'
import { FlowControl, FormValues } from '../../component'

const messages = {
  OTP_TO_RESEND: { id: 'form.registration.input.otp.toResend' },
  OTP_TO_CHANGEPHONE: {
    id: 'form.registration.input.otp.toChangePhone',
  },
  OTP_FIRSTSEND: {
    id: 'form.registration.input.otp.firstSendOtp',
  },
  OTP_TYPEPLACEHOLDER: { id: 'form.registration.input.otp.typeOtp' },
  OTP_CONFIRMED: {
    id: 'form.registration.input.otp.confirmedOtp',
  },
}

interface ResendButtonProps extends HTMLAttributes<HTMLSpanElement> {
  onSendSms: () => void
  onSendWhatsapp: () => void
}

const CountText = ({ disabled, count, onClick, children }) => {
  return (
    <span
      onClick={onClick}
      className={cn('text-sm transition-all', {
        link: !disabled,
        'pointer-events-none cursor-not-allowed': disabled,
      })}
    >
      {children} {disabled && count !== 0 && <span>in {count} seconds </span>}
    </span>
  )
}

const ResendButton = memo(({ onSendSms = () => null, onSendWhatsapp = () => null, ...props }: ResendButtonProps) => {
  const { countdown: smsCount, restart: restartSms, isDone: smsIsDone } = useCountdown(30)
  const { countdown: waCount, start, isDone: waIsDone } = useCountdown(0)

  useEffect(() => {
    restartSms()
  }, [restartSms])

  const handleClickSms = useCallback(
    e => {
      restartSms()
      onSendSms()
    },
    [restartSms, onSendSms]
  )

  const handleClickWhatsapp = useCallback(
    e => {
      start(30)
      onSendWhatsapp()
    },
    [start, onSendWhatsapp]
  )

  return (
    <>
      <CountText onClick={handleClickSms} disabled={!smsIsDone || !waIsDone} count={!waIsDone ? 0 : smsCount}>
        Resend an SMS
      </CountText>
      or{' '}
      <CountText onClick={handleClickWhatsapp} disabled={!waIsDone} count={waCount}>
        resend via WhatsApp
      </CountText>
    </>
  )
})

type Props = {
  onNumberOkClick: (channel?: 'sms' | 'whatsapp') => void
  formValues: FormValues
  flowControl: FlowControl
  isMobile: boolean
  processOnInputBlur: () => void
  onOTPOkClick: () => void
  fullpageApi: any
  otpChoiceComplete: boolean
  getFieldDecorator: <T>(id: keyof T, options?: GetFieldDecoratorOptions | undefined) => (node: React.ReactNode) => React.ReactNode
  tokenHasBeenPreviouslyProcessed?: boolean
}

const autoCompleteRandomizer = Date.now()

const OtpCodeInput = (props: Props) => {
  let hasOTPFeedback: boolean = false
  const intl = useIntl()
  if (
    !props.flowControl.otpValidation.otpValidationSuccessfull &&
    !props.flowControl.otpValidation.otpValidationInProcess &&
    props.flowControl.otpValidation.otpValidationErrorMessage
  ) {
    hasOTPFeedback = true
  }

  const getOTPErrorMessage = () => {
    let linkSuffix = ''
    let eventHandler: Function | undefined = undefined
    if (
      props.flowControl.otpValidation.otpValidationErrorType === enums.OTP_VALIDATION_ERRORS.OTP_INCORRECT_OR_EXPIRED ||
      props.flowControl.otpValidation.otpValidationErrorType === enums.OTP_VALIDATION_ERRORS.TECHNICAL_ERROR
    ) {
      linkSuffix = intl.formatMessage(messages.OTP_TO_RESEND)
      eventHandler = props.onNumberOkClick
    } else if (props.flowControl.otpValidation.otpValidationErrorType === enums.OTP_VALIDATION_ERRORS.PROFILE_EXISTS) {
      linkSuffix = intl.formatMessage(messages.OTP_TO_CHANGEPHONE)
      eventHandler = () => {
        props.fullpageApi.moveSectionUp()
      }
    }
    return (
      <CustomError
        visible={hasOTPFeedback}
        message={props.flowControl.otpValidation.otpValidationErrorMessage}
        linkEvent={eventHandler as () => void}
        linkSuffix={linkSuffix}
      />
    )
  }

  const otpVerified = props.otpChoiceComplete

  return (
    <CustomFormItem
      className='required-form-item'
      label={
        <FormLabel
          number='2'
          sublabel={
            !otpVerified && (
              <div>
                Didn't get an OTP?{' '}
                <ResendButton onSendSms={() => props.onNumberOkClick('sms')} onSendWhatsapp={() => props.onNumberOkClick('whatsapp')} />
              </div>
            )
          }
        >
          {!otpVerified ? (
            <div>
              Great, please enter the one time pin we've sent to <span className='font-medium'>{props.formValues.phoneNumber.value}</span>.{' '}
              <span
                className={cn('link', {
                  hidden: props.tokenHasBeenPreviouslyProcessed,
                })}
                onClick={() => props.fullpageApi.moveSectionUp()}
              >
                Wrong number?
              </span>
            </div>
          ) : (
            <FormattedMessage id='form.registration.input.otp.verifiedOtp' defaultMessage='Your otp has been verified' />
          )}
        </FormLabel>
      }
      hasFeedback={hasOTPFeedback}
      extra={
        <Animated
          animationIn='fadeInUp'
          animationOut='fadeOutDown'
          animateOnMount={true}
          isVisible={props.formValues.otpCode.isValid && !props.isMobile && !hasOTPFeedback && !props.otpChoiceComplete}
        >
          <EnterButton size='large' onClick={props.onOTPOkClick} loading={props.flowControl.otpValidation.otpValidationInProcess}>
            <FormattedMessage id='form.registration.button.ok' />
          </EnterButton>
        </Animated>
      }
    >
      {props.getFieldDecorator('otpCode', {
        rules: [
          {
            required: true,
            message: (
              <Animated animationIn='fadeIn' animationOut='fadeOut' animateOnMount={true} isVisible={true}>
                <StyledInputAlert
                  className={'input-error'}
                  type='error'
                  message={<FormattedMessage id='form.registration.input.otp.error.required' />}
                  banner={true}
                />
              </Animated>
            ),
          },
          {
            len: 4,
            message: (
              <Animated animationIn='fadeIn' animationOut='fadeOut' animateOnMount={true} isVisible={true}>
                <StyledInputAlert
                  className={'input-error'}
                  type='error'
                  message={<FormattedMessage id='form.registration.input.otp.error.length' />}
                  banner={true}
                />
              </Animated>
            ),
          },
          {
            type: 'string',
            pattern: /^[0-9]+$/,
            message: (
              <Animated animationIn='fadeIn' animationOut='fadeOut' animateOnMount={true} isVisible={true}>
                <StyledInputAlert
                  className={'input-error'}
                  type='error'
                  message={<FormattedMessage id='form.registration.input.otp.error.type' />}
                  banner={true}
                />
              </Animated>
            ),
          },
        ],
      })(
        <Input
          type='number'
          onPressEnter={props.onOTPOkClick}
          autoComplete={`kenai-otpCode-${autoCompleteRandomizer}`}
          placeholder={
            !props.flowControl.otpRequest.otpRequestSuccessfull
              ? intl.formatMessage(messages.OTP_FIRSTSEND)
              : intl.formatMessage(messages.OTP_TYPEPLACEHOLDER)
          }
          size='large'
          disabled={
            !props.flowControl.otpRequest.otpRequestSuccessfull ||
            props.flowControl.otpValidation.otpValidationInProcess ||
            props.flowControl.otpValidation.otpValidationSuccessfull
          }
          onBlur={props.processOnInputBlur}
          {...props.isMobile && {
            addonAfter: (
              <AddonButton
                nextItemFocus={props.onOTPOkClick}
                isValid={props.formValues.otpCode.isValid}
                value={props.formValues.otpCode.value as string}
                loading={props.flowControl.otpValidation.otpValidationInProcess}
              />
            ),
          }}
        />
      )}
      {getOTPErrorMessage()}
      <CustomSuccess visible={props.otpChoiceComplete} message={intl.formatMessage(messages.OTP_CONFIRMED)} />
    </CustomFormItem>
  )
}

export default OtpCodeInput

import { Select as AntSelect } from 'antd'
import { SelectProps } from 'antd/lib/select'
import styled from 'styled-components'

class TagSelect extends AntSelect {
  disableInput() {
    const selects = document.getElementsByClassName(`ant-select-search__field`)
    for (let el of Array.from(selects)) {
      el.setAttribute(`maxlength`, '0')
      el.setAttribute(`readonly`, 'true')
    }
  }
  componentDidMount() {
    this.disableInput()
  }
}

const SelectStyle = Component => styled<SelectProps>(Component)`
  && {
    .ant-select-open {
      .ant-select-selection {
        border-color: ${props => props.theme.branding.primary};
      }
    }
    .ant-select-selection {
      overflow: hidden;
      &:hover,
      &:focus {
        border-color: ${props => props.theme.branding.primary};
      }
      &:focus {
        box-shadow: none;
      }
    }
    .ant-select-selection--multiple {
      overflow: hidden;
      border-color: rgb(233, 233, 233);
      box-shadow: none;
      &:hover,
      &:focus,
      &:active {
        border-color: ${props => props.theme.branding.primary};
      }
      &:focus,
      &:active {
        box-shadow: none;
      }
    }
    &.ant-select-selection__rendered {
      color: ${props => props.theme.branding.primary};
    }
  }
`

const OptionStyle = Component => styled(Component)`
  /* OPTION IS ADDED DYNAMICALLY SO STYLING DOES NOT APPLY TO THIS COMPONENT */
`
export const SelectOption = OptionStyle(AntSelect.Option)

export default SelectStyle(TagSelect)

import React from "react"

// type ViewProps = {
//   title: string | ReactNode
// }

export const FlexItem = props => {
  return (
    <div className="flex-item">
      <strong>{props.title}</strong>
      {props.children}
    </div>
  )
}

export default FlexItem

const CONSTANTS = {
  ENV_QUALIFIER: 'stg',
  ANALYTICS: {
    HEAP_APP_ID: '368424302',
    HOTJAR_APP_ID: '1062722'
  },
  LIC_KEYS: {
    FP: '000291F0-DCFA4815-AE67988E-75BD2E58'
  }
}

export default CONSTANTS

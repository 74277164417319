import React from 'react'
import { message, Spin, Icon } from 'antd' //, Upload
import { StaticUpload } from '~/app/components/StyledUpload'
import { Animated } from 'react-animated-css'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAlt } from '@fortawesome/pro-solid-svg-icons'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import update from 'immutability-helper'
import { RcFile } from 'antd/lib/upload/interface'
import StyledButton from '~/app/components/StyledButton'
import * as enums from '~/typing/KENAI/enums.d.ts'

import CameraModal from './CameraModalSFC'

const messages = {
  ERROR_TYPE: {
    id: 'form.registration.field.camera.facecapture.error.type',
  },
  ERROR_SIZE: {
    id: 'form.registration.field.camera.facecapture.error.size',
  },
  ERROR_ALERT: {
    id: 'form.registration.field.camera.facecapture.error.alert',
  },
  INFO_LOADING: {
    id: 'form.registration.field.camera.facecapture.info.loading',
  },
}

const UploaderWrapper = styled.div`
  display: flex;
  margin-top: -80px;
  .image-uploader {
    margin-right: 5px;
  }
  .cancel-button {
    margin-left: 5px;
  }
  @supports (display: grid) {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 110px 1fr;
    grid-template-areas:
      'face camera'
      'face cancel';
    .image-uploader {
      grid-area: face;
    }
    .camera-button {
      grid-area: camera;
    }
    .cancel-button {
      grid-area: cancel;
      margin-left: 0px;
    }
    .camera-button,
    .cancel-button {
      button {
        width: 100%;
        height: 100%;
      }
    }
  }
`

const UploaderContent = styled.div`
  position: relative;
  .placeholder-image,
  .placeholder-icon {
    position: absolute;
    width: 110px;
    height: 110px;
    left: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .placeholder-icon {
    height: 47px;
    svg {
      font-size: 7rem;
      opacity: 0.1;
      margin-top: -24px;
    }
  }
`

const getBase64 = (img): Promise<string | ArrayBuffer | null> => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result))
    reader.readAsDataURL(img)
  })
}

interface CompProps extends WrappedComponentProps {
  isFaceValid: boolean
  displayFace: string
  validateFace: (faceB64: string, captureType: enums.FACE_CAPTURE_TYPE) => void
  faceIsBeingValidated: boolean
  acceptCapturedFace: (faceB64: string) => void
  changeCapturedFace: () => void
  onToggleFaceData: () => void
}

interface State {
  isCameraVisible: boolean
  cameraFaceThatIsBeingValidated: string
  isCameraLoaded: boolean
}

class FaceCapture extends React.Component<CompProps, State> {
  _cameraRef: any
  constructor(props: CompProps) {
    super(props)
    this.state = {
      isCameraVisible: false,
      cameraFaceThatIsBeingValidated: '',
      isCameraLoaded: false,
    }
  }

  _onToggleCamera = () => {
    this.setState(
      update(this.state, {
        isCameraVisible: {
          $set: !this.state.isCameraVisible,
        },
        isCameraLoaded: { $set: false },
        ...(!this.state.isCameraVisible &&
          this.props.displayFace.length > 0 && {
            cameraFaceThatIsBeingValidated: {
              $set: this.props.displayFace,
            },
          }),
      })
    )
  }

  _onSetCameraLoaded = () => {
    this.setState(
      update(this.state, {
        isCameraLoaded: { $set: true },
      })
    )
  }

  _beforeUpload = (file: RcFile, FileList: RcFile[]): boolean | PromiseLike<any> => {
    const isVALIDType = ['image/jpg', 'image/jpeg', 'image/png'].indexOf(file.type) > -1
    if (!isVALIDType) {
      message.error(this.props.intl.formatMessage(messages.ERROR_TYPE))
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
      message.error(this.props.intl.formatMessage(messages.ERROR_SIZE))
    }
    return isVALIDType && isLt2M
  }

  _proccessValidateUploaderFace = async ({ onSuccess, onError, file }) => {
    try {
      const uploadImageUri = await getBase64(file)
      if (this.props.validateFace) {
        this.props.validateFace(uploadImageUri as string, enums.FACE_CAPTURE_TYPE.UPLOAD)
        onSuccess()
      }
    } catch (e) {
      alert(this.props.intl.formatMessage(messages.ERROR_ALERT)) //todo replace with rjs - possibly call error handler
    }
  }

  _proccessValidateCameraFace = () => {
    if (this._cameraRef.state.hasUserMedia) {
      const checkB64 = this._cameraRef.getScreenshot()
      if (checkB64) {
        this.setState(
          update(this.state, {
            cameraFaceThatIsBeingValidated: {
              $set: checkB64 as string,
            },
          })
        )
        this.props.validateFace(checkB64 as string, enums.FACE_CAPTURE_TYPE.CAMERA)
      } else {
        message.info(this.props.intl.formatMessage(messages.INFO_LOADING))
      }
    } else {
      message.info(this.props.intl.formatMessage(messages.INFO_LOADING))
    }
  }

  _setCameraRef = (ref: any) => {
    this._cameraRef = ref
  }

  _acceptCapturedFace = () => {
    this.props.acceptCapturedFace(this.state.cameraFaceThatIsBeingValidated)
    this._onToggleCamera()
  }

  render() {
    const renderCamera = () => {
      return (
        <CameraModal
          onCloseModal={this._onToggleCamera}
          checkCapturedFace={this._proccessValidateCameraFace}
          faceIsBeingValidated={this.props.faceIsBeingValidated}
          setCameraRef={this._setCameraRef}
          cameraFaceThatIsBeingValidated={this.state.cameraFaceThatIsBeingValidated}
          isFaceValid={this.props.isFaceValid}
          acceptCapturedFace={this._acceptCapturedFace}
          changeCapturedFace={this.props.changeCapturedFace}
          setCameraLoaded={this._onSetCameraLoaded}
          isCameraLoaded={this.state.isCameraLoaded}
        />
      )
    }
    return (
      <Spin spinning={this.props.faceIsBeingValidated && !this.state.isCameraVisible}>
        <UploaderWrapper>
          <StaticUpload disabled={true}>
            <UploaderContent>
              <Animated
                className='placeholder-image'
                animationIn='fadeIn'
                animationOut='fadeOut'
                isVisible={this.props.displayFace.length > 0 && this.props.isFaceValid}
              >
                <img src={this.props.displayFace} alt='avatar' className='face-image' />
              </Animated>
              <Animated className='placeholder-icon' animationIn='fadeIn' animationOut='fadeOut' isVisible={!this.props.isFaceValid}>
                <FontAwesomeIcon icon={faUserAlt} />
              </Animated>
            </UploaderContent>
          </StaticUpload>

          {/* <Upload
            name="faceImage"
            showUploadList={false}
            accept="image/jpg, image/jpeg, image/png"
            beforeUpload={this._beforeUpload}
            customRequest={this._proccessValidateUploaderFace}
            className="upload-button"
          >
            <StyledButton type="primary" size="large">
              <FontAwesomeIcon icon={faFileUpload} size="2x" />
              Upload Photo
            </StyledButton>
          </Upload> */}
          <div className='camera-button'>
            <StyledButton type='primary' onClick={this._onToggleCamera} size='large'>
              <Icon type='camera' theme='filled' />
              <FormattedMessage id='form.registration.field.camera.facecapture.button.selfie' />
            </StyledButton>
          </div>
          <div className='cancel-button'>
            <StyledButton type='danger' ghost={true} onClick={this.props.onToggleFaceData} size='large' icon='close'>
              <FormattedMessage id='global.text.cancel' />
            </StyledButton>
          </div>
          {this.state.isCameraVisible && renderCamera()}
        </UploaderWrapper>
      </Spin>
    )
  }
}

export default injectIntl(FaceCapture)

import API from '~/API'
import { ActionCreator } from '~/sagas/saga-action-creator'
import { ActionCreators as RegistrationStoreActionCreators, getRegistrationInstanceState } from '~/store/registrationinstance'
import { ActionCreators as ProcessInstanceActionCreators, getProcessInstanceState } from '~/store/processinstance'
import translate from '~/translations/translate'
import * as enums from '~/typing/KENAI/enums.d.ts'
import * as interfaces from '~/typing/KENAI/interfaces.d.ts'
import { all, call, delay, fork, put, select, takeLeading } from 'redux-saga/effects'
import { processTokenError } from '../registrationmetadata'
const messages = {
  ERROR_OTP_GENERIC: {
    id: 'saga.registrationProcessing.error.otp.generic',
  },
  ERROR_OTP_TOKEN: {
    id: 'saga.registrationProcessing.error.otp.token',
  },
  ERROR_OTP_EXPIRED: {
    id: 'saga.registrationProcessing.error.otp.expired',
  },
  ERROR_OTP_PROCESSED: {
    id: 'saga.registrationProcessing.error.otp.processed',
  },
  ERROR_OTP_UNDEFINED: {
    id: 'saga.registrationProcessing.error.otp.undefined',
  },
  ERROR_FACE_NOTDETECTED: {
    id: 'saga.registrationProcessing.error.face.notDetected',
  },
  ERROR_FACE_MULTIPLEFACES: {
    id: 'saga.registrationProcessing.error.face.multipleFaces',
  },
  ERROR_FACE_FACEMATCHED: {
    id: 'saga.registrationProcessing.error.face.faceMatched',
  },
  ERROR_FACE_MULTIMATCHED: {
    id: 'saga.registrationProcessing.error.face.faceMultiMatched',
  },
  ERROR_OTP_VAL_PHONEINUSE: {
    id: 'saga.registrationProcessing.error.otpValidation.phoneNumberUsed',
  },
  ERROR_OTP_VAL_EXPIRED: {
    id: 'saga.registrationProcessing.error.otpValidation.codeExpired',
  },
  ERROR_OTP_VAL_INCORRECTCODE: {
    id: 'saga.registrationProcessing.error.otpValidation.incorrectCode',
  },
  ERROR_SUBMIT_PROFILE_ALREADY_EXISTS: {
    id: 'saga.registrationProcessing.error.submission.profileExists',
  },
}

export const ActionCreators = {
  SagaRequestOTPCode: new ActionCreator<'SagaRequestOTPCode', { phoneNumber: string; channel?: 'sms' | 'whatsapp' }>('SagaRequestOTPCode'),
  SagaValidateOTPCode: new ActionCreator<'SagaValidateOTPCode', { phoneNumber: string; otpCode: string }>('SagaValidateOTPCode'),
  SagaValidateFace: new ActionCreator<'SagaValidateFace', string>('SagaValidateFace'),
  SagaSubmitProfile: new ActionCreator<
    'SagaSubmitProfile',
    {
      firstName: string
      lastName: string
      email: string
      company: string
      submittedWithFace: boolean
      archiveExisting: boolean
      inviteOnlyFieldValues: object
      creationEventTiming: interfaces.EventTiming
      personalIdentificationNr?: string
      parkingData?: object
      inductionData?: object
      checkInFieldValues?: object
      selectedHost?: object
      phoneNumber?: string
    }
  >('SagaSubmitProfile'),
  SagaConfirmLocalProfile: new ActionCreator<'SagaConfirmLocalProfile', void>('SagaConfirmLocalProfile'),
  SagaSubmitUpdatePostRegistrationCompletion: new ActionCreator<
    'SagaSubmitUpdatePostRegistrationCompletion',
    {
      inductionData?: object
      parkingData?: object
      checkInFieldValues?: object
      submittedWithFace?: boolean
      selectedHost?: object
    }
  >('SagaSubmitUpdatePostRegistrationCompletion'),
  SagaRegistrationSearchHost: new ActionCreator<
    'SagaRegistrationSearchHost',
    {
      searchString: string
      cb: any
    }
  >('SagaRegistrationSearchHost'),
}

export type Action = typeof ActionCreators[keyof typeof ActionCreators]

const GENERIC_ERROR_MSG = translate(messages.ERROR_OTP_GENERIC)

const getFaceErrorMessage = (type): string => {
  if (type === 'FACE_NOT_DETECTED') {
    return translate(messages.ERROR_FACE_NOTDETECTED)
  } else if (type === 'MULTIPLE_FACES_IN_FRAME') {
    return translate(messages.ERROR_FACE_MULTIPLEFACES)
  } else if (type === 'FACE_MATCHED') {
    return translate(messages.ERROR_FACE_FACEMATCHED)
  } else if (type === 'FACE_MULTI_MATCHED') {
    return translate(messages.ERROR_FACE_MULTIMATCHED)
  } else {
    return GENERIC_ERROR_MSG
  }
}

function* processSagaRequestOTPCode(action: typeof ActionCreators['SagaRequestOTPCode']) {
  try {
    const { phoneNumber, channel = 'sms' } = action.payload
    if (phoneNumber) {
      const processInstance = yield select(getProcessInstanceState)
      const otpDetails = yield call([API, API.requestOTP], processInstance.inviteToken, phoneNumber, channel)
      if (otpDetails.key === 'OPERATION_PROCESSED') {
        yield put(
          RegistrationStoreActionCreators.StoreSetOTPInstanceCode.create({
            otpInstanceCode: otpDetails.otpInstance,
            otpRequestErrorMessage: undefined,
          })
        )
      } else {
        if (otpDetails.key === 'TOKEN_ERROR') {
          yield call(processTokenError, otpDetails.key, otpDetails.type, !!processInstance.registrationEntity.sourceDeviceToken)
        } else {
          throw Object.assign({ message: GENERIC_ERROR_MSG, code: 999 })
        }
      }
    }
  } catch (e) {
    let errorMessage = e && e.message ? e.message : GENERIC_ERROR_MSG
    if (errorMessage === 'Failed to fetch') {
      errorMessage = GENERIC_ERROR_MSG
    }
    yield put(
      RegistrationStoreActionCreators.StoreSetOTPInstanceCode.create({
        otpInstanceCode: '',
        otpRequestErrorMessage: errorMessage,
      })
    )
    // if (!backoff) {
    //   yield call(processSagaRequestOTPCode, action, 100)
    // } else {
    //   if (backoff && backoff > 2500) {
    //     let errorMessage = e && e.message ? e.message : GENERIC_ERROR_MSG
    //     if (errorMessage === 'Failed to fetch') {
    //       errorMessage = GENERIC_ERROR_MSG
    //     }
    //     yield put(
    //       RegistrationStoreActionCreators.StoreSetOTPInstanceCode.create({
    //         otpInstanceCode: '',
    //         otpRequestErrorMessage: errorMessage,
    //       })
    //     )
    //   } else {
    //     yield call(delay, backoff, true)
    //     yield call(processSagaRequestOTPCode, action, backoff * 5)
    //   }
    // }
  }
}

function* processSagaValidateOTPCode(action: Action, backoff?: number) {
  try {
    const { phoneNumber, otpCode } = action.payload as {
      phoneNumber: string
      otpCode: string
    }
    if (phoneNumber && otpCode) {
      const processInstance = yield select(getProcessInstanceState)
      const registrationInstance = yield select(getRegistrationInstanceState)
      const otpValidation = yield call(
        [API, API.validateOTP],
        processInstance.inviteToken,
        phoneNumber,
        registrationInstance.otpInstanceCode,
        otpCode
      )
      if (otpValidation.key === 'OPERATION_PROCESSED') {
        if (otpValidation.shouldCreateNewProfile) {
          //this is a phone number change - we need to set all local state to go into a new profile create + archive existing flow
          yield put(
            ProcessInstanceActionCreators.StoreSetPostInitialRegistrationFlowAvailability.create({
              isPostInitialRegistration: false,
              tokenHasBeenPreviouslyProcessed: false,
              tokenPhoneNumber: '',
              completeInduction: {
                available: false,
                previousData: undefined,
              },
              updateParking: {
                available: false,
              },
              captureCheckInFields: {
                available: false,
              },
            })
          )
        }
        yield put(
          RegistrationStoreActionCreators.StoreSetNumberValid.create({
            message: undefined,
            type: undefined,
            hasExistingLocalProfile: otpValidation.hasExistingLocalProfile,
            hasActiveParkingBookingOnThisToken: otpValidation.hasActiveParkingBookingOnThisToken,
            hasValidInduction: otpValidation.hasValidInduction,
            globalFirstName: otpValidation.globalFirstName,
            globalLastName: otpValidation.globalLastName,
            prePopulatedFieldValues: otpValidation.prePopulatedFieldValues ? otpValidation.prePopulatedFieldValues : {},
          })
        )
      } else {
        if (otpValidation.key === 'TOKEN_ERROR') {
          yield call(processTokenError, otpValidation.key, otpValidation.type, !!processInstance.registrationEntity.sourceDeviceToken)
        } else {
          let message = GENERIC_ERROR_MSG
          if (otpValidation.body && otpValidation.body.key === 'OTP_ERROR') {
            if (otpValidation.body && otpValidation.body.error === 'EXPIRED') {
              message = translate(messages.ERROR_OTP_VAL_EXPIRED)
            } else if (otpValidation.body && (otpValidation.body.error === 'INVALID_OTPCODE' || otpValidation.body.error === 'INVALID')) {
              message = translate(messages.ERROR_OTP_VAL_INCORRECTCODE)
            }
          }
          throw Object.assign({ message: message, code: 999 })
        }
      }
    }
  } catch (e) {
    if (!backoff) {
      yield call(processSagaValidateOTPCode, action, 100)
    } else {
      if (backoff && backoff > 2500) {
        let errorMessage = e && e.message ? e.message : GENERIC_ERROR_MSG
        if (errorMessage === 'Failed to fetch') {
          errorMessage = GENERIC_ERROR_MSG
        }
        yield put(
          RegistrationStoreActionCreators.StoreSetNumberValid.create({
            message: errorMessage,
            type: enums.OTP_VALIDATION_ERRORS.TECHNICAL_ERROR,
            hasExistingLocalProfile: false,
            hasActiveParkingBookingOnThisToken: false,
            hasValidInduction: false,
            globalFirstName: '',
            globalLastName: '',
            prePopulatedFieldValues: {},
          })
        )
      } else {
        yield call(delay, backoff, true)
        yield call(processSagaValidateOTPCode, action, backoff * 5)
      }
    }
  }
  // }
}

function* processSagaValidateFace(action: Action, backoff?: number) {
  try {
    const faceB64 = action.payload as string
    if (faceB64) {
      const registrationInstance = yield select(getRegistrationInstanceState)
      const processInstance = yield select(getProcessInstanceState)
      const faceValidation = yield call([API, API.validateFace], processInstance.inviteToken, registrationInstance.otpInstanceCode, faceB64)
      if (faceValidation.key === 'OPERATION_PROCESSED') {
        if (faceValidation.validationResult === 'FACE_CAN_BE_PROCESSED') {
          yield put(
            RegistrationStoreActionCreators.StoreSetFaceData.create({
              message: undefined,
              type: undefined,
              faceB64: faceB64,
            })
          )
        } else {
          if (faceValidation.validationResult === 'FACE_NOT_DETECTED') {
            yield put(
              RegistrationStoreActionCreators.StoreSetFaceData.create({
                message: getFaceErrorMessage('FACE_NOT_DETECTED'),
                type: enums.FACE_VALIDATION_ERRORS.FACE_NOT_DETECTED,
                faceB64: '',
              })
            )
          } else if (faceValidation.validationResult === 'MULTIPLE_FACES_IN_FRAME') {
            yield put(
              RegistrationStoreActionCreators.StoreSetFaceData.create({
                message: getFaceErrorMessage('MULTIPLE_FACES_IN_FRAME'),
                type: enums.FACE_VALIDATION_ERRORS.MULTIPLE_FACES_IN_FRAME,
                faceB64: '',
              })
            )
          } else if (faceValidation.validationResult === 'FACE_MATCHED') {
            yield put(
              RegistrationStoreActionCreators.StoreSetFaceData.create({
                message: getFaceErrorMessage('FACE_MATCHED'),
                type: enums.FACE_VALIDATION_ERRORS.FACE_MATCHED,
                faceB64: '',
              })
            )
          } else if (faceValidation.validationResult === 'FACE_MULTI_MATCHED') {
            yield put(
              RegistrationStoreActionCreators.StoreSetFaceData.create({
                message: getFaceErrorMessage('FACE_MULTI_MATCHED'),
                type: enums.FACE_VALIDATION_ERRORS.FACE_MULTI_MATCHED,
                faceB64: '',
              })
            )
          } else {
            yield put(
              RegistrationStoreActionCreators.StoreSetFaceData.create({
                message: getFaceErrorMessage(''),
                type: enums.FACE_VALIDATION_ERRORS.GENERIC_ERROR,
                faceB64: '',
              })
            )
          }
        }
      } else {
        if (faceValidation.key === 'TOKEN_ERROR') {
          yield call(processTokenError, faceValidation.key, faceValidation.type, !!processInstance.registrationEntity.sourceDeviceToken)
        } else {
          throw Object.assign({ message: GENERIC_ERROR_MSG, code: 999 })
        }
      }
    }
  } catch (e) {
    if (!backoff) {
      yield call(processSagaValidateFace, action, 100)
    } else {
      if (backoff && backoff > 2500) {
        let errorMessage = e && e.message ? e.message : GENERIC_ERROR_MSG
        if (errorMessage === 'Failed to fetch') {
          errorMessage = GENERIC_ERROR_MSG
        }
        yield put(
          RegistrationStoreActionCreators.StoreSetFaceData.create({
            message: errorMessage,
            type: enums.FACE_VALIDATION_ERRORS.GENERIC_ERROR,
            faceB64: '',
          })
        )
      } else {
        yield call(delay, backoff, true)
        yield call(processSagaValidateFace, action, backoff * 5)
      }
    }
  }
}

function* processSagaSubmitProfile(action: Action, backoff?: number, inProcessProfileId?: string) {
  try {
    const {
      firstName,
      lastName,
      email,
      company,
      submittedWithFace,
      archiveExisting,
      inviteOnlyFieldValues,
      creationEventTiming,
      personalIdentificationNr,
      parkingData,
      inductionData,
      checkInFieldValues,
      selectedHost,
      phoneNumber,
    } = action.payload as {
      firstName: string
      lastName: string
      email: string
      company: string
      submittedWithFace: boolean
      archiveExisting: boolean
      inviteOnlyFieldValues: object
      creationEventTiming: interfaces.EventTiming
      personalIdentificationNr?: string
      parkingData?: object
      inductionData?: object
      checkInFieldValues?: object
      selectedHost?: object
      phoneNumber?: string
    }
    if (firstName && lastName) {
      const registrationInstance = yield select(getRegistrationInstanceState)
      const processInstance = yield select(getProcessInstanceState)
      const profileSubmission = yield call(
        [API, API.submitProfile],
        processInstance.inviteToken,
        registrationInstance.otpInstanceCode,
        firstName,
        lastName,
        email,
        company,
        submittedWithFace,
        archiveExisting,
        inviteOnlyFieldValues,
        creationEventTiming,
        personalIdentificationNr,
        parkingData,
        inductionData,
        checkInFieldValues,
        inProcessProfileId,
        selectedHost,
        phoneNumber
      )
      if (profileSubmission.key === 'OPERATION_PROCESSED') {
        if (profileSubmission.submissionResult === 'PROFILE_SUBMITTED') {
          yield put(
            RegistrationStoreActionCreators.StoreSetProfileSubmissionData.create({
              message: undefined,
              type: undefined,
              triggeredAlert: profileSubmission.triggeredAlert,
              ...(!processInstance.registrationEntity.sourceDeviceTokenUnverifiedProcessing
                ? { accessPassDetails: profileSubmission.accessPassDetails }
                : { accessPassDetails: undefined }),
            })
          )
        } else {
          if (profileSubmission.submissionResult === 'PROFILE_ALREADY_EXISTS') {
            yield put(
              RegistrationStoreActionCreators.StoreSetProfileSubmissionData.create({
                message: translate(messages.ERROR_SUBMIT_PROFILE_ALREADY_EXISTS),
                type: enums.PROFILE_SUBMISSION_ERRORS.PROFILE_ALREADY_EXISTS,
                triggeredAlert: profileSubmission.triggeredAlert,
                accessPassDetails: profileSubmission.accessPassDetails,
              })
            )
          } else {
            throw Object.assign({
              message: GENERIC_ERROR_MSG,
              code: 999,
              inProcessProfileId:
                profileSubmission && profileSubmission.inProcessProfileId ? profileSubmission.inProcessProfileId : undefined,
            })
          }
        }
      } else {
        if (profileSubmission.key === 'TOKEN_ERROR') {
          yield call(
            processTokenError,
            profileSubmission.key,
            profileSubmission.type,
            !!processInstance.registrationEntity.sourceDeviceToken
          )
        } else {
          throw Object.assign({
            message: GENERIC_ERROR_MSG,
            code: 999,
            inProcessProfileId:
              profileSubmission && profileSubmission.inProcessProfileId ? profileSubmission.inProcessProfileId : undefined,
          })
        }
      }
    }
  } catch (e) {
    if (!backoff) {
      yield call(processSagaSubmitProfile, action, 100, e ? e.inProcessProfileId : undefined)
    } else {
      if (backoff && backoff > 2500) {
        let errorMessage = e && e.message ? e.message : GENERIC_ERROR_MSG
        if (errorMessage === 'Failed to fetch') {
          errorMessage = GENERIC_ERROR_MSG
        }
        yield put(
          RegistrationStoreActionCreators.StoreSetProfileSubmissionData.create({
            message: errorMessage,
            type: enums.PROFILE_SUBMISSION_ERRORS.GENERIC_ERROR,
          })
        )
      } else {
        yield call(delay, backoff, true)
        yield call(processSagaSubmitProfile, action, backoff * 5, e ? e.inProcessProfileId : undefined)
      }
    }
  }
}

function* processSagaConfirmLocalProfile(action: Action, backoff?: number) {
  try {
    const registrationInstance = yield select(getRegistrationInstanceState)
    const processInstance = yield select(getProcessInstanceState)
    const profileConfirmation = yield call(
      [API, API.confirmLocalProfile],
      processInstance.inviteToken,
      registrationInstance.otpInstanceCode
    )
    if (profileConfirmation.key !== 'OPERATION_PROCESSED') {
      if (profileConfirmation.key === 'TOKEN_ERROR') {
        yield call(
          processTokenError,
          profileConfirmation.key,
          profileConfirmation.type,
          !!processInstance.registrationEntity.sourceDeviceToken
        )
      } else {
        throw Object.assign({ message: GENERIC_ERROR_MSG, code: 999 })
      }
    } else {
      const registrationEntity: interfaces.REGISTRATION_ENTITY = {
        ...profileConfirmation.appStateUpdateAfterProfileConfirmation.configData,
        hasBeenLoaded: true,
      }
      yield put(ProcessInstanceActionCreators.StoreSetRegistrationEntity.create(registrationEntity))
      yield put(
        RegistrationStoreActionCreators.StoreSetLocalProfileConfirmationData.create({
          hasValidInduction: profileConfirmation.appStateUpdateAfterProfileConfirmation.confirmedProfileData.hasValidInduction,
          prePopulatedFieldValues: profileConfirmation.appStateUpdateAfterProfileConfirmation.confirmedProfileData.prePopulatedFieldValues,
        })
      )
    }
  } catch (e) {
    if (!backoff) {
      yield call(processSagaConfirmLocalProfile, action, 100)
    } else {
      if (backoff && backoff > 2500) {
        let errorMessage = e && e.message ? e.message : GENERIC_ERROR_MSG
        if (errorMessage === 'Failed to fetch') {
          errorMessage = GENERIC_ERROR_MSG
        }
        //no further handling
      } else {
        yield call(delay, backoff, true)
        yield call(processSagaConfirmLocalProfile, action, backoff * 5)
      }
    }
  }
}

function* processSagaSubmitUpdatePostRegistrationCompletion(action: Action, backoff?: number) {
  try {
    const { inductionData, parkingData, checkInFieldValues, submittedWithFace, selectedHost } = action.payload as {
      inductionData: object
      parkingData: object
      checkInFieldValues: object
      submittedWithFace: boolean
      selectedHost?: object
    }
    const registrationInstance = yield select(getRegistrationInstanceState)
    const processInstance = yield select(getProcessInstanceState)
    const updateSubmission = yield call(
      [API, API.submitUpdatePostRegistrationCompletion],
      processInstance.inviteToken,
      registrationInstance.otpInstanceCode,
      inductionData,
      parkingData,
      checkInFieldValues,
      selectedHost,
      submittedWithFace
    )
    if (updateSubmission.key === 'OPERATION_PROCESSED') {
      yield put(
        RegistrationStoreActionCreators.StoreSetProfileSubmissionData.create({
          message: undefined,
          type: undefined,
          triggeredAlert: updateSubmission.triggeredAlert,
          accessPassDetails: updateSubmission.accessPassDetails,
        })
      )
    } else {
      if (updateSubmission.key === 'TOKEN_ERROR') {
        yield call(processTokenError, updateSubmission.key, updateSubmission.type, !!processInstance.registrationEntity.sourceDeviceToken)
      } else {
        throw Object.assign({ message: GENERIC_ERROR_MSG, code: 999 })
      }
    }
  } catch (e) {
    if (!backoff) {
      yield call(processSagaSubmitUpdatePostRegistrationCompletion, action, 100)
    } else {
      if (backoff && backoff > 2500) {
        let errorMessage = e && e.message ? e.message : GENERIC_ERROR_MSG
        if (errorMessage === 'Failed to fetch') {
          errorMessage = GENERIC_ERROR_MSG
        }
        yield put(
          RegistrationStoreActionCreators.StoreSetProfileSubmissionData.create({
            message: errorMessage,
            type: enums.PROFILE_SUBMISSION_ERRORS.GENERIC_ERROR,
          })
        )
      } else {
        yield call(delay, backoff, true)
        yield call(processSagaSubmitUpdatePostRegistrationCompletion, action, backoff * 5)
      }
    }
  }
}

function* processSagaRegistrationSearchHost(action: Action) {
  const { searchString, cb } = action.payload as {
    searchString: string
    cb: any
  }
  try {
    const processInstance = yield select(getProcessInstanceState)
    const registrationInstance = yield select(getRegistrationInstanceState)

    const hostSearchResults = yield call(
      [API, API.searchRegistrationHost],
      processInstance.inviteToken,
      registrationInstance.otpInstanceCode,
      searchString
    )
    const notOnListEntry = {
      EntityHierarchy: processInstance.registrationEntity.EntityHierarchy,
      uniqueAttributeValue: '00000000',
      email: '00000000',
      phone_number: '00000000',
      name: 'Not on list',
    }
    if (hostSearchResults.key === 'OPERATION_PROCESSED') {
      hostSearchResults.searchResult.push(notOnListEntry)
      cb({
        succesfull: true,
        hostSearchResults: hostSearchResults.searchResult,
      })
    } else {
      if (hostSearchResults.key === 'TOKEN_ERROR') {
        yield call(processTokenError, hostSearchResults.key, hostSearchResults.type, !!processInstance.registrationEntity.sourceDeviceToken)
      } else {
        throw Object.assign({ message: GENERIC_ERROR_MSG, code: 999 })
      }
    }
  } catch (e) {
    let errorMessage = e && e.message ? e.message : GENERIC_ERROR_MSG
    if (errorMessage === 'Failed to fetch') {
      errorMessage = GENERIC_ERROR_MSG
    }
    cb({
      succesfull: false,
      message: errorMessage,
      hostSearchResults: undefined,
    })
  }
}

function* watchLookupsSagas() {
  yield takeLeading(ActionCreators.SagaRequestOTPCode.type, processSagaRequestOTPCode)
  yield takeLeading(ActionCreators.SagaValidateOTPCode.type, processSagaValidateOTPCode)
  yield takeLeading(ActionCreators.SagaValidateFace.type, processSagaValidateFace)
  yield takeLeading(ActionCreators.SagaSubmitProfile.type, processSagaSubmitProfile)
  yield takeLeading(ActionCreators.SagaConfirmLocalProfile.type, processSagaConfirmLocalProfile)
  yield takeLeading(ActionCreators.SagaSubmitUpdatePostRegistrationCompletion.type, processSagaSubmitUpdatePostRegistrationCompletion)
  yield takeLeading(ActionCreators.SagaRegistrationSearchHost.type, processSagaRegistrationSearchHost)
  yield null
}

export default function* lookupsSagas() {
  yield all([fork(watchLookupsSagas)])
}

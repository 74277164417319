import React, { useMemo } from 'react'
import { Form, Badge, Popover, Popconfirm } from 'antd'
import { Animated } from 'react-animated-css'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { chain } from 'lodash'
import * as interfaces from '~/typing/KENAI/interfaces'
import {
  FACE_CAPTURE_TYPE as FaceCaptureType,
  POST_INITIAL_FLOW_SELECTION_STATE as PostInitialFlowSelectionState,
} from '~/typing/KENAI/enums'
import StyledButton from '~/app/components/StyledButton'
import { ExtendedWrappedFormUtils } from '~/typing/vendor/antd-form-untyped-public'

import { FormValues, FlowControl, DataSources } from '../component'
import CustomFormItem from '~/app/components/SharedFormComponents/CustomFormItem'
import FormLabel from '~/app/components/SharedFormComponents/FormLabelSFC'
import FaceCapture from './FaceCapture'
import PhoneNumberInput from './InputComponents/PhoneNumberInputSFC'
import OtpCodeInput from './InputComponents/OtpCodeInputSFC'
import DefaultInput from './InputComponents/DefaultInputSFC'
import NumberInput from './InputComponents/NumberInputSFC'
import SingleSelection from './InputComponents/SingleSelectionSFC'
import MultiSelection from './InputComponents/MultiSelectionSFC'
import SliderInput from './InputComponents/SliderSFC'
import Video from './inductionVideo'
import InductionQuestions from './inductionQuestions'
import translate from '~/translations/translate'
import EnterButtonSFC from '~/app/components/EnterButton'
import ParkingLegals from './ParkingLegals'
import StyledSwitch from '../../StyledSwitch'
import { omit } from 'rc-mentions/lib/util'
import PostFlowSelectionSFC from './InputComponents/PostFlowSelectionSFC'
import HostSearch from './InputComponents/HostSearchSFC'
import { SelectValue } from 'antd/lib/select'
import EmailInputSFC from './InputComponents/EmailInputSFC'
import filterProcessor from '~/utils/filterProcessor'

const messages = {
  INPUT_FIRSTNAME_REQUIRED: {
    id: 'form.registration.input.firstName.requiredText',
  },
  INPUT_FIRSTNAME_PLACEHOLDER: {
    id: 'form.registration.input.firstName.placeholder',
  },
  INPUT_LASTNAME_REQUIRED: {
    id: 'form.registration.input.lastName.requiredText',
  },
  INPUT_LASTNAME_PLACEHOLDER: {
    id: 'form.registration.input.lastName.placeholder',
  },
  INPUT_EMAIL_REQUIRED: {
    id: 'form.registration.input.email.requiredText',
  },
  INPUT_EMAIL_PLACEHOLDER: {
    id: 'form.registration.input.email.placeholder',
  },
  INPUT_EMAIL_RULE1: {
    id: 'form.registration.input.email.rule.1',
  },
  INPUT_COMPANY_REQUIRED: {
    id: 'form.registration.input.company.requiredText',
  },
  INPUT_COMPANY_PLACEHOLDER: {
    id: 'form.registration.input.company.placeholder',
  },
  INPUT_ID_REQUIRED: {
    id: 'form.registration.input.id.requiredText',
  },
  INPUT_ID_PLACEHOLDER: {
    id: 'form.registration.input.id.placeholder',
  },
  INPUT_DIET_PLACEHOLDER: {
    id: 'form.registration.input.diet.placeholder',
  },
  INPUT_INDUCTION_COMPLETE_SUBLABEL: {
    id: 'form.registration.field.induction.subLabel.completed',
  },
  INPUT_INDUCTION_SKIPPED_SUBLABEL: {
    id: 'form.registration.field.induction.subLabel.skipped',
  },
  INPUT_INDUCTION_INCOMPLETE_SUBLABEL: {
    id: 'form.registration.field.induction.subLabel.incomplete',
  },
}

const StatusSpan = styled.span`
  line-height: 6px;
  .ant-badge {
    margin-left: 5px;
    .ant-badge-status-dot {
      top: 0px;
      margin-right: 2px;
    }
  }
`

interface FormProps {
  onFieldsChange: (changedFields: object) => void
  onNumberOkClick: (channel?: 'sms' | 'whatsapp') => void
  onOTPOkClick: () => void
  onFieldBlur: (activeSection: any) => void
  onToggleFaceData: () => void
  onToggleSkipFaceData: () => void
  onToggleParkingData: () => void
  onToggleSkipParkingData: () => void
  onToggleReEnabledParkingData: () => void
  onToggleParkingTerms: () => void
  validateFace: (faceBase64: string, captureType: FaceCaptureType) => void
  acceptCapturedFace: (faceBase64: string) => void
  setPhoneNumberRef: (ref: any) => void
  changeCapturedFace: () => void
  onInductionCompleted: () => void
  onGlobalNotMe: () => void
  onGlobalConfirmed: () => void
  handleInductionVideoSkipped: () => void
  handleInductionQuestionsSkipped: () => void
  toggleConfirmInductionVideoSkip: () => void
  toggleConfirmInductionQuestionsSkip: () => void
  onToggleInductionQuestionsProcessing: () => void
  onCompleteInductionQuestions: () => void
  processOnLastFieldCheck: () => void
  togglePostFlow: (selectedPostFlow: PostInitialFlowSelectionState) => void
  onCompletePostFlowSelection: () => void
  onToggleReEnabledInductionQuestions: () => void
  confirmInductionVideoSkipVisible: boolean
  confirmInductionQuestionsSkipVisible: boolean
  formValues: FormValues
  flowControl: FlowControl
  dataSources: DataSources
  isDeviceTokenBased: boolean
  fullpageApi: any
  isMobile: boolean
  className: string
  ref: any
  form: ExtendedWrappedFormUtils
  country: string
  optionalFieldsEnabled: {
    email: boolean
    emailRequired: boolean
    personalIdentificationNr: boolean
    company: boolean
    inductionVideo: boolean
    inductionQuestions: boolean
    hostSearch: boolean
  }
  inviteOnlyFieldEnablement: {
    dietaryRequirements: boolean
  }
  parkingDetails: interfaces.ParkingDetails
  checkInFieldDetails: interfaces.CheckInFieldDetails
  inductionContent: interfaces.InductionContent
  postInitialRegistrationFlowAvailability: interfaces.PostInitialRegistrationFlowAvailability
  inviteCreationFields: interfaces.InviteCreationFields
  hasValidInduction: boolean
  sourceDeviceTokenUnverifiedProcessing: boolean
  onHostSearch: (value: string) => void
  onHostSelect: (value: SelectValue, option: any) => void
  onSkipEmail: () => void
}

const ViewContainer = styled.div`
  overflow: hidden;
  -ms-overflow-style: none;
  max-width: 80vw;
  margin: auto;
  padding: 20px;
  .mobile-device & {
    max-width: 95vw;
    padding: 9px;
  }
`
let debugOn = false
const RegistrationFormSFC: React.FC<FormProps> = props => {
  const customFields: interfaces.CheckInField[] = useMemo(() => {
    const visibleCustomFields: interfaces.CheckInField[] = []
    if (props.checkInFieldDetails) {
      props.checkInFieldDetails.captureFields.forEach(captureField => {
        if (!captureField.availableForConditionInput) {
          const rootCondition = props.checkInFieldDetails.fieldConditions[captureField.id]
          const { isConditionalVisible, isConditionalEditable } = filterProcessor(rootCondition, props.formValues)
          if (isConditionalVisible) {
            visibleCustomFields.push({
              ...captureField,
              readOnly: isConditionalEditable,
            })
          }
        }
      })
    }
    return visibleCustomFields
  }, [props.formValues, props.checkInFieldDetails])
  const { getFieldDecorator } = props.form

  const onTextInputKeyDown = event => {
    //placeholder in case for key 13 handling
  }
  const nextItemFocus = () => {
    props.fullpageApi.moveSectionDown()
    props.processOnLastFieldCheck()
  }
  const processOnPressEnter = (event: React.FormEvent<HTMLInputElement>): void => {
    //can now switch out based on event.currentTarget.id which contains the formElement name
    if (props.formValues[event.currentTarget.id].isValid) {
      // if (props.isMobile) { //TODO - blur for all formats
      try {
        let instance = props.form.getFieldInstance(event.currentTarget.id)
        if (instance && instance.blur && instance.input && instance.input.blur) {
          instance.blur()
          instance.input.blur()
        }
      } catch (e) {
        console.log(e)
        debugger
      }
      // }
      setTimeout(() => {
        props.fullpageApi.moveSectionDown()
        props.processOnLastFieldCheck()
      }, 50) //give the blur a chance to process
    }
  }

  const processOnInputBlur = () => {
    if (!props.isMobile) {
      const section = props.fullpageApi.getActiveSection()
      if (section) {
        props.onFieldBlur(section)
      }
      props.processOnLastFieldCheck()
    }
  }

  let otpChoiceComplete: boolean = false
  let hasConfirmedGlobal: boolean = false
  if (props.flowControl.otpRequest.otpRequestSuccessfull && props.flowControl.otpValidation.otpValidationSuccessfull) {
    if (
      props.flowControl.globalProfileConfirmation.hasGlobalProfile &&
      props.flowControl.globalProfileConfirmation.globalProfileConfirmed
    ) {
      otpChoiceComplete = true
      hasConfirmedGlobal = true
    } else if (
      !props.flowControl.globalProfileConfirmation.hasGlobalProfile ||
      props.flowControl.globalProfileConfirmation.localProfileConfirmed
    ) {
      otpChoiceComplete = true
      hasConfirmedGlobal = false
    } else if (props.sourceDeviceTokenUnverifiedProcessing) {
      otpChoiceComplete = true
      hasConfirmedGlobal = false
    }
  }

  const getFieldNumber = (field: String): string => {
    let baseFieldNumber = props.sourceDeviceTokenUnverifiedProcessing ? 4 : 5
    let increment = 0
    if (field === 'email') {
      increment = 0
    } else if (field === 'company') {
      if (props.optionalFieldsEnabled.email) {
        increment++
      }
    } else if (field === 'personalIdentificationNr') {
      if (props.optionalFieldsEnabled.email) {
        increment++
      }
      if (props.optionalFieldsEnabled.company) {
        increment++
      }
    } else if (field === 'dietaryRequirements') {
      if (props.optionalFieldsEnabled.email) {
        increment++
      }
      if (props.optionalFieldsEnabled.company) {
        increment++
      }
      if (props.optionalFieldsEnabled.personalIdentificationNr) {
        increment++
      }
    } else if (field === 'parkingAvailable') {
      if (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration) {
        if (props.optionalFieldsEnabled.email) {
          increment++
        }
        if (props.optionalFieldsEnabled.company) {
          increment++
        }
        if (props.optionalFieldsEnabled.personalIdentificationNr) {
          increment++
        }
        if (props.inviteOnlyFieldEnablement.dietaryRequirements) {
          increment++
        }
      } else {
        baseFieldNumber = props.sourceDeviceTokenUnverifiedProcessing ? 2 : 3 //starting point for post flow selection
        if (props.flowControl.postInitialFlowSelection.postFlowUserCanSelect) {
          increment++ //include post flow selection
        }
      }
    } else if (field === 'parkingTerms') {
      if (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration) {
        if (props.optionalFieldsEnabled.email) {
          increment++
        }
        if (props.optionalFieldsEnabled.company) {
          increment++
        }
        if (props.optionalFieldsEnabled.personalIdentificationNr) {
          increment++
        }
        if (props.inviteOnlyFieldEnablement.dietaryRequirements) {
          increment++
        }
      } else {
        baseFieldNumber = props.sourceDeviceTokenUnverifiedProcessing ? 2 : 3 //starting point for post flow selection
        if (props.flowControl.postInitialFlowSelection.postFlowUserCanSelect) {
          increment++ //include post flow selection
        }
      }
      if (props.parkingDetails.parkingAvailable) {
        increment++
      }
    } else if (field === 'parkingClassification') {
      if (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration) {
        if (props.optionalFieldsEnabled.email) {
          increment++
        }
        if (props.optionalFieldsEnabled.company) {
          increment++
        }
        if (props.optionalFieldsEnabled.personalIdentificationNr) {
          increment++
        }
        if (props.inviteOnlyFieldEnablement.dietaryRequirements) {
          increment++
        }
      } else {
        baseFieldNumber = props.sourceDeviceTokenUnverifiedProcessing ? 2 : 3 //starting point for post flow selection
        if (props.flowControl.postInitialFlowSelection.postFlowUserCanSelect) {
          increment++ //include post flow selection
        }
      }
      if (props.parkingDetails.parkingAvailable) {
        increment++
        if (props.flowControl.parking.parkingProcessingEnabled) {
          if (props.parkingDetails.hasTerms) {
            increment++
          }
        }
      }
    } else if (field === 'parkingLicensePlate') {
      if (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration) {
        if (props.optionalFieldsEnabled.email) {
          increment++
        }
        if (props.optionalFieldsEnabled.company) {
          increment++
        }
        if (props.optionalFieldsEnabled.personalIdentificationNr) {
          increment++
        }
        if (props.inviteOnlyFieldEnablement.dietaryRequirements) {
          increment++
        }
      } else {
        baseFieldNumber = props.sourceDeviceTokenUnverifiedProcessing ? 2 : 3 //starting point for post flow selection
        if (props.flowControl.postInitialFlowSelection.postFlowUserCanSelect) {
          increment++ //include post flow selection
        }
      }
      if (props.parkingDetails.parkingAvailable) {
        increment++
        if (props.flowControl.parking.parkingProcessingEnabled) {
          if (props.parkingDetails.hasTerms) {
            increment++
          }
          if (props.parkingDetails.availableClassifications && props.parkingDetails.availableClassifications.length > 0) {
            increment++
          }
        }
      }
    } else if (field === 'parkingVehicleMake') {
      if (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration) {
        if (props.optionalFieldsEnabled.email) {
          increment++
        }
        if (props.optionalFieldsEnabled.company) {
          increment++
        }
        if (props.optionalFieldsEnabled.personalIdentificationNr) {
          increment++
        }
        if (props.inviteOnlyFieldEnablement.dietaryRequirements) {
          increment++
        }
      } else {
        baseFieldNumber = props.sourceDeviceTokenUnverifiedProcessing ? 2 : 3 //starting point for post flow selection
        if (props.flowControl.postInitialFlowSelection.postFlowUserCanSelect) {
          increment++ //include post flow selection
        }
      }
      if (props.parkingDetails.parkingAvailable) {
        increment++
        if (props.flowControl.parking.parkingProcessingEnabled) {
          if (props.parkingDetails.hasTerms) {
            increment++
          }
          if ((props.parkingDetails.hasTerms && props.flowControl.parking.parkingTermsAccepted) || !props.parkingDetails.hasTerms) {
            if (props.parkingDetails.captureFields.find(captureField => captureField.fieldTechnicalName === 'parkingLicensePlate')) {
              increment++
            }
          }
          if (props.parkingDetails.availableClassifications && props.parkingDetails.availableClassifications.length > 0) {
            increment++
          }
        }
      }
    } else if (field === 'parkingVehicleModel') {
      if (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration) {
        if (props.optionalFieldsEnabled.email) {
          increment++
        }
        if (props.optionalFieldsEnabled.company) {
          increment++
        }
        if (props.optionalFieldsEnabled.personalIdentificationNr) {
          increment++
        }
        if (props.inviteOnlyFieldEnablement.dietaryRequirements) {
          increment++
        }
      } else {
        baseFieldNumber = props.sourceDeviceTokenUnverifiedProcessing ? 2 : 3 //starting point for post flow selection
        if (props.flowControl.postInitialFlowSelection.postFlowUserCanSelect) {
          increment++ //include post flow selection
        }
      }
      if (props.parkingDetails.parkingAvailable) {
        increment++
        if (props.flowControl.parking.parkingProcessingEnabled) {
          if (props.parkingDetails.hasTerms) {
            increment++
          }
          if ((props.parkingDetails.hasTerms && props.flowControl.parking.parkingTermsAccepted) || !props.parkingDetails.hasTerms) {
            if (props.parkingDetails.captureFields.find(captureField => captureField.fieldTechnicalName === 'parkingLicensePlate')) {
              increment++
            }
            if (props.parkingDetails.captureFields.find(captureField => captureField.fieldTechnicalName === 'parkingVehicleMake')) {
              increment++
            }
          }
          if (props.parkingDetails.availableClassifications && props.parkingDetails.availableClassifications.length > 0) {
            increment++
          }
        }
      }
    } else if (field === 'induction') {
      if (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration) {
        if (props.optionalFieldsEnabled.email) {
          increment++
        }
        if (props.optionalFieldsEnabled.company) {
          increment++
        }
        if (props.optionalFieldsEnabled.personalIdentificationNr) {
          increment++
        }
        if (props.inviteOnlyFieldEnablement.dietaryRequirements) {
          increment++
        }
      } else {
        baseFieldNumber = props.sourceDeviceTokenUnverifiedProcessing ? 2 : 3 //starting point for post flow selection
        if (props.flowControl.postInitialFlowSelection.postFlowUserCanSelect) {
          increment++ //include post flow selection
        }
      }
      if (
        props.parkingDetails.parkingAvailable &&
        (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration ||
          (props.postInitialRegistrationFlowAvailability.isPostInitialRegistration && props.flowControl.postInitialFlowSelection.parking))
      ) {
        increment++
        if (props.flowControl.parking.parkingProcessingEnabled) {
          if (props.parkingDetails.hasTerms) {
            increment++
          }
          if ((props.parkingDetails.hasTerms && props.flowControl.parking.parkingTermsAccepted) || !props.parkingDetails.hasTerms) {
            if (props.parkingDetails.captureFields.find(captureField => captureField.fieldTechnicalName === 'parkingLicensePlate')) {
              increment++
            }
            if (props.parkingDetails.captureFields.find(captureField => captureField.fieldTechnicalName === 'parkingVehicleMake')) {
              increment++
            }
            if (props.parkingDetails.captureFields.find(captureField => captureField.fieldTechnicalName === 'parkingVehicleModel')) {
              increment++
            }
          }
          if (props.parkingDetails.availableClassifications && props.parkingDetails.availableClassifications.length > 0) {
            increment++
          }
        }
      }
    } else if (field === 'camera') {
      if (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration) {
        if (props.optionalFieldsEnabled.email) {
          increment++
        }
        if (props.optionalFieldsEnabled.company) {
          increment++
        }
        if (props.optionalFieldsEnabled.personalIdentificationNr) {
          increment++
        }
        if (props.inviteOnlyFieldEnablement.dietaryRequirements) {
          increment++
        }
      } else {
        baseFieldNumber = props.sourceDeviceTokenUnverifiedProcessing ? 2 : 3 //starting point for post flow selection
        if (props.flowControl.postInitialFlowSelection.postFlowUserCanSelect) {
          increment++ //include post flow selection
        }
      }
      if (props.parkingDetails.parkingAvailable) {
        increment++
        if (props.flowControl.parking.parkingProcessingEnabled) {
          if (props.parkingDetails.hasTerms) {
            increment++
          }
          if ((props.parkingDetails.hasTerms && props.flowControl.parking.parkingTermsAccepted) || !props.parkingDetails.hasTerms) {
            if (props.parkingDetails.captureFields.find(captureField => captureField.fieldTechnicalName === 'parkingLicensePlate')) {
              increment++
            }
            if (props.parkingDetails.captureFields.find(captureField => captureField.fieldTechnicalName === 'parkingVehicleMake')) {
              increment++
            }
            if (props.parkingDetails.captureFields.find(captureField => captureField.fieldTechnicalName === 'parkingVehicleModel')) {
              increment++
            }
          }
          if (props.parkingDetails.availableClassifications && props.parkingDetails.availableClassifications.length > 0) {
            increment++
          }
        }
      }
      if (props.optionalFieldsEnabled.inductionVideo || props.optionalFieldsEnabled.inductionQuestions) {
        increment++
      }
      if (props.checkInFieldDetails && props.checkInFieldDetails.checkInFieldsAvailable === true && customFields) {
        for (let i = 0; i < customFields.length; i++) {
          increment++
        }
      }
    } else if (field === 'hostSearch') {
      if (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration) {
        if (props.optionalFieldsEnabled.email) {
          increment++
        }
        if (props.optionalFieldsEnabled.company) {
          increment++
        }
        if (props.optionalFieldsEnabled.personalIdentificationNr) {
          increment++
        }
        if (props.inviteOnlyFieldEnablement.dietaryRequirements) {
          increment++
        }
      } else {
        baseFieldNumber = props.sourceDeviceTokenUnverifiedProcessing ? 2 : 3 //starting point for post flow selection
        if (props.flowControl.postInitialFlowSelection.postFlowUserCanSelect) {
          increment++ //include post flow selection
        }
      }
      if (props.parkingDetails.parkingAvailable) {
        increment++
        if (props.flowControl.parking.parkingProcessingEnabled) {
          if (props.parkingDetails.hasTerms) {
            increment++
          }
          if ((props.parkingDetails.hasTerms && props.flowControl.parking.parkingTermsAccepted) || !props.parkingDetails.hasTerms) {
            if (props.parkingDetails.captureFields.find(captureField => captureField.fieldTechnicalName === 'parkingLicensePlate')) {
              increment++
            }
            if (props.parkingDetails.captureFields.find(captureField => captureField.fieldTechnicalName === 'parkingVehicleMake')) {
              increment++
            }
            if (props.parkingDetails.captureFields.find(captureField => captureField.fieldTechnicalName === 'parkingVehicleModel')) {
              increment++
            }
          }
          if (props.parkingDetails.availableClassifications && props.parkingDetails.availableClassifications.length > 0) {
            increment++
          }
        }
      }
      if (props.optionalFieldsEnabled.inductionVideo || props.optionalFieldsEnabled.inductionQuestions) {
        increment++
      }
      if (props.checkInFieldDetails && props.checkInFieldDetails.checkInFieldsAvailable === true && customFields) {
        for (let i = 0; i < customFields.length; i++) {
          increment++
        }
      }
      if (
        props.flowControl.isCameraEnabled &&
        (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration ||
          (props.postInitialRegistrationFlowAvailability.isPostInitialRegistration && props.isDeviceTokenBased))
      ) {
        increment++
      }
    } else if (field === 'flowSelection') {
      baseFieldNumber = props.sourceDeviceTokenUnverifiedProcessing ? 2 : 3 //starting point for post flow selection
    } else if (props.checkInFieldDetails && props.checkInFieldDetails.checkInFieldsAvailable === true && customFields) {
      const checkInFieldIndex = customFields.findIndex(captureField => captureField.id === field)
      if (checkInFieldIndex > -1) {
        if (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration) {
          if (props.optionalFieldsEnabled.email) {
            increment++
          }
          if (props.optionalFieldsEnabled.company) {
            increment++
          }
          if (props.optionalFieldsEnabled.personalIdentificationNr) {
            increment++
          }
          if (props.inviteOnlyFieldEnablement.dietaryRequirements) {
            increment++
          }
        } else {
          baseFieldNumber = props.sourceDeviceTokenUnverifiedProcessing ? 2 : 3 //starting point for post flow selection
          if (props.flowControl.postInitialFlowSelection.postFlowUserCanSelect) {
            increment++ //include post flow selection
          }
        }
        if (
          props.parkingDetails.parkingAvailable &&
          (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration ||
            (props.postInitialRegistrationFlowAvailability.isPostInitialRegistration && props.flowControl.postInitialFlowSelection.parking))
        ) {
          increment++
          if (props.flowControl.parking.parkingProcessingEnabled) {
            if (props.parkingDetails.hasTerms) {
              increment++
            }
            if ((props.parkingDetails.hasTerms && props.flowControl.parking.parkingTermsAccepted) || !props.parkingDetails.hasTerms) {
              if (props.parkingDetails.captureFields.find(captureField => captureField.fieldTechnicalName === 'parkingLicensePlate')) {
                increment++
              }
              if (props.parkingDetails.captureFields.find(captureField => captureField.fieldTechnicalName === 'parkingVehicleMake')) {
                increment++
              }
              if (props.parkingDetails.captureFields.find(captureField => captureField.fieldTechnicalName === 'parkingVehicleModel')) {
                increment++
              }
            }
            if (props.parkingDetails.availableClassifications && props.parkingDetails.availableClassifications.length > 0) {
              increment++
            }
          }
        }
        if (
          (props.optionalFieldsEnabled.inductionVideo || props.optionalFieldsEnabled.inductionQuestions) &&
          (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration ||
            (props.postInitialRegistrationFlowAvailability.isPostInitialRegistration &&
              props.flowControl.postInitialFlowSelection.induction))
        ) {
          increment++
        }
        for (let i = 0; i < checkInFieldIndex; i++) {
          increment++
        }
      }
    }
    return `${baseFieldNumber + increment}`
  }

  const hasFirstName = props.formValues.firstName.value && props.formValues.firstName.value.length > 0
  if (debugOn) {
    debugger
  }

  return (
    <ViewContainer>
      <div className={props.className}>
        <div className='section'>
          <PhoneNumberInput
            getFieldDecorator={getFieldDecorator}
            onNumberOkClick={props.onNumberOkClick}
            setPhoneNumberRef={props.setPhoneNumberRef}
            formValues={props.formValues}
            flowControl={props.flowControl}
            isMobile={props.isMobile}
            country={props.country}
            otpChoiceComplete={otpChoiceComplete}
            isPostInitialRegistration={props.postInitialRegistrationFlowAvailability.isPostInitialRegistration}
            tokenHasBeenPreviouslyProcessed={props.postInitialRegistrationFlowAvailability.tokenHasBeenPreviouslyProcessed}
            tokenPhoneNumber={props.postInitialRegistrationFlowAvailability.tokenPhoneNumber}
            inviteCreationFields={props.inviteCreationFields}
            sourceDeviceTokenUnverifiedProcessing={props.sourceDeviceTokenUnverifiedProcessing}
          />
        </div>
        {!props.sourceDeviceTokenUnverifiedProcessing && (
          <div className='section'>
            <Popover
              content={
                <div>
                  <h3>
                    <FormattedMessage id='form.registration.globalProfileConfirmation.confirm.title' />
                  </h3>
                  <div>
                    {props.flowControl.globalProfileConfirmation.globalFirstName}{' '}
                    {props.flowControl.globalProfileConfirmation.globalLastName}
                  </div>
                  <br />
                  <StyledButton
                    type='primary'
                    size='small'
                    ghost={true}
                    style={{ marginRight: 15 }}
                    onClick={props.onGlobalNotMe}
                    disabled={props.flowControl.globalProfileConfirmation.isConfirmingLocalProfile}
                  >
                    <FormattedMessage id='form.registration.globalProfileConfirmation.confirm.cancelText' />
                  </StyledButton>
                  <StyledButton
                    type='primary'
                    size='small'
                    onClick={props.onGlobalConfirmed}
                    disabled={props.flowControl.globalProfileConfirmation.isConfirmingLocalProfile}
                    loading={props.flowControl.globalProfileConfirmation.isConfirmingLocalProfile}
                  >
                    <FormattedMessage id='form.registration.globalProfileConfirmation.confirm.okText' />
                  </StyledButton>
                </div>
              }
              visible={props.flowControl.globalProfileConfirmation.globalProfileConfirmationVisible}
              placement='topLeft'
            >
              <OtpCodeInput
                isMobile={props.isMobile}
                formValues={props.formValues}
                flowControl={props.flowControl}
                fullpageApi={props.fullpageApi}
                processOnInputBlur={processOnInputBlur}
                onOTPOkClick={props.onOTPOkClick}
                otpChoiceComplete={otpChoiceComplete}
                onNumberOkClick={props.onNumberOkClick}
                getFieldDecorator={getFieldDecorator}
                tokenHasBeenPreviouslyProcessed={props.postInitialRegistrationFlowAvailability.tokenHasBeenPreviouslyProcessed}
              />
            </Popover>
          </div>
        )}
        {otpChoiceComplete && (
          <React.Fragment>
            {props.postInitialRegistrationFlowAvailability.isPostInitialRegistration &&
              props.flowControl.postInitialFlowSelection.postFlowUserCanSelect && (
                <div className='section'>
                  <PostFlowSelectionSFC
                    className='no-focus'
                    getFieldDecorator={getFieldDecorator}
                    flowControl={props.flowControl}
                    isMobile={props.isMobile}
                    fieldName='flowSelection'
                    fieldNumber={getFieldNumber('flowSelection')}
                    fieldRequired={true}
                    hasValidInduction={props.hasValidInduction}
                    fieldLabel={
                      <span>
                        <FormattedMessage
                          id='form.registration.input.postflow.title'
                          values={{
                            DIET_TEXT: (
                              <strong>
                                <FormattedMessage id='form.registration.input.diet.label.text' />
                              </strong>
                            ),
                          }}
                        />
                      </span>
                    }
                    togglePostFlow={props.togglePostFlow}
                    onCompletePostFlowSelection={props.onCompletePostFlowSelection}
                    postInitialRegistrationFlowAvailability={props.postInitialRegistrationFlowAvailability}
                    activeSection={props.fullpageApi && props.fullpageApi.getActiveSection()}
                  />
                </div>
              )}

            {!hasConfirmedGlobal && !props.postInitialRegistrationFlowAvailability.isPostInitialRegistration && (
              <React.Fragment>
                <div className='section'>
                  <DefaultInput
                    getFieldDecorator={getFieldDecorator}
                    formValues={props.formValues}
                    flowControl={props.flowControl}
                    isMobile={props.isMobile}
                    processOnPressEnter={processOnPressEnter}
                    processOnInputBlur={processOnInputBlur}
                    onTextInputKeyDown={onTextInputKeyDown}
                    nextItemFocus={nextItemFocus}
                    // inputProps={{
                    //   autoCapitalize: 'words',
                    // }}
                    fieldName='firstName'
                    fieldNumber={props.sourceDeviceTokenUnverifiedProcessing ? '2' : '3'}
                    fieldLabel={
                      <span>
                        <FormattedMessage
                          id='form.registration.input.firstName.label'
                          values={{
                            FIRSTNAME_TEXT: (
                              <strong>
                                <FormattedMessage id='form.registration.input.firstName.label.text' />
                              </strong>
                            ),
                          }}
                        />
                      </span>
                    }
                    fieldRequired={true}
                    fieldRequiredText={translate(messages.INPUT_FIRSTNAME_REQUIRED)}
                    fieldPlaceHolder={translate(messages.INPUT_FIRSTNAME_PLACEHOLDER)}
                  />
                </div>
                <div className='section'>
                  <DefaultInput
                    getFieldDecorator={getFieldDecorator}
                    formValues={props.formValues}
                    flowControl={props.flowControl}
                    isMobile={props.isMobile}
                    processOnPressEnter={processOnPressEnter}
                    processOnInputBlur={processOnInputBlur}
                    onTextInputKeyDown={onTextInputKeyDown}
                    nextItemFocus={nextItemFocus}
                    // inputProps={{
                    //   autoCapitalize: 'words',
                    // }}
                    fieldName='lastName'
                    fieldNumber={props.sourceDeviceTokenUnverifiedProcessing ? '3' : '4'}
                    fieldLabel={
                      <span>
                        <FormattedMessage
                          id='form.registration.input.lastName.label'
                          values={{
                            FIRST_NAME: hasFirstName ? ` ${props.formValues.firstName.value}` : '',
                            LASTNAME_TEXT: (
                              <strong>
                                <FormattedMessage id='form.registration.input.lastName.label.lastnametext' />
                              </strong>
                            ),
                          }}
                        />
                      </span>
                    }
                    fieldRequired={true}
                    fieldRequiredText={translate(messages.INPUT_LASTNAME_REQUIRED)}
                    fieldPlaceHolder={translate(messages.INPUT_LASTNAME_PLACEHOLDER)}
                  />
                </div>
              </React.Fragment>
            )}
            {!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration && props.optionalFieldsEnabled.email && (
              <div className='section'>
                <EmailInputSFC
                  getFieldDecorator={getFieldDecorator}
                  formValues={props.formValues}
                  flowControl={props.flowControl}
                  isMobile={props.isMobile}
                  processOnPressEnter={processOnPressEnter}
                  processOnInputBlur={processOnInputBlur}
                  onTextInputKeyDown={onTextInputKeyDown}
                  nextItemFocus={nextItemFocus}
                  onSkipEmail={props.onSkipEmail}
                  fieldName='email'
                  fieldNumber={getFieldNumber('email')}
                  fieldLabel={
                    <span>
                      <FormattedMessage
                        id='form.registration.input.email.label'
                        values={{
                          EMAIL_TEXT: <strong>email</strong>,
                        }}
                      />
                    </span>
                  }
                  fieldRequired={props.optionalFieldsEnabled.emailRequired}
                  fieldRequiredText={translate(messages.INPUT_EMAIL_REQUIRED)}
                  fieldPlaceHolder={translate(messages.INPUT_EMAIL_PLACEHOLDER)}
                  inputProps={{ type: 'email' }}
                  fieldRules={[
                    {
                      type: 'email',
                      message: translate(messages.INPUT_EMAIL_RULE1),
                    },
                  ]}
                />
              </div>
            )}
            {!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration && props.optionalFieldsEnabled.company && (
              <div className='section'>
                <DefaultInput
                  getFieldDecorator={getFieldDecorator}
                  formValues={props.formValues}
                  flowControl={props.flowControl}
                  isMobile={props.isMobile}
                  processOnPressEnter={processOnPressEnter}
                  processOnInputBlur={processOnInputBlur}
                  onTextInputKeyDown={onTextInputKeyDown}
                  nextItemFocus={nextItemFocus}
                  // inputProps={{
                  //   autoCapitalize: 'words',
                  // }}
                  fieldName='company'
                  fieldNumber={getFieldNumber('company')}
                  fieldLabel={
                    <span>
                      <FormattedMessage
                        id='form.registration.input.company.label'
                        values={{
                          COMPANY_NAME: (
                            <strong>
                              <FormattedMessage id='form.registration.input.company.label.company' />
                            </strong>
                          ),
                          FIRST_NAME: hasFirstName ? `, ${props.formValues.firstName.value}` : '',
                        }}
                      />
                    </span>
                  }
                  fieldRequired={true}
                  fieldRequiredText={translate(messages.INPUT_COMPANY_REQUIRED)}
                  fieldPlaceHolder={translate(messages.INPUT_COMPANY_PLACEHOLDER)}
                />
              </div>
            )}
            {!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration &&
              props.optionalFieldsEnabled.personalIdentificationNr && (
                <div className='section'>
                  <DefaultInput
                    getFieldDecorator={getFieldDecorator}
                    formValues={props.formValues}
                    flowControl={props.flowControl}
                    isMobile={props.isMobile}
                    processOnPressEnter={processOnPressEnter}
                    processOnInputBlur={processOnInputBlur}
                    onTextInputKeyDown={onTextInputKeyDown}
                    nextItemFocus={nextItemFocus}
                    // inputProps={{
                    //   autoCapitalize: 'characters',
                    // }}
                    fieldName='personalIdentificationNr'
                    fieldNumber={getFieldNumber('personalIdentificationNr')}
                    fieldLabel={
                      <span>
                        <FormattedMessage
                          id='form.registration.input.id.label'
                          values={{
                            IDPASSPORT_TEXT: (
                              <strong>
                                <FormattedMessage id='form.registration.input.id.label.idpassport' />
                              </strong>
                            ),
                          }}
                        />
                      </span>
                    }
                    fieldRequired={true}
                    fieldRequiredText={translate(messages.INPUT_ID_REQUIRED)}
                    fieldPlaceHolder={translate(messages.INPUT_ID_PLACEHOLDER)}
                  />
                </div>
              )}
            {!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration &&
              props.inviteOnlyFieldEnablement.dietaryRequirements && (
                <div className='section'>
                  <DefaultInput
                    getFieldDecorator={getFieldDecorator}
                    formValues={props.formValues}
                    flowControl={props.flowControl}
                    isMobile={props.isMobile}
                    processOnPressEnter={processOnPressEnter}
                    processOnInputBlur={processOnInputBlur}
                    onTextInputKeyDown={onTextInputKeyDown}
                    nextItemFocus={nextItemFocus}
                    // inputProps={{
                    //   autoCapitalize: 'sentences',
                    // }}
                    fieldName='dietaryRequirements'
                    fieldNumber={getFieldNumber('dietaryRequirements')}
                    fieldLabel={
                      <span>
                        <FormattedMessage
                          id='form.registration.input.diet.label'
                          values={{
                            DIET_TEXT: (
                              <strong>
                                <FormattedMessage id='form.registration.input.diet.label.text' />
                              </strong>
                            ),
                          }}
                        />
                      </span>
                    }
                    fieldRequired={false}
                    fieldRequiredText=''
                    fieldPlaceHolder={translate(messages.INPUT_DIET_PLACEHOLDER)}
                  />
                </div>
              )}

            {props.parkingDetails.parkingAvailable &&
              (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration ||
                (props.postInitialRegistrationFlowAvailability.isPostInitialRegistration &&
                  props.flowControl.postInitialFlowSelection.postFlowSelectionComplete === true &&
                  props.flowControl.postInitialFlowSelection.parking === true)) && (
                // this will need to be updated to more specific postflow boolean once we enable parking updates
                <div className='section'>
                  <CustomFormItem
                    className='no-focus'
                    required={true}
                    label={
                      <FormLabel
                        number={getFieldNumber('parkingAvailable')}
                        sublabel={
                          props.flowControl.parking.parkingProcessingSkipped ? (
                            <FormattedMessage id='form.registration.field.parkingAvailable.subLabel.skipped' />
                          ) : props.flowControl.parking.parkingProcessingEnabled ? (
                            <FormattedMessage id='form.registration.field.parkingAvailable.subLabel.enabled' />
                          ) : (
                            <FormattedMessage id='form.registration.field.parkingAvailable.subLabel.disabled' />
                          )
                        }
                      >
                        {props.flowControl.parking.parkingProcessingSkipped ? (
                          <FormattedMessage id='form.registration.field.parkingAvailable.label.skipped' />
                        ) : props.flowControl.parking.parkingProcessingEnabled ? (
                          <FormattedMessage id='form.registration.field.parkingAvailable.label.enabled' />
                        ) : (
                          <FormattedMessage id='form.registration.field.parkingAvailable.label.disabled' />
                        )}
                      </FormLabel>
                    }
                  >
                    <div style={{ marginLeft: props.isMobile ? '30px' : '' }}>
                      <div className='flex-container'>
                        <Animated
                          animationIn='fadeInLeft'
                          animationOut='fadeOutLeft'
                          animateOnMount={true}
                          animationInDelay={500}
                          isVisible={
                            !props.flowControl.parking.parkingProcessingSkipped && !props.flowControl.parking.parkingProcessingEnabled
                          }
                          style={{
                            zIndex: 10,
                            ...(!(
                              !props.flowControl.parking.parkingProcessingSkipped && !props.flowControl.parking.parkingProcessingEnabled
                            ) && {
                              display: 'none',
                            }),
                          }}
                        >
                          <StyledButton type='primary' onClick={props.onToggleParkingData} size='large'>
                            <FormattedMessage id='form.registration.field.parkingAvailable.button.yes' />
                          </StyledButton>
                        </Animated>
                        <Animated
                          animationIn='fadeInRight'
                          animationOut='fadeOutRight'
                          animateOnMount={true}
                          animationInDelay={500}
                          isVisible={
                            !props.flowControl.parking.parkingProcessingSkipped && !props.flowControl.parking.parkingProcessingEnabled
                          }
                          style={{
                            zIndex: 10,
                            ...(!(
                              !props.flowControl.parking.parkingProcessingSkipped && !props.flowControl.parking.parkingProcessingEnabled
                            ) && {
                              display: 'none',
                            }),
                          }}
                        >
                          <StyledButton type='primary' ghost={true} onClick={props.onToggleSkipParkingData} size='large'>
                            <FormattedMessage id='form.registration.field.parkingAvailable.button.no' />
                          </StyledButton>
                        </Animated>
                        <Animated
                          animationIn='fadeInUp'
                          animationOut='fadeOutDown'
                          animateOnMount={true}
                          animationInDelay={500}
                          isVisible={
                            props.flowControl.parking.parkingProcessingEnabled || props.flowControl.parking.parkingProcessingSkipped
                          }
                          style={{
                            zIndex: 10,
                            ...(!(
                              props.flowControl.parking.parkingProcessingEnabled || props.flowControl.parking.parkingProcessingSkipped
                            ) && {
                              display: 'none',
                              zIndex: 15,
                            }),
                          }}
                        >
                          <StyledButton type='primary' onClick={props.onToggleReEnabledParkingData} size='large'>
                            <FormattedMessage id='form.registration.field.parkingAvailable.button.change' />
                          </StyledButton>
                        </Animated>
                      </div>
                    </div>
                  </CustomFormItem>
                </div>
              )}

            {props.parkingDetails.parkingAvailable &&
              (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration ||
                (props.postInitialRegistrationFlowAvailability.isPostInitialRegistration &&
                  props.flowControl.postInitialFlowSelection.postFlowSelectionComplete === true &&
                  props.flowControl.postInitialFlowSelection.parking === true)) &&
              props.flowControl.parking.parkingProcessingEnabled &&
              props.parkingDetails.hasTerms && (
                <div className='section'>
                  <CustomFormItem
                    className='no-focus'
                    required={true}
                    label={
                      <FormLabel
                        number={getFieldNumber('parkingTerms')}
                        sublabel={
                          <>
                            <ParkingLegals termsSingleLines={props.parkingDetails.termsSingleLines} />
                            <br />
                            <StyledSwitch
                              defaultChecked={props.flowControl.parking.parkingTermsAccepted}
                              onChange={props.onToggleParkingTerms}
                            />
                          </>
                        }
                      >
                        <FormattedMessage
                          id='form.registration.field.parkingTerms.label'
                          values={{
                            PARKING_PROVIDER_NAME: props.parkingDetails.parkingProviderName,
                          }}
                        />
                      </FormLabel>
                    }
                  />
                </div>
              )}
            {props.parkingDetails.parkingAvailable &&
              props.parkingDetails.availableClassifications &&
              props.parkingDetails.availableClassifications.length > 0 &&
              (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration ||
                (props.postInitialRegistrationFlowAvailability.isPostInitialRegistration &&
                  props.flowControl.postInitialFlowSelection.postFlowSelectionComplete === true &&
                  props.flowControl.postInitialFlowSelection.parking === true)) &&
              props.flowControl.parking.parkingProcessingEnabled &&
              ((props.parkingDetails.hasTerms && props.flowControl.parking.parkingTermsAccepted) || !props.parkingDetails.hasTerms) && (
                <div className='section' key={'preferredParkingClassification'}>
                  <SingleSelection
                    selectItems={props.parkingDetails.availableClassifications.map(availableClassification => ({
                      dataKey: availableClassification,
                      text: availableClassification,
                    }))}
                    getFieldDecorator={getFieldDecorator}
                    formValues={props.formValues}
                    flowControl={props.flowControl}
                    isMobile={props.isMobile}
                    processOnPressEnter={processOnPressEnter}
                    processOnInputBlur={processOnInputBlur}
                    onTextInputKeyDown={onTextInputKeyDown}
                    nextItemFocus={nextItemFocus}
                    fieldName={'preferredParkingClassification'}
                    fieldNumber={getFieldNumber('preferredParkingClassification')}
                    fieldLabel={<span>Parking type</span>}
                    fieldRequired={true}
                    fieldRequiredText={translate({
                      id: 'form.registration.input.error.required',
                    })}
                    fieldPlaceHolder={'Please select the type of parking'}
                  />
                </div>
              )}
            {props.parkingDetails.parkingAvailable &&
              (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration ||
                (props.postInitialRegistrationFlowAvailability.isPostInitialRegistration &&
                  props.flowControl.postInitialFlowSelection.postFlowSelectionComplete === true &&
                  props.flowControl.postInitialFlowSelection.parking === true)) &&
              props.flowControl.parking.parkingProcessingEnabled &&
              ((props.parkingDetails.hasTerms && props.flowControl.parking.parkingTermsAccepted) || !props.parkingDetails.hasTerms) &&
              props.parkingDetails.captureFields.map((captureField, index) => (
                <div key={index} className='section'>
                  <DefaultInput
                    getFieldDecorator={getFieldDecorator}
                    formValues={props.formValues}
                    flowControl={props.flowControl}
                    isMobile={props.isMobile}
                    processOnPressEnter={processOnPressEnter}
                    processOnInputBlur={processOnInputBlur}
                    onTextInputKeyDown={onTextInputKeyDown}
                    nextItemFocus={nextItemFocus}
                    fieldName={captureField.fieldTechnicalName}
                    fieldNumber={getFieldNumber(captureField.fieldTechnicalName)}
                    fieldLabel={<FormattedMessage id='useDefault' defaultMessage={captureField.label} />}
                    fieldRequired={true}
                    fieldRequiredText=''
                    fieldPlaceHolder={captureField.placeHolder}
                    // inputProps={{
                    //   autoCapitalize: 'characters',
                    // }}
                  />
                </div>
              ))}
            {(props.optionalFieldsEnabled.inductionVideo || props.optionalFieldsEnabled.inductionQuestions) &&
              (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration ||
                (props.postInitialRegistrationFlowAvailability.isPostInitialRegistration &&
                  props.flowControl.postInitialFlowSelection.postFlowSelectionComplete === true &&
                  props.flowControl.postInitialFlowSelection.induction === true)) && (
                // this is for both normal flow as well as post flow, however we should consider impact when we have parkling post flow
                <div className='section'>
                  {props.optionalFieldsEnabled.inductionVideo && (
                    <>
                      <FormLabel
                        number={getFieldNumber('induction')}
                        sublabel={
                          props.flowControl.inductionVideo.inductionVideoSkipped ? (
                            <StatusSpan>
                              <FormattedMessage id='form.registration.field.induction.subLabel' />:{' '}
                              <Badge status='warning' text={translate(messages.INPUT_INDUCTION_SKIPPED_SUBLABEL)} />
                            </StatusSpan>
                          ) : props.flowControl.inductionVideo.inductionVideoCompleted ? (
                            <StatusSpan>
                              <FormattedMessage id='form.registration.field.induction.subLabel' />:{' '}
                              <Badge status='success' text={translate(messages.INPUT_INDUCTION_COMPLETE_SUBLABEL)} />
                            </StatusSpan>
                          ) : (
                            <StatusSpan>
                              <FormattedMessage id='form.registration.field.induction.subLabel' />:{' '}
                              <Badge status='processing' text={translate(messages.INPUT_INDUCTION_INCOMPLETE_SUBLABEL)} />
                            </StatusSpan>
                          )
                        }
                      >
                        {!props.flowControl.inductionVideo.inductionVideoCompleted &&
                          !props.flowControl.inductionVideo.inductionVideoSkipped && (
                            <FormattedMessage id='form.registration.field.induction.label.incomplete' />
                          )}
                        {!props.flowControl.inductionVideo.inductionVideoCompleted &&
                          props.flowControl.inductionVideo.inductionVideoSkipped && (
                            <FormattedMessage id='form.registration.field.induction.label.skipped' />
                          )}
                        {props.flowControl.inductionVideo.inductionVideoCompleted &&
                          (props.optionalFieldsEnabled.inductionQuestions ? (
                            props.flowControl.inductionQuestions.inductionQuestionsCompleted ? (
                              <FormattedMessage id='form.registration.field.induction.label.completed' />
                            ) : props.flowControl.inductionQuestions.inductionQuestionsSkipped ? (
                              <FormattedMessage id='form.registration.field.inductionquestion.label.skipped' />
                            ) : (
                              <FormattedMessage id='form.registration.field.induction.label.completedpendingquestions' />
                            )
                          ) : (
                            <FormattedMessage id='form.registration.field.induction.label.completed' />
                          ))}
                      </FormLabel>
                      <Video
                        completed={props.flowControl.inductionVideo.inductionVideoCompleted}
                        skipped={props.flowControl.inductionVideo.inductionVideoSkipped}
                        onVideoCompleted={props.onInductionCompleted}
                        inductionVideoSrc={props.inductionContent.inductionVideoLink}
                      />
                      {props.flowControl.inductionQuestions.inductionQuestionsProcessingEnabled && (
                        <InductionQuestions
                          onCloseModal={props.onToggleInductionQuestionsProcessing}
                          onCompleteInductionQuestions={props.onCompleteInductionQuestions}
                          inductionQuestions={props.inductionContent.inductionQuestions}
                        />
                      )}
                      <div
                        className='flex-container'
                        style={{
                          flexWrap: 'wrap',
                          maxWidth: window.outerWidth * 0.5,
                        }}
                      >
                        <Animated
                          animationIn='fadeInUp'
                          animationOut='fadeOutDown'
                          animateOnMount={false}
                          isVisible={
                            !props.flowControl.inductionVideo.inductionVideoSkipped &&
                            !props.flowControl.inductionVideo.inductionVideoCompleted &&
                            (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration ||
                              (props.postInitialRegistrationFlowAvailability.isPostInitialRegistration &&
                                !props.flowControl.inductionVideo.inductionVideoCompleted))
                          }
                          style={{
                            ...(!(
                              !props.flowControl.inductionVideo.inductionVideoSkipped &&
                              !props.flowControl.inductionVideo.inductionVideoCompleted
                            ) && {
                              display: 'none',
                            }),
                            marginTop: '10px',
                          }}
                        >
                          <Popconfirm
                            placement='topLeft'
                            title={
                              <div>
                                <h3 className='confirm-title'>
                                  <FormattedMessage id='form.registration.field.induction.confirmSkip.title' />
                                </h3>
                                <p className='confirm-description'>
                                  <FormattedMessage id='form.registration.field.induction.confirmSkip.description' />
                                </p>
                                <div className='confirm-footer'>
                                  <StyledButton
                                    onClick={props.toggleConfirmInductionVideoSkip}
                                    type='primary'
                                    size='small'
                                    style={{ marginRight: 15 }}
                                    ghost={true}
                                  >
                                    <FormattedMessage id='form.registration.field.induction.confirmSkip.cancelText' />
                                  </StyledButton>
                                  <StyledButton onClick={props.handleInductionVideoSkipped} type='primary' size='small'>
                                    <FormattedMessage id='form.registration.field.induction.confirmSkip.okText' />
                                  </StyledButton>
                                </div>
                              </div>
                            }
                            onConfirm={props.handleInductionVideoSkipped}
                            onCancel={props.toggleConfirmInductionVideoSkip}
                            okText={null}
                            cancelText={null}
                            visible={props.confirmInductionVideoSkipVisible}
                            overlayClassName='induction-confirmskip-popup'
                          >
                            <EnterButtonSFC size='large' onClick={props.toggleConfirmInductionVideoSkip}>
                              <FormattedMessage id='form.registration.button.skip' />
                            </EnterButtonSFC>
                          </Popconfirm>
                        </Animated>
                        <Animated
                          animationIn='fadeInLeft'
                          animationOut='fadeOutLeft'
                          animateOnMount={true}
                          animationInDelay={500}
                          isVisible={
                            props.optionalFieldsEnabled.inductionQuestions &&
                            props.flowControl.inductionVideo.inductionVideoCompleted &&
                            !props.flowControl.inductionQuestions.inductionQuestionsSkipped &&
                            !props.flowControl.inductionQuestions.inductionQuestionsCompleted &&
                            (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration ||
                              (props.postInitialRegistrationFlowAvailability.isPostInitialRegistration &&
                                !props.flowControl.inductionQuestions.inductionQuestionsCompleted))
                          }
                          style={{
                            zIndex: 10,
                            ...(!(
                              !props.flowControl.inductionQuestions.inductionQuestionsSkipped &&
                              !props.flowControl.inductionQuestions.inductionQuestionsCompleted
                            ) && {
                              display: 'none',
                            }),
                            marginTop: '10px',
                          }}
                        >
                          <StyledButton type='primary' onClick={props.onToggleInductionQuestionsProcessing} size='large'>
                            <FormattedMessage id='form.registration.field.inductionquestions.button.start' />
                          </StyledButton>
                        </Animated>
                        <Animated
                          animationIn='fadeInRight'
                          animationOut='fadeOutRight'
                          animateOnMount={true}
                          animationInDelay={500}
                          isVisible={
                            props.optionalFieldsEnabled.inductionQuestions &&
                            props.flowControl.inductionVideo.inductionVideoCompleted &&
                            !props.flowControl.inductionQuestions.inductionQuestionsSkipped &&
                            !props.flowControl.inductionQuestions.inductionQuestionsCompleted &&
                            (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration ||
                              (props.postInitialRegistrationFlowAvailability.isPostInitialRegistration &&
                                !props.flowControl.inductionQuestions.inductionQuestionsCompleted))
                          }
                          style={{
                            zIndex: 10,
                            ...(!(
                              !props.flowControl.inductionQuestions.inductionQuestionsSkipped &&
                              !props.flowControl.inductionQuestions.inductionQuestionsCompleted
                            ) && {
                              display: 'none',
                            }),
                            marginTop: '10px',
                          }}
                        >
                          <Popconfirm
                            placement='topLeft'
                            title={
                              <div>
                                <h3 className='confirm-title'>
                                  <FormattedMessage id='form.registration.field.inductionquestions.confirmSkip.title' />
                                </h3>
                                <p className='confirm-description'>
                                  <FormattedMessage id='form.registration.field.inductionquestions.confirmSkip.description' />
                                </p>
                                <div className='confirm-footer'>
                                  <StyledButton
                                    onClick={props.toggleConfirmInductionQuestionsSkip}
                                    type='primary'
                                    size='small'
                                    style={{ marginRight: 15 }}
                                    ghost={true}
                                  >
                                    <FormattedMessage id='form.registration.field.inductionquestions.confirmSkip.cancelText' />
                                  </StyledButton>
                                  <StyledButton onClick={props.handleInductionQuestionsSkipped} type='primary' size='small'>
                                    <FormattedMessage id='form.registration.field.inductionquestions.confirmSkip.okText' />
                                  </StyledButton>
                                </div>
                              </div>
                            }
                            onConfirm={props.handleInductionQuestionsSkipped}
                            onCancel={props.toggleConfirmInductionQuestionsSkip}
                            okText={null}
                            cancelText={null}
                            visible={props.confirmInductionQuestionsSkipVisible}
                            overlayClassName='induction-confirmskip-popup'
                          >
                            <EnterButtonSFC size='large' onClick={props.toggleConfirmInductionQuestionsSkip}>
                              <FormattedMessage id='form.registration.button.skip' />
                            </EnterButtonSFC>
                          </Popconfirm>
                        </Animated>
                        <Animated
                          animationIn='fadeInUp'
                          animationOut='fadeOutDown'
                          animateOnMount={true}
                          animationInDelay={500}
                          isVisible={
                            (props.optionalFieldsEnabled.inductionQuestions &&
                              props.flowControl.inductionVideo.inductionVideoCompleted &&
                              !props.flowControl.inductionQuestions.inductionQuestionsCompleted) ||
                            props.flowControl.inductionQuestions.inductionQuestionsSkipped
                          }
                          style={{
                            zIndex: 10,
                            ...(!(
                              props.flowControl.inductionQuestions.inductionQuestionsCompleted ||
                              props.flowControl.inductionQuestions.inductionQuestionsSkipped
                            ) && {
                              display: 'none',
                              zIndex: 15,
                            }),
                            marginTop: '10px',
                          }}
                        >
                          <StyledButton type='primary' onClick={props.onToggleReEnabledInductionQuestions} size='large'>
                            <FormattedMessage id='form.registration.field.parkingAvailable.button.change' />
                          </StyledButton>
                        </Animated>
                      </div>
                    </>
                  )}
                  {!props.optionalFieldsEnabled.inductionVideo && props.optionalFieldsEnabled.inductionQuestions && (
                    <>
                      <FormLabel number={getFieldNumber('induction')}>
                        {!props.flowControl.inductionQuestions.inductionQuestionsCompleted &&
                          !props.flowControl.inductionQuestions.inductionQuestionsSkipped && (
                            <FormattedMessage id='form.registration.field.inductionquestion.label.incomplete' />
                          )}
                        {!props.flowControl.inductionQuestions.inductionQuestionsCompleted &&
                          props.flowControl.inductionQuestions.inductionQuestionsSkipped && (
                            <FormattedMessage id='form.registration.field.inductionquestion.label.skipped' />
                          )}
                        {props.flowControl.inductionQuestions.inductionQuestionsCompleted && (
                          <FormattedMessage id='form.registration.field.inductionquestion.label.completed' />
                        )}
                      </FormLabel>
                      {props.flowControl.inductionQuestions.inductionQuestionsProcessingEnabled && (
                        <InductionQuestions
                          onCloseModal={props.onToggleInductionQuestionsProcessing}
                          onCompleteInductionQuestions={props.onCompleteInductionQuestions}
                          inductionQuestions={props.inductionContent.inductionQuestions}
                        />
                      )}
                      <div
                        style={{
                          zIndex: 10,
                          marginLeft: props.isMobile ? '30px' : '',
                        }}
                      >
                        <div className='flex-container' style={{ flexWrap: 'wrap', maxWidth: window.outerWidth * 0.5 }}>
                          <Animated
                            animationIn='fadeInLeft'
                            animationOut='fadeOutLeft'
                            animateOnMount={true}
                            animationInDelay={500}
                            isVisible={
                              !props.flowControl.inductionQuestions.inductionQuestionsSkipped &&
                              !props.flowControl.inductionQuestions.inductionQuestionsCompleted &&
                              (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration ||
                                (props.postInitialRegistrationFlowAvailability.isPostInitialRegistration &&
                                  !props.flowControl.inductionQuestions.inductionQuestionsCompleted))
                            }
                            style={{
                              zIndex: 10,
                              ...(!(
                                !props.flowControl.inductionQuestions.inductionQuestionsSkipped &&
                                !props.flowControl.inductionQuestions.inductionQuestionsCompleted
                              ) && {
                                display: 'none',
                              }),
                              marginTop: '10px',
                            }}
                          >
                            <StyledButton type='primary' onClick={props.onToggleInductionQuestionsProcessing} size='large'>
                              <FormattedMessage id='form.registration.field.inductionquestions.button.start' />
                            </StyledButton>
                          </Animated>
                          <Animated
                            animationIn='fadeInRight'
                            animationOut='fadeOutRight'
                            animateOnMount={true}
                            animationInDelay={500}
                            isVisible={
                              !props.flowControl.inductionQuestions.inductionQuestionsSkipped &&
                              !props.flowControl.inductionQuestions.inductionQuestionsCompleted &&
                              (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration ||
                                (props.postInitialRegistrationFlowAvailability.isPostInitialRegistration &&
                                  !props.flowControl.inductionQuestions.inductionQuestionsCompleted))
                            }
                            style={{
                              zIndex: 10,
                              ...(!(
                                !props.flowControl.inductionQuestions.inductionQuestionsSkipped &&
                                !props.flowControl.inductionQuestions.inductionQuestionsCompleted
                              ) && {
                                display: 'none',
                              }),
                              marginTop: '10px',
                            }}
                          >
                            <Popconfirm
                              placement='topLeft'
                              title={
                                <div>
                                  <h3 className='confirm-title'>
                                    <FormattedMessage id='form.registration.field.inductionquestions.confirmSkip.title' />
                                  </h3>
                                  <p className='confirm-description'>
                                    <FormattedMessage id='form.registration.field.inductionquestions.confirmSkip.description' />
                                  </p>
                                  <div className='confirm-footer'>
                                    <StyledButton
                                      onClick={props.toggleConfirmInductionQuestionsSkip}
                                      type='primary'
                                      size='small'
                                      style={{ marginRight: 15 }}
                                      ghost={true}
                                    >
                                      <FormattedMessage id='form.registration.field.inductionquestions.confirmSkip.cancelText' />
                                    </StyledButton>
                                    <StyledButton onClick={props.handleInductionQuestionsSkipped} type='primary' size='small'>
                                      <FormattedMessage id='form.registration.field.inductionquestions.confirmSkip.okText' />
                                    </StyledButton>
                                  </div>
                                </div>
                              }
                              onConfirm={props.handleInductionQuestionsSkipped}
                              onCancel={props.toggleConfirmInductionQuestionsSkip}
                              okText={null}
                              cancelText={null}
                              visible={props.confirmInductionQuestionsSkipVisible}
                              overlayClassName='induction-confirmskip-popup'
                            >
                              <EnterButtonSFC size='large' onClick={props.toggleConfirmInductionQuestionsSkip}>
                                <FormattedMessage id='form.registration.button.skip' />
                              </EnterButtonSFC>
                            </Popconfirm>
                          </Animated>
                          <Animated
                            animationIn='fadeInUp'
                            animationOut='fadeOutDown'
                            animateOnMount={true}
                            animationInDelay={500}
                            isVisible={
                              !props.flowControl.inductionQuestions.inductionQuestionsCompleted ||
                              props.flowControl.inductionQuestions.inductionQuestionsSkipped
                            }
                            style={{
                              zIndex: 10,
                              ...(!(
                                props.flowControl.inductionQuestions.inductionQuestionsCompleted ||
                                props.flowControl.inductionQuestions.inductionQuestionsSkipped
                              ) && {
                                display: 'none',
                                zIndex: 15,
                              }),
                              marginTop: '10px',
                            }}
                          >
                            <StyledButton type='primary' onClick={props.onToggleReEnabledInductionQuestions} size='large'>
                              <FormattedMessage id='form.registration.field.parkingAvailable.button.change' />
                            </StyledButton>
                          </Animated>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            {props.checkInFieldDetails.checkInFieldsAvailable &&
              (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration ||
                (props.postInitialRegistrationFlowAvailability.isPostInitialRegistration &&
                  props.flowControl.postInitialFlowSelection.postFlowSelectionComplete === true &&
                  props.flowControl.postInitialFlowSelection.checkin === true)) &&
              customFields &&
              customFields.map(checkInField => (
                <div className='section' key={checkInField.id}>
                  {checkInField.inputSettings.inputType === 'staticDropDownListSingleSelect' && (
                    <SingleSelection
                      selectItems={checkInField.staticDropDownListItems || []}
                      getFieldDecorator={getFieldDecorator}
                      formValues={props.formValues}
                      flowControl={props.flowControl}
                      isMobile={props.isMobile}
                      processOnPressEnter={processOnPressEnter}
                      processOnInputBlur={processOnInputBlur}
                      onTextInputKeyDown={onTextInputKeyDown}
                      nextItemFocus={nextItemFocus}
                      fieldName={checkInField.id}
                      fieldNumber={getFieldNumber(checkInField.id)}
                      fieldLabel={<span>{checkInField.label}</span>}
                      fieldRequired={checkInField.inputSettings.mandatory}
                      fieldRequiredText={translate({
                        id: 'form.registration.input.error.required',
                      })}
                      fieldPlaceHolder={checkInField.inputSettings.inputPlaceholder}
                    />
                  )}
                  {checkInField.inputSettings.inputType === 'staticDropDownListMultiSelect' && (
                    <MultiSelection
                      selectItems={checkInField.staticDropDownListItems || []}
                      getFieldDecorator={getFieldDecorator}
                      formValues={props.formValues}
                      flowControl={props.flowControl}
                      isMobile={props.isMobile}
                      processOnPressEnter={processOnPressEnter}
                      processOnInputBlur={processOnInputBlur}
                      onTextInputKeyDown={onTextInputKeyDown}
                      nextItemFocus={nextItemFocus}
                      fieldName={checkInField.id}
                      fieldNumber={getFieldNumber(checkInField.id)}
                      fieldLabel={<span>{checkInField.label}</span>}
                      fieldRequired={checkInField.inputSettings.mandatory}
                      fieldRequiredText={translate({
                        id: 'form.registration.input.error.required',
                      })}
                      fieldPlaceHolder={checkInField.inputSettings.inputPlaceholder}
                    />
                  )}
                  {checkInField.inputSettings.inputType === 'text' && (
                    <DefaultInput
                      getFieldDecorator={getFieldDecorator}
                      formValues={props.formValues}
                      flowControl={props.flowControl}
                      isMobile={props.isMobile}
                      processOnPressEnter={processOnPressEnter}
                      processOnInputBlur={processOnInputBlur}
                      onTextInputKeyDown={onTextInputKeyDown}
                      nextItemFocus={nextItemFocus}
                      // inputProps={{
                      //   autoCapitalize: 'sentences',
                      // }}
                      fieldName={checkInField.id}
                      fieldNumber={getFieldNumber(checkInField.id)}
                      fieldLabel={<span>{checkInField.label}</span>}
                      fieldRequired={checkInField.inputSettings.mandatory}
                      fieldRequiredText={translate({
                        id: 'form.registration.input.error.required',
                      })}
                      fieldPlaceHolder={checkInField.inputSettings.inputPlaceholder}
                    />
                  )}
                  {checkInField.inputSettings.inputType === 'number' && (
                    <NumberInput
                      getFieldDecorator={getFieldDecorator}
                      formValues={props.formValues}
                      flowControl={props.flowControl}
                      isMobile={props.isMobile}
                      processOnPressEnter={processOnPressEnter}
                      processOnInputBlur={processOnInputBlur}
                      onTextInputKeyDown={onTextInputKeyDown}
                      nextItemFocus={nextItemFocus}
                      fieldName={checkInField.id}
                      fieldNumber={getFieldNumber(checkInField.id)}
                      fieldLabel={<span>{checkInField.label}</span>}
                      fieldRequired={checkInField.inputSettings.mandatory}
                      fieldRequiredText={translate({
                        id: 'form.registration.input.error.required',
                      })}
                      fieldPlaceHolder={checkInField.inputSettings.inputPlaceholder}
                    />
                  )}
                  {checkInField.inputSettings.inputType === 'slider' && (
                    <SliderInput
                      getFieldDecorator={getFieldDecorator}
                      formValues={props.formValues}
                      flowControl={props.flowControl}
                      isMobile={props.isMobile}
                      fieldName={checkInField.id}
                      fieldNumber={getFieldNumber(checkInField.id)}
                      fieldLabel={<span>{checkInField.label}</span>}
                      fieldRequired={checkInField.inputSettings.mandatory}
                      fieldRequiredText={translate({
                        id: 'form.registration.input.error.required',
                      })}
                      sliderConfig={checkInField.sliderConfig!}
                      inputSettings={checkInField.inputSettings}
                      nextItemFocus={nextItemFocus}
                    />
                  )}
                </div>
              ))}

            {props.flowControl.isCameraEnabled &&
              (!props.postInitialRegistrationFlowAvailability.isPostInitialRegistration ||
                (props.postInitialRegistrationFlowAvailability.isPostInitialRegistration && props.isDeviceTokenBased)) && (
                <div className='section'>
                  <CustomFormItem
                    className='no-focus'
                    label={
                      <FormLabel
                        number={getFieldNumber('camera')}
                        sublabel={
                          props.isDeviceTokenBased ? null : props.flowControl.faceData.faceDataProcessingSkipped ? (
                            <FormattedMessage id='form.registration.field.camera.subLabel.skipped' />
                          ) : props.flowControl.faceData.faceDataProcessingEnabled ? (
                            <FormattedMessage id='form.registration.field.camera.subLabel.enabled' />
                          ) : (
                            <FormattedMessage id='form.registration.field.camera.subLabel.disabled' />
                          )
                        }
                      >
                        {props.flowControl.faceData.faceDataProcessingSkipped ? (
                          <FormattedMessage id='form.registration.field.camera.label.skipped' />
                        ) : props.flowControl.faceData.faceDataProcessingEnabled ? (
                          <FormattedMessage id='form.registration.field.camera.label.enabled' />
                        ) : (
                          <FormattedMessage id='form.registration.field.camera.label.disabled' />
                        )}
                      </FormLabel>
                    }
                  >
                    <div style={{ marginLeft: props.isMobile ? '30px' : '' }}>
                      <div className='flex-container'>
                        <Animated
                          animationIn='fadeInLeft'
                          animationOut='fadeOutLeft'
                          animateOnMount={true}
                          animationInDelay={500}
                          isVisible={
                            !props.flowControl.faceData.faceDataProcessingSkipped && !props.flowControl.faceData.faceDataProcessingEnabled
                          }
                          style={{ zIndex: 10 }}
                        >
                          <StyledButton type='primary' onClick={props.onToggleFaceData} size='large'>
                            <FormattedMessage id='global.text.yes' />
                          </StyledButton>
                        </Animated>
                        <Animated
                          animationIn='fadeInRight'
                          animationOut='fadeOutRight'
                          animateOnMount={true}
                          animationInDelay={500}
                          isVisible={
                            !props.flowControl.faceData.faceDataProcessingSkipped && !props.flowControl.faceData.faceDataProcessingEnabled
                          }
                          style={{ zIndex: 10 }}
                        >
                          <StyledButton type='primary' ghost={true} onClick={props.onToggleSkipFaceData} size='large'>
                            <FormattedMessage id='global.text.no' />
                          </StyledButton>
                        </Animated>
                      </div>
                      <div
                        className='flex-container'
                        style={{
                          marginBottom: 10,
                          marginTop: '-35x',
                          position: 'relative',
                        }}
                      >
                        <Animated
                          animationIn='fadeInUp'
                          animationOut='fadeOutDown'
                          animateOnMount={true}
                          animationInDelay={500}
                          isVisible={
                            props.flowControl.faceData.faceDataProcessingSkipped && !props.flowControl.faceData.faceDataProcessingEnabled
                          }
                          style={{ zIndex: 10 }}
                        >
                          <StyledButton type='primary' onClick={props.onToggleSkipFaceData} size='large'>
                            <FormattedMessage id='global.text.activate' />
                          </StyledButton>
                        </Animated>
                      </div>

                      <div>
                        <Animated
                          animationIn='fadeInUp'
                          animationOut='fadeOutDown'
                          animateOnMount={false}
                          isVisible={props.flowControl.faceData.faceDataProcessingEnabled}
                          animationInDelay={400}
                        >
                          <div className='flex-container'>
                            <FaceCapture
                              isFaceValid={props.formValues.faceData.isValid}
                              displayFace={props.formValues.faceData.value as string}
                              faceIsBeingValidated={props.flowControl.faceData.faceValidationInProcess}
                              validateFace={props.validateFace}
                              acceptCapturedFace={props.acceptCapturedFace}
                              changeCapturedFace={props.changeCapturedFace}
                              onToggleFaceData={props.onToggleFaceData}
                            />
                          </div>
                        </Animated>
                      </div>
                    </div>
                  </CustomFormItem>
                </div>
              )}
            {props.optionalFieldsEnabled.hostSearch && (
              <div className='section'>
                <HostSearch
                  fieldName={'hostSearch'}
                  fieldNumber={getFieldNumber('hostSearch')}
                  getFieldDecorator={getFieldDecorator}
                  data={props.dataSources.hosts}
                  onSearch={props.onHostSearch}
                  onSelect={props.onHostSelect}
                  value={props.formValues.selectedHost ? props.formValues.selectedHost.value : ''}
                  loading={props.flowControl.hostSearchRequest.hostSearchInProcess}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </ViewContainer>
  )
}
const CustomizedForm = Form.create<FormProps>({
  onFieldsChange(props, changedFields) {
    props.onFieldsChange(changedFields)
  },
  mapPropsToFields(props) {
    return {
      phoneNumber: Form.createFormField({
        ...props.formValues.phoneNumber,
        value: props.formValues.phoneNumber.value,
      }),
      otpCode: Form.createFormField({
        ...props.formValues.otpCode,
        value: props.formValues.otpCode.value,
      }),
      firstName: Form.createFormField({
        ...props.formValues.firstName,
        value: props.formValues.firstName.value,
      }),
      lastName: Form.createFormField({
        ...props.formValues.lastName,
        value: props.formValues.lastName.value,
      }),
      email: Form.createFormField({
        ...props.formValues.email,
        value: props.formValues.email.value,
      }),
      company: Form.createFormField({
        ...props.formValues.company,
        value: props.formValues.company.value,
      }),
      personalIdentificationNr: Form.createFormField({
        ...props.formValues.personalIdentificationNr,
        value: props.formValues.personalIdentificationNr.value,
      }),
      dietaryRequirements: Form.createFormField({
        ...props.formValues.dietaryRequirements,
        value: props.formValues.dietaryRequirements.value,
      }),
      induction: Form.createFormField(),
      ...(props.parkingDetails.availableClassifications &&
        props.parkingDetails.availableClassifications.length && {
          preferredParkingClassification: Form.createFormField({
            ...props.formValues.preferredParkingClassification,
            value: props.formValues.preferredParkingClassification.value,
          }),
        }),
      ...(props.parkingDetails.parkingAvailable && {
        ...chain(
          props.parkingDetails.captureFields.map(field => ({
            fieldTechnicalName: field.fieldTechnicalName,
            ...props.formValues[field.fieldTechnicalName],
            value: props.formValues[field.fieldTechnicalName].value,
          }))
        )
          .keyBy('fieldTechnicalName')
          .mapValues(formVal => Form.createFormField(omit(formVal, 'fieldTechnicalName')))
          .value(),
      }),
      ...(props.checkInFieldDetails.checkInFieldsAvailable && {
        ...chain(
          props.checkInFieldDetails.captureFields
            .filter(captureField => !captureField.availableForConditionInput)
            .map(field => ({
              fieldTechnicalName: field.id,
              ...props.formValues[field.id],
              value: props.formValues[field.id].value,
            }))
        )
          .keyBy('fieldTechnicalName')
          .mapValues(formVal => Form.createFormField(omit(formVal, 'fieldTechnicalName')))
          .value(),
      }),
      faceData: Form.createFormField({
        ...props.formValues.faceData,
        value: props.formValues.faceData.value,
      }),
      ...(props.optionalFieldsEnabled.hostSearch && {
        hostSearch: Form.createFormField({
          ...props.formValues.selectedHost,
          value: props.formValues.selectedHost.value,
        }),
      }),
    }
  },
})(RegistrationFormSFC)
export default CustomizedForm

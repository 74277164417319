import React from 'react'
import styled from 'styled-components'
import { getReturnOfExpression } from 'utility-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { FormattedMessage } from 'react-intl'

import { getProcessInstanceState } from '~/store/processinstance'
import LocationSelection from './subComponents/LocationSelectSFC'
import VisitorTypeSelect from './subComponents/VisitorTypeSelectSFC'
import DistributionTypeSelect from './subComponents/DistributionTypeSelect'
import HostSearch from './subComponents/HostSearchSFC'
import InviteTable from './subComponents/InviteTable'
import CaptureOTPForm from './subComponents/CaptureOTPForm'
import { getSetupInstanceState } from '~/store/setupinstance'
import * as interfaces from '~/typing/KENAI/interfaces.d.ts'
import * as enums from '~/typing/KENAI/enums.d.ts'
import { ExtendedWrappedFormUtils } from '~/typing/vendor/antd-form-untyped-public'
import update from 'immutability-helper'
import { ActionCreators as SetupProcessingActionCreators } from '~/sagas/setupprocessing'
import { forOwn, cloneDeep } from 'lodash'
import getTimeStringFromEventMetaData from './subComponents/getTimeStringFromEventMetaData'
import { SelectData } from './subComponents/SelectionSFC'
import { SelectValue } from 'antd/lib/select'
import { notification, Popover } from 'antd'
import { DataSourceItemObject } from 'antd/lib/auto-complete'
import StyledButton from '../StyledButton'
import Spin from '~/app/components/StyledSpin'
import FlexItem from './subComponents/FlexItemSFC'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons'
import StyledCheckbox from '~/app/components/StyledCheckBox'
import translate from '~/translations/translate'
import { isMobile } from 'react-device-detect'

const KenaiLogo = '/images/kenai-logo-invites.svg'

const SetupWrapper = styled.div`
  padding: 10vw;
  .selection-content {
    width: 300px;
    max-width: 100%;
  }
  .flex-item {
    display: flex;
    flex-direction: column;
  }
  .center-content {
    display: flex;
    flex-direction: column;
    height: 80vh;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  @media screen and (max-width: 500px) {
    h1 {
      font-size: 1.5rem;
    }
  }

  .panel-wrapper {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 100px;
    grid-template-areas: 'panel logo';
    .panel {
      grid-area: panel;
    }
    .logo {
      grid-area: logo;
    }
  }
`

const ControlWrapper = styled.div`
  max-width: 500px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 20px;
  margin-bottom: 20px;
`

const ButtonWrapper = styled.div`
  @media only screen and (min-width: 350px) {
    margin-top: 1em;
  }
  @media only screen and (max-width: 349px) {
    > * {
      margin: auto;
    }
  }
`

const mapStateToProps = state => {
  return {
    processinstance: getProcessInstanceState(state),
    setupinstance: getSetupInstanceState(state),
  }
}

const mapDispatchToProps = {
  push: push,
  SagaSetupRequestOTPCode: SetupProcessingActionCreators.SagaSetupRequestOTPCode.create,
  SagaSetupValidateOTPCode: SetupProcessingActionCreators.SagaSetupValidateOTPCode.create,
  SagaSetupSearchHost: SetupProcessingActionCreators.SagaSetupSearchHost.create,
  SagaSetupGetAttendeeDetailsForLocation: SetupProcessingActionCreators.SagaSetupGetAttendeeDetailsForLocation.create,
  SagaSetupCreateInvitesForEvent: SetupProcessingActionCreators.SagaSetupCreateInvitesForEvent.create,
}

const stateProps = getReturnOfExpression(mapStateToProps)

type LocalCompProps = {
  className?: string
  entityName: string
}

type CompProps = typeof stateProps & typeof mapDispatchToProps & LocalCompProps

interface DeltaDetail {
  dataKey: string
  dataValue: string
}
export interface FlowControl {
  otpRequest: {
    otpRequesTimeStamp: number
    otpRequestSuccessfull: boolean
    otpRequestErrorMessage: string | undefined
    otpRequestInProcess: boolean
  }
  otpValidation: {
    otpValidationTimeStamp: number
    otpValidationSuccessfull: boolean
    otpValidationErrorMessage: string | undefined
    otpValidationErrorType: enums.OTP_VALIDATION_ERRORS | undefined
    otpValidationInProcess: boolean
  }
  hostSearchRequest: {
    hostSearchTimeStamp: number
    hostSearchSuccessfull: boolean
    hostSearchErrorMessage: string | undefined
    hostSearchErrorType: enums.SEARCH_SETUP_HOSTS_ERRORS | undefined
    hostSearchInProcess: boolean
    hostSearchSecondaryInProcess: boolean
  }
  attendeeDetails: {
    attendeeDetailsTimeStamp: number
    attendeeDetailsSuccessfull: boolean
    attendeeDetailsErrorMessage: string | undefined
    attendeeDetailsErrorType: enums.SETUP_GET_ATTENDEE_ERRORS | undefined
    attendeeDetailsInProcess: boolean
  }
  createInvites: {
    createInvitesTimeStamp: number
    createInvitesSuccessfull: boolean
    createInvitesErrorMessage: string | undefined
    createInvitesErrorType: enums.SETUP_CREATE_INVITES_ERRORS | undefined
    createInvitesInProcess: boolean
  }
  resendAction: {
    resendActionTimeStamp: number
    resendActionSuccessfull: boolean
    resendActionErrorMessage: string | undefined
    resendActionErrorType: enums.SETUP_RESEND_ACTION_ERRORS | undefined
    resendActionInProcess: boolean
  }
  setupProgress: {
    locationSelected: boolean
    hostSelected: boolean
    visitorTypeSelected: boolean
    hasDelta: boolean
    deltaDetails: Array<DeltaDetail>
    saveSettingsAsDefault: boolean
    createInvitesButtonEnabled: boolean
  }
}

type FormValue = {
  value: string
  isValid: boolean
  data?: object
}

export interface FormValues {
  otpCode: FormValue
  eventDate: string
  eventSummary: string
  inviteEmailDistrubutionType: number
  sendToExternalOnly: boolean
  automationConfigSource: string
  locationKey: string
  hostKey: string
  hostNameText: string
  secondHostKey: string
  secondHostNameText: string
  visitorFlowIndex: number
}

export interface DataSources {
  locations: Array<SelectData>
  distrubutionTypes: Array<SelectData>
  visitorTypes: Array<SelectData>
  hosts: Array<DataSourceItemObject>
  attendees: Array<interfaces.SETUP_ATTENDEE_DETAILS>
}

type State = {
  flowControl: FlowControl
  formValues: FormValues
  dataSources: DataSources
}

class SetupPage extends React.Component<CompProps, State> {
  otpFormRef: ExtendedWrappedFormUtils | any

  constructor(props) {
    super(props)
    this.state = {
      flowControl: {
        otpRequest: {
          otpRequesTimeStamp: 0,
          otpRequestSuccessfull: false,
          otpRequestErrorMessage: undefined,
          otpRequestInProcess: false,
        },
        otpValidation: {
          otpValidationTimeStamp: 0,
          otpValidationSuccessfull: false,
          otpValidationErrorMessage: undefined,
          otpValidationErrorType: undefined,
          otpValidationInProcess: false,
        },
        hostSearchRequest: {
          hostSearchTimeStamp: 0,
          hostSearchSuccessfull: false,
          hostSearchErrorMessage: undefined,
          hostSearchErrorType: undefined,
          hostSearchInProcess: false,
          hostSearchSecondaryInProcess: false,
        },
        attendeeDetails: {
          attendeeDetailsTimeStamp: 0,
          attendeeDetailsSuccessfull: false,
          attendeeDetailsErrorMessage: undefined,
          attendeeDetailsErrorType: undefined,
          attendeeDetailsInProcess: false,
        },
        createInvites: {
          createInvitesTimeStamp: 0,
          createInvitesSuccessfull: false,
          createInvitesErrorMessage: undefined,
          createInvitesErrorType: undefined,
          createInvitesInProcess: false,
        },
        resendAction: {
          resendActionTimeStamp: 0,
          resendActionSuccessfull: false,
          resendActionErrorMessage: undefined,
          resendActionErrorType: undefined,
          resendActionInProcess: false,
        },
        setupProgress: {
          locationSelected: false,
          hostSelected: false,
          visitorTypeSelected: false,
          hasDelta: false,
          deltaDetails: [],
          saveSettingsAsDefault: false,
          createInvitesButtonEnabled: false,
        },
      },
      formValues: {
        otpCode: {
          value: '',
          isValid: false,
        },
        eventDate: '',
        eventSummary: '',
        inviteEmailDistrubutionType: 0,
        sendToExternalOnly: true,
        automationConfigSource: '',
        locationKey: '',
        hostKey: '',
        hostNameText: '',
        secondHostKey: '',
        secondHostNameText: '',
        visitorFlowIndex: -1,
      },
      dataSources: {
        locations: [],
        visitorTypes: [],
        hosts: [],
        attendees: [],
        distrubutionTypes: [
          {
            key: '0',
            value: translate({ id: 'form.setup.distro-types.all-visitors' }),
          },
          {
            key: '1',
            value: translate({
              id: 'form.setup.distro-types.unregistered-visitors',
            }),
          },
          {
            key: '2',
            value: translate({ id: 'form.setup.text.dont-send' }),
          },
        ],
      },
    }
    document.title = 'Invite Setup'
  }

  // tslint:disable-next-line:member-ordering
  static getDerivedStateFromProps(nextProps: CompProps, prevState: State) {
    if (
      nextProps.setupinstance.otpRequestTimeStamp !== prevState.flowControl.otpRequest.otpRequesTimeStamp &&
      prevState.flowControl.otpRequest.otpRequestInProcess === true
    ) {
      return {
        flowControl: {
          ...prevState.flowControl,
          otpRequest: {
            ...prevState.flowControl.otpRequest,
            otpRequesTimeStamp: nextProps.setupinstance.otpRequestTimeStamp,
            otpRequestSuccessfull: nextProps.setupinstance.otpRequestErrorMessage === undefined,
            otpRequestErrorMessage: nextProps.setupinstance.otpRequestErrorMessage,
            otpRequestInProcess: false,
          },
        },
      }
    } else if (
      nextProps.setupinstance.otpValidationTimeStamp !== prevState.flowControl.otpValidation.otpValidationTimeStamp &&
      prevState.flowControl.otpValidation.otpValidationInProcess === true
    ) {
      if (nextProps.setupinstance.eventDetails && nextProps.setupinstance.eventDetails.setupLocationKey) {
        //previous selected values exist
        const setupLocationKey = nextProps.setupinstance.eventDetails.setupLocationKey
        const otpValidationSuccessfull = nextProps.setupinstance.otpValidationErrorMessage === undefined
        const availableIdentities = nextProps.setupinstance.eventDetails.locationData.filter(
          location => location.EntityHierarchy === setupLocationKey
        )[0].availableIdentities
        const selectableIdentities =
          availableIdentities &&
          availableIdentities.map(item => ({ key: `${item.flowIndex}`, value: item.text })).concat({ key: '-1', value: '' })
        return {
          flowControl: {
            ...prevState.flowControl,
            otpValidation: {
              ...prevState.flowControl.otpValidation,
              otpValidationTimeStamp: nextProps.setupinstance.otpValidationTimeStamp,
              otpValidationSuccessfull: otpValidationSuccessfull,
              otpValidationErrorMessage: nextProps.setupinstance.otpValidationErrorMessage,
              otpValidationErrorType: nextProps.setupinstance.otpValidationErrorType,
              otpValidationInProcess: false,
            },
            attendeeDetails: {
              attendeeDetailsSuccessfull: false,
              attendeeDetailsErrorMessage: undefined,
              attendeeDetailsErrorType: undefined,
              attendeeDetailsInProcess: true,
            },
            setupProgress: {
              locationSelected: true,
              hostSelected: true,
              visitorTypeSelected: true,
              createInvitesButtonEnabled: true,
              hasDelta: false,
              deltaDetails: [],
            },
          },
          ...(otpValidationSuccessfull && {
            formValues: {
              ...prevState.formValues,
              eventDate: getTimeStringFromEventMetaData(nextProps.setupinstance.eventDetails.eventMetaData),
              eventSummary: nextProps.setupinstance.eventDetails.eventMetaData.summary,
              inviteEmailDistrubutionType: nextProps.setupinstance.eventDetails.setupInviteEmailDistrubutionType,
              sendToExternalOnly: nextProps.setupinstance.eventDetails.setupSendToExternalOnly,
              automationConfigSource: nextProps.setupinstance.eventDetails.setupAutomationConfigSource,
              locationKey: nextProps.setupinstance.eventDetails.setupLocationKey,
              hostKey: nextProps.setupinstance.eventDetails.setupHostKey,
              hostNameText: nextProps.setupinstance.eventDetails.setupHostNameText,
              secondHostKey: nextProps.setupinstance.eventDetails.setupSecondHostKey,
              secondHostNameText: nextProps.setupinstance.eventDetails.setupSecondHostNameText,
              visitorFlowIndex:
                nextProps.setupinstance.eventDetails.setupVisitorFlowIndex === -1
                  ? 0
                  : nextProps.setupinstance.eventDetails.setupVisitorFlowIndex, //as this selection is disabled we will use default of 0 or index provided from default, we cannot use -1 as user cannot input as the control is hidden
            },
            dataSources: {
              ...prevState.dataSources,
              locations: nextProps.setupinstance.eventDetails.locationData.map(item => ({
                key: item.EntityHierarchy,
                value: item.entityLocationData.locationShortDescription,
              })),
              visitorTypes: selectableIdentities,
            },
          }),
        }
      } else {
        const otpValidationSuccessfull = nextProps.setupinstance.otpValidationErrorMessage === undefined
        return {
          flowControl: {
            ...prevState.flowControl,
            otpValidation: {
              ...prevState.flowControl.otpValidation,
              otpValidationTimeStamp: nextProps.setupinstance.otpValidationTimeStamp,
              otpValidationSuccessfull: otpValidationSuccessfull,
              otpValidationErrorMessage: nextProps.setupinstance.otpValidationErrorMessage,
              otpValidationErrorType: nextProps.setupinstance.otpValidationErrorType,
              otpValidationInProcess: false,
            },
          },
          ...(otpValidationSuccessfull && {
            formValues: {
              ...prevState.formValues,
              ...(nextProps.setupinstance.eventDetails && {
                eventDate: getTimeStringFromEventMetaData(nextProps.setupinstance.eventDetails.eventMetaData),
                eventSummary: nextProps.setupinstance.eventDetails.eventMetaData.summary,
              }),
            },
            dataSources: {
              ...prevState.dataSources,
              ...(nextProps.setupinstance.eventDetails && {
                locations: nextProps.setupinstance.eventDetails.locationData.map(item => ({
                  key: item.EntityHierarchy,
                  value: item.entityLocationData.locationShortDescription,
                })),
              }),
            },
          }),
        }
      }
    } else if (
      nextProps.setupinstance.hostSearchTimeStamp !== prevState.flowControl.hostSearchRequest.hostSearchTimeStamp &&
      (prevState.flowControl.hostSearchRequest.hostSearchInProcess === true ||
        prevState.flowControl.hostSearchRequest.hostSearchSecondaryInProcess === true)
    ) {
      const hostSearchSuccessfull = nextProps.setupinstance.hostSearchErrorMessage === undefined
      let hosts: Array<DataSourceItemObject> = []
      if (hostSearchSuccessfull && nextProps.setupinstance.hostSearchResults) {
        hosts = nextProps.setupinstance.hostSearchResults.map(host => ({
          value: host.uniqueAttributeValue,
          text: host.name,
        }))
      }
      return {
        flowControl: {
          ...prevState.flowControl,
          hostSearchRequest: {
            ...prevState.flowControl.hostSearchRequest,
            hostSearchTimeStamp: nextProps.setupinstance.hostSearchTimeStamp,
            hostSearchSuccessfull: hostSearchSuccessfull,
            hostSearchErrorMessage: nextProps.setupinstance.hostSearchErrorMessage,
            hostSearchErrorType: nextProps.setupinstance.hostSearchErrorType,
            hostSearchInProcess: false,
            hostSearchSecondaryInProcess: false,
          },
        },
        ...(hostSearchSuccessfull && {
          dataSources: {
            ...prevState.dataSources,
            hosts: hosts,
          },
        }),
      }
    } else if (
      nextProps.setupinstance.attendeeDetailsTimeStamp !== prevState.flowControl.attendeeDetails.attendeeDetailsTimeStamp &&
      prevState.flowControl.attendeeDetails.attendeeDetailsInProcess === true
    ) {
      const attendeeDetailsSuccessfull = nextProps.setupinstance.attendeeDetailsErrorMessage === undefined
      let attendees: Array<interfaces.SETUP_ATTENDEE_DETAILS> = []
      if (attendeeDetailsSuccessfull && nextProps.setupinstance.attendeeDetails) {
        attendees = nextProps.setupinstance.attendeeDetails.map(attendee => {
          return attendee
        })
      }
      return {
        flowControl: {
          ...prevState.flowControl,
          attendeeDetails: {
            ...prevState.flowControl.attendeeDetails,
            attendeeDetailsTimeStamp: nextProps.setupinstance.attendeeDetailsTimeStamp,
            attendeeDetailsSuccessfull: attendeeDetailsSuccessfull,
            attendeeDetailsErrorMessage: nextProps.setupinstance.attendeeDetailsErrorMessage,
            attendeeDetailsErrorType: nextProps.setupinstance.attendeeDetailsErrorType,
            attendeeDetailsInProcess: false,
          },
        },
        ...(attendeeDetailsSuccessfull && {
          dataSources: {
            ...prevState.dataSources,
            attendees: attendees,
            initialisationAttendees: [],
          },
        }),
      }
    } else if (
      nextProps.setupinstance.createInvitesTimeStamp !== prevState.flowControl.createInvites.createInvitesTimeStamp &&
      prevState.flowControl.createInvites.createInvitesInProcess === true
    ) {
      const createInvitesSuccessfull = nextProps.setupinstance.createInvitesErrorMessage === undefined
      return {
        flowControl: {
          ...prevState.flowControl,
          createInvites: {
            ...prevState.flowControl.createInvites,
            createInvitesTimeStamp: nextProps.setupinstance.createInvitesTimeStamp,
            createInvitesSuccessfull: createInvitesSuccessfull,
            createInvitesErrorMessage: nextProps.setupinstance.createInvitesErrorMessage,
            createInvitesErrorType: nextProps.setupinstance.createInvitesErrorType,
            createInvitesInProcess: false,
          },
        },
      }
    } else if (
      nextProps.setupinstance.resendActionTimeStamp !== prevState.flowControl.resendAction.resendActionTimeStamp &&
      prevState.flowControl.resendAction.resendActionInProcess === true
    ) {
      const resendActionSuccessfull = nextProps.setupinstance.resendActionErrorMessage === undefined
      return {
        flowControl: {
          ...prevState.flowControl,
          resendAction: {
            ...prevState.flowControl.resendAction,
            resendActionTimeStamp: nextProps.setupinstance.resendActionTimeStamp,
            resendActionSuccessfull: resendActionSuccessfull,
            resendActionErrorMessage: nextProps.setupinstance.resendActionErrorMessage,
            resendActionErrorType: nextProps.setupinstance.resendActionErrorType,
            resendActionInProcess: false,
          },
        },
      }
    } else {
      return null
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.flowControl.createInvites.createInvitesInProcess === true &&
      this.state.flowControl.createInvites.createInvitesInProcess === false
    ) {
      if (this.state.flowControl.createInvites.createInvitesSuccessfull) {
        this.props.push('/setupcomplete')
      } else if (!this.state.flowControl.createInvites.createInvitesSuccessfull) {
        notification.error({
          message: translate({ id: 'page.error.text.title' }),
          description: this.state.flowControl.createInvites.createInvitesErrorMessage,
        })
      }
    } else if (
      prevState.flowControl.resendAction.resendActionInProcess === true &&
      this.state.flowControl.resendAction.resendActionInProcess === false
    ) {
      if (this.state.flowControl.resendAction.resendActionSuccessfull) {
        notification.success({
          message: translate({ id: 'form.setup.text.success' }),
          description: translate({
            id: 'form.setup.text.resend-success-submit',
          }),
        })
      } else if (!this.state.flowControl.resendAction.resendActionSuccessfull) {
        notification.error({
          message: translate({ id: 'page.error.text.title' }),
          description: this.state.flowControl.resendAction.resendActionErrorMessage,
        })
      }
    } else if (prevState.formValues.locationKey !== this.state.formValues.locationKey && this.state.formValues.locationKey.length > 0) {
      this.props.SagaSetupGetAttendeeDetailsForLocation({
        entityHierarchy: this.state.formValues.locationKey,
      })
    }
  }

  componentDidMount() {
    this.requestSetupOTP()
  }

  _setOtpFormRef = (formRef: any) => {
    this.otpFormRef = formRef as ExtendedWrappedFormUtils
  }

  requestSetupOTP = () => {
    this.setState(
      update(this.state, {
        flowControl: {
          otpRequest: {
            otpRequestInProcess: {
              $set: true,
            },
          },
          otpValidation: {
            otpValidationSuccessfull: { $set: false },
            otpValidationErrorMessage: { $set: undefined },
            otpValidationErrorType: { $set: undefined },
            otpValidationInProcess: { $set: false },
            otpValidationTimeStamp: { $set: 0 },
          },
        },
        formValues: {
          otpCode: {
            value: {
              $set: '',
            },
            isValid: {
              $set: false,
            },
          },
        },
      }),
      () => {
        this.props.SagaSetupRequestOTPCode()
        if (isMobile) {
          let instance = this.otpFormRef.getFieldInstance('otpCode')
          if (instance) {
            try {
              instance.blur()
            } catch (e) {
              //hi hi hi ha ha ha blah bleh
            }
          }
        }
      }
    )
  }

  validateSetupOTP = () => {
    if (this.state.flowControl.otpRequest.otpRequestSuccessfull) {
      this.setState(
        update(this.state, {
          flowControl: {
            otpValidation: {
              otpValidationInProcess: {
                $set: true,
              },
              otpValidationSuccessfull: { $set: false },
              otpValidationErrorMessage: { $set: undefined },
              otpValidationErrorType: { $set: undefined },
            },
          },
        }),
        () => {
          this.props.SagaSetupValidateOTPCode({
            otpCode: this.state.formValues.otpCode.value,
          })
          if (isMobile) {
            let instance = this.otpFormRef.getFieldInstance('otpCode')
            if (instance) {
              try {
                instance.blur()
              } catch (e) {
                //hi hi hi ha ha ha blah bleh
              }
            }
          }
        }
      )
    } else {
      //form validation has failed - consider triggering error notification from here if this is found to be possible
    }
  }

  _onOtpFieldsChange = (changedFields: object): void => {
    const formValuesUpdates: any = {}
    const flowControlUpdates: any = {}
    let isDirtyValues = false
    let isDirtyFlow = false
    forOwn(changedFields, (changeDetails, formValue) => {
      if (changeDetails.validating === false || !changeDetails.hasOwnProperty('validating')) {
        isDirtyValues = true
        let isValid = false
        if (changeDetails.isValid) {
          isValid = true
        }
        formValuesUpdates[formValue] = {
          $set: {
            ...changeDetails,
            isValid,
          },
        }
        if (changeDetails.name === 'otpCode') {
          isDirtyFlow = true
          flowControlUpdates['otpValidation'] = {
            otpValidationSuccessfull: { $set: false },
            otpValidationErrorMessage: { $set: undefined },
            otpValidationErrorType: { $set: undefined },
            otpValidationInProcess: { $set: false },
          }
        }
      }
    })
    if (isDirtyValues || isDirtyFlow) {
      this.setState(
        update(this.state, {
          ...(isDirtyValues
            ? {
                formValues: {
                  ...formValuesUpdates,
                },
              }
            : {}),
          ...(isDirtyFlow
            ? {
                flowControl: {
                  ...flowControlUpdates,
                },
              }
            : {}),
        })
      )
    }
  }

  _calculateDelta = (dataKey: string, dataValue: string | number): { hasDelta: boolean; deltaDetails: Array<DeltaDetail> } => {
    let hasFieldDelta = false
    let deltaDetails = cloneDeep(this.state.flowControl.setupProgress.deltaDetails)
    let locationKey = dataKey === 'setupLocationKey' ? dataValue : this.state.formValues.locationKey
    if (this.props.setupinstance.eventDetails && this.props.setupinstance.eventDetails.setupLocationKey === locationKey) {
      if (dataValue !== this.props.setupinstance.eventDetails[dataKey]) {
        hasFieldDelta = true
      } else {
        hasFieldDelta = false
      }
    } else {
      if (`${dataValue}`.length > 0) {
        hasFieldDelta = true
      } else {
        hasFieldDelta = false
      }
    }
    if (hasFieldDelta) {
      if (dataKey === 'setupLocationKey') {
        deltaDetails = [
          {
            dataKey,
            dataValue,
          },
        ]
      } else {
        const deltaIndex = deltaDetails.findIndex(delta => delta.dataKey === dataKey)
        if (deltaIndex > -1) {
          deltaDetails[deltaIndex] = {
            dataKey,
            dataValue,
          }
        } else {
          deltaDetails.push({
            dataKey,
            dataValue,
          })
        }
      }
    } else {
      const deltaIndex = deltaDetails.findIndex(delta => delta.dataKey === dataKey)
      if (deltaIndex > -1) {
        deltaDetails.splice(deltaIndex, 1)
      }
    }
    let hasDelta = false
    if (deltaDetails.length > 0) {
      hasDelta = true
    }
    return { hasDelta, deltaDetails }
  }

  _setSelectedLocation = (value: SelectValue, option: React.ReactElement<any>) => {
    const availableIdentities =
      this.props.setupinstance.eventDetails &&
      this.props.setupinstance.eventDetails.locationData.filter(location => location.EntityHierarchy === (value as string))[0]
        .availableIdentities
    const selectableIdentities =
      availableIdentities &&
      availableIdentities.map(item => ({ key: `${item.flowIndex}`, value: item.text })).concat({ key: '-1', value: '' })
    const { hasDelta, deltaDetails } = this._calculateDelta('setupLocationKey', value as string)
    let hostKey = ''
    let hostNameText = ''
    let secondHostKey = ''
    let secondHostNameText = ''
    let visitorFlowIndex = -1
    if (this.props.setupinstance.eventDetails && this.props.setupinstance.eventDetails.setupLocationKey === (value as string)) {
      hostKey = this.props.setupinstance.eventDetails.setupHostKey
      hostNameText = this.props.setupinstance.eventDetails.setupHostNameText
      secondHostKey = this.props.setupinstance.eventDetails.setupSecondHostKey
      secondHostNameText = this.props.setupinstance.eventDetails.setupSecondHostNameText
      visitorFlowIndex = this.props.setupinstance.eventDetails.setupVisitorFlowIndex
        ? this.props.setupinstance.eventDetails.setupVisitorFlowIndex
        : -1
    }
    visitorFlowIndex = visitorFlowIndex === -1 ? 0 : visitorFlowIndex //as this selection is disabled we will use default of 0 or index provided from default, we cannot use -1 as user cannot input as the control is hidden
    this.setState(
      update(this.state, {
        formValues: {
          locationKey: {
            $set: value as string,
          },
          hostKey: {
            $set: hostKey,
          },
          hostNameText: {
            $set: hostNameText,
          },
          secondHostKey: {
            $set: secondHostKey,
          },
          secondHostNameText: {
            $set: secondHostNameText,
          },
          visitorFlowIndex: {
            $set: visitorFlowIndex,
          },
        },
        flowControl: {
          attendeeDetails: {
            attendeeDetailsSuccessfull: {
              $set: false,
            },
            attendeeDetailsErrorMessage: {
              $set: undefined,
            },
            attendeeDetailsErrorType: {
              $set: undefined,
            },
            attendeeDetailsInProcess: {
              $set: true,
            },
          },
          setupProgress: {
            locationSelected: {
              $set: (value as string).length > 0,
            },
            hostSelected: {
              $set: hostKey.length > 0,
            },
            visitorTypeSelected: {
              $set: visitorFlowIndex !== -1,
            },
            createInvitesButtonEnabled: {
              $set: (value as string).length > 0 && hostKey.length > 0 && visitorFlowIndex !== -1,
            },
            hasDelta: {
              $set: hasDelta,
            },
            deltaDetails: {
              $set: deltaDetails,
            },
          },
        },
        dataSources: {
          visitorTypes: {
            $set: selectableIdentities as Array<SelectData>,
          },
          hosts: {
            $set: [],
          },
          attendees: {
            $set: [],
          },
        },
      })
    )
  }

  _setSelectedVisitorType = (value: SelectValue, option: React.ReactElement<any>) => {
    const { hasDelta, deltaDetails } = this._calculateDelta('setupVisitorFlowIndex', parseInt(value as string, 10))
    let attendees = cloneDeep(this.state.dataSources.attendees)
    if (value !== this.state.formValues.visitorFlowIndex) {
      if (hasDelta) {
        attendees = attendees.map(attendee => {
          delete attendee.inviteSendPopoverVisible
          delete attendee.inviteSendPopoverKey
          delete attendee.inviteSendPopoverChanges
          delete attendee.inviteSendPopoverQuestion
          delete attendee.inviteSendPopoverSendText
          delete attendee.inviteSendPopoverEmailCommand
          if (attendee.shouldCreateInvite && deltaDetails.length === 1 && deltaDetails[0].dataKey === 'setupVisitorFlowIndex') {
            attendee.inviteEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.DONT_SEND
            attendee.inviteSendText = translate({
              id: 'form.setup.text.dont-send',
            })
          }
          return attendee
        })
      } else {
        attendees = attendees.map(attendee => {
          delete attendee.inviteSendPopoverVisible
          delete attendee.inviteSendPopoverKey
          delete attendee.inviteSendPopoverChanges
          delete attendee.inviteSendPopoverQuestion
          delete attendee.inviteSendPopoverSendText
          delete attendee.inviteSendPopoverEmailCommand
          if (attendee.shouldCreateInvite) {
            if (attendee.inviteHasBeenMailed === true) {
              attendee.inviteEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.DONT_SEND
              attendee.inviteSendText = translate({
                id: 'form.setup.text.sent',
              })
            }
          }
          return attendee
        })
      }
    }
    this.setState(
      update(this.state, {
        formValues: {
          visitorFlowIndex: {
            $set: parseInt(value as string, 10),
          },
        },
        flowControl: {
          setupProgress: {
            visitorTypeSelected: {
              $set: parseInt(value as string, 10) !== -1,
            },
            createInvitesButtonEnabled: {
              $set:
                parseInt(value as string, 10) !== -1 &&
                this.state.flowControl.setupProgress.locationSelected &&
                this.state.flowControl.setupProgress.hostSelected,
            },
            hasDelta: {
              $set: hasDelta,
            },
            deltaDetails: {
              $set: deltaDetails,
            },
          },
        },
        dataSources: {
          attendees: {
            $set: attendees,
          },
        },
      })
    )
  }

  _onHostSearchPrimary = (value: string): void => {
    this.setState(
      update(this.state, {
        formValues: {
          hostNameText: {
            $set: value,
          },
        },
        flowControl: {
          hostSearchRequest: {
            hostSearchInProcess: {
              $set: true,
            },
          },
          setupProgress: {
            hostSelected: {
              $set: false,
            },
            createInvitesButtonEnabled: {
              $set: false,
            },
          },
        },
      }),
      () => {
        this.props.SagaSetupSearchHost({
          searchString: value,
          entityHierarchy: this.state.formValues.locationKey,
        })
      }
    )
  }

  _onHostSearchSecondary = (value: string): void => {
    this.setState(
      update(this.state, {
        formValues: {
          secondHostNameText: {
            $set: value,
          },
        },
        flowControl: {
          hostSearchRequest: {
            hostSearchSecondaryInProcess: {
              $set: true,
            },
          },
        },
      }),
      () => {
        this.props.SagaSetupSearchHost({
          searchString: value,
          entityHierarchy: this.state.formValues.locationKey,
        })
      }
    )
  }

  _onHostSelect = (value: SelectValue, option: any): void => {
    const { hasDelta, deltaDetails } = this._calculateDelta('setupHostKey', value as string)
    let attendees = cloneDeep(this.state.dataSources.attendees)
    if (value !== this.state.formValues.hostKey) {
      if (hasDelta) {
        attendees = attendees.map(attendee => {
          delete attendee.inviteSendPopoverVisible
          delete attendee.inviteSendPopoverKey
          delete attendee.inviteSendPopoverChanges
          delete attendee.inviteSendPopoverQuestion
          delete attendee.inviteSendPopoverSendText
          delete attendee.inviteSendPopoverEmailCommand
          if (attendee.shouldCreateInvite) {
            if (deltaDetails.length === 1 && deltaDetails[0].dataKey === 'setupVisitorFlowIndex') {
              attendee.inviteEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.DONT_SEND
              attendee.inviteSendText = translate({
                id: 'form.setup.text.dont-send',
              })
            } else {
              attendee.inviteEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.SEND
              attendee.inviteSendText = translate({
                id: 'form.setup.text.send',
              })
            }
          }
          return attendee
        })
      } else {
        attendees = attendees.map(attendee => {
          delete attendee.inviteSendPopoverVisible
          delete attendee.inviteSendPopoverKey
          delete attendee.inviteSendPopoverChanges
          delete attendee.inviteSendPopoverQuestion
          delete attendee.inviteSendPopoverSendText
          delete attendee.inviteSendPopoverEmailCommand
          if (attendee.shouldCreateInvite) {
            if (attendee.inviteHasBeenMailed === true) {
              attendee.inviteEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.DONT_SEND
              attendee.inviteSendText = translate({
                id: 'form.setup.text.sent',
              })
            }
          }
          return attendee
        })
      }
    }
    this.setState(
      update(this.state, {
        formValues: {
          hostKey: {
            $set: value as string,
          },
          hostNameText: {
            $set: option.props.children,
          },
          secondHostKey: {
            $set: (value as string) === '00000000' ? '' : this.state.formValues.secondHostKey,
          },
          secondHostNameText: {
            $set: (value as string) === '00000000' ? '' : this.state.formValues.secondHostNameText,
          },
        },
        flowControl: {
          setupProgress: {
            hostSelected: {
              $set: (value as string).length > 0,
            },
            createInvitesButtonEnabled: {
              $set:
                (value as string).length > 0 &&
                this.state.flowControl.setupProgress.locationSelected &&
                this.state.flowControl.setupProgress.visitorTypeSelected,
            },
            hasDelta: {
              $set: hasDelta,
            },
            deltaDetails: {
              $set: deltaDetails,
            },
          },
        },
        dataSources: {
          attendees: {
            $set: attendees,
          },
        },
      })
    )
  }

  _onSecondHostSelect = (value: SelectValue, option: any): void => {
    this.setState(
      update(this.state, {
        formValues: {
          secondHostKey: {
            $set: value as string,
          },
          secondHostNameText: {
            $set: option.props.children,
          },
        },
      })
    )
  }

  _onInviteAttendeeSwitchChange = (checked: boolean, email: string) => {
    const newAttendees = cloneDeep(this.state.dataSources.attendees)
    const attendeeIndex = newAttendees.findIndex(newAttendee => newAttendee.email === email)
    let attendee = newAttendees[attendeeIndex]
    attendee.shouldCreateInvite = checked
    delete attendee.inviteSendPopoverVisible
    delete attendee.inviteSendPopoverKey
    delete attendee.inviteSendPopoverChanges
    delete attendee.inviteSendPopoverQuestion
    delete attendee.inviteSendPopoverSendText
    delete attendee.inviteSendPopoverEmailCommand
    if (attendee.shouldCreateInvite) {
      attendee.inviteEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.SEND
      attendee.inviteSendText = translate({ id: 'form.setup.text.send' })
      attendee.isSendTicked = true
    } else {
      attendee.inviteEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.DONT_SEND
      attendee.inviteSendText = 'N/A'
      attendee.isSendTicked = true
    }

    this.setState(
      update(this.state, {
        dataSources: {
          attendees: {
            $set: newAttendees,
          },
        },
      })
    )
  }

  _onCreateInvitesButtonClick = () => {
    this.setState(
      update(this.state, {
        flowControl: {
          createInvites: {
            createInvitesSuccessfull: {
              $set: false,
            },
            createInvitesErrorMessage: {
              $set: undefined,
            },
            createInvitesErrorType: {
              $set: undefined,
            },
            createInvitesInProcess: {
              $set: true,
            },
          },
        },
      }),
      () => {
        const setupAttendeeProcessing = cloneDeep(this.state.dataSources.attendees).map(attendee => {
          delete attendee.inviteSendText
          delete attendee.inviteSendPopoverVisible
          delete attendee.inviteSendPopoverKey
          delete attendee.inviteSendPopoverChanges
          delete attendee.inviteSendPopoverQuestion
          delete attendee.inviteSendPopoverSendText
          delete attendee.inviteSendPopoverEmailCommand
          attendee.processingSource = 'MANUAL_CONFIG'
          return attendee
        })

        let setupDontSendOrganizerConfirmation = false

        if (this.props.setupinstance.eventDetails) {
          const selectedLocationData = this.props.setupinstance.eventDetails.locationData.find(
            location => location.EntityHierarchy === this.state.formValues.locationKey
          )
          if (
            selectedLocationData &&
            selectedLocationData.inviteAutomation &&
            selectedLocationData.inviteAutomation.hasOwnProperty('setupDontSendOrganizerConfirmation')
          ) {
            setupDontSendOrganizerConfirmation = selectedLocationData.inviteAutomation.setupDontSendOrganizerConfirmation as boolean
          }
        }

        //fallbacks for organizerkeys for old events
        let setupOrganizerKey = this.state.formValues.hostKey
        let setupOrganizerNameText = this.state.formValues.hostNameText
        if (this.props.setupinstance.eventDetails) {
          if (this.props.setupinstance.eventDetails.setupOrganizerKey) {
            setupOrganizerKey = this.props.setupinstance.eventDetails.setupOrganizerKey
          }
          if (this.props.setupinstance.eventDetails.setupOrganizerNameText) {
            setupOrganizerNameText = this.props.setupinstance.eventDetails.setupOrganizerNameText
          }
        }
        const setupProcessingDetailsSubmission = {
          saveSettingsAsDefault: this.state.flowControl.setupProgress.saveSettingsAsDefault,
          setupAutomationConfigSource: this.state.formValues.automationConfigSource,
          setupSendToExternalOnly: this.state.formValues.sendToExternalOnly,
          setupInviteEmailDistrubutionType: this.state.formValues.inviteEmailDistrubutionType,
          setupLocationKey: this.state.formValues.locationKey,
          setupOrganizerKey,
          setupOrganizerNameText,
          setupHostKey: this.state.formValues.hostKey,
          setupHostNameText: this.state.formValues.hostNameText,
          setupSecondHostKey: this.state.formValues.secondHostKey,
          setupSecondHostNameText: this.state.formValues.secondHostNameText,
          setupVisitorFlowIndex: this.state.formValues.visitorFlowIndex,
          setupAttendeeProcessing,
          setupDontSendOrganizerConfirmation,
        }
        this.props.SagaSetupCreateInvitesForEvent(setupProcessingDetailsSubmission)
      }
    )
  }

  _onInviteAttendeeSendChange = (checkboxChangeEvent: CheckboxChangeEvent, email: string) => {
    const newAttendees = cloneDeep(this.state.dataSources.attendees)
    const attendeeIndex = newAttendees.findIndex(newAttendee => newAttendee.email === email)
    let attendee = newAttendees[attendeeIndex]
    attendee.inviteSendPopoverKey = Date.now()
    delete attendee.inviteSendPopoverVisible
    delete attendee.inviteSendPopoverChanges
    delete attendee.inviteSendPopoverQuestion
    delete attendee.inviteSendPopoverSendText
    delete attendee.inviteSendPopoverEmailCommand
    if (checkboxChangeEvent.target.checked) {
      if (this.state.flowControl.setupProgress.hasDelta) {
        attendee.inviteEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.SEND
        attendee.inviteSendText = translate({ id: 'form.setup.text.send' })
        attendee.inviteSendPopoverVisible = false
      } else {
        if (attendee.inviteHasBeenMailed === true) {
          attendee.inviteSendPopoverVisible = true
          attendee.inviteSendPopoverQuestion = translate({
            id: 'form.setup.send.popover.question.already-sent',
          })
          attendee.inviteSendPopoverSendText = translate({
            id: 'form.setup.text.send',
          })
          attendee.inviteSendPopoverEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.SEND
          attendee.inviteSendPopoverChanges = this.state.flowControl.setupProgress.deltaDetails.map(deltaDetail => {
            if (deltaDetail.dataKey === 'setupLocationKey') {
              return translate({ id: 'form.setup.meta.location' })
            }
            if (deltaDetail.dataKey === 'setupHostKey') {
              return translate({ id: 'form.setup.meta.host' })
            }
            if (deltaDetail.dataKey === 'setupVisitorFlowIndex') {
              return translate({ id: 'form.setup.meta.visitor-type' })
            }
            return ''
          })
        } else {
          attendee.inviteSendPopoverVisible = false
          delete attendee.inviteSendPopoverChanges
          delete attendee.inviteSendPopoverQuestion
          delete attendee.inviteSendPopoverSendText
          delete attendee.inviteSendPopoverEmailCommand
          attendee.inviteEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.SEND
          attendee.inviteSendText = translate({ id: 'form.setup.text.send' })
        }
      }
    } else {
      if (this.state.flowControl.setupProgress.hasDelta) {
        //check type of delta and then allow setting to Don't send
        //confirm then update state - may need to move this up before state update loop
        if (this.state.flowControl.setupProgress.deltaDetails.findIndex(deltaDetail => deltaDetail.dataKey === 'setupLocationKey') > -1) {
          attendee.inviteSendPopoverQuestion = translate({
            id: 'form.setup.send.popover.question.update.location',
          })
          attendee.inviteSendPopoverVisible = true
          attendee.inviteSendPopoverEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.DONT_SEND
          attendee.inviteSendPopoverSendText = translate({
            id: 'form.setup.text.dont-send',
          })
          attendee.inviteSendPopoverChanges = this.state.flowControl.setupProgress.deltaDetails.map(deltaDetail => {
            if (deltaDetail.dataKey === 'setupLocationKey') {
              return translate({ id: 'form.setup.meta.location' })
            }
            if (deltaDetail.dataKey === 'setupHostKey') {
              return translate({ id: 'form.setup.meta.host' })
            }
            if (deltaDetail.dataKey === 'setupVisitorFlowIndex') {
              return translate({ id: 'form.setup.meta.visitor-type' })
            }
            return ''
          })
        } else if (
          this.state.flowControl.setupProgress.deltaDetails.findIndex(deltaDetail => deltaDetail.dataKey === 'setupHostKey') > -1
        ) {
          attendee.inviteSendPopoverQuestion = translate({
            id: 'form.setup.send.popover.question.update.host',
          })
          attendee.inviteSendPopoverVisible = true
          attendee.inviteSendPopoverEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.DONT_SEND
          attendee.inviteSendPopoverSendText = translate({
            id: 'form.setup.text.dont-send',
          })
          attendee.inviteSendPopoverChanges = this.state.flowControl.setupProgress.deltaDetails.map(deltaDetail => {
            if (deltaDetail.dataKey === 'setupLocationKey') {
              return translate({ id: 'form.setup.meta.location' })
            }
            if (deltaDetail.dataKey === 'setupHostKey') {
              return translate({ id: 'form.setup.meta.host' })
            }
            if (deltaDetail.dataKey === 'setupVisitorFlowIndex') {
              return translate({ id: 'form.setup.meta.visitor-type' })
            }
            return ''
          })
        } else {
          attendee.inviteSendPopoverVisible = false
          delete attendee.inviteSendPopoverChanges
          delete attendee.inviteSendPopoverQuestion
          delete attendee.inviteSendPopoverSendText
          delete attendee.inviteSendPopoverEmailCommand
          attendee.inviteEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.DONT_SEND
          attendee.inviteSendText = translate({
            id: 'form.setup.text.dont-send',
          })
        }
      } else {
        attendee.inviteSendPopoverVisible = false
        if (attendee.inviteHasBeenMailed) {
          attendee.inviteEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.DONT_SEND
          attendee.inviteSendText = translate({ id: 'form.setup.text.sent' })
        } else {
          attendee.inviteEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.DONT_SEND
          attendee.inviteSendText = translate({
            id: 'form.setup.text.dont-send',
          })
        }
      }
    }
    this.setState(
      update(this.state, {
        dataSources: {
          attendees: {
            $set: newAttendees,
          },
        },
      })
    )
  }

  _onInviteAttendeeBulkSendChange = (checkboxChangeEvent: CheckboxChangeEvent) => {
    debugger
  }

  _onInviteAttendeeProcessPopupConfirmation = (confirmed: boolean, record: any) => {
    const newAttendees = cloneDeep(this.state.dataSources.attendees)
    const attendeeIndex = newAttendees.findIndex(newAttendee => newAttendee.email === record.email)
    let attendee = newAttendees[attendeeIndex]
    if (confirmed) {
      attendee.inviteEmailCommand = record.inviteSendPopoverEmailCommand
      attendee.inviteSendText = record.inviteSendPopoverSendText
    }
    attendee.inviteSendPopoverVisible = false
    attendee.inviteSendPopoverKey = Date.now()
    delete attendee.inviteSendPopoverChanges
    delete attendee.inviteSendPopoverQuestion
    delete attendee.inviteSendPopoverSendText
    delete attendee.inviteSendPopoverEmailCommand
    this.setState(
      update(this.state, {
        dataSources: {
          attendees: {
            $set: newAttendees,
          },
        },
      })
    )
  }

  _updateSaveSettingsAsDefault = (checkboxChangeEvent: CheckboxChangeEvent) => {
    this.setState(
      update(this.state, {
        flowControl: {
          setupProgress: {
            saveSettingsAsDefault: {
              $set: checkboxChangeEvent.target.checked,
            },
          },
        },
      })
    )
  }

  _setSelectedDistributionType = (value: SelectValue, option: React.ReactElement<any>) => {
    let attendees = cloneDeep(this.state.dataSources.attendees)
    const inviteEmailDistrubutionType = parseInt(value as string, 10)
    if (inviteEmailDistrubutionType !== this.state.formValues.inviteEmailDistrubutionType) {
      attendees = attendees.map(attendee => {
        delete attendee.inviteSendPopoverVisible
        delete attendee.inviteSendPopoverKey
        delete attendee.inviteSendPopoverChanges
        delete attendee.inviteSendPopoverQuestion
        delete attendee.inviteSendPopoverSendText
        delete attendee.inviteSendPopoverEmailCommand
        if (attendee.shouldCreateInvite === true) {
          if (inviteEmailDistrubutionType === 0) {
            if (attendee.inviteHasBeenMailed === true) {
              attendee.inviteEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.DONT_SEND
              attendee.inviteSendText = translate({
                id: 'form.setup.text.sent',
              })
            } else {
              attendee.inviteEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.SEND
              attendee.inviteSendText = translate({
                id: 'form.setup.text.send',
              })
            }
          } else if (inviteEmailDistrubutionType === 1) {
            if (attendee.isRegistered === false) {
              if (attendee.inviteHasBeenMailed === true) {
                attendee.inviteEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.DONT_SEND
                attendee.inviteSendText = translate({
                  id: 'form.setup.text.sent',
                })
              } else {
                attendee.inviteEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.SEND
                attendee.inviteSendText = translate({
                  id: 'form.setup.text.send',
                })
              }
            } else {
              if (attendee.inviteHasBeenMailed === true) {
                attendee.inviteEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.DONT_SEND
                attendee.inviteSendText = translate({
                  id: 'form.setup.text.sent',
                })
              } else {
                attendee.inviteEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.DONT_SEND
                attendee.inviteSendText = translate({
                  id: 'form.setup.text.dont-send',
                })
              }
            }
          } else if (inviteEmailDistrubutionType === 2) {
            attendee.inviteEmailCommand = enums.ATTENDEE_EMAIL_COMMANDS.DONT_SEND
            if (attendee.inviteHasBeenMailed === true) {
              attendee.inviteSendText = translate({
                id: 'form.setup.text.sent',
              })
            } else {
              attendee.inviteSendText = translate({
                id: 'form.setup.text.dont-send',
              })
            }
          }
        }
        return attendee
      })
    }
    this.setState(
      update(this.state, {
        formValues: {
          inviteEmailDistrubutionType: {
            $set: inviteEmailDistrubutionType,
          },
        },
        dataSources: {
          attendees: {
            $set: attendees,
          },
        },
      })
    )
  }

  render() {
    return (
      <SetupWrapper>
        {this.state.flowControl.otpValidation.otpValidationSuccessfull ? (
          <Spin
            spinning={
              this.state.flowControl.createInvites.createInvitesInProcess || this.state.flowControl.resendAction.resendActionInProcess
            }
          >
            <div className='panel-wrapper'>
              <div className='panel'>
                <h2>
                  <FormattedMessage id='form.setup.header.title' />
                </h2>
                <ControlWrapper>
                  <FlexItem title={<FormattedMessage id='form.setup.meta.event-summary' />}>
                    <small>{this.state.formValues.eventSummary}</small>
                  </FlexItem>
                  <FlexItem title={<FormattedMessage id='form.setup.meta.arrival-data-time' />}>
                    <small>{this.state.formValues.eventDate}</small>
                  </FlexItem>
                  <LocationSelection
                    data={this.state.dataSources.locations}
                    onSelect={this._setSelectedLocation}
                    value={this.state.formValues.locationKey}
                  />
                  {this.state.flowControl.setupProgress.locationSelected && (
                    <>
                      {this.state.dataSources.visitorTypes.length > 2 && ( //we will always have at least 2 - the -1 index is for defaulting upon no selection and is added in addition to 1 or more types
                        <VisitorTypeSelect
                          data={this.state.dataSources.visitorTypes}
                          onSelect={this._setSelectedVisitorType}
                          value={`${this.state.formValues.visitorFlowIndex}`}
                        />
                      )}

                      <DistributionTypeSelect
                        data={this.state.dataSources.distrubutionTypes}
                        onSelect={this._setSelectedDistributionType}
                        value={`${this.state.formValues.inviteEmailDistrubutionType}`}
                      />
                    </>
                  )}
                  {this.state.flowControl.setupProgress.locationSelected && (
                    <React.Fragment>
                      <HostSearch
                        title={<FormattedMessage id='form.setup.meta.host' />}
                        data={this.state.dataSources.hosts}
                        onSearch={this._onHostSearchPrimary}
                        onSelect={this._onHostSelect}
                        value={this.state.formValues.hostNameText}
                        loading={this.state.flowControl.hostSearchRequest.hostSearchInProcess}
                      />
                      <HostSearch
                        title={
                          <span>
                            <FormattedMessage id='form.setup.meta.secondary-host' />{' '}
                            <Popover
                              content={<FormattedMessage id='form.setup.meta.secondary-host.popover' tagName='p' />}
                              trigger='hover'
                              overlayClassName='dark-popover'
                              overlayStyle={{ width: '280px' }}
                            >
                              <i title='Filter menu' className='anticon ant-dropdown-trigger'>
                                <FontAwesomeIcon icon={faQuestionCircle} />
                              </i>
                            </Popover>
                          </span>
                        }
                        disabled={this.state.formValues.hostKey.length === 0 || this.state.formValues.hostKey === '00000000'}
                        data={this.state.dataSources.hosts}
                        onSearch={this._onHostSearchSecondary}
                        onSelect={this._onSecondHostSelect}
                        value={this.state.formValues.secondHostNameText}
                        loading={this.state.flowControl.hostSearchRequest.hostSearchSecondaryInProcess}
                      />
                    </React.Fragment>
                  )}
                </ControlWrapper>
              </div>
              <div className='logo'>
                <img src={KenaiLogo} alt='Kenai' width='100%' />
              </div>
            </div>
            {this.state.flowControl.setupProgress.locationSelected && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <InviteTable
                  attendees={this.state.dataSources.attendees}
                  attendeeDetailsFlowControl={this.state.flowControl.attendeeDetails}
                  onInviteAttendeeSwitchChange={this._onInviteAttendeeSwitchChange}
                  onInviteAttendeeSendChange={this._onInviteAttendeeSendChange}
                  onInviteAttendeeBulkSendChange={this._onInviteAttendeeBulkSendChange}
                  onInviteAttendeeProcessPopupConfirmation={this._onInviteAttendeeProcessPopupConfirmation}
                />
                <StyledCheckbox
                  checked={this.state.flowControl.setupProgress.saveSettingsAsDefault}
                  onChange={this._updateSaveSettingsAsDefault}
                >
                  <FormattedMessage id='form.setup.save-default' />
                </StyledCheckbox>
                <ButtonWrapper className='flex-container'>
                  <StyledButton
                    disabled={!this.state.flowControl.setupProgress.createInvitesButtonEnabled}
                    onClick={this._onCreateInvitesButtonClick}
                    type='primary'
                  >
                    <FormattedMessage id='form.setup.create-invites' />
                  </StyledButton>
                </ButtonWrapper>
              </div>
            )}
          </Spin>
        ) : (
          <div className='center-content'>
            <CaptureOTPForm
              onFieldsChange={this._onOtpFieldsChange}
              onResendOTP={this.requestSetupOTP}
              onOTPOkClick={this.validateSetupOTP}
              formValues={this.state.formValues}
              flowControl={this.state.flowControl}
              isMobile={isMobile}
              className={isMobile ? 'mobile-form' : 'web-form'}
              ref={this._setOtpFormRef}
            />
          </div>
        )}
      </SetupWrapper>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupPage)

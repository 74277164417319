const alpha = function(color: string, a: number) {
  const currentColor = color;
  const match = /rgba?\((\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(,\s*\d+[.\d+]*)*\)/g.exec(
    currentColor
  );
  a = a > 1 ? a / 100 : a;
  if (match) {
    return "rgba(" + [match[1], match[2], match[3], a].join(",") + ")";
  } else {
    return currentColor;
  }
};

export default alpha;

import { applyMiddleware, createStore, compose, Store, AnyAction } from 'redux'
import createHistory from 'history/createMemoryHistory'
import createSagaMiddleware from 'redux-saga'
import { History as AppHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'

import rootReducer, { RootState } from '~/store/rootReducer'
import SagaManager from '~/sagas/SagaManager'

// const history = createHistory({
//     basename: process.env.REACT_APP_BCR_URL
// });
const history = createHistory()

const sagaMiddleware = createSagaMiddleware()
const getMiddleware = () => {
  if (process.env.NODE_ENV === 'production') {
    return compose(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
  } else {
    return require('redux-devtools-extension').composeWithDevTools(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
        require('redux-logger').createLogger(),
        require('redux-immutable-state-invariant').default()
      )
    )
  }
}

export interface StoreRefs {
  store: Store<RootState, AnyAction>
  history: AppHistory
}

export default function createTheStore(): StoreRefs {
  let store = createStore(connectRouter(history)(rootReducer), getMiddleware())
  //start the sagas
  SagaManager.startSagas(sagaMiddleware)
  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept(() => {
      store.replaceReducer(require('~/store/rootReducer').default)
      SagaManager.cancelSagas(store)
      require('~/sagas/SagaManager').default.startSagas(sagaMiddleware)
    })
  }
  return { store, history }
}

import React from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import { FormattedMessage } from 'react-intl'

type ViewProps = {
  className?: string
  entityName: string
}

const ConfirmationContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  .details {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    .text {
      font-size: 1.2rem;
    }
  }
  .icon {
    position: absolute;
    width: 35vh;
    height: 35vh;
    color: green;
    opacity: 0.1;
    z-index: -1;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const LocalProfileConfirmedPageSFC = ({ className, entityName }: ViewProps) => {
  return (
    <Animated isVisible={true} animationIn='bounceInUp' animationOut='bounceOutDown' style={{ opacity: 1 }}>
      <React.Fragment>
        <ConfirmationContent className={className}>
          {/* <FontAwesomeIcon icon={faThumbsUp} className="icon" /> */}
          <div className='details'>
            <span className='text'>
              <FormattedMessage id='page.confirmation.text' /> <strong>{entityName}</strong>
            </span>
          </div>
        </ConfirmationContent>
      </React.Fragment>
    </Animated>
  )
}

export default LocalProfileConfirmedPageSFC

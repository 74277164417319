import React, { ReactNode } from 'react'
import styled from 'styled-components'

const FormLabelHoc = styled.div`
  display: grid;
  text-align: left;
  grid-template-columns: 30px 1fr;
  grid-template-areas:
    'number label  label'
    '...... sublabel sublabel';
  margin-bottom: 10px;
  .form-number {
    color: ${props => props.theme.branding.primary};
    font-size: 17px;
    grid-area: number;
    margin-right: 10px;
  }
  .form-label {
    font-size: 17px;
    white-space: normal;
    grid-area: label;
    display: inline-flex;
    /* * {
      padding-left: 4px;
    } */
  }
  .form-required {
    display: none;
  }
  .ant-form-item-required & {
    .form-required {
      display: block;
      line-height: 24px;
      padding-left: 5px;
    }
  }
  .form-sublabel {
    display: block;
    /* margin-left: 21px; */
    color: rgba(128, 128, 128, 0.6);
    font-size: 80%;
    white-space: normal;
    grid-area: sublabel;
    align-self: center;
    line-height: 12px;
    .ant-badge-status-text {
      font-size: 11px;
      margin-left: 2px;
      line-height: 9px;
      vertical-align: top;
    }
  }
  @media screen and (max-width: 400px) {
    max-width: 95vw;
    .form-number {
      font-size: 14px;
    }
    .form-label {
      font-size: 18px;
      line-height: 22px;
    }
    .ant-input,
    #number-input {
      font-size: 18px;
    }
  }
`

type Props = {
  number?: string
  label?: string
  sublabel?: string | ReactNode
  optional?: boolean
}

const FormLabelSFC: React.FC<Props> = props => (
  <FormLabelHoc>
    <span className='form-number'>{props.number && props.number.length > 0 ? `${props.number}.` : ''}</span>
    <span className='form-label'>
      <span>{props.children || props.label}</span>{' '}
      {props.optional && <span className='form-optional opacity-25 text-xs pl-2 leading-9'>(optional)</span>}
    </span>
    {props.sublabel &&
      (typeof props.sublabel === 'string' ? (
        <small className='form-sublabel'>{props.sublabel}</small>
      ) : (
        <div className='form-sublabel'> {props.sublabel}</div>
      ))}
  </FormLabelHoc>
)

export default FormLabelSFC

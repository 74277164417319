import { Input as AntInput } from "antd"

import styled from "styled-components"

const InputStyle = (Component) => styled(Component)`
  && {
    &:hover {
      .ant-input:not(.ant-input-disabled) {
        border-color: ${(props) => props.theme.branding.primary};
      }
    }
    .ant-input,
    &.ant-input {
      color: ${(props) => props.theme.branding.primary};
      &:focus,
      &:hover {
        border-color: ${(props) => props.theme.branding.primary};
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
`
export default InputStyle(AntInput)

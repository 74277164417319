import { getLocalMessage } from '~/app/components/Locale/localMessages'

const translate = (
  requiredSettings: {
    id: string
  },
  values?: object
) => {
  const { id } = requiredSettings

  let message = getLocalMessage(id)

  if (values) {
    const keys = Object.keys(values)
    keys.forEach(key => {
      message = message.replace(`{${key}}`, values[key])
    })
  }

  return message
}

export default translate

import React from 'react'
import initI18N from '~/app/components/Locale/initI18N'
import { MyLocaleProvider } from '~/app/components/Locale/LocaleContext'
import LocaleSwitch from '~/app/components/Locale/LocaleSwitch'
import { StoreProvider } from '~/store'
import AppRouter from './AppRouter'

const AppWrapper = props => {
  const initialValue = initI18N()
  return (
    <StoreProvider initialValue={initialValue}>
      <MyLocaleProvider locale={initialValue.locale}>
        <>
          <AppRouter storeRefs={props.storeRefs} />
          <LocaleSwitch />
        </>
      </MyLocaleProvider>
    </StoreProvider>
  )
}

export default AppWrapper

import React from 'react'
import { withRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import RegistrationForm from '~/app/components/RegistrationForm'
import { connect } from 'react-redux'
import { getProcessInstanceState } from '~/store/processinstance'
import { getReturnOfExpression } from 'utility-types'
import GlobalStyle from '~/app/styles/base'
// import { Animated } from "react-animated-css"

const mapStateToProps = state => {
  return {
    processinstance: getProcessInstanceState(state),
  }
}

const mapDispatchToProps = {}

const stateProps = getReturnOfExpression(mapStateToProps)

type LocalCompProps = {}

type CompProps = typeof stateProps & typeof mapDispatchToProps & LocalCompProps

type State = {}

class RegistrationContainer extends React.Component<CompProps, State> {
  render() {
    return (
      <ThemeProvider theme={this.props.processinstance.registrationEntity}>
        <React.Fragment>
          <GlobalStyle />
          <RegistrationForm />
        </React.Fragment>
      </ThemeProvider>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegistrationContainer)
)

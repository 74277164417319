import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import { FormattedMessage } from 'react-intl'

import LottieCompleteIndicator from '../LottieCompleteIndicator/LottieCompleteIndicator'

type ViewProps = {
  className?: string
}

const CompletionContainer = styled.div`
  width: 50%;
  height: 100vh;
  margin: 0 auto;
  overflow: scroll;
  .wrapper {
    position: relative;
    max-height: 100%;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1.5em;
    .lower {
      max-width: 700px;
      margin: -100px auto 0;
    }
  }
  @media screen and (max-width: 800px) {
    width: 95%;
  }
`
const SetupCompletePageSFC: React.FC<ViewProps> = props => {
  return (
    <Fragment>
      <CompletionContainer>
        <div className='wrapper'>
          <div className='upper'>
            <LottieCompleteIndicator />
          </div>
          <div className='lower'>
            <Animated isVisible={true} animationIn='bounceInUp' animationOut='bounceOutDown' style={{ opacity: 1 }}>
              <FormattedMessage id='page.setup.completion.text' />
            </Animated>
          </div>
        </div>
      </CompletionContainer>
    </Fragment>
  )
}

export default SetupCompletePageSFC

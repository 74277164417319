import { hotjar } from 'react-hotjar'
import constants from './constants'

const bsHeap = (heapAppId, heapUserId) => {
  /* eslint-disable no-unused-expressions */
  // eslint-disable-next-line
  ;(window.heap = window.heap || []),
    // eslint-disable-next-line
    (window.heap.load = function(e, t) {
      // eslint-disable-next-line
      ;(window.heap.appid = e), (window.heap.config = t = t || {})
      // eslint-disable-next-line
      var r = t.forceSSL || 'https:' === document.location.protocol,
        // eslint-disable-next-line
        a = document.createElement('script')
      // eslint-disable-next-line
      ;(a.type = 'text/javascript'), (a.async = !0), (a.src = (r ? 'https:' : 'http:') + '//cdn.heapanalytics.com/js/heap-' + e + '.js')
      var n = document.getElementsByTagName('script')[0]
      n.parentNode.insertBefore(a, n)
      for (
        var o = function(e) {
            return function() {
              window.heap.push([e].concat(Array.prototype.slice.call(arguments, 0)))
            }
          },
          p = [
            'addEventProperties',
            'addUserProperties',
            'clearEventProperties',
            'identify',
            'resetIdentity',
            'removeEventProperty',
            'setEventProperties',
            'track',
            'unsetEventProperty',
          ],
          c = 0;
        c < p.length;
        c++
      )
        window.heap[p[c]] = o(p[c])
    })
  window.heap.load(heapAppId)
  const HI = setInterval(() => {
    if (window.heap && window.heap.identify) {
      clearInterval(HI)
      window.heap.identify(heapUserId)
    }
  }, 500)
}

export default function bootstrapAnalytics(inviteToken) {
  if (process.env.NODE_ENV === 'production') {
    hotjar.initialize(constants.ANALYTICS.HOTJAR_APP_ID, 6)
    bsHeap(constants.ANALYTICS.HEAP_APP_ID, inviteToken)
  }
}

import React from 'react'
import { withRouter } from 'react-router-dom'
import ErrorPage from '~/app/components/ErrorPage'
import API from '~/API'
import { getProcessInstanceState } from '~/store/processinstance'
import { getReturnOfExpression } from 'utility-types'
import { connect } from 'react-redux'
import * as interfaces from '~/typing/KENAI/interfaces.d.ts'
import getUrlParameter from '~/utils/getUrlParameter'
import { getRegistrationInstanceState } from '~/store/registrationinstance'

const messages = {
  ERROR_TITLE: {
    id: 'page.error.text.title',
  },
  ERROR_MESSAGE: {
    id: 'page.error.text',
  },
}

const mapStateToProps = state => {
  return {
    processinstance: getProcessInstanceState(state),
    registrationinstance: getRegistrationInstanceState(state),
  }
}

const mapDispatchToProps = {}

const stateProps = getReturnOfExpression(mapStateToProps)

type LocalCompProps = {}

type CompProps = typeof stateProps & typeof mapDispatchToProps & LocalCompProps

type State = {
  titleText: interfaces.MESSAGE_STRUCTURE
  errorMessage: interfaces.MESSAGE_STRUCTURE
  messageTextProvided: boolean
}

const triggerArchive = async inviteToken => {
  try {
    await API.devArchiveProfileAndResetToken(inviteToken)
    alert('Archived and reset, reloading')
  } catch (e) {
    alert('Failed to archived and reset, reloading')
  }
  window.location.replace(window.location.href.replace(window.location.hash, ''))
}
class ErrorContainer extends React.Component<CompProps, State> {
  constructor(props) {
    super(props)
    this.state = {
      titleText: messages.ERROR_TITLE,
      errorMessage: messages.ERROR_MESSAGE,
      messageTextProvided: false,
    }
  }

  // tslint:disable-next-line:member-ordering
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.processinstance &&
      nextProps.processinstance.tokenErrorMessage &&
      nextProps.processinstance.tokenErrorMessage.id.length > 0 &&
      nextProps.processinstance.tokenErrorMessage.id !== prevState.errorMessage.id
    ) {
      return {
        titleText: {
          id: `${nextProps.processinstance.tokenErrorMessage.id}.title`,
        },
        errorMessage: nextProps.processinstance.tokenErrorMessage,
        messageTextProvided: false,
      }
    } else if (nextProps.processinstance && nextProps.processinstance.blockingPreviousSubmissionAlert) {
      return {
        titleText: nextProps.processinstance.blockingPreviousSubmissionAlert.headerText,
        errorMessage: nextProps.processinstance.blockingPreviousSubmissionAlert.bodyText,
        messageTextProvided: true,
      }
    } else if (nextProps.registrationinstance && nextProps.registrationinstance.profileSubmissionTriggeredAlert) {
      return {
        titleText: nextProps.registrationinstance.profileSubmissionTriggeredAlert.headerText,
        errorMessage: nextProps.registrationinstance.profileSubmissionTriggeredAlert.bodyText,
        messageTextProvided: true,
      }
    } else {
      return null
    }
  }

  componentDidMount() {
    if (window.location.search && window.location.search.indexOf('token=') > -1 && window.location.search.length > 7) {
      const inviteToken = getUrlParameter('token')
      if (inviteToken && inviteToken.indexOf('invite_token') > -1) {
        const shouldArchiveAndReset = window.confirm('Dev token detected - do you want to archive profiles and reset the token?')
        if (shouldArchiveAndReset) {
          triggerArchive(inviteToken)
        }
      }
    }
  }

  render() {
    if (this.state.messageTextProvided) {
      return <ErrorPage providedTitle={this.state.titleText as any} providedMessage={this.state.errorMessage as any} />
    } else if (this.state.errorMessage && this.state.errorMessage.id && this.state.errorMessage.id.length > 0) {
      return <ErrorPage titleText={this.state.titleText} errorMessage={this.state.errorMessage} />
    } else {
      return null
    }
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ErrorContainer)
)
